import { useEffect, useState } from 'react';
import save from '../../icons/save-admin-row-icon.svg'
import checker from '../../icons/selected-checker-icon.svg'
import upload from '../../icons/upload-logo-icon.svg'
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changeUser } from '../../features/users/userSlice';
import { convertImageToBase64viaFileReader, handlePostRequest } from './admin_page_functions';

const UsersTableRow = ({user, index, userToggle, setUpdateTrigger}) => {

  const  { users } = useSelector( state => state.users)
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [isChosen, setIsChosen] = useState(false)

  const [userName, setUserName] = useState(user.name);
  const [userEmail, setUserEmail] = useState(user.email);
  const [userComments, setUserComments] = useState(user.comments);
  const [userLogoUpload, setUserLogoUpload] = useState('');
  const [userLogo, setUserLogo] = useState({
    small: '',
    site: '',
    origin: ''
  });
  const [buttonText, setButtonText] = useState('Copy link');


  useEffect(()=>{
    setUserName(user.name)
    setUserEmail(user.email)
    setUserComments(user.comments)
    if(user.logo){
      setUserLogo({
        small: user.logo.small,
        site: user.logo.site,
        origin: user.logo.origin
      })
    }
  },[user])

  const imageUploadHandler = (e) => {
    setUserLogoUpload(e.target.files[0])
  }

  useEffect(() => {
    if (!userLogoUpload) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(userLogoUpload)
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      // Create resized images
      const canvasSmall = document.createElement('canvas');
      const canvasSite = document.createElement('canvas');

      const contextSmall = canvasSmall.getContext('2d');
      const contextSite = canvasSite.getContext('2d');

      canvasSmall.width = 36;
      canvasSmall.height = 36;
      contextSmall.drawImage(img, 0, 0, 36, 36);

      canvasSite.width = 120;
      canvasSite.height = 120;
      contextSite.drawImage(img, 0, 0, 120, 120);

      const base64Small = canvasSmall.toDataURL(userLogoUpload.type);
      const base64Site = canvasSite.toDataURL(userLogoUpload.type);

      convertImageToBase64viaFileReader(objectUrl, (base64Img) => {
        setUserLogo(prev => ({...prev, origin: base64Img}))
      })
      convertImageToBase64viaFileReader(base64Small, (base64Img) => {
        setUserLogo(prev => ({...prev, small: base64Img}))
      });
      convertImageToBase64viaFileReader(base64Site, (base64Img) => {
        setUserLogo(prev => ({...prev, site: base64Img}))
      })
      setIsEditing(true)
    };

    return () => {
      setUserLogoUpload(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [userLogoUpload])

  const userHandleUpdate = () => {
    if(userName === '' || userEmail === ''){
      toast.error('You have empty inputs !', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{ 
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(userEmail)){
        toast.error('Write a correct email !', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        })
      } else{
        const isUnicEmail = users.find(user => user.email === userEmail)
        if(isUnicEmail !== undefined && user.email !== userEmail){
          toast.error('This email is already in use !', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "light",
          })
        }else{
          if(user.logo && userLogo.origin !==''){
            if(userLogo.origin !== user.logo.origin){
            const userDataToFetch = {
              user_logo: userLogo.origin,
              user_email: userEmail,
              user_id: user._id,
              functionName: "saveLogo"
            }
            handlePostRequest(userDataToFetch)
            }
          }
          const updateData =  {...user, 
            name: userName,
            email: userEmail,
            comments: userComments,
            logo: userLogo,
          }
          dispatch(changeUser(updateData))
          setUpdateTrigger(true)
          setIsEditing(false)
        }
      }
    }
  };

  const choseHandler = (id) => {
    userToggle(id)
    setIsChosen(prev => !prev)
  }

  const editingHandler = (property, value) => {
    if(user[property] !== value){
      setIsEditing(true)
    } else{
      setIsEditing(false)
    }
  }

  const copyInvitationLink = (link) => {
    navigator.clipboard.writeText('https://majoto.app/register/'+link)
      .then(() => {
        setButtonText('Copied!');
        setTimeout(() => {
          setButtonText('Copy link');
        }, 2500); 
      })
      .catch(err => {
        console.error('Failed to copy link: ', err);
      });
  };

  return ( 
    <tr className={isChosen ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
      {isEditing ?
        <td className="admin-control-form-update-td">
          <img className="admin-control-form-update" 
          onClick={userHandleUpdate}
          src={save} alt="save"/>
        </td>
        :
        <td key={index} className="admin-control-space-selector" onClick={() => choseHandler(user._id)}>
          <div className={isChosen ? "admin-control-space-selected" : "admin-control-space-unselected"}>
            {isChosen && <img src={checker} alt='yes'/>}
          </div>
        </td>
      }
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={userName}
          onChange={(e) => {
            editingHandler('name', e.target.value);
            setUserName(e.target.value);
          }}/>
      </td>
      <td>
        {
          user.userStatus === 'Active' ?
          <>{user.email}</>
          :
          <input 
          type="text"
          className="admin-control-usual-row-input"
          value={userEmail}
          onChange={(e) => {
            editingHandler('email', e.target.value);
            setUserEmail(e.target.value);
          }}/>}
      </td>
      <td>{moment.utc(user.createdAt).format('D MMMM yyyy')}</td>
      <td>{user.userStatus !== 'Inactive' && user.lastAccess && moment.utc(user.lastAccess).format('D MMMM yyyy')}</td>
      <td>{user.userStatus}</td>
      <td>
        {
        user.invitationLink !== '' && user.userStatus !== 'Active' ? 
          <div className='copy-link-button' onClick={() => copyInvitationLink(user.invitationLink)}>
            {buttonText}
          </div>
          : 
          ''
        }
      </td>
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={userComments}
          onChange={(e) => {
            editingHandler('comments', e.target.value);
            setUserComments(e.target.value);
          }}/>
      </td>
      <td className="admin-control-form-upload-td">
        <label className="admin-control-form-upload-label" htmlFor={'upload_image'+user._id}>
          {userLogo.small !== '' ? 
            <img className="admin-control-form-upload-logo" src={userLogo.small} alt="Small Logo" />
            :
            <img className="admin-control-form-upload" src={upload} alt="upload"/>
          } 
        </label>
        <input id={'upload_image'+user._id} onChange={(e) => {imageUploadHandler(e)}} 
        className="admin-control-form-upload-input" type="file" accept="image/*"/>
      </td>
    </tr>
   );
}
 
export default UsersTableRow;
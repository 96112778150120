import React, { useState, useEffect, useRef, useCallback } from 'react';

const AgreementsTilesScrollbar = ({
  children,
  className,
  ...props}) => {
  const contentRef = useRef(null);
  const scrollTrackRef = useRef(null);
  const scrollThumbRef = useRef(null);
  const observer = useRef(null);
  const [isVisible, setIsVisible] = useState(true);
  const [thumbWidth, setThumbWidth] = useState(40);
  const [scrollStartPosition, setScrollStartPosition] = useState(null);
  const [initialScrollLeft, setInitialScrollLeft] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  function handleResize(ref, trackSize) {
    const { clientWidth, scrollWidth } = ref;
    const isOverflow = clientWidth < scrollWidth
    //setIsVisible(prev => isOverflow)
    setThumbWidth(Math.max((clientWidth / scrollWidth) * trackSize, 40))
  }

  const useResize = (ref) => {
    const [width, setWidth] = useState(0)
    const handleChanges = () => {
      setWidth(prev => contentRef.current.scrollWidth)
    }
    useEffect(() => {
      ref.current && ref.current.addEventListener('mouseover', handleChanges)
      return () => {
        ref.current && ref.current.removeEventListener('mouseover', handleChanges)
      }
    }, [ref])
    return {width}
  }

  const {width} = useResize(contentRef)

  const handleTrackClick = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      const { current: trackCurrent } = scrollTrackRef;
      const { current: contentCurrent } = contentRef;
      if (trackCurrent && contentCurrent) {
        const { clientX } = e;
        const target = e.target;
        const rect = target.getBoundingClientRect();
        const trackLeft = rect.left;
        const thumbOffset = -(thumbWidth / 2);
        const clickRatio =
          (clientX - trackLeft + thumbOffset) / trackCurrent.clientWidth;
        const scrollAmount = Math.floor(
          clickRatio * contentCurrent.scrollWidth
        );
        contentCurrent.scrollTo({
          left: scrollAmount,
          behavior: 'smooth',
        });
      }
    },
    [thumbWidth]
  );

  const handleThumbPosition = useCallback(() => {
    if (
      !contentRef.current ||
      !scrollTrackRef.current ||
      !scrollThumbRef.current
    ) {
      return;
    }
    const { scrollLeft: contentLeft, scrollWidth: contentWidth } =
      contentRef.current;
    const { clientWidth: trackWidth } = scrollTrackRef.current;
    let newLeft = (+contentLeft / +contentWidth) * trackWidth;
    newLeft = Math.min(newLeft, trackWidth - thumbWidth);
    const thumb = scrollThumbRef.current;
    thumb.style.left = `${newLeft}px`;
  }, []);

  const handleThumbMousedown = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setScrollStartPosition(e.clientX);
    if (contentRef.current) setInitialScrollLeft(contentRef.current.scrollLeft);
    setIsDragging(true);
  }, []);

  const handleThumbMouseup = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        setIsDragging(false);
      }
    },
    [isDragging]
  );

  const handleThumbMousemove = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (isDragging) {
        const {
          scrollWidth: contentScrollWidth,
          offsetWidth: contentOffsetWidth,
        } = contentRef.current;

        const deltaX =
          (e.clientX - scrollStartPosition) *
          (contentOffsetWidth / thumbWidth);
        const newScrollLeft = Math.min(
          initialScrollLeft + deltaX,
          contentScrollWidth - contentOffsetWidth
        );

        contentRef.current.scrollLeft = newScrollLeft;
      }
    },
    [isDragging, scrollStartPosition, thumbWidth]
  );

  // If the content and the scrollbar track exist, use a ResizeObserver to adjust width of thumb and listen for scroll event to move the thumb
  useEffect(() => {
    if (contentRef.current && scrollTrackRef.current) {
      const ref = contentRef.current;
      const { clientWidth: trackSize } = scrollTrackRef.current;
      observer.current = new ResizeObserver(() => {
        handleResize(ref, trackSize);
      });
      observer.current.observe(ref);
      ref.addEventListener('scroll', handleThumbPosition);
      return () => {
        observer.current?.unobserve(ref);
        ref.removeEventListener('scroll', handleThumbPosition);
      };
    }
  }, [width])

  // Listen for mouse events to handle scrolling by dragging the thumb
  useEffect(() => {
    document.addEventListener('mousemove', handleThumbMousemove);
    document.addEventListener('mouseup', handleThumbMouseup);
    document.addEventListener('mouseleave', handleThumbMouseup);
    return () => {
      document.removeEventListener('mousemove', handleThumbMousemove);
      document.removeEventListener('mouseup', handleThumbMouseup);
      document.removeEventListener('mouseleave', handleThumbMouseup);
    };
  }, [handleThumbMousemove, handleThumbMouseup]);

  return (
    <div className="agreements-scrollbar__container">
      <div className="agreements-scrollbar__content" ref={contentRef} {...props}>
        {children}
      </div>
      <div className="agreements-scrollbar__scrollbar" style={{visibility: !isVisible && 'hidden'}}>
        <div className="agreements-scrollbar__track-and-thumb" style={{visibility: !isVisible && 'hidden'}} >
          <div className="agreements-scrollbar__track"
            ref={scrollTrackRef}
            onClick={handleTrackClick}
            style={{cursor: isDragging && 'grabbing'}}
          />
          <div className="agreements-scrollbar__thumb"
            ref={scrollThumbRef}
            onMouseDown={handleThumbMousedown}
            style={{
              width: `${thumbWidth}px`,
              cursor: isDragging ? 'grabbing' : 'grab',
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default AgreementsTilesScrollbar;

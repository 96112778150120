import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


export const useParentHight = (componentData, typeOfParent, columnData) => {
  const {editingDocument} = useSelector(state => state.editingDocument)

  const [componentParentHeight, setComponentParentHeight] = useState()

  const findChosenComponent = (data, typeOfParent, columnData) => {
    if(typeOfParent === 'module' && columnData === undefined){
      const parentIndex = editingDocument.body.findIndex(part => part.id === data.parent)
      //console.log(editingDocument.body[parentIndex].content.find(part => part.position === data.position))
      const componentParentStyle = editingDocument.body[parentIndex].style
      setComponentParentHeight(parseInt(componentParentStyle.height))
      if(componentParentStyle.padding){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.padding.split(' ')))
      }
      if(componentParentStyle.paddingTop){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingTop))
      }
      if(componentParentStyle.paddingBottom){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingBottom))
      }
    }
    if(typeOfParent === 'module' && columnData !== undefined){
      const indexOfModule = editingDocument.body.findIndex(part => part.id === columnData.parent)
      const indexOfColHolder = editingDocument.body[indexOfModule].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
      const componentParentStyle = editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].style
    }
    if(typeOfParent === 'level1' && columnData === undefined){
      const indexOfModule = editingDocument.body.findIndex( module => 
      -1 !== module.content.findIndex( part => part.position === data.parent))
      const indexOfLevel1 = editingDocument.body[indexOfModule].content.findIndex(part => part.position === data.parent)
      const componentParentStyle = editingDocument.body[indexOfModule].content[indexOfLevel1].style
      setComponentParentHeight(parseInt(componentParentStyle.height))
      if(componentParentStyle.padding){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.padding.split(' ')))
      }
      if(componentParentStyle.paddingTop){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingTop))
      }
      if(componentParentStyle.paddingBottom){
        setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingBottom))
      }
    }
    if(typeOfParent === 'level1' && columnData !== undefined){
      const indexOfModule = editingDocument.body.findIndex( module => 
      -1 !== module.content.findIndex( part => part.position === columnData.parent))
      const indexOfLevel1 = editingDocument.body[indexOfModule].content.findIndex(part => part.position === columnData.parent)
      const indexOfColHolder = editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
      
    }
    if(typeOfParent === 'level2' && columnData === undefined){
      const indexOfModule = editingDocument.body.findIndex( module => 
        -1 !== module.content.findIndex( level => level.typeOfContent === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === data.parent)))
      const indexOfLevel1 = editingDocument.body[indexOfModule].content.findIndex( level => level.typeOfContent === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === data.parent))
      const indexOfLevel2 = editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === data.parent)
        const componentParentStyle = editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style
        setComponentParentHeight(parseInt(componentParentStyle.height))
        if(componentParentStyle.padding){
          setComponentParentHeight(prev => prev - parseInt(componentParentStyle.padding.split(' ')))
        }
        if(componentParentStyle.paddingTop){
          setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingTop))
        }
        if(componentParentStyle.paddingBottom){
          setComponentParentHeight(prev => prev - parseInt(componentParentStyle.paddingBottom))
        }
      }
    if(typeOfParent === 'level2' && columnData !== undefined){
      const indexOfModule = editingDocument.body.findIndex( module => 
        -1 !== module.content.findIndex( level => level.typeOfContent === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === columnData.parent)))
      const indexOfLevel1 = editingDocument.body[indexOfModule].content.findIndex( level => level.typeOfContent === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === columnData.parent))
      const indexOfLevel2 = editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === columnData.parent)
      const indexOfColHolder = editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
    }
  }

  useEffect(() => { 
    findChosenComponent(componentData, typeOfParent, columnData)
  }, [])

  useEffect(() => { 
    findChosenComponent(componentData, typeOfParent, columnData)
    //console.log(componentParentHeight)
    return () => {
      setComponentParentHeight('');
    };
  }, [editingDocument])

  return [componentParentHeight];
}
import { useDispatch } from 'react-redux'
import { deleteDocument } from "../features/documents/documentSlice"
import { removeActiveDocument } from "../features/localDocument/editingDocumentSlice"
import closeIcon from "../icons/right-panel-close.svg"
import useOnclickOutside from 'react-cool-onclickoutside'
import { useLocation, useNavigate } from 'react-router-dom'

const DeleteDocumentForm = ({active, setActive, doc, setUpdate}) => {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
    
  const ref = useOnclickOutside(() => {
    active && setActive(prev => false);
  })

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(removeActiveDocument(doc))
    dispatch(deleteDocument(doc._id))

    location.pathname !== '/launch/templates' && navigate('/launch/templates')
    setActive(false)
    setUpdate(prev => true)
  }
  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
      <div ref={ref} className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
        <div className="form-header">
          <div >Do you want to delete this document ?</div>
          <div className="close_button" onClick={() => setActive(false)}><img src={closeIcon} alt="close"/></div> 
        </div>
        <form className="style-form" onSubmit={onSubmit}>
          <button className="form-bottom-button" type='submit'>
            Delete 
          </button>
        </form>
      </div>
    </div>
  );
}
 
export default DeleteDocumentForm;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import plus from '../../icons/plus-grey-icon.svg'
import save from '../../icons/save-admin-row-icon.svg'
import upload from '../../icons/upload-logo-icon.svg'
import close from '../../icons/right-panel-close.svg'
import moment from "moment";
import { v4 as uuidv4 } from 'uuid';
import 'react-datepicker/dist/react-datepicker.css';
import { createUser } from "../../features/users/userSlice";
import UsersTableRow from "./UsersTableRow";
import { convertImageToBase64viaFileReader } from "./admin_page_functions";

const UsersTableBody = ({tableData, setUserUpdateTrigger, userToggle}) => {

  const dispatch = useDispatch()

  const [usersData, setUsersData] = useState([])

  useEffect(() => {
    setUsersData(tableData)
  }, [tableData]);

  const [userName, setUserName] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userComments, setUserComments] = useState('');
  const [userLogoUpload, setUserLogoUpload] = useState('');
  const [userLogo, setUserLogo] = useState({
    small: '',
    site: '',
    origin: ''
  });

  const imageUploadHandler = (e) => {
    setUserLogoUpload(e.target.files[0])
  }

  useEffect(() => {
    if (!userLogoUpload) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(userLogoUpload)
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      // Create resized images
      const canvasSmall = document.createElement('canvas');
      const canvasSite = document.createElement('canvas');

      const contextSmall = canvasSmall.getContext('2d');
      const contextSite = canvasSite.getContext('2d');

      canvasSmall.width = 36;
      canvasSmall.height = 36;
      contextSmall.drawImage(img, 0, 0, 36, 36);

      canvasSite.width = 120;
      canvasSite.height = 120;
      contextSite.drawImage(img, 0, 0, 120, 120);

      // Convert images to base64
      const base64Small = canvasSmall.toDataURL(userLogoUpload.type);
      const base64Site = canvasSite.toDataURL(userLogoUpload.type);

      // Update logo object
      convertImageToBase64viaFileReader(objectUrl, (base64Img) => {
        setUserLogo(prev => ({...prev, origin: base64Img}))
      })
      convertImageToBase64viaFileReader(base64Small, (base64Img) => {
        setUserLogo(prev => ({...prev, small: base64Img}))
      });
      convertImageToBase64viaFileReader(base64Site, (base64Img) => {
        setUserLogo(prev => ({...prev, site: base64Img}))
      })
    };

    // free memory when ever this component is unmounted
    return () => {
      setUserLogoUpload(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [userLogoUpload])

  const [isUserAdding, setIsUserAdding] = useState(false)
  const [userSavingStatus, setUserSavingStatus] = useState(false)
  
  const userHandleSave = () => {
    if(userName === '' || userEmail === ''){
      toast.error('Fill all inputs !', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
      setUserSavingStatus('Please, fill all fields')
    }else{ 
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if(!emailRegex.test(userEmail)){
        toast.error('Write a correct email !', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        })
      } else{
        const invitationLink = uuidv4();
        setUserSavingStatus('Saving a new customer space')
        const userData = {
          name: userName,
          email: userEmail,
          password:'12345678',
          role: 'user',
          isRegistered: false,
          invitationLink: invitationLink,
          comments: userComments,
          logo: userLogo,
        };
        const emailData = {
          user_name: userName,
          user_email: userEmail,
          user_link: invitationLink,
        }
        toast.promise(
          dispatch(createUser(userData)),
          {
            pending: 'Saving..',
            success: 'Saved new user',
            error: 'Promise rejected 🤯'
          }
        )
        setUserUpdateTrigger(true)
        setIsUserAdding(false)
        setUserName('')
        setUserEmail('')
        setUserComments('')
      }
    }
  };

  return ( 
    <tbody>
    { usersData.length !== 0 ? 
        <>
      {usersData.filter(user => user.userStatus !== 'archive').sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((user, index) => 
        (<UsersTableRow key={index} user={user} index={index} 
          userToggle={userToggle} 
          setUpdateTrigger={setUserUpdateTrigger}/>)
      )}
      {
        !isUserAdding ?
        <tr className="admin-control-usual-row"> 
          <td className="admin-control-space-selector" onClick={() => setIsUserAdding(true)}>
            <div className="admin-control-adding-row">
              <img src={plus} alt='yes'/>
            </div>
          </td>
        </tr>
        :
        <tr className="admin-control-usual-row">
        <td className="admin-control-form-save-td">
          <img className="admin-control-form-save" 
          onClick={userHandleSave}
          src={save} alt="save"/>
        </td>
        <td>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </td>
        <td>
          <input
            type="text"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </td>
        <td>
          {moment.utc(new Date()).format('D MMMM yyyy')}
        </td>
        <td> </td>
        <td>
          New
        </td>
        <td> </td>
        <td>
          <input
            type="text"
            value={userComments}
            onChange={(e) => setUserComments(e.target.value)}
          />
        </td>
        <td className="admin-control-form-upload-td">
          {/* <label className="admin-control-form-upload-label" htmlFor="upload_image">
            {userLogo.small ? 
              <img className="admin-control-form-upload-logo" src={userLogo.small} alt="Small Logo" />
              :
              <img className="admin-control-form-upload" src={upload} alt="upload"/>
            } 
          </label>
          <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
          className="admin-control-form-upload-input" type="file" accept="image/*"/> */}
        </td>
        <td>
          <img className="admin-control-form-close" 
          onClick={() => setIsUserAdding(false)}
          src={close} alt="close"/>
        </td>
      </tr>
      }
      </>
      : 
      <tr className="admin-control-usual-row">
        <td className="admin-control-form-save-td">
          <img className="admin-control-form-save" 
          onClick={userHandleSave}
          src={save} alt="save"/>
        </td>
        <td>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            required
          />
        </td>
        <td>
          <input
            type="text"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            required
          />
        </td>
        <td>
          {moment.utc(new Date()).format('D MMMM yyyy')} 
        </td>
        <td> </td>
        <td> 
          New
        </td>
        <td> </td>
        <td>
          <input
            type="text"
            value={userComments}
            onChange={(e) => setUserComments(e.target.value)}
          />
        </td>
        <td className="admin-control-form-upload-td">
          {/* <label className="admin-control-form-upload-label" htmlFor="upload_image">
            {userLogo.small ? 
              <img className="admin-control-form-upload-logo" src={userLogo.small} alt="Small Logo" />
              :
              <img className="admin-control-form-upload" src={upload} alt="upload"/>
            } 
          </label>
          <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
          className="admin-control-form-upload-input" type="file" accept="image/*"/> */}
        </td>
        <td>
          <img className="admin-control-form-close" 
          onClick={() => setIsUserAdding(false)}
          src={close} alt="close"/>
        </td>
      </tr> 
      }
    </tbody>
   );
}
 
export default UsersTableBody;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner";
import { getAgreements, changeAgreement } from "../../features/agreements/agreementSlice";
import AgreementsListTable from './AgreementsListTable'
import DeleteAgreementForm from './DeleteAgreementForm'

const AgreementsList = ({ }) => {

  const [ chosenAgreement, setChosenAgreement] = useState('null')
  const [ selectedAgrements, setSelectedAgreements] = useState([])
  const [ choser, setChoser] = useState('all')
  const [selectedNum, setSelectedNum] = useState(0)
  const [deleteForm, setDeleteForm] = useState(false)


  const [updateTrigger, setUpdateTrigger] = useState(false)
  const dispatch = useDispatch()

  const { agreements, isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )

  const deleteAgreements = () =>{ 
    setDeleteForm(prev=>true);  
  }

  const pinAgreements = () =>{
    if(selectedAgrements.length !== 0){
      selectedAgrements.forEach(agreement => {
        let newAgreement = {...agreement, isPinned: !agreement.isPinned}
        dispatch(changeAgreement(newAgreement))
        setUpdateTrigger(prev=> true)
        setSelectedAgreements(prev => [])
      })
    }
  }

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }
  }, [isError, message, dispatch])

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getAgreements())
        setSelectedNum(prev=> 0)
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

	return (
    <>
    <div className='agreements-list'>
      <div className='agreements-list-choser'>
        <div className={choser === 'all' ? 'agreements-list-choser-all-chosen' : 'agreements-list-choser-all'}
        onClick={() => setChoser(prev=> 'all')}>
          All
        </div>
        <div className={choser === 'archived' ? 'agreements-list-choser-archived-chosen' : 'agreements-list-choser-archived'}
        onClick={() => setChoser(prev=> 'archived')}>
          Archived
        </div>
      </div>
      <div className="agreements-list-body">
        <div className="agreements-list-tittle">
          <div className="agreements-list-selected-num">
            {selectedNum} selected
          </div>
          <div className="agreements-list-options">
            <div className="agreements-list-option">
              Open
            </div>
            <div className="agreements-list-option" onClick={() => pinAgreements()}>
              Pin
            </div>
            <div className="agreements-list-option-delete" onClick={() => deleteAgreements()}>
              Delete
            </div>
          </div>
        </div>
        <>
          {isLoading || updateTrigger ? <Spinner /> : agreements.length !== 0 ? 
              <AgreementsListTable data={agreements}
              selectedNum={selectedNum} setSelectedNum={setSelectedNum}
              setSelectedAgreements={setSelectedAgreements}
              chosenAgreement={chosenAgreement} setChosenAgreement={setChosenAgreement}/>
            : 
            <div className="agreements-list-empty">
              There are any agreements yet. Create one.
            </div> 
          }
        </>
      </div>
    </div>
    <DeleteAgreementForm active={deleteForm} setActive={setDeleteForm} 
    agreements={selectedAgrements} setUpdate={setUpdateTrigger}/>
    </>
		);
	}
 
export default AgreementsList;
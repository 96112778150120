import { useState } from "react"
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { createDocument, updateDocument } from "../features/documents/documentSlice"
import closeIcon from "../icons/right-panel-close.svg"

const details = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0',
  pointerEvents: 'none',
  transition: '0.2s',
  zIndex:'10',
}
const form_input ={
  padding: '10px',
  border:' 1px solid #e6e6e6',
  borderRadius: '5px',
  marginBottom: '10px',
}

const DocumentSavingForm = ({
  active,
  setActive,
}) => {
  
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { editingDocument } = useSelector((state) => state.editingDocument)
  const [name, setName] = useState(editingDocument.name + ' copy')

  const onSubmit = (e) => {
    e.preventDefault()
    let document = {
      name:'',
      documentBody: [],
      documentClasses: {},
    }
    document.name = name;
    document.documentBody = editingDocument.body;
    document.documentClasses = editingDocument.classes;
    console.log("Document ready to save: ", document)
    dispatch(createDocument(document))
    setName('')
    // if(editingDocument._id === 'new'){   
    //   let document = {
    //     name:'',
    //     documentBody: []
    //   }
    //   document.name = name;
    //   document.documentBody = editingDocument.body;
    //   console.log("Document ready to save: ", document)
    //   dispatch(createDocument(document))
    //   setName('')

    // } else{
    //   dispatch(updateDocument(editingDocument))
    // }
    setActive(prev=> false)
  }

  return (
    <div style={active ? { ...details, opacity: '1', pointerEvents: 'all'} : details}>
      <div className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
        <div className="form-header">
          <div>Save as a new template</div>
          <div className="close_button" onClick={() => setActive(prev=> false)}>
            <img src={closeIcon} alt="close"/>
          </div> 
        </div>
        <form className="style-form" onSubmit={onSubmit}>
          <div style={{marginBottom:'10px'}}>
            <label style={{display: 'block', marginBottom:'10px'}}>Name of template :</label>
            <input
              style={form_input}
              type='text'
              name='name'
              id='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="operation-form-buttons">
            <button className="operation-form-save-button" type='submit'>
              Save 
            </button>
            <div onClick={() => setActive(prev=> false)} className="operation-form-cancel-button">
              Cancel 
            </div>
          </div>
        </form>
        {/* { editingDocument._id === 'new' ?
        <>
          <div className="form-header">
            <div>Save a new document before leaving</div>
            <div className="close_button" onClick={() => cancelNavigation(true)}><img src={closeIcon} alt="close"/></div> 
          </div>
          <form className="style-form" onSubmit={onSubmit}>
            <div style={{marginBottom:'10px'}}>
              <label style={{display: 'block', marginBottom:'10px'}}>Name of template :</label>
              <input
                style={form_input}
                type='text'
                name='name'
                id='name'
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <button className="form-bottom-button" type='submit'>
              Save 
            </button>
            <button onClick={() => {cancelNavigation(false)}} className="form-bottom-button">
              Don`t save 
            </button>
          </form>
        </>
        : 
        <>
          <div className="form-header">
            <div>Save a document before living ?</div>
            <div className="close_button" onClick={() => cancelNavigation(true)}><img src={closeIcon} alt="close"/></div> 
          </div>
          <form className="style-form" onSubmit={onSubmit}>
            <button className="form-bottom-button" type='submit'>
              Save 
            </button>
            <button onClick={() => cancelNavigation(false)} className="form-bottom-button">
              Don`t save 
            </button>
          </form>
        </>
        } */}
      </div>
    </div>
  )
}
export default DocumentSavingForm
import { useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setChosenComponent } from "../../../features/localDocument/editingDocumentSlice";
import { useDeleteComponent } from "./deleteComponentFromTemplate";

const Line = ({part, index, 
  parentData, columnData, parentType,  
  setStylingPanel, setStylingComponent,
  moveChildFromParent }) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    positionOfParent: parentData,
    typeOfParent: parentType,
    columnData: columnData,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setStylingPanel
  });

  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }

  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({ ...part.style, width: '100%', position: 'relative',}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style, width: '100%', position: 'relative',}))
  }, [part.style])


  const ref = useRef(null)
  useEffect(() => {
    const onLineClick = (event) => {
      console.log('clicked on line')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([part, parentData, parentType, columnData]);
      setStylingPanel(prev=> true)
    }
  
    const lineRef = ref.current;
    lineRef.addEventListener("click", onLineClick);
  
    return () => {
      lineRef.removeEventListener("click", onLineClick);
    };
  }, [])
  const [{ }, drop] = useDrop({
    accept: ['part'],
    drop( ){ 
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(columnData !== undefined && item.columnData !== undefined){
        if(columnData.column === item.columnData.column){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }else{
        if(item.part !== undefined && item.parentType === parentType){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType, columnData }
    },
    collect: (smonitor) => ({
      isDragging: smonitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div style={{...style, opacity}} ref={ref} 
    onKeyDown={keyPressHandler}
    tabIndex="0"/>
  )
}

export default Line
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import StyleEditorHeader from "../components/style_editor_components/StyleEditorHeader";
import StyleEditor from "../components/style_editor_components/StyleEditor";
import ShortMenu from "../components/ShortMenu";
import { addActiveStyleDocument, removeActiveStyleDocument, setEditingStyleDoc} from "../features/localDocument/editingStyleDocumentSlice";
import { getStyles, updateStyle } from "../features/styles/styleSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import DeleteStyleDocForm from "../components/style_editor_components/DeleteStyleDocForm";
import StyleDocumentSavingForm from "../components/StyleDocumentSavingForm";
import { getStyleEditorPanels, updateStyleEditorPanel } from "../features/styleEditorPanel/styleEditorPanelSlice";

const StyleEditorPage = () => {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {user} = useSelector(state => state.auth)
	const [saveForm , setSaveForm] = useState(false)
  const [deleteForm, setDeleteForm] = useState(false)
  const [closeTrigger, setCloseTrigger] = useState(false)
  const [saveTrigger, setSaveTrigger] = useState(false)
	const [viewEditorMode, setViewEditorMode] = useState(false)

	const { styles, isLoadingStyle, isErrorStyle, messageStyle } = useSelector(
    (state) => state.styles
  )
	const {styleEditorPanel, isLoadingStyleEditorPanel } =  useSelector(
    state => state.styleEditorPanel)
	const { editingStyleDocument } = useSelector(state => state.editingStyleDocument )
	const {id, stage} = useParams()

	useEffect(() => {
    dispatch(getStyles())
    dispatch(getStyleEditorPanels())
	}, [])

	const saveDocument = () => {
		console.log('saving gooo')
		dispatch(updateStyle(editingStyleDocument))
		//toast.success("Saved !", { toastId: 'success1', autoClose: 2000 })
    setSaveTrigger(false)
  }
	const closeDocument = () => {
    dispatch(removeActiveStyleDocument(editingStyleDocument))
    setCloseTrigger(false)
    navigate('/launch/templates')
  }

	//setting editingStyleDocument function
	const choosingDocument = (id) => {
    const chosenStyle = styles.filter(doc => doc._id === id)[0]

		if(chosenStyle !== undefined){
			dispatch(setEditingStyleDoc(chosenStyle))
			dispatch(addActiveStyleDocument([chosenStyle, 'style']))
		}
	}

	useEffect(() => {
		if(closeTrigger){
			closeDocument()}
	},[closeTrigger])

	useEffect(() => {
		if(saveTrigger){
			saveDocument()}
	},[saveTrigger])

	useEffect(() => {
    if (isErrorStyle) {
      console.log("Error: "+messageStyle)
    }
    if (!user) {
      navigate('/login')
    }

  }, [user, navigate, isErrorStyle, messageStyle, dispatch])

	//setting editingStyleDocument while isLoading
	useEffect(() => {
		!isLoadingStyle && choosingDocument(id)
	}, [isLoadingStyle, id])

	//updating state of editor panel only when view-editor mode is On
	useEffect(() => {
		if(viewEditorMode){
			dispatch(updateStyleEditorPanel(styleEditorPanel))
		}
	}, [styleEditorPanel])

	//realtime updating document in db 
	useEffect(() => {
    if(editingStyleDocument._id !== undefined){
			const chosenStyle = styles.filter(doc => doc._id === id)[0]
			if(chosenStyle){
				if(chosenStyle.body !== editingStyleDocument.body && chosenStyle !== undefined && editingStyleDocument._id === id){
					saveDocument()
					console.log("we are updating tjis" ,chosenStyle, editingStyleDocument)
				}
			}
    }
  }, [editingStyleDocument])

	return ( 
		<div className='content-board'>
			{ isLoadingStyle || isLoadingStyleEditorPanel ? <Spinner/> 
			: user ? 
			editingStyleDocument && styleEditorPanel &&
			<>
				<ShortMenu chosenPage={id}/>
				<div className='board-body'>
					<StyleDocumentSavingForm active={saveForm} setActive={setSaveForm}/>
					<DeleteStyleDocForm active={deleteForm} setActive={setDeleteForm} 
						doc={editingStyleDocument} setUpdate={()=> {}}/> 
					<StyleEditorHeader editingStyleDocument={editingStyleDocument}
						documentData={{name: editingStyleDocument.name, date: editingStyleDocument.updatedAt}} 
						setSaveStyleForm={setSaveForm} setDeleteForm={setDeleteForm}
						viewEditorMode={viewEditorMode} setViewEditorMode={setViewEditorMode}
						setCloseDoc={setCloseTrigger}/> 
						{
							stage === 'design' ?
							<StyleEditor editingStyleDocument={editingStyleDocument} viewEditorMode={viewEditorMode} styleEditorPanel={styleEditorPanel}/> 
							:
							<></>
						}
						
				</div>
			</>
			:
			<div className="message">Log in to get access to data</div>}
		</div>
		);
}
 
export default StyleEditorPage;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import styleService from './styleService'

const initialState = {
  styles: [],
  newStyle: '',
  isErrorStyleStyle: false,
  isSuccessStyle: false,
  isLoadingStyle: false,
  messageStyle: '',
}

// Create new style
export const createStyle = createAsyncThunk(
  'styles/create',
  async (styleData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await styleService.createStyle(styleData, token)
    } catch (error) {
      const messageStyle =
        (error.response &&
          error.response.data &&
          error.response.data.messageStyle) ||
        error.messageStyle ||
        error.toString()
      return thunkAPI.rejectWithValue(messageStyle)
    }
  }
)

// Get user styles
export const getStyles = createAsyncThunk(
  'styles/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await styleService.getStyles(token)
    } catch (error) {
      const messageStyle =
        (error.response &&
          error.response.data &&
          error.response.data.messageStyle) ||
        error.messageStyle ||
        error.toString()
      return thunkAPI.rejectWithValue(messageStyle)
    }
  }
)
// Update style
export const updateStyle = createAsyncThunk(
  'styles/update',
  async (style,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await styleService.updateStyle(style,  token)
    } catch (error) {
      const messageStyle =
        (error.response &&
          error.response.data &&
          error.response.data.messageStyle) ||
        error.messageStyle ||
        error.toString()
      return thunkAPI.rejectWithValue(messageStyle)
    }
  }
)

// Delete user style
export const deleteStyle = createAsyncThunk(
  'styles/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await styleService.deleteStyle(id, token)
    } catch (error) {
      const messageStyle =
        (error.response &&
          error.response.data &&
          error.response.data.messageStyle) ||
        error.messageStyle ||
        error.toString()
      return thunkAPI.rejectWithValue(messageStyle)
    }
  }
)

export const styleSlice = createSlice({
  name: 'style',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStyle.pending, (state) => {
        state.isLoadingStyle = true
      })
      .addCase(createStyle.fulfilled, (state, action) => {
        state.isLoadingStyle = false
        state.isSuccessStyle = true
        state.styles.push(action.payload)
        state.newStyle = action.payload
      })
      .addCase(createStyle.rejected, (state, action) => {
        state.isLoadingStyle = false
        state.isErrorStyle = true
        state.messageStyle = action.payload
      })
      .addCase(getStyles.pending, (state) => {
        state.isLoadingStyle = true
      })
      .addCase(getStyles.fulfilled, (state, action) => {
        state.isLoadingStyle = false
        state.isSuccessStyle = true
        state.styles = action.payload
      })
      .addCase(getStyles.rejected, (state, action) => {
        state.isLoadingStyle = false
        state.isErrorStyle = true
        state.messageStyle = action.payload
      })
      .addCase(updateStyle.pending, (state) => {
        //state.isLoadingStyle = true
      })
      .addCase(updateStyle.fulfilled, (state, action) => {
        state.isLoadingStyle = false
        state.isSuccessStyle = true
        //const indexDoc = state.styles.findIndex((element) => element._id === action.payload.styleId)
        //state.styles[indexDoc].body = action.payload.styleBody
      })
      .addCase(updateStyle.rejected, (state, action) => {
        //state.isLoadingStyle = false
        state.isErrorStyle = true
        state.messageStyle = action.payload
      })
      .addCase(deleteStyle.pending, (state) => {
        state.isLoadingStyle = true
      })
      .addCase(deleteStyle.fulfilled, (state, action) => {
        state.isLoadingStyle = false
        state.isSuccessStyle = true
        state.styles = state.styles.filter(
          (style) => style._id !== action.payload.id
        )
      })
      .addCase(deleteStyle.rejected, (state, action) => {
        state.isLoadingStyle = false
        state.isErrorStyle = true
        state.messageStyle = action.payload
      })
  },
})

export const { reset } = styleSlice.actions
export default styleSlice.reducer
import { useCallback, useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setChosenComponent, 
  addComponentInModuleColumnStyleDoc, addComponentInLevel1ColumnStyleDoc, addComponentInLevel2ColumnStyleDoc 
} from "../../../features/localDocument/editingStyleDocumentSlice";
import StyleHeading from "./StyleHeading";
import StyleSubHeading from "./StyleSubHeading";
import StyleTextArea from "./StyleTextArea";
import StyleLevel1 from "./StyleLevel1";
import StyleLevel2 from "./StyleLevel2";
import StyleLevel3 from "./StyleLevel3";
import StyleColumnsHolder from "./StyleColumnsHolder";
import StyleLine from "./StyleLine";
import StyleImageHolder from "./StyleImageHolder";

const StyleDynamicPart = ({part, index, moduleId,
  changeClickability,
  parentData, holderData, parentType, 
  setStylingPanel, setStylingComponent,
  moveChildFromParent }) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()


  const [style, setStyle] = useState({})
  const [partSize, setPartSize] = useState({})

  const [isResizingOver, setIsResizingOver] = useState(true)

  const changePartClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }

  useEffect(()=> {
    setStyle(prev => ({ ...part.style, width: '100%', height: '100%'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({ ...part.style, width: '100%', height: '100%'}))
  }, [ part.style])

  useEffect(() => {
    const resizableComponent = refBox.current;
    setPartSize(prev => ({
      width: resizableComponent.offsetWidth - 2*parseInt(part.style.padding),
      height: resizableComponent.offsetHeight - 2*parseInt(part.style.padding)
    }))
  },[dispatch, style])

  const ref = useRef(null)
  const refBox = useRef(null);
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'level2','columns_holder'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        if(item.type !== undefined && item.type !== 'columns_holder' 
        && (item.parentType === undefined || item.parentType === 'column')){
          if(parentType === 'module'){
            dispatch(addComponentInModuleColumnStyleDoc([ item, moduleId, holderData, part.position]))
          }
          if(parentType === 'level1'){
            dispatch(addComponentInLevel1ColumnStyleDoc([ item, moduleId, holderData, part.position]))
          }
          if(parentType === 'level2'){
            dispatch(addComponentInLevel2ColumnStyleDoc([ item, moduleId, parentData, holderData, part.position]))
          }
        }
      }
    },
  })
  drop(ref)
  const hoverRes = isOverCurrent

  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
    //   (child.part === undefined && child.parent === part.position) &&
    // dispatch(updatingComponentsOrderInLevel1(
    //   [dragIndex, hoverIndex, moduleId, part.position, child ]))
  }, []) 

  const renderLevel2 = useCallback((child, index) => {
    return (
      <StyleLevel2 
        key={child.position} part = {child} index={index}
        moduleId={moduleId}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback((child, index) => {
    return (
      <StyleColumnsHolder 
        key={child.position} part = {child} index={index}
        moduleId = {moduleId} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <StyleTextArea 
        key={child.position} index={index} part={child} 
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <StyleHeading 
        key={child.position} part = {child}  index={index}
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <StyleSubHeading 
        key={child.position} part = {child} index={index}
        parentData = {part.position}  
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <StyleImageHolder 
        key={child.position}  part={child} index={index}
        changeClickability={changePartClickability}
        parentSize={partSize}
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <StyleLine 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])

  useEffect(() => {
    const onDynamicPartClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, parentData, parentType, {holder: holderData, column: part.position, parent:parentData}]);
        setStylingPanel(prev=> true)
      }
    }

    const dynamicPartRef = ref.current;
    dynamicPartRef.addEventListener("dblclick", onDynamicPartClick);

    return () => {
      dynamicPartRef.removeEventListener("dblclick", onDynamicPartClick);
    };
  }, [isResizingOver])

  return (
    <div ref={refBox} className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{height: style.height, width: style.width,}}  
    >
    <div ref={ref} data-handler-id={handlerId} draggable="false" style={{...style, height: '100%', width:'100%'}}>
      {part.content.length !== 0 ? 
        part.content.map( (child, index) => 
          child.type === 'text_field' ?
          renderTextArea(child, index)
          : 
          child.type === "level2" ? 
          renderLevel2(child, index)
          : 
          child.type === "columns_holder" ? 
          renderColumnsHolder(child, index)
          : 
          child.type === "heading" ? 
          renderHeading(child, index)
          : 
          child.type === "subheading" ? 
          renderSubHeading(child, index)
          : 
          child.type === "image_holder" ?
          renderImageHolder(child, index)
          : 
          child.type === "line" ? 
          renderLine(child, index)
          : console.log('ceivo', child)
        )
      :  <></>
      }
    </div>
    </div>
  )
}

export default StyleDynamicPart
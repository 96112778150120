import axios from 'axios'

const API_URL = '/api/users/'

// Add user
const add = async (userData) => {
  const response = await axios.post(API_URL+ 'add', userData)
  console.log("we've not response data")

  if (response.data) {
    console.log("we've response data")
    sessionStorage.setItem('invite_user', JSON.stringify(response.data))
  }

  return response.data
}
// Register user
const register = async (userData) => {
  const response = await axios.post(API_URL, userData)
  console.log("we've not response data")

  if (response.data) {
    console.log("we've response data")
    localStorage.setItem('user', JSON.stringify(response.data))
    //sessionStorage.setItem('user', JSON.stringify(response.data))
  }

  return response.data
}
// Get user
const get = async (userData) => {
  const response = await axios.post(API_URL + 'get', userData)
  if (response.data) {
    sessionStorage.setItem('invite_user', JSON.stringify(response.data))
  }
  return response.data
}
// Get all users
const getAll = async (userData) => {
  const response = await axios.post(API_URL + 'getAll', userData)
  if (response.data) {
    sessionStorage.setItem('users', JSON.stringify(response.data))
  }
  return response.data
}
// get up to date user
const getUpToDateUser = async (userData) => {
  const response = await axios.post(API_URL + 'getUpToDate', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('pagesView', JSON.stringify(response.data.pagesView))
    // sessionStorage.setItem('user', JSON.stringify(response.data))
    // sessionStorage.setItem('pagesView', JSON.stringify(response.data.pagesView))
  }

  return response.data
}
// Login user
const login = async (userData) => {
  const response = await axios.post(API_URL + 'login', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('pagesView', JSON.stringify(response.data.pagesView))
    // sessionStorage.setItem('user', JSON.stringify(response.data))
    // sessionStorage.setItem('pagesView', JSON.stringify(response.data.pagesView))
  }

  return response.data
}
// Login user with google
const loginGoogle = async (userData) => {
  const response = await axios.post(API_URL +'loginGoogle', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
    localStorage.setItem('pagesView', JSON.stringify(response.data.pagesView))
  }

  return response.data
}


// Logout user
const logout = () => {
  sessionStorage.clear();
  localStorage.clear();
}

const authService = {
  add,
  register,
  get,
  getAll,
  getUpToDateUser,
  logout,
  login,
  loginGoogle
}

export default authService

import { useEffect} from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStyles } from "../../features/styles/styleSlice";
import Spinner from "../Spinner";

const StylesList = ({chosenStyle, setChosenStyle, setChangeClassTrigger}) => {

  const dispatch = useDispatch()

  const { styles, isLoadingStyle, isErrorStyle, messageStyle } = useSelector(
    (state) => state.styles
  )
  const { editingDocument } = useSelector(
    (state) => state.editingDocument
  )

  useEffect(() =>{
    if(editingDocument && chosenStyle !== 'default'){
      if(editingDocument.classes){
        setChosenStyle(prev => editingDocument.classes._id)
      }
    }
  }, [editingDocument])

  useEffect(() => {
    if (isErrorStyle) {
      console.log("Error: "+messageStyle)
    }
    dispatch(getStyles())

  }, [ isErrorStyle, messageStyle, dispatch])

  const changeClassHandler = (classId) => {
    setChosenStyle(prev => classId)
    setChangeClassTrigger(prev => !prev)
  }

  return ( 
    styles ?
    <div className="dropdown-styles-container">
      <div className="select-option">
        <div className="select-option-tittle">
          Majoto default style
        </div>
        <div className={chosenStyle === "default" ? "select-option-describe-s" : "select-option-describe"}>
          Majoto default style 
        </div>
        <div className={chosenStyle === "default" ? "select-option-ellipse s" :"select-option-ellipse"}
        onClick={() => changeClassHandler("default")}>
          {chosenStyle === "default" ? <div className="select-option-ellipse-inside"/>
          :<></>}
        </div>
      </div>
    { isLoadingStyle ? 
      <div className="select-option-spinner">
        <Spinner/> 
      </div>
      : styles.map(style => (
        <div className="select-option" key={style._id}>
          <div className="select-option-tittle">
            { style.name }
          </div>
          <div className={chosenStyle === "majoto" ? "select-option-describe-s" : "select-option-describe"}>
            Majoto house style 
          </div>
          <div className={chosenStyle === "majoto" ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => changeClassHandler(style._id)}>
            {chosenStyle === style._id ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div> 
      ))}
    </div>
    : 
    <div className="templates-list">
        There are no styles yet
    </div>
   );
}
 
export default StylesList;
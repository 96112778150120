import { useDrag } from "react-dnd";
import { useDispatch } from "react-redux";
import { removeEmptyComponents } from "../../features/localDocument/editingDocumentSlice";


const difMarginColor = {
  module:{ background: '#FEF7F8', cursor:'pointer'},
  level1:{background: '#FCE0E4', marginLeft: '16px', cursor:'grab'},
  level2:{ background: '#FAC9D0', marginLeft: '32px',cursor:'grab'},
  level3:{ background: '#F8B2BC', marginLeft: '48px',cursor:'grab'},
}

const MapSideBarItem = ({ type }) => {
  const dispatch = useDispatch()

  let data = {
    part: {
      origin:'sidebar',
      content:[],
      name: type ==='level1' ? "Level 1" : type ==='level2'?  "Level 2" : "Level 3",
      typeOfContent:type,
      style:
      type ==='level1' ?
      {
        display:'flex', padding: '20px 16px',
        outlineWidth:'1px', outlineOffset:'-1px',
        outlineStyle: 'solid', outlineColor:'rgba(229,225,225,255)',
        borderRadius: '8px',
        width: '300px',
        height: '120px',
        flexDirection:'column', isUnicStyle:false,
      }
      :
      {
        display:'flex', padding: '20px 16px',
        outlineWidth:'1px',outlineOffset:'-1px',
        outlineStyle: 'solid', outlineColor:'rgba(229,225,225,255)',
        borderRadius: '8px',
        flexDirection:'column', isUnicStyle:false,
      },
      position: 0,
    },
    type: type,
    component: {content: type ==='level1' ? "+ Add level 1" : type ==='level2'?  "+ Add level 2" : "+ Add level 3"}}
  let unicId = Math.round(Math.random()*10000)-Math.round(Math.random()*1000)
  data.part.position = unicId;
  unicId = 0;

  const [{ opacity }, drag] = useDrag({
    type: data.type ,
    item: () => {
      return data
    },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    }),
    end: () => {
      dispatch(removeEmptyComponents())
    }
  });
  
  return (
    <div ref={drag} className='map-button-add' style={{...difMarginColor[data.type], opacity }}>
      {data.component.content}
    </div>
  );
};
export default MapSideBarItem;

import { useDispatch } from "react-redux";
import { deleteModuleStyleDoc, deleteComponentInModuleStyleDoc, deleteComponentInModuleColumnStyleDoc, 
  deleteComponentInLevel1StyleDoc, deleteComponentInLevel1ColumnStyleDoc, 
  deleteComponentInLevel2StyleDoc, deleteComponentInLevel2ColumnStyleDoc,  } from "../../features/localDocument/editingStyleDocumentSlice";
import { useCallback, useEffect, useState } from "react";
import { useDisplayChosenStyleComponent } from "../../hooks/useDisplayChosenStyleComponent";
import { createDesignItem } from "../../features/designItems/designItemSlice";

const ActionsPanel = ({setPanel, chosenComponent}) => {

  //chosenComponent[0] = part
  //chosenComponent[1] = position or id of parent
  //chosenComponent[2] = type of parent
  //chosenComponent[3] = position of columns holder

  const component = chosenComponent[0]
  const positionOfParent = chosenComponent[1]
  const typeOfParent = chosenComponent[2]
  const columnData = chosenComponent[3]
  
  const [displayingComponent] = useDisplayChosenStyleComponent(chosenComponent[0], typeOfParent, columnData)
  const [designItemName, setDesignItemName] = useState('')

  const dispatch = useDispatch()

  const deleteComponentHandler = () => {
    component.type === 'module' ?  dispatch(deleteModuleStyleDoc([component.id]))
    :
    (typeOfParent === 'module' && columnData === undefined) || component.type === 'level1' ? 
    dispatch(deleteComponentInModuleStyleDoc([component.position, positionOfParent]))
    :
    typeOfParent === 'module' && columnData !== undefined && component.type !== 'column' ? 
    dispatch(deleteComponentInModuleColumnStyleDoc([component.position, columnData]))
    :
    typeOfParent === 'level1' && columnData === undefined ?
    dispatch(deleteComponentInLevel1StyleDoc([component.position, positionOfParent]))
    :
    typeOfParent === 'level1' && columnData !== undefined && component.type !== 'column' ? 
    dispatch(deleteComponentInLevel1ColumnStyleDoc([component.position, columnData]))
    :
    typeOfParent === 'level2' && columnData === undefined ?
    dispatch(deleteComponentInLevel2StyleDoc([component.position, positionOfParent]))
    :
    typeOfParent === 'level2' && columnData !== undefined && component.type !== 'column' ? 
    dispatch(deleteComponentInLevel2ColumnStyleDoc([component.position, columnData]))
    :
    console.log('')
    setPanel(prev => false)
  }

  return ( 
  <>
    <div className="actions-panel-row"style={{padding:'20px 0px'}}>
      <div className="actions-panel-row-description" style={{color:'grey'}}>
        Delete this component from the document
        {/* <div style={{color:'grey'}}></div>
        <div style={{color:'grey'}}>Delete this component from the document</div> */}
      </div>
      <div className="actions-panel-delete-button"
      onClick={deleteComponentHandler}>
        Delete
      </div>
    </div>
  </> 
  );
}
 
export default ActionsPanel;
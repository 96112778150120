import { useCallback, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useDispatch } from 'react-redux'


const LibraryFileWindow = ({active, setActive, file}) => {

  const dispatch = useDispatch()


  const ref = useOnclickOutside(() => {
    active && setActive(prev => false);
  })


  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
     <div ref={ref} className='upload-image-form-content' style={{opacity: active && '1'}}>
      {
        file && 
        <div className="upload-image-form-header">
          {file.name}
        </div>  
      }
      </div>
    </div>
   );
}
 
export default LibraryFileWindow
import { useEffect, useState } from "react";
import { ChromePicker } from "react-color";
import choserArrows from '../../icons/arrows-left-right-icon.svg'
import colorPalette from '../../icons/color-palette-background-icon.svg'
import alignRight from '../../icons/text-align-right-icon.svg'
import alignCenter from '../../icons/text-align-center-icon.svg'
import alignLeft from '../../icons/text-align-left-icon.svg'
import alignJustify from '../../icons/text-align-justify-icon.svg'
import expandArrow from '../../icons/spacing-expand-arrow-icon.svg'
import borderRadiusIcon from '../../icons/border-radius-icon.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import { setStyleForModuleStyleDoc, updateStyleForLevel1StyleDoc,
  setStyleForPartInModuleStyleDoc, setStyleForPartInLevel1StyleDoc, setStyleForPartInLevel2StyleDoc, 
  setStyleForPartInModuleColumnStyleDoc, setStyleForPartInLevel1ColumnStyleDoc, setStyleForPartInLevel2ColumnStyleDoc, 
  setStyleForModuleColumnStyleDoc, setStyleForLevel1ColumnStyleDoc, setStyleForLevel2ColumnStyleDoc} from '../../features/localDocument/editingStyleDocumentSlice'
import { useDispatch } from "react-redux";
import StylingPanelScrollbar from "./StylingPanelScrollbar";
import Slider from '@mui/material/Slider';
import { useDisplayChosenStyleComponent } from "../../hooks/useDisplayChosenStyleComponent";

const fontFamilyList = [
  'Courier Prime',
  'Montserrat',
  'Lato',
  'Open Sans',
  'Poppins',
  'Raleway',
  'Roboto',
]
const fontStyleList = [
  'Regular',
]
const borderStylesList = [
  'dotted',
  'dashed',
  'solid',
  'double',
  'groove',
  'ridge',
  'inset',
  'outset'
]
const StylingPanel = ({active, stylingComponent}) => {

  //stylingComponent[0] = part
  //stylingComponent[1] = position or id of parent
  //stylingComponent[2] = type of parent
  //stylingComponent[3] = position of columns holder

  const stylingPart = stylingComponent[0]
  const styleOfPart = stylingPart.style
  const positionOfParent = stylingComponent[1]
  const typeOfParent = stylingComponent[2]
  const columnData = stylingComponent[3]

  const [displayingComponent] = useDisplayChosenStyleComponent(stylingPart, typeOfParent, columnData)
  const [firstStyle, setFirstStyle] = useState(false)
  const dispatch = useDispatch()

  const [newStyle, setNewStyle] = useState({})

  const [fontFamily, setFontFamily] = useState('Montserrat')
  const [isFontFamilyPicker, setIsFontFamilyPicker] = useState(false)
  const [fontStyle, setFontStyle] = useState('Regular')
  const [isFontStylePicker, setIsFontStylePicker] = useState(false)
  const [fontSize, setFontSize] = useState(14)
  const [textColor, setTextColor] = useState({hex: '#000'})
  const [isTextColorPicker, setIsTextColorPicker] = useState(false)
  const [fontBold, setFontBold] = useState(false)
  const [fontItalic, setFontItalic] = useState(false)
  const [fontUnderline, setFontUnderline] = useState(false)
  const [fontLineThrough, setFontLineThrough] = useState(false)
  const [textDecoration, setTextDecoration] = useState('')
  const [textAlign, setTextAlign] = useState('center')
  const [alignItems, setAlignItems] = useState('center')
  const [isComponentPadding, setIsComponentPadding] = useState(false)
  const [isComponentMargin, setIsComponentMargin] = useState(false)
  const [isSpacing, setIsSpacing] = useState(false)
  const [lineHeight, setLineHeight] = useState(1.2)
  const [letterSpacing, setLetterSpacing] = useState(1)
  const [paddingTop, setPaddingTop] = useState(0)
  const [paddingBottom, setPaddingBottom] = useState(0)
  const [paddingLeft, setPaddingLeft] = useState(0)
  const [paddingRight, setPaddingRight] = useState(0)
  const [padding, setPadding] = useState(0)
  const [marginTop, setMarginTop] = useState(0)
  const [marginBottom, setMarginBottom] = useState(0)
  const [marginLeft, setMarginLeft] = useState(0)
  const [marginRight, setMarginRight] = useState(0)
  const [gap, setGap] = useState(0)
  const [isBackgroundColorPicker, setIsBackgroundColorPicker] = useState(false)
  const [backgroundColor, setBackgroundColor] = useState({hex: '#FFFFF'})

  const [isBorder, setIsBorder] = useState(false)
  const [isBorderColorPicker, setIsBorderColorPicker] = useState(false)
  const [borderColor, setBorderColor] = useState({hex: '#ECF1E6'})
  const [borderSize, setBorderSize] = useState(0)
  const [isBorderStylePicker, setIsBorderStylePicker] = useState(false)
  const [borderStyle, setBorderStyle] = useState('solid')
  const [isBorderShadowPicker, setIsBorderShadowPicker] = useState(false)
  const [isBorderRadiusPicker, setIsBorderRadiusPicker] = useState(false)
  const [borderRadius, setBorderRadius] = useState('8px')

  const colorPicker = (e) => {
    const newColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
    }
    setTextColor(newColor);
    stylingHandler({color: newColor.hex})
  }
  const backgroundColorPicker = e => {
    const newBackgroundColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
    }
    setBackgroundColor(newBackgroundColor);
    stylingHandler({backgroundColor: newBackgroundColor.hex})
  }
  const borderColorPicker = e => {
    const newBorderColor = {
      hex: e.hex,
      rgb: "(" + e.rgb.r + "," + e.rgb.g + "," + e.rgb.b + "," + e.rgb.a + ")"
    }
    setBorderColor(newBorderColor);
    stylingHandler({outlineColor: newBorderColor.hex})
  }
  
  const textColorRef = useOnclickOutside(() => {
    isTextColorPicker && setIsTextColorPicker(prev => false);
  })
  const backgroundColorRef =  useOnclickOutside(() => {
    isBackgroundColorPicker && setIsBackgroundColorPicker(prev => false);
  })
  const borderColorRef =  useOnclickOutside(() => {
    isBorderColorPicker && setIsBorderColorPicker(prev => false);
  })

  const stylingHandler = (styleProperty) => {
    setNewStyle({...newStyle, ...styleProperty})
  }

  const fontWeightHandler = () => {
    stylingHandler({fontWeight: !fontBold ? 'bold' : 'normal'}); 
    setFontBold(prev => !prev)
  }
  const fontItalicHandler = () => {
    stylingHandler({fontStyle: !fontItalic ? 'italic' : 'normal'}); 
    setFontItalic(prev => !prev)
  }
  const underlineHandler = () => {
    setFontUnderline(prev => !prev)
    if (textDecoration.includes("underline")) {
      setTextDecoration(textDecoration.replace("underline", "").trim());
      stylingHandler({textDecoration: textDecoration.replace("underline", "").trim()})
    } else if (textDecoration.includes("line-through")) {
      setTextDecoration("line-through underline");
      stylingHandler({textDecoration: "line-through underline"})
    } else {
      setTextDecoration("underline");
      stylingHandler({textDecoration: "underline"})
    }
  };

  const lineThroughHandler = () => {
    setFontLineThrough(prev => !prev)
    if (textDecoration.includes("line-through")) {
      setTextDecoration(textDecoration.replace("line-through", "").trim());
      stylingHandler({textDecoration: textDecoration.replace("line-through", "").trim()})
    } else if (textDecoration.includes("underline")) {
      setTextDecoration("line-through underline");
      stylingHandler({textDecoration: "line-through underline"})
    } else {
      setTextDecoration("line-through");
      stylingHandler({textDecoration: "line-through"})
    }
  };
  const textAlignHandler = (option) => {
    let alignItemsTranslate = ''
    if(option === 'center' || option === 'justify'){
      alignItemsTranslate = 'center'
    }
    if(option === 'left'){
      alignItemsTranslate = 'start'
    }
    if(option === 'right'){
      alignItemsTranslate = 'end'
    }
    if(stylingPart.type === 'module' || stylingPart.type.startsWith('level') || stylingPart.type ==='column'|| stylingPart.type ==='dynamic_part'){
      stylingHandler({textAlign: option, alignItems: alignItemsTranslate})
      setAlignItems(prev => alignItemsTranslate)
      setTextAlign(prev => option)
    } else{
      stylingHandler({textAlign: option}); 
      setTextAlign(prev => option)
    }
  }
  const lineHeightHandler = (e) => {
    stylingHandler({lineHeight: e.target.value })
    setLineHeight(prev => e.target.value === '' ? '' : Number(e.target.value))
  }
  const letterSpacingHandler = (e) => {
    if(e.target.value === 0){
      stylingHandler({letterSpacing: 'normal'})
    }else{
      stylingHandler({letterSpacing: e.target.value +'px'})
    }
    setLetterSpacing(prev => e.target.value === '' ? '' : Number(e.target.value))
  }
  const borderSizeHandler = (e) => {
    const offset = `-${e.target.value}px`
    const width = `${e.target.value}px`
    const additionalPaddingInteger = parseInt(e.target.value)+ parseInt(padding)
    const additionalPadding = `${additionalPaddingInteger}px`
    console.log(offset, width)
    stylingHandler({outlineWidth: width, outlineOffset: offset, padding: additionalPadding})
    setBorderSize(prev => e.target.value === '' ? '' : Number(e.target.value))
  }

  const handlePaddingChange = (e, side) => {
    if(side ==='top'){ 
      stylingHandler({paddingTop: e.target.value +'px'})
      setPaddingTop(prev => e.target.value)
    }
    if(side ==='right'){ 
      stylingHandler({paddingRight: e.target.value +'px'})
      setPaddingRight(prev => e.target.value)
    }
    if(side ==='bottom'){ 
      stylingHandler({paddingBottom: e.target.value +'px'})
      setPaddingBottom(prev => e.target.value)
    }
    if(side ==='left'){ 
      stylingHandler({paddingLeft: e.target.value +'px'})
      setPaddingLeft(prev => e.target.value)
    }
  }
  const handleMarginChange = (e, side) => {
    if(side ==='top'){ 
      stylingHandler({marginTop: e.target.value +'px'})
      setMarginTop(prev => e.target.value)
    }
    if(side ==='right'){ 
      stylingHandler({marginRight: e.target.value +'px'})
      setMarginRight(prev => e.target.value)
    }
    if(side ==='bottom'){ 
      stylingHandler({marginBottom: e.target.value +'px'})
      setMarginBottom(prev => e.target.value)
    }
    if(side ==='left'){ 
      stylingHandler({marginLeft: e.target.value +'px'})
      setMarginLeft(prev => e.target.value)
    }
  }
  const gapHandler = (e) => {
    stylingHandler({gap: e.target.value +'px'})
    setGap(prev => e.target.value)
  }
  const handleBorderRadius = (e) => {
    stylingHandler({borderRadius: e.target.value +'px'})
    setBorderRadius(prev => e.target.value)
  }
  useEffect(() => {
    setFirstStyle(prev => false)
    console.log('styling component changes')
  }, [stylingComponent])

  useEffect(() => {
    if(!active){
      setIsFontFamilyPicker(prev=> false)
      setIsFontStylePicker(prev => false)
      setIsTextColorPicker(prev=> false)
      setFontUnderline(prev=> false) 
      setFontLineThrough(prev=> false)
      setFirstStyle(prev => false)
    }
    if(displayingComponent && !firstStyle){
      setFirstStyle(prev => true)
      let border = displayingComponent.style.border && !displayingComponent.style.outline ? displayingComponent.style.border.split(' ') 
      : !displayingComponent.style.border && displayingComponent.style.outline ? displayingComponent.style.outline.split(' ') 
      : ['0px', 'solid', '#ECF1E6']
  
      setFontFamily(prev => displayingComponent.style.fontFamily ? displayingComponent.style.fontFamily : 'Montserrat')
      setFontStyle(prev => displayingComponent.style.fontStyle ? displayingComponent.style.fontStyle : 'normal')
      setFontSize(prev => displayingComponent.style.fontSize ? displayingComponent.style.fontSize.slice(0, -2) : 14)
      setFontBold(prev => displayingComponent.style.fontWeight === "bold" ? true : false);
      setFontItalic(prev => displayingComponent.style.fontStyle === "italic" ? true : false);
      setFontUnderline(prev => displayingComponent.style.textDecoration && displayingComponent.style.textDecoration.includes('underline') ? true : false)
      setFontLineThrough(prev => displayingComponent.style.textDecoration && displayingComponent.style.textDecoration.includes('line-through') ? true : false)
      setTextDecoration(prev => displayingComponent.style.textDecoration ? displayingComponent.style.textDecoration : '')
      setTextColor(prev => displayingComponent.style.color ? {hex: displayingComponent.style.color.toString()} : {hex: '#000'});
      setTextAlign(prev => displayingComponent.style.textAlign ? displayingComponent.style.textAlign : 'center');
      setLineHeight(prev => displayingComponent.style.lineHeight ? displayingComponent.style.lineHeight : 1.2)
      setLetterSpacing(prev => displayingComponent.style.letterSpacing &&  displayingComponent.style.letterSpacing !== 'normal' ? displayingComponent.style.letterSpacing.slice(0, -2) : 0 )
      
      setPaddingTop(prev => displayingComponent.style.paddingTop ? displayingComponent.style.paddingTop.slice(0, -2) :
      !displayingComponent.style.paddingTop && displayingComponent.style.padding ? displayingComponent.style.padding.slice(0, -2) :
       0)
      setPaddingRight(prev => displayingComponent.style.paddingRight ? displayingComponent.style.paddingRight.slice(0, -2) :
      !displayingComponent.style.paddingRight && displayingComponent.style.padding ? displayingComponent.style.padding.slice(0, -2) :
       0)
      setPaddingBottom(prev => displayingComponent.style.paddingBottom ? displayingComponent.style.paddingBottom.slice(0, -2) :
      !displayingComponent.style.paddingBottom && displayingComponent.style.padding ? displayingComponent.style.padding.slice(0, -2) :
       0)
      setPaddingLeft(prev => displayingComponent.style.paddingLeft ? displayingComponent.style.paddingLeft.slice(0, -2) :
      !displayingComponent.style.paddingLeft && displayingComponent.style.padding ? displayingComponent.style.padding.slice(0, -2) :
       0)
      setPadding(prev =>  displayingComponent.style.padding ? displayingComponent.style.padding.slice(0, -2) : 0)
      
      setMarginTop(prev => displayingComponent.style.marginTop ? displayingComponent.style.marginTop.slice(0, -2) :
      !displayingComponent.style.marginTop && displayingComponent.style.margin ? displayingComponent.style.margin.slice(0, -2) :
       0)
      setMarginRight(prev => displayingComponent.style.marginRight ? displayingComponent.style.marginRight.slice(0, -2) :
      !displayingComponent.style.marginRight && displayingComponent.style.margin ? displayingComponent.style.margin.slice(0, -2) :
       0)
      setMarginBottom(prev => displayingComponent.style.marginBottom ? displayingComponent.style.marginBottom.slice(0, -2) :
      !displayingComponent.style.marginBottom && displayingComponent.style.margin ? displayingComponent.style.margin.slice(0, -2) :
       0)
      setMarginLeft(prev => displayingComponent.style.marginLeft ? displayingComponent.style.marginLeft.slice(0, -2) :
      !displayingComponent.style.marginLeft && displayingComponent.style.margin ? displayingComponent.style.margin.slice(0, -2) :
       0)

      setGap(prev =>  displayingComponent.style.gap ? displayingComponent.style.gap.slice(0, -2) : 0)
      setBackgroundColor(prev => displayingComponent.style.backgroundColor ? {hex: displayingComponent.style.backgroundColor.toString()} : {hex: '#FFFFFF'});
      // setAlignItems(prev => displayingComponent.style.alignItems ? displayingComponent.style.alignItems : 'center');
      // setJustifyContent(prev => displayingComponent.style.justifyContent ? displayingComponent.style.justifyContent : 'center');
      setBorderSize(prev =>  displayingComponent.style.outlineWidth ? Number(displayingComponent.style.outlineWidth.slice(0, -2)) : 0) 
      setBorderStyle(prev =>  displayingComponent.style.outlineStyle ? displayingComponent.style.outlineStyle : 'solid')
      setBorderColor({hex: displayingComponent.style.outlineColor ? {hex: displayingComponent.style.outlineColor.toString()} : {hex: '#ECF1E6'}})
      setBorderRadius(prev=> displayingComponent.style.borderRadius ? displayingComponent.style.borderRadius.slice(0, -2) : 8)
    }
  },[displayingComponent, active])

  useEffect(() => {
    if(newStyle && typeof newStyle === 'object' && Object.keys(newStyle).length !== 0){
      const style = {...styleOfPart, ...newStyle, isUnicStyle: true}
      console.log('newStyle  ',newStyle)
      stylingPart.type === 'module' ?  dispatch(setStyleForModuleStyleDoc([positionOfParent, style]))
      :
      stylingPart.type === 'level1' ? dispatch(updateStyleForLevel1StyleDoc([positionOfParent, newStyle, stylingPart.position]))
      :
      typeOfParent === 'module' && columnData === undefined ? 
      dispatch(setStyleForPartInModuleStyleDoc([positionOfParent, stylingPart.position, style, stylingPart.type]))
      :
      typeOfParent === 'module' && (stylingPart.type === 'column' || stylingPart.type === 'dynamic_part') ? 
      dispatch(setStyleForModuleColumnStyleDoc([columnData, style]))
      :
      typeOfParent === 'module' && columnData !== undefined && stylingPart.type !== 'column' ? 
      dispatch(setStyleForPartInModuleColumnStyleDoc([columnData, stylingPart.position, style]))
      :
      typeOfParent === 'level1' && columnData === undefined ?
      dispatch(setStyleForPartInLevel1StyleDoc([positionOfParent, stylingPart.position, style]))
      :
      typeOfParent === 'level1' && stylingPart.type === 'column' ? 
      dispatch(setStyleForLevel1ColumnStyleDoc([columnData, style]))
      :
      typeOfParent === 'level1' && columnData !== undefined && stylingPart.type !== 'column' ?
      dispatch(setStyleForPartInLevel1ColumnStyleDoc([columnData, stylingPart.position, style]))
      :
      typeOfParent === 'level2' && columnData === undefined ?
      dispatch(setStyleForPartInLevel2StyleDoc([positionOfParent, stylingPart.position, style]))
      :
      typeOfParent === 'level2' && stylingPart.type === 'column' ? 
      dispatch(setStyleForLevel2ColumnStyleDoc([columnData, style]))
      :
      typeOfParent === 'level2' && columnData !== undefined && stylingPart.type !== 'column' ?
      dispatch(setStyleForPartInLevel2ColumnStyleDoc([columnData, stylingPart.position, style]))
      :
      console.log('')
    }
  }, [newStyle])

  useEffect(() => {
    setNewStyle(prev => {})
  }, [stylingPart])

  return ( 
    stylingPart.type === 'image' || stylingPart.type === 'image_holder' ?
      <div className="styling-panel-development-message">
        Stylization of images and images` holders is in development
      </div>
      :
      <StylingPanelScrollbar>
        <div className="styling-panel-font">
          <div className="styling-panel-body-option-name">
            Font
          </div>
          <div className="styling-panel-font-container" >
            <div className="styling-panel-font-choser" onClick={() => setIsFontFamilyPicker(prevState => !prevState)}>
              <div>
                {fontFamily}
              </div> 
              <div className="styling-panel-font-choser-arrows">
                <img style={{width:'22px', rotate:'90deg'}} src={choserArrows} alt='arrows'/>
              </div> 
            </div>
            { isFontFamilyPicker && 
              <div className="styling-panel-font-family-droplist">
                {fontFamilyList.map(font => (
                  <div className={font === fontFamily ? 'fonts-droplist-option-selected' :"fonts-droplist-option"}
                  onClick={() => {setFontFamily(prev => font); stylingHandler({fontFamily: font})}}>
                    <div key={font}>
                      {font}
                    </div>
                  </div>
                ))}
              </div>
            }
          </div>
          <div className="styling-panel-font-style-size-row">
            <div className="styling-panel-font-container">
              <div className="styling-panel-font-choser" onClick={() => setIsFontStylePicker(prevState => !prevState)}>
                <div>
                  {fontStyle}
                </div> 
                <div className="styling-panel-font-choser-arrows">
                  <img style={{width:'22px', rotate:'90deg'}} src={choserArrows} alt='arrows'/>
                </div>
              </div>
              { isFontStylePicker && 
                <div className="styling-panel-font-style-droplist">
                  {fontStyleList.map(font => (
                    <div className={font === fontStyle ? 'fonts-droplist-option-selected' :"fonts-droplist-option"}
                    onClick={() => {setFontStyle(prev => font); stylingHandler({fontStyle: font})}}>
                      <div key={font}>
                        {font}
                      </div>
                    </div>
                  ))}
                </div>
              }
            </div>
            <div className="styling-panel-font-size-container">
              <input
                type='number'
                name='font_size'
                className="styling-panel-font-size-choser"
                value={fontSize}
                autoComplete="off"
                onChange={(e) => {setFontSize(e.target.value); stylingHandler({fontSize: e.target.value.toString()+'px'}) }}
              />
            </div>
          </div>
          <div className="styling-panel-font-style-size-row">
            <div className="styling-panel-font-type-container">
              <div className={fontBold  ? 'styling-panel-font-style-chosen': ''} style={{fontWeight:'bold'}}
              onClick={() => fontWeightHandler()}>
                B
              </div>
              <hr className="styling-panel-font-icon-hr"/>
              <div className={fontItalic ? 'styling-panel-font-style-chosen': ''} style={{fontStyle:'italic'}}
              onClick={() => fontItalicHandler()}>
                I
              </div>
              <hr className="styling-panel-font-icon-hr"/>
              <div className={fontUnderline  ? 'styling-panel-font-style-chosen': ''} style={{textDecoration:'underline'}}
              onClick={() => underlineHandler()}>
                U
              </div>
              <hr className="styling-panel-font-icon-hr"/>
              <div className={fontLineThrough  ? 'styling-panel-font-style-chosen' : ''} style={{textDecoration:'line-through'}}
              onClick={() => lineThroughHandler()}>
                S
              </div>
            </div>
            <div></div>
          </div>
        </div>
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-color">
          <div className="styling-panel-body-option-name">
            Text color 
          </div>
          <div className="styling-panel-text-color-picker" onClick={() => setIsTextColorPicker(prev => true)}>
            <div className='styling-panel-text-color-picker-color' style={{background: textColor.hex}}/>
            <div className="styling-panel-text-color-picker-icon">
              <img style={{width: '18px'}} src={colorPalette} alt="picker"/>  
            </div>
            { isTextColorPicker && 
            <div ref={textColorRef} className="styling-panel-text-color-picker-form">
              <ChromePicker color={textColor !== null && textColor.hex} 
                onChange={e => colorPicker(e)}
                disableAlpha renderers={false} />
            </div> }
          </div>
        </div>
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-color">
          <div className="styling-panel-body-option-name">
            Background color 
          </div>
          <div className="styling-panel-text-color-picker" onClick={() => setIsBackgroundColorPicker(prev => true)}>
            <div className='styling-panel-text-color-picker-color' style={{background: backgroundColor.hex}}/>
            <div className="styling-panel-text-color-picker-icon">
              <img style={{width: '18px'}} src={colorPalette} alt="picker"/>  
            </div>
            { isBackgroundColorPicker && 
            <div ref={backgroundColorRef} className="styling-panel-text-color-picker-form">
              <ChromePicker color={backgroundColor !== null && backgroundColor.hex} 
                onChange={e => backgroundColorPicker(e)}
                disableAlpha renderers={false} />
            </div> }
          </div>
        </div>
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-alignment">
          <div className="styling-panel-text-alignment-container">
            <div className={textAlign === 'left' ? 'styling-panel-text-alignment-chosen': ''} onClick={() => textAlignHandler('left')}>
              <img style={{width: '20px'}} src={alignLeft} alt="left"/>  
            </div>
            <hr className="styling-panel-font-icon-hr"/>
            <div className={textAlign === 'center' ? 'styling-panel-text-alignment-chosen': ''} onClick={() => textAlignHandler('center')}>
              <img style={{width: '20px'}} src={alignCenter} alt="center"/> 
            </div>  
            <hr className="styling-panel-font-icon-hr"/>
            <div className={textAlign === 'right' ? 'styling-panel-text-alignment-chosen': ''} onClick={() => textAlignHandler('right')}>
              <img style={{width: '20px'}} src={alignRight} alt="right"/>  
            </div>
            <hr className="styling-panel-font-icon-hr"/>
            <div className={textAlign === 'justify' ? 'styling-panel-text-alignment-chosen': ''} onClick={() => textAlignHandler('justify')}>
              <img style={{width: '20px'}} src={alignJustify} alt="justify"/> 
            </div> 
          </div>
        </div>
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-spacing">
          <div className="styling-panel-text-spacing-heading" onClick={() => setIsComponentPadding(prev=> !prev)}>
            <img style={{transform: isComponentPadding ? 'rotate(-180deg)': ''}} className="styling-panel-text-spacing-expand-arrow" src={expandArrow} alt="expand"/> 
            Component Padding
          </div>
          {isComponentPadding && 
          <div className="styling-panel-text-spacing-options">
            <div className="component-padding-container">
              <div className="styling-panel-border-radius-row">
                <img src={borderRadiusIcon} alt="raidus"/>
                <input 
                    type="number" 
                    value={borderRadius}
                    onChange={(e) => handleBorderRadius(e)} 
                    className="styling-panel-border-radius-input" 
                  />
              </div>
              <div className="component" style={{borderRadius: borderRadius+'px'}}>
                <div className="inputs">
                <input 
                  type="number" 
                  value={paddingTop}
                  onChange={(e) => handlePaddingChange(e, 'top')} 
                  className="component-padding-input top" 
                />
                <div className="component-padding-line top"/>
                <input 
                  type="number" 
                  value={paddingRight}
                  onChange={(e) => handlePaddingChange(e, 'right')} 
                  className="component-padding-input right" 
                />
                <div className="component-padding-line right"/>
                <input 
                  type="number" 
                  value={paddingBottom}
                  onChange={(e) => handlePaddingChange(e, 'bottom')} 
                  className="component-padding-input bottom" 
                />
                <div className="component-padding-line bottom"/>
                <input 
                  type="number" 
                  value={paddingLeft}
                  onChange={(e) => handlePaddingChange(e, 'left')} 
                  className="component-padding-input left" 
                />
                <div className="component-padding-line left"/>
                </div>
              </div>
            </div>
          </div> }
        </div> 
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-spacing">
          <div className="styling-panel-text-spacing-heading" onClick={() => setIsComponentMargin(prev=> !prev)}>
            <img style={{transform: isComponentMargin ? 'rotate(-180deg)': ''}} className="styling-panel-text-spacing-expand-arrow" src={expandArrow} alt="expand"/> 
            Component Margin
          </div>
          {isComponentMargin && 
          <div className="styling-panel-text-spacing-options">
            <div className="component-margin-container">
              <div className="component-margin">
                <div className="inputs">
                <input 
                  type="number" 
                  value={marginTop}
                  onChange={(e) => handleMarginChange(e, 'top')} 
                  className="component-margin-input top" 
                />
                <div className="component-margin-line top"/>
                <input 
                  type="number" 
                  value={marginRight}
                  onChange={(e) => handleMarginChange(e, 'right')} 
                  className="component-margin-input right" 
                />
                <div className="component-margin-line right"/>
                <input 
                  type="number" 
                  value={marginBottom}
                  onChange={(e) => handleMarginChange(e, 'bottom')} 
                  className="component-margin-input bottom" 
                />
                <div className="component-margin-line bottom"/>
                <input 
                  type="number" 
                  value={marginLeft}
                  onChange={(e) => handleMarginChange(e, 'left')} 
                  className="component-margin-input left" 
                />
                <div className="component-margin-line left"/>
                </div>
              </div>
            </div>
          </div> }
        </div> 
        { (stylingPart.type === 'module' || stylingPart.type === 'column' || stylingPart.type === 'dynamic_part' || stylingPart.type.startsWith('level')) &&
          <>
          <hr className="styling-panel-hr" />
          <div className="styling-panel-text-color">
            <div>Components Gap</div>
            <input className="styling-panel-text-spacing-input" type='number'
            value={gap}
            onChange={(e) => gapHandler(e)}/>
          </div>
          </>
        }
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-spacing">
          <div className="styling-panel-text-spacing-heading" onClick={() => setIsSpacing(prev=> !prev)}>
            <img style={{transform: isSpacing ? 'rotate(-180deg)': ''}} className="styling-panel-text-spacing-expand-arrow" src={expandArrow} alt="expand"/> 
            Spacing
          </div>
          {isSpacing && 
          <div className="styling-panel-text-spacing-options">
            <div className="styling-panel-text-spacing-option-with-slider">
              <div className="styling-panel-text-spacing-option">
                <div>Line height </div>
                <input className="styling-panel-text-spacing-input" type='number' step="0.1"
                value={lineHeight}
                onChange={lineHeightHandler}/>
              </div>
              <div className="styling-panel-text-spacing-input-slider">
                <Slider min={-8} max={20} step={0.1}
                value={typeof lineHeight === 'number' ? lineHeight : 0}
                onChange={lineHeightHandler}/>
              </div>
            </div>
            <div className="styling-panel-text-spacing-option-with-slider">
              <div className="styling-panel-text-spacing-option">
                <div>Letter spacing </div>
                <input className="styling-panel-text-spacing-input" type='number' step="0.1"
                value={letterSpacing}
                onChange={letterSpacingHandler}/>
              </div>
              <div className="styling-panel-text-spacing-input-slider">
                <Slider min={-8} max={20} step={0.1}
                value={typeof letterSpacing === 'number' ? letterSpacing : 0}
                onChange={letterSpacingHandler}/>
              </div>
            </div>
          </div> }
        </div> 
        <hr className="styling-panel-hr" />
        <div className="styling-panel-text-spacing">
          <div className="styling-panel-text-spacing-heading" onClick={() => setIsBorder(prev=> !prev)}>
            <img style={{transform: isBorder ? 'rotate(-180deg)': ''}} className="styling-panel-text-spacing-expand-arrow" src={expandArrow} alt="expand"/> 
            Border
          </div>
          {isBorder && 
          <div className="styling-panel-text-spacing-options">
            <div className="styling-panel-text-spacing-option-with-slider">
              <div className="styling-panel-text-spacing-option">
                <div>Border size</div>
                <input className="styling-panel-text-spacing-input" type='number'
                value={borderSize}
                onChange={borderSizeHandler}/>
              </div>
              <div className="styling-panel-text-spacing-input-slider">
                <Slider min={0} max={20} step={1}
                value={typeof borderSize === 'number' ? borderSize : 0}
                onChange={borderSizeHandler}/>
              </div>
            </div>
            <div className="styling-panel-border-style-choser">
              <div>
                Border style
              </div>
              <div className="styling-panel-font-container">
                <div className="styling-panel-font-choser" onClick={() => setIsBorderStylePicker(prevState => !prevState)}>
                  <div>
                    {borderStyle}
                  </div> 
                  <div className="styling-panel-font-choser-arrows">
                    <img style={{width:'22px', rotate:'90deg'}} src={choserArrows} alt='arrows'/>
                  </div> 
                </div>
                { isBorderStylePicker && 
                  <div className="styling-panel-font-family-droplist">
                    {borderStylesList.map(style => (
                      <div className={style === borderStyle ? 'fonts-droplist-option-selected' :"fonts-droplist-option"}
                      onClick={() => {setBorderStyle(prev => style); stylingHandler({outlineStyle: style})}}>
                        <div key={style}>
                          {style}
                        </div>
                      </div>
                    ))}
                  </div>
                }
              </div>
          </div>
            <div className="styling-panel-border-color">
              <div className="styling-panel-body-option-name">
                Border color 
              </div>
              <div className="styling-panel-text-color-picker" onClick={() => setIsBorderColorPicker(prev => true)}>
                <div className='styling-panel-text-color-picker-color' style={{background: borderColor.hex}}/>
                <div className="styling-panel-text-color-picker-icon">
                  <img style={{width: '18px'}} src={colorPalette} alt="picker"/>  
                </div>
                { isBorderColorPicker && 
                <div ref={borderColorRef} className="styling-panel-text-color-picker-form">
                  <ChromePicker color={borderColor !== null && borderColor.hex} 
                    onChange={e => borderColorPicker(e)}
                    disableAlpha renderers={false} />
                </div> }
              </div>
            </div>
          </div> }
        </div> 
      </StylingPanelScrollbar>
  );
}
 
export default StylingPanel;
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

const LinkupPage = () => {

	const navigate = useNavigate()

  const {user} = useSelector( (state) => state.auth)
  const {token} = useParams()
  let folder_ids_string =''
  const [srcIframe, setSrcIframe] = useState('')

  // useEffect(() => {

  //   if (!user) {
  //     navigate('/login')
  //   }
  //   if(user) {
  //     if (user) {
  //       let temp_view_folder_ids = '';
  //       let temp_editing_folder_ids = '';
  //       let doc_view_folder_ids = '';
  //       let doc_editing_folder_ids = '';
      
  //       user.folderIds.forEach((folder) => {
  //         const folderId = folder.folderId.id;
  //         const prefix = folder.prefix;
      
  //         if (prefix === 'tfv') {
  //           temp_view_folder_ids = temp_view_folder_ids ? `${temp_view_folder_ids},${folderId}` : folderId;
  //         } else if (prefix === 'tfe') {
  //           temp_editing_folder_ids = temp_editing_folder_ids ? `${temp_editing_folder_ids},${folderId}` : folderId;
  //         } else if (prefix === 'dfv') {
  //           doc_view_folder_ids = doc_view_folder_ids ? `${doc_view_folder_ids},${folderId}` : folderId;
  //         } else if (prefix === 'dfe') {
  //           doc_editing_folder_ids = doc_editing_folder_ids ? `${doc_editing_folder_ids},${folderId}` : folderId;
  //         } else {
  //           console.log('Unknown prefix: ' + prefix);
  //         }
  //       });
      
  //       let folder_ids_string = '';
  //       if (temp_view_folder_ids !== '') {
  //         folder_ids_string = folder_ids_string.concat('&tfv=', temp_view_folder_ids);
  //       }
  //       if (temp_editing_folder_ids !== '') {
  //         folder_ids_string = folder_ids_string.concat('&tfe=', temp_editing_folder_ids);
  //       }
  //       if (doc_view_folder_ids !== '') {
  //         folder_ids_string = folder_ids_string.concat('&dfv=', doc_view_folder_ids);
  //       }
  //       if (doc_editing_folder_ids !== '') {
  //         folder_ids_string = folder_ids_string.concat('&dfe=', doc_editing_folder_ids);
  //       }
  //     }
  //     setSrcIframe(prev => `https://script.google.com/a/yourdomain.com/macros/s/AKfycbz5xEAR79-imLhqhuPqvGltAs00NcUenIvwwEGi/exec?user_id=${user._id}&email=${user.email}${folder_ids_string}&active_page=launch`)
  //   }
  // }, [user, navigate])

  useEffect(() => {
    if(token){
      setSrcIframe(prev => `https://script.google.com/a/yourdomain.com/macros/s/AKfycbz5xEAR79-imLhqhuPqvGltAs00NcUenIvwwEGi/exec?${token}`)
    }
  }, [token])

	return (
    <div>
      <iframe className="iframe"
      src={srcIframe}/>
    </div>
  );
}
 
export default LinkupPage;
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getDocuments, reset } from '../features/documents/documentSlice';
import { useNavigate } from "react-router-dom";
import ShortMenu from '../components/ShortMenu';
import DocumentsBoard from "../components/DocumentsBoard";
import LaunchpadHeader from "../components/LaunchpadHeader";
import { useCreateNewDocument } from "../hooks/useCreateNewDocument";

const Templates = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

	const {user} = useSelector((state) => state.auth)
  const { documents, isLoading, isError, message } = useSelector(state => state.documents)

  const createNewDocument = useCreateNewDocument('templates')

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
    if (isError) {
      console.log("Error: "+message)
    }

    dispatch(getDocuments())
    return () => {
      dispatch(reset())
    }
  }, [user, isError, message, navigate, dispatch])

  return ( 
    <div className='content-board'>
      <ShortMenu chosenPage={'launchpad'}/> 
      <div className='board-body'>
        <LaunchpadHeader stage={'templates'}/>
        {user ?
        <div className="board-content">
          <div>
            <div className="create-template-label">
              <div id="red-label"/>
              <div>Majoto Templates</div>
            </div>
            <DocumentsBoard documents={documents} isLoading={isLoading}/>
          </div>
          <div>
            <div className="create-template-label">
              <div id="blue-label"/>
              <div>Created by You</div>
            </div>
            <div className="create-template">
              <div className="create-template-description">
                You haven’t built a template yet. You can build a new template from scratch, or drag & drop an existing template into this space as your starting point.
              </div>
              <div className="create-new" onClick={() => createNewDocument()}>
                Create new
              </div>
            </div>
          </div>
        </div>
        :
        <div className="message">Log in to get access to data</div>}
			</div>
    </div>
  )
}
 
export default Templates;
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'
import MapLevel1 from "./MapLevel1";
import { useDispatch } from 'react-redux'
import { addingNewLevel1, addNewLevel1, updateModuleOrder, updateLevel1Order, updatingLevel1Order, changeModuleName, addOldModule } from "../../features/localDocument/editingDocumentSlice"

const column_style = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingTop: '9px',
}
const r_sub_column_style = {
  display:'grid',
  gridTemplateColumns: '1fr 1.5fr',
  padding: '9px',
}
const l_sub_column_style = {
  display:'grid',
  gridTemplateColumns: '1.5fr 1fr',
  padding: '9px',
}
const number_style = {
  display: 'flex',
  justifyContent:'center',
  background: '#FEF7F8',
  margin: '0 auto 6px',
  fontSize: '32px',
  padding: '8px 12px ',
  borderRadius: '8px',
  border: '1px solid #DADFF2',
  width:'40px',
}
const hor_line_between = {
  borderTop:'1px solid #DADFF2',
}

const MapModule = ({index, module, moveModule }) => {

  const id = module.id

  const dispatch = useDispatch()
  const ref = useRef(null)

  const [{ handlerId }, drop] = useDrop({
    accept: ['part', 'module'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop(item) {
      console.log(item)
      if(item.part !== undefined){
        dispatch(addOldModule(item.part))
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      const clientOffset = monitor.getClientOffset()

      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      console.log(item)
      if(item.part === undefined){
        dispatch(updateModuleOrder([dragIndex, hoverIndex]))
        moveModule(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'part',
    item: () => {
      return { id, index, module }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))



  const moveLevel = useCallback(
  (dragPosition, hoverPosition, newLevel) => {
    newLevel.origin === 'root' ?  
    dispatch(updatingLevel1Order([dragPosition, hoverPosition, id , newLevel])) 
    : dispatch(addingNewLevel1([hoverPosition, id]))
  }, [])

  const changeOrder = useCallback(
    newLevel => {
      newLevel.origin !== 'root' ? 
      dispatch(addNewLevel1([newLevel, id])) 
      :  dispatch(updateLevel1Order([newLevel, id]))  
    }, [])

  const renderSection = useCallback((part, index) => {
  return (
    <MapLevel1
      key={part.position}
      level1Position={part.position}
      level1Index= {index}
      part = {part}
      moveLevel={moveLevel}
      dropLevel1={changeOrder}
    />
  )
}, [])

  return (
    <>
    <div ref={ref} className="agreement-map-module"
    style={ {opacity} } data-handler-id={handlerId}>
      { index % 2 === 0 
      ? 
      <>
        <div/>
        <div style={column_style}>
          <div style={number_style}>{index+1}</div>
          <hr className="agreement-map-ver-line"/>
        </div>
        <div style={r_sub_column_style}>
          <div>
            <div className="agreement-map-module-name-container">
              <textarea  value={module.name} className="agreement-map-module-name"
              onChange={(e) => {dispatch(changeModuleName([module.id, e.target.value]))}}
              /> 
            </div>
            <div style={hor_line_between}/>
          </div>
          <div className="agreement-map-level1-list">
            <div className="agreement-map-level1-list-absolute">
            {module.content.filter(part => 
              part.typeOfContent === "level1").length !== 0 ? 
             module.content.filter(part => 
              part.typeOfContent === "level1").map((part, i) => 
                renderSection(part, i) 
                ) 
              : renderSection({value:null}, 0)}
            </div>
          </div>
        </div>
      </> 
      : 
      <>
        <div style={l_sub_column_style}>
          <div className="agreement-map-level1-list">
            <div className="agreement-map-level1-list-absolute">
            {module.content.filter(part => 
              part.typeOfContent === "level1").length !== 0 ? 
             module.content.filter(part => 
              part.typeOfContent === "level1").map((part, i) => 
                renderSection(part, i) 
                ) 
              : renderSection({value: null}, 0)}
            </div>
          </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <div className="agreement-map-module-name-container">
              <textarea  value={module.name} className="agreement-map-module-name"
              onChange={(e) => {dispatch(changeModuleName([module.id, e.target.value]))}}
              /> 
            </div>
            <div style={hor_line_between}/>
          </div>
        </div>
        <div style={column_style}>
          <div style={number_style}>{index+1}</div>
          <hr className="agreement-map-ver-line"/>
        </div>
        <div/>
      </> 
      }  
    </div >
    </>
  )
}

export default MapModule;

const SortableTh = ({ columnName, currentSort, sortDirection, onSort, children }) => {
    const isSorted = currentSort === columnName;
    return (
      <th className="clickable" onClick={() => onSort(columnName)}>
        {children}
        {isSorted && <div style={{display:'contents', fontSize:'22px',}}>{sortDirection === 'asc' ? ' ↑' : ' ↓'}</div>}
      </th>
    );
  };
 
export default SortableTh 
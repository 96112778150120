import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel1Column, addComponentInLevel2Column, addComponentInModuleColumn, setChosenComponent, updatingComponentsOrderInLevel1Column, updatingComponentsOrderInLevel2Column, updatingComponentsOrderInModuleColumn } from "../../../features/localDocument/editingDocumentSlice"
import EmptyComponent from "./EmptyComponent"
import Heading from "./Heading"
import Level1 from "./Level1"
import Level2 from "./Level2"
import Level3 from "./Level3"
import SubHeading from "./SubHeading"
import TextArea from "./TextArea"
import Line from "./Line"
import Image from "./Image"
import ImageHolder from "./ImageHolder"

const dropColumnStyle = {
  minHeight:'40px',
  height:'fit-content',
  width:'60%',
  maxWidth: '90%',
  display:'flex',
  flexDirection:'column',
  position: 'relative',
}

const DropColumn = ({part, index, moduleId, 
  parentData, holderData, parentType, 
  setParentShow,
  setStylingPanel, setStylingComponent,
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveColumns}) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()
  const [isShown, setIsShown] = useState(false)
  const [isClickable, setIsClickable] = useState(true)
  const [levelNum, setLevelNum] = useState('')
  const [style, setStyle] = useState({})

  useEffect(()=> {
    parentType === 'module' ?
    setLevelNum(prev => 'level1')
    :
    parentType === 'level1' ?
    setLevelNum(prev => 'level2')
    :
    parentType === 'level2' ?
    setLevelNum(prev => 'level3')
    :
    console.log('is not architecture`s component')
  }, [])
  useEffect(()=> {
    setStyle(prev => ({...dropColumnStyle, ...part.style, width:'90%', height:'fit-content'}))
  }, [])
  useEffect(() => {
    setStyle(prev => ({...dropColumnStyle, ...part.style, width:'90%', height:'fit-content'}))
  }, [part.style])

  const ref = useRef(null)  
  useEffect(() => {
    const onColumnClick = (event) => {
      console.log('clicked on column')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([part, parentData, parentType, {holder: holderData, column: part.position, parent:parentData}]);
      setStylingPanel(prev=> true)
    }
  
    const columnRef = ref.current;
    columnRef.addEventListener("click", onColumnClick);
  
    return () => {
      columnRef.removeEventListener("click", onColumnClick);
    };
  }, [])
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', levelNum],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        if(item.type !== undefined && item.type !== 'columns_holder' 
        && (item.parentType === undefined || item.parentType === 'column')){
          if(item.type === 'new_image'){
            setUploadImageData('new_image');
            setUploadImageParent({
              position: part.id, 
              type: parentType,
              columnData:{holder: holderData, column: part.position, parent:parentData, moduleId: moduleId}
            }); 
            setImageForm(prev => true)
          }else{
            if(parentType === 'module'){
              dispatch(addComponentInModuleColumn([ item, moduleId, holderData, part.position]))
            }
            if(parentType === 'level1'){
              dispatch(addComponentInLevel1Column([ item, moduleId, {holder: holderData, parent:parentData}, part.position]))
            }
            if(parentType === 'level2'){
              dispatch(addComponentInLevel2Column([ item, moduleId, parentData, holderData, part.position]))
            }
          }
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      if(item.part !== undefined && item.parentData === holderData){
        moveColumns(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentData: holderData}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return isShown
    }
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
      parentType === 'module' && child.parent === part.position ?
      dispatch(updatingComponentsOrderInModuleColumn(
        [dragIndex, hoverIndex, moduleId, holderData, part.position, child ]))
      : 
      parentType === 'level1' && child.parent === part.position ?
      dispatch(updatingComponentsOrderInLevel1Column(
        [dragIndex, hoverIndex, moduleId, parentData, holderData, part.position, child ]))
      : 
      parentType === 'level2' && child.parent === part.position ?
      dispatch(updatingComponentsOrderInLevel2Column(
        [dragIndex, hoverIndex, moduleId, parentData, holderData, part.position, child ]))
      : 
      console.log('nooo plz no')
    }, []) 

  const hoverRes = isOverCurrent 
  const renderLevel1 = useCallback((child, index) => {
    return (
      <Level1 
        key={child.position} part = {child} index={index}
        moduleId = {module.id} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel2 = useCallback((child, index) => {
    return (
      <Level2 
        key={child.position} part = {child} index={index}
        moduleId={moduleId}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {"level1"} 
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel3 = useCallback((child, index) => {
    return (
      <Level3 
        key={child.position} part = {child} index={index}
        moduleId={moduleId}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <TextArea 
        key={child.position}
        part={child}
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <Heading 
        key={child.position}
        part = {child} 
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <SubHeading 
        key={child.position}
        part = {child} 
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <ImageHolder 
        key={child.position} part={child} index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown}
        setImageForm={setImageForm}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImage = useCallback((child, index) => {
    return (
      <Image 
        key={child.position} part={child} index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <Line 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown} 
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderEmptyComponent = useCallback((child, index, visibility) => {
    return (
      <EmptyComponent 
        key={index} part = {child} index={index}
        parentData = {part.position} 
        parentType = {parentType}
        visibility={visibility}
      />
    )
  }, [])

  return (
    <div className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{width: style.width, height: style.height}}>
    <div ref={ref} data-handler-id={handlerId} 
    style={{...style, height: '100%', width:'100%',
      opacity}} 
      >
      {part.content.length !== 0 ? 
        part.content.map( (child, index) => 
          child.typeOfContent === 'text_field' ?
          renderTextArea(child, index)
          : 
          child.typeOfContent === "heading" ? 
          renderHeading(child, index)
          : 
          child.typeOfContent === "subheading" ? 
          renderSubHeading(child, index)
          : 
          child.typeOfContent === "image_holder" ?
          renderImageHolder(child, index)
          : 
          child.typeOfContent === "image" ?
          renderImage(child, index)
          :
          child.typeOfContent === "level1" ? 
          renderLevel1(child, index)
          : 
          child.typeOfContent === "level2" ? 
          renderLevel2(child, index)
          : 
          child.typeOfContent === "level3" ? 
          renderLevel3(child, index)
          : 
          child.typeOfContent === "line" ? 
          renderLine(child, index)
          : 
          child.position === 0 ? 
          renderEmptyComponent(child, index, true)
          : console.log('ceivo', child)
        )
      : <></>}
    </div>
    </div>
  )
}

export default DropColumn
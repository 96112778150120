import axios from 'axios'

const API_URL = '/api/folderIds/'

// Create new folderId
const createFolderId = async (folderIdData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, folderIdData, config)

  return response.data
}

// Get user folderIds
const getFolderIds = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// change user document
const changeFolderId = async (folderIdData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let folderIdBody = folderIdData
  let folderIdId = folderIdData._id;
  const response = await axios.post(API_URL + folderIdId, folderIdBody, config)

  return response.data
}
// update user document
const updateFolderId = async (folderIdData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let folderIdBody = folderIdData
  let folderIdId = folderIdData._id;
  const response = await axios.put(API_URL + folderIdId, folderIdBody, config)

  return response.data
}

// Delete user folderId
const deleteFolderId = async (folderIdId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + folderIdId, config)

  return response.data
}

const folderIdService = {
  createFolderId,
  getFolderIds,
  changeFolderId,
  updateFolderId,
  deleteFolderId,
}

export default folderIdService
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import arrow from "../../icons/arrow-icon.svg"
import checker from '../../icons/selected-checker-icon.svg'
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';
import { changeCustomerSpace, deleteCustomerSpace, getCustomerSpaces } from "../../features/customerSpaces/customerSpaceSlice";
import { changeUser, deleteUser, getUsers } from "../../features/users/userSlice";
import { changeFolderId, deleteFolderId, getFolderIds } from "../../features/folderIds/folderIdSlice";
import { getFolderRegisters } from "../../features/folderRegister/folderRegisterSlice";
import ArchiveRegisterRowsTable from "./ArchiveRegisterRowsTable";

const ArchiveControl = () => {

  const dispatch = useDispatch()
  const  { customerSpaces, isLoadingCS, isErrorCS, messageCS } = useSelector( 
    state => state.customerSpaces)
  const  { folderIds, isLoadingFId, isErrorFId, messageFId} = useSelector( 
    state => state.folderIds)
  const  { users, isLoadingU, isErrorU, messageU } = useSelector( 
    state => state.users)

  const [updateTrigger, setUpdateTrigger] = useState(false)  
  const [customerSpaceUpdateTrigger, setCustomerSpaceUpdateTrigger] = useState(false)
  const [folderUpdateTrigger, setFolderUpdateTrigger] = useState(false)
  const [userUpdateTrigger, setUserUpdateTrigger] = useState(false)
  const [registerUpdateTrigger, setRegisterUpdateTrigger] = useState(false)
  
  
  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getUsers())  
        dispatch(getFolderRegisters())
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  const [chosenGroups, setChosenGroups] = useState([]);
  const [chosenFolderIds, setChosenFolderIds] = useState([]);
  const [chosenUsers, setChosenUsers] = useState([]);

  const customerGroupToggle = (index) => {
    setChosenGroups((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }
  const [isCSActionsMenu, setIsCSActionsMenu] = useState(false)

  useEffect(() => {
    chosenGroups.length !== 0 ? setIsCSActionsMenu(prev => true) : setIsCSActionsMenu(prev => false) 
  }, [chosenGroups])

  const csActionsHandler = (option) => {
    chosenGroups.forEach((groupId) => {
      const customerSpaceToUpdate = customerSpaces.find((customerSpace) => customerSpace._id === groupId);
  
      if (customerSpaceToUpdate) {
        if(option !== 'delete'){
          const updatedCustomerSpace = { ...customerSpaceToUpdate, customerSpaceStatus: option };
          toast.promise(
            dispatch(changeCustomerSpace(updatedCustomerSpace)),
            {
              pending: 'Applying changes',
              success: `Changed customer space state to ${option}`,
              error: 'Promise rejected 🤯'
            }
          )
        } else{
          toast.promise(
            dispatch(deleteCustomerSpace(customerSpaceToUpdate._id)),
            {
              pending: 'Deleting..',
              success: `${customerSpaceToUpdate.name} deleted`,
              error: 'Promise rejected 🤯'
            }
          )
        }
      }
    })
    if (chosenGroups.length !== 0){
      setCustomerSpaceUpdateTrigger(prev => true)
      setChosenFolderIds(prevChosenGroups => [])
    }
  }


  const folderIdToggle = (index) => {
    setChosenFolderIds((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }
  const [isFIActionsMenu, setIsFIActionsMenu] = useState(false)

  useEffect(() => {
    chosenFolderIds.length !== 0 ? setIsFIActionsMenu(prev => true) : setIsFIActionsMenu(prev => false) 
  }, [chosenFolderIds])

  const fiActionsHandler = (option) => {
    chosenFolderIds.forEach((folderId) => {
      const folderToUpdate = folderIds.find((folder) => folder._id === folderId);
  
      if (folderToUpdate) {
        if(option !== 'delete'){
          const updatedFolder = { ...folderToUpdate, folderStatus: option };
          toast.promise(
            dispatch(changeFolderId(updatedFolder)),
            {
              pending: 'Applying changes',
              success: `Changed folder id state to ${option}`,
              error: 'Promise rejected 🤯'
            }
          )
        } else{
          toast.promise(
            dispatch(deleteFolderId(folderToUpdate._id)),
            {
              pending: 'Deleting..',
              success: `${folderToUpdate.name} deleted`,
              error: 'Promise rejected 🤯'
            }
          )
        }
      }
    })
    if (chosenFolderIds.length !== 0){
      setFolderUpdateTrigger(prev => true)
      setChosenFolderIds(prev => [])
    }
  }


  const userToggle = (index) => {
    setChosenUsers((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }
  const [isUActionsMenu, setIsUActionsMenu] = useState(false)

  useEffect(() => {
    chosenUsers.length !== 0 ? setIsUActionsMenu(prev => true) : setIsUActionsMenu(prev => false) 
  }, [chosenUsers])

  const uActionsHandler = (option) => {
    chosenUsers.forEach((userId) => {
      const userToUpdate = users.find((user) => user._id === userId);
  
      if (userToUpdate) {
        if(option !== 'delete'){
          const updatedUser = { ...userToUpdate, userStatus: option };
          toast.promise(
            dispatch(changeUser(updatedUser)),
            {
              pending: 'Applying changes',
              success: `Changed user state to ${option}`,
              error: 'Promise rejected 🤯'
            }
          )
        } else{
          toast.promise(
            dispatch(deleteUser(userToUpdate._id)),
            {
              pending: 'Deleting..',
              success: `${userToUpdate.name} deleted`,
              error: 'Promise rejected 🤯'
            }
          )
        }
      }
    })
    if (chosenUsers.length !== 0){
      setUserUpdateTrigger(prev => true)
      setChosenUsers(prev => [])
    }
  }

  useEffect(() => {
    if(customerSpaceUpdateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        setCustomerSpaceUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [customerSpaceUpdateTrigger])

  useEffect(() => {
    if(folderUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderIds())
        setFolderUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [folderUpdateTrigger])

  useEffect(() => {
    if(userUpdateTrigger){
      setTimeout(() => {
        dispatch(getUsers())  
        setUserUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [userUpdateTrigger])

  useEffect(() => {
    if(registerUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderRegisters())
        setRegisterUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [registerUpdateTrigger])


  return ( 
    <div className="admin-register-body">
      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Customer spaces
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <th>Customer space</th>
              <th>Start date</th>
              <th>Duration</th>
              <th>Payment status</th>
              <th>Type</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoadingCS || updateTrigger ? <div className="admin-control-loading"><Spinner/></div>
              : customerSpaces.filter(space => space.customerSpaceStatus === 'archive').length !== 0 ? 
              <>
              {customerSpaces.filter(space => space.customerSpaceStatus === 'archive').map((space, index) => (
              <tr key={index} className={chosenGroups.includes(space._id) ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
                <td key={index} className="admin-control-space-selector" onClick={() => customerGroupToggle(space._id)}>
                  <div className={chosenGroups.includes(space._id) ? "admin-control-space-selected" : "admin-control-space-unselected"}>
                    {chosenGroups.includes(space._id) && <img src={checker} alt='yes'/>}
                  </div>
                </td>
                <td>{space.name}</td>
                <td>{moment.utc(space.start_date).format('D MMMM yyyy')}</td>
                <td>
                  {!space.duration.type ?
                    ' '
                    :
                    space.duration.type !== 'months' ?
                    space.duration.duration
                    :
                    space.duration.duration+' months'
                  }
                </td>
                <td>{space.payment_status}</td>
                <td>{space.type}</td>
                <td>{space.comments}</td>
              </tr>
            ))}
            </>
            : <div></div>
            }
            
          </tbody>
        </table>
        {customerSpaces.filter(space => space.customerSpaceStatus === 'archive').length === 0 && 
        <div className="admin-controll-archive-message">There are no customer space in archive</div>}
      </div>
      <>
        { isCSActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => csActionsHandler('active')}>
                Unarchive
              </div>
              <div className="admin-register-actions-option-delete"
                onClick={() => csActionsHandler('delete')}>
                Delete
              </div>
          </div>
        }
      </>
      </div>

      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Spaces and folders
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <th>Customer space</th>
              <th>Folder ID</th>
              <th>Name</th>
              <th>Creation date</th>
              <th>Folder type</th>
              <th>Status</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
          {
              isLoadingFId || updateTrigger ? <div className="admin-control-loading"><Spinner/></div>
              : folderIds.filter(folder => folder.folderStatus === 'archive').length !== 0 ? 
              <>
              {folderIds.filter(folder => folder.folderStatus === 'archive').map((folder, index) => (
                <tr key={index} className={chosenFolderIds.includes(folder._id) ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
                  <td key={index} className="admin-control-space-selector" onClick={() => folderIdToggle(folder._id)}>
                    <div className={chosenFolderIds.includes(folder._id) ? "admin-control-space-selected" : "admin-control-space-unselected"}>
                      {chosenFolderIds.includes(folder._id) && <img src={checker} alt='yes'/>}
                    </div>
                  </td>
                  <td>{folder.customerSpace}</td>
                  <td>{folder.id}</td>
                  <td>{folder.name}</td>
                  <td>{moment.utc(folder.createdAt).format('D MMMM yyyy')}</td>
                  <td>{folder.folderType}</td>
                  <td>{folder.folderStatus}</td>
                  <td>{folder.comments}</td>
                </tr>
              ))}
            </>
            : <div></div>
          }
          </tbody>
        </table>
        {folderIds.filter(folder => folder.folderStatus === 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no folder ids in archive</div>}
      </div>
      <>
        { isFIActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => fiActionsHandler('active')}>
                Unarchive
              </div>
              <div className="admin-register-actions-option-delete"
                onClick={() => fiActionsHandler('delete')}>
                Delete
              </div>
          </div>
        }
      </>
      </div>

      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Users
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <th>User name</th>
              <th>Email</th>
              <th>Added on</th>
              <th>Last access</th>
              <th>Status</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
          {
              isLoadingU || updateTrigger ? 
              <div className="admin-control-loading"><Spinner/></div>
              : users.filter(user => user.userStatus === 'archive').length !== 0 ? 
              <>
            {users.filter(user => user.userStatus === 'archive').map((user, index) => (
              <tr key={index} className={chosenUsers.includes(user._id) ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
                <td key={index} className="admin-control-space-selector" onClick={() => userToggle(user._id)}>
                  <div className={chosenUsers.includes(user._id) ? "admin-control-space-selected" : "admin-control-space-unselected"}>
                    {chosenUsers.includes(user._id) && <img src={checker} alt='yes'/>}
                  </div>
                </td>
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{moment.utc(user.createdAt).format('D MMMM yyyy')}</td>
                <td>{user.userStatus !== 'Inactive' &&  moment.utc(user.lastAccess).format('D MMMM yyyy')}</td>
                <td>{user.userStatus}</td>
                <td>{user.comments}</td>
              </tr>
            ))}
            </>
            : <div></div>
            }
          </tbody>
        </table>
        {users.filter(user => user.userStatus === 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no users in archive</div>}
      </div>
      <>
        { isUActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('Disactivated')}>
                Disactivate
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('Active')}>
                Activate
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('Active')}>
                Unarchive
              </div>
              <div className="admin-register-actions-option-delete"
                onClick={() => uActionsHandler('delete')}>
                Delete
              </div>
          </div>
        }
      </>
      </div>
      <ArchiveRegisterRowsTable/>
    </div>
   );
}
 
export default ArchiveControl;


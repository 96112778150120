import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import logo_icon from '../icons/majoto-logo-icon.svg'
import google_auth from '../icons/google-auth-icon.svg'
import emailjs from '@emailjs/browser';
import axios from 'axios'

const  ForgotPasswordPage = () =>  {
  const [formData, setFormData] = useState({
    email: '',
    code: '',
  })
  
  
  const template_id = 'template_gg2hbyp'
  const service_id = 'service_cdtgthm'
  const public_key = '3WYeIk6ploNZtWWLi'

  const {  email, code } = formData

  const [isCodeSent, setIsCodeSent] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || user) {
      navigate('/welcome')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const handleCodeChange = (e, index) => {
    const value = e.target.value;
    setFormData((prevFormData) => {
      const newCode = prevFormData.code.split('');
      newCode[index] = value;
      return {
        ...prevFormData,
        code: newCode.join(''),
      };
    });
    if (value !== '' && index < 5) {
      const nextInput = document.querySelector(`input[name=code-${index + 1}]`);
      if (nextInput) {
        nextInput.focus();
      }
    }
  };
  const handlePaste = (e) => {
    e.preventDefault();
    const pastedCode = e.clipboardData.getData('text').slice(0, 6);
    setFormData((prevFormData) => ({
      ...prevFormData,
      code: pastedCode,
    }));
    
    const inputs = document.querySelectorAll('.code-input');
    inputs.forEach((input, index) => {
      input.value = pastedCode[index] || '';
    });
  };
  const handleKeyDown = (e, index) => {
    if (e.key === "Backspace" && !formData.code.split('')[index]) {
      const prevInput = document.querySelector(`input[name=code-${index - 1}]`);
      if (prevInput) {
        prevInput.focus();
      }
    }
  };

  const generateResetCode = () => {
    const length = 6;
    let code = '';
  
    for (let i = 0; i < length; i++) {
      const randomDigit = Math.floor(Math.random() * 10);
      code += randomDigit;
    }
  
    return code;
  };

  const sendResetCode = async (e) => {
    e.preventDefault();
  
    // Generate the reset code
    const code = generateResetCode(); // Implement this function to generate a random code
  
    const emailData = {
      user_email: email,
      reset_code: code,
    };
    setIsLoading(true)
  
    try {
      // Send the reset code to the backend
      await axios.post('/api/reset-code', emailData);
  
      // Send the reset code via email using emailjs
      await emailjs.send(service_id, template_id, emailData, public_key);
  
      toast.success('Reset code sent');
      
      setIsCodeSent(true);
      setIsLoading(false);
    } catch (error) {
      toast.error('Failed to send reset code');
      console.error(error);
    }
  };

  const verifyResetCode  = async(e) => {
    e.preventDefault();

    try {
      const response = await axios.post('/api/verify-code', { code, email });
      if (response.data.isValid) {
        // Code is valid, allow the user to reset their password
        navigate('/reset-password', { state: { isValid: true, email } });
      } else {
        // Code is invalid, display an error message
        toast.error('Invalid reset code');
      }
    } catch (error) {
      toast.error('Failed to verify reset code');
      console.error(error);
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='auth-background'>
      <div className='auth-body'>
        {
          isLoading ?
            <Spinner />
          :
          <>
            <div>
              <img src={logo_icon} alt="logo"/>
            </div>
            {
              isCodeSent ? 
              <div className='auth-content'>
                <div className='auth-form-name'>
                  Enter reset code:
                </div>
                <form onSubmit={verifyResetCode}>
                  <div className='auth-form-group'>
                    <div className="reset-code-input">
                      {Array.from({ length: 6 }, (_, index) => (
                        <input
                          key={index}
                          type='text'
                          maxLength='1'
                          className="code-input"
                          name={`code-${index}`}
                          value={code[index] || ''}
                          onChange={(e) => handleCodeChange(e, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          onPaste={index === 0 ? handlePaste : undefined}
                        />
                      ))}
                    </div>
                  </div>
                  <div className='auth-form-bottom'>
                    <div className="auth-form-buttons">
                      <button type='submit' className='auth-btn'>
                        Verify code
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              :
              <div className='auth-content'>
                <div className='auth-form-name'>
                  Forgot Password?
                </div>
                <form onSubmit={sendResetCode}>
                  <div className='auth-form-group'>
                    <label htmlFor="email">Email address to send reset code</label>
                    <input
                      type='email'
                      className="input-field col s6"
                      id='email' name='email'
                      value={email}
                      placeholder='Email address'
                      onChange={onChange}
                    />
                  </div>
                  <div className='auth-form-bottom'>
                    
                    <div className="auth-form-buttons">
                      <button type='submit' className='auth-btn'>
                        Send code
                      </button>
                      <form action="https://majoto.app/api/googleAuth/" method="get" className='auth-form-icon' >
                        <label  htmlFor="google_auth">
                          <img src={google_auth}/>
                        </label>
                        <input id='google_auth' type="submit"/>
                      </form>
                    </div>
                  </div>
                </form>
              </div>
            }
          </>
        }
        
      </div>
    </div>
  )
}

export default ForgotPasswordPage
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ToastContainer} from 'react-toastify'
import { useEffect } from "react";
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomerSpaces} from "../../features/customerSpaces/customerSpaceSlice";
import { getUsers} from "../../features/users/userSlice";
import { getFolderIds } from "../../features/folderIds/folderIdSlice";
import { getFolderRegisters } from "../../features/folderRegister/folderRegisterSlice";
import CustomerSpacesTable from "./CustomerSpacesTable";
import FolderIdsTable from "./FolderIdsTable";
import UsersTable from "./UsersTable";
import RegisterRowsTable from "./RegisterRowsTable";

const RegisterControl = () => {

  const dispatch = useDispatch()

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [customerSpaceUpdateTrigger, setCustomerSpaceUpdateTrigger] = useState(false)
  const [folderUpdateTrigger, setFolderUpdateTrigger] = useState(false)
  const [userUpdateTrigger, setUserUpdateTrigger] = useState(false)
  const [registerUpdateTrigger, setRegisterUpdateTrigger] = useState(false)
  

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getFolderIds())
        dispatch(getUsers())  
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  useEffect(() => {
    if(customerSpaceUpdateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        setCustomerSpaceUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [customerSpaceUpdateTrigger])

  useEffect(() => {
    if(folderUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderIds())
        setFolderUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [folderUpdateTrigger])

  useEffect(() => {
    if(userUpdateTrigger){
      setTimeout(() => {
        dispatch(getUsers())  
        setUserUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [userUpdateTrigger])

  useEffect(() => {
    if(registerUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderRegisters())
        setRegisterUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [registerUpdateTrigger])

  return ( 
    <div className="admin-register-body">
      <ToastContainer/>
      <CustomerSpacesTable/>
      <FolderIdsTable/>
      <UsersTable/>
      <RegisterRowsTable/>
    </div>
   );
}
 
export default RegisterControl;
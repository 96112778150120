import { useCallback, useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setChosenComponent, 
  //updatingColumnsOrderInLevel1Columns, updatingColumnsOrderInLevel2Columns, updatingColumnsOrderInModuleColumns 
} from "../../../features/localDocument/editingStyleDocumentSlice";
import StyleDropColumn from "./StyleDropColumn";

const holder_style = {
  display: 'grid',
  padding: '12px',
  gap: '8px', 
  height: 'fit-content',
  overflow: 'hidden',
}
const container_style = {
  width:'100%',
  overflow: 'hidden',
  position: 'relative'
}

const StyleColumnsHolder = ({part, index, 
   moduleId, parentType, 
  setStylingPanel, setStylingComponent,
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveChildFromParent}) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()
  const [isShown, setIsShown] = useState(false)
  const [style, setStyle] = useState({})  

  useEffect(()=> {
    setStyle(prev => ({...holder_style, ...part.style,  height: '100%',}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...holder_style, ...part.style,  height: '100%',}))
  }, [part.style])


  const ref = useRef(null)
  useEffect(() => {
    const onHolderClick = (event) => {
      console.log('clicked on holder')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([part, part.parent, parentType]);
      setStylingPanel(prev=> true)
    }
  
    const holderRef = ref.current;
    holderRef.addEventListener("click", onHolderClick);
  
    return () => {
      holderRef.removeEventListener("click", onHolderClick);
    };
  }, [])
  const [{ handlerId }, drop] = useDrop({
    accept: ['part'],
    collect(smonitor) {
      return {  
        handlerId: smonitor.getHandlerId(),
      }
    },
    drop( ){ 
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined && item.parentType === parentType && item.columnData === undefined){
        moveChildFromParent(dragIndex, hoverIndex, item.part)
        item.index = hoverIndex
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType }
    },
    collect: (smonitor) => ({
      isDragging: smonitor.isDragging(),
    }),
    canDrag: () => {
      return isShown
    }
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const moveColumns = useCallback(
    (dragIndex, hoverIndex) => {
      // parentType === 'module' && dispatch(updatingColumnsOrderInModuleColumns([dragIndex, hoverIndex, moduleId, part.position]))
      // parentType === 'level1' && dispatch(updatingColumnsOrderInLevel1Columns([dragIndex, hoverIndex, moduleId, part.parent, part.position]))
      // parentType === 'level2' && dispatch(updatingColumnsOrderInLevel2Columns([dragIndex, hoverIndex, moduleId, part.parent, part.position]))
  }, []) 

  const renderColumn = useCallback((column, index) => {
    return (
      <StyleDropColumn 
        key={column.position}
        part = {column}
        index={index}
        moduleId = {moduleId} 
        parentData = {part.parent}
        holderData = {part.position}
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveColumns = {moveColumns}
      />
    )
  }, [])

  return (
    <div style={{...container_style, 
      borderRadius: '8px', opacity}} 
      className={chosenComponent.position=== part.position ? "resizeable-box-chosen" : 'resizeable-box'}
    ref={ref} data-handler-id={handlerId}
    >
      <div style={style}>
        {part.content.map((column, index) => 
        renderColumn(column, index))}
      </div>
    </div>
  )
}

export default StyleColumnsHolder
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import userService from './userService'

const initialState = {
  users: [],
  isErrorU: false,
  isSuccessU: false,
  isLoadingU: false,
  messageU: '',
}

// Create new user
export const createUser = createAsyncThunk(
  'users/create',
  async (userData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await userService.createUser(userData, token)
    } catch (error) {
      const messageU =
        (error.response &&
          error.response.data &&
          error.response.data.messageU) ||
        error.messageU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageU)
    }
  }
)

// Get user users
export const getUsers = createAsyncThunk(
  'users/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await userService.getUsers(token)
    } catch (error) {
      const messageU =
        (error.response &&
          error.response.data &&
          error.response.data.messageU) ||
        error.messageU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageU)
    }
  }
)

// Change user
export const changeUser = createAsyncThunk(
  'users/change',
  async (user,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await userService.changeUser(user,  token)
    } catch (error) {
      const messageU =
        (error.response &&
          error.response.data &&
          error.response.data.messageU) ||
        error.messageU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageU)
    }
  }
)

// Update user
export const updateUser = createAsyncThunk(
  'users/update',
  async (user,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await userService.updateUser(user,  token)
    } catch (error) {
      const messageU =
        (error.response &&
          error.response.data &&
          error.response.data.messageU) ||
        error.messageU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageU)
    }
  }
)

// Delete user user
export const deleteUser = createAsyncThunk(
  'users/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await userService.deleteUser(id, token)
    } catch (error) {
      const messageU =
        (error.response &&
          error.response.data &&
          error.response.data.messageU) ||
        error.messageU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageU)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoadingU = true
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.isLoadingU = false
        state.isSuccessU = true
        state.users.push(action.payload)
      })
      .addCase(createUser.rejected, (state, action) => {
        state.isLoadingU = false
        state.isErrorU = true
        state.messageU = action.payload
      })
      .addCase(getUsers.pending, (state) => {
        state.isLoadingU = true
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.isLoadingU = false
        state.isSuccessU = true
        state.users = action.payload
      })
      .addCase(getUsers.rejected, (state, action) => {
        state.isLoadingU = false
        state.isErrorU = true
        state.messageU = action.payload
      })
      .addCase(changeUser.pending, (state) => {
        state.isLoadingU = true
      })
      .addCase(changeUser.fulfilled, (state, action) => {
        state.isLoadingU = false
        state.isSuccessU = true
        let indexOfUser = state.users.findIndex((element) => element._id === action.payload.userId)
        state.users[indexOfUser] = action.payload.userBody
      })
      .addCase(changeUser.rejected, (state, action) => {
        state.isLoadingU = false
        state.isErrorU = true
        state.messageU = action.payload
      })
      .addCase(updateUser.pending, (state) => {
        state.isLoadingU = true
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.isLoadingU = false
        state.isSuccessU = true
        let indexOfUser = state.users.findIndex((element) => element._id === action.payload.userId)
        state.users[indexOfUser] = action.payload.userBody
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.isLoadingU = false
        state.isErrorU = true
        state.messageU = action.payload
      })
      .addCase(deleteUser.pending, (state) => {
        state.isLoadingU = true
      })
      .addCase(deleteUser.fulfilled, (state, action) => {
        state.isLoadingU = false
        state.isSuccessU = true
        state.users = state.users.filter(
          (user) => user._id !== action.payload.id
        )
      })
      .addCase(deleteUser.rejected, (state, action) => {
        state.isLoadingU = false
        state.isErrorU = true
        state.messageU = action.payload
      })
  },
})

export const { reset } = userSlice.actions
export default userSlice.reducer
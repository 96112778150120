import React, { useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch } from "react-redux";
import { changeLevel2Name, changeLevel3Name } from "../../features/localDocument/editingDocumentSlice";

const empty_container = {
  display: 'flex',
  cursor: 'grab',
  borderRadius: '8px',
  border: '1px dashed blue',
  background: '#FFFFFF',
  opacity:'0.5',
}
const empty_row ={
  padding: '8px',
  paddingRight: '12px',
  width: '9vw',
  height: '30px',
}
const drop_holder = {
  paddingTop:'30px',
  borderRadius: '8px',
  border: '1px dashed #7A7E7E',
  width: '9vw',
  zIndex: '0',
}

const MapLevel3 = ({ part, index, level3Position, moveLevel3, dropLevel3}) => {
  
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [name, setName] = useState(part.value === 'no' ? '' : part.name)

  const [{ handlerId }, drop] = useDrop({
    accept: ['level3'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    drop( item ){ 
      if(item.part.origin === 'sidebar'){
        dropLevel3(item.part )
      }else{
        if(item.part.parent !== part.parent){
          dropLevel3(item.part)
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragPosition = item.level3Position
      const hoverPosition = level3Position
      if (dragPosition === hoverPosition && item.part.parent === part.parent) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined ){
        moveLevel3(dragPosition, hoverPosition, item.part)
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: part.value !== 'no' ? 'level3' : 'undraggable',
    item: () => {
      return {  level3Position, part }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      //dispatch(removeEmptyComponents())
    }
  })
  
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div className="agreement-map-level-container">
      <div ref={ref} data-handler-id={handlerId}
      style={{opacity}}
      className='agreement-map-level-heading'>
        {part !== undefined ?
          (part.value === 'no' ?
            <div style={drop_holder}/>
          : part.position === 0 ?
          <div style={empty_container}>
            <div style={empty_row}>     </div>
          </div>
          :
          <>
            <div className="agreement-map-level3-num">{index+1}</div>
            <div className="agreement-map-level-name-container">
              <textarea className="agreement-map-level3-name" value={name} 
              onChange={(e) => {
                setName(e.target.value)
                dispatch(changeLevel3Name([part.parent, part.position, e.target.value]))}}
              /> 
            </div>
          </>
          ) 
        : <></>}
      </div>
    </div>
  )
}


export default MapLevel3;
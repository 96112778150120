import {Text, StyleSheet } from '@react-pdf/renderer';

const TextPDF = ({part, index}) => {

  const styles = StyleSheet.create({
    text: part.style
  })

  return (
    <Text style={styles.text}>
      {part.content}
    </Text>
  )
};

export default TextPDF

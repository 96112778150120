
import { useDispatch, useSelector } from "react-redux"
import React, {useRef, useState } from "react";
import { useDrop } from "react-dnd";
import StyleLevel1 from "./style_parts/StyleLevel1";
import LevelChoserForm from "./LevelChoserForm";
import StyleModule from "./style_parts/StyleModule";
import ComponentsPanel from "./StyleEditorComponentsPanel";
import SidePanel from "./StyleEditorSidePanel";
import { addModuleStyleDoc } from "../../features/localDocument/editingStyleDocumentSlice";


const StyleEditor = ({editingStyleDocument, viewEditorMode, styleEditorPanel}) => {

  const dispatch = useDispatch()
  const [chosenStyle, setChosenStyle] = useState('undefined')

  const user = useSelector(state => state.auth)
  
  const [levelChoserForm, setLevelChoserForm] = useState(false)

  const [styleForm, setStyleForm] = useState(false)

  const [sidePanel, setSidePanel] = useState(false)
  
  const [currentStyle, setCurrentStyle ] = useState({})
  const [stylingComponent, setStylingComponent] = useState(['no',])


  const ref = useRef(null)
  const [{isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['level', 'module'],//, 'component' 
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      handlerId: monitor.getHandlerId(),
    }),
    drop( item ){
      if(isOverCurrent){
        if(item.type === 'module'){
          dispatch(addModuleStyleDoc())
        } else{
          setLevelChoserForm(true)
        }
      }
    },
  })
  drop(ref)
  //робити спадкування для відображення структури поки що не потрібно
  // можна просто відозмінювати вигляд дроп поля

  return (
     <>
     { editingStyleDocument  ?
    <>
      <LevelChoserForm active={levelChoserForm} setActive={setLevelChoserForm}/>
      <div className="document-editor">
        {editingStyleDocument.body !== undefined ?
        <div ref={ref} data-handler-id={handlerId}>
          {editingStyleDocument.body.length !== 0 ?
            <div className="document-editor-body" 
            style={{border: isOverCurrent ? '2px solid blue' : ''}}>
              {editingStyleDocument.body.map( (part, index) => {
                switch(part.type){
                  case 'module': return <StyleModule part={part} key={index}
                  setSidePanel={setSidePanel} setStylingComponent={setStylingComponent}
                  setCurrentStyle={setCurrentStyle}/>
                  case 'level1': return <StyleLevel1 part={part} key={index} setModuleShow={()=> {}}
                  setSidePanel={setSidePanel} setStylingComponent={setStylingComponent}
                  setCurrentStyle={setCurrentStyle}/>
                }
              })}
            </div>
          :
          <div className="document-editor-body" 
          style={{border: isOverCurrent ? '2px solid blue' : ''}}>
            Drop level here
          </div>}
        </div>
        : <></>} 
        { sidePanel ?
          <SidePanel active={sidePanel} setSidePanel={setSidePanel} 
            stylingComponent={stylingComponent}/>
          :
          <ComponentsPanel chosenStyle={chosenStyle} setChosenStyle={setChosenStyle} viewEditorMode={viewEditorMode}
          styleEditorPanel={styleEditorPanel}/>
          }
      </div>
    </>
    :<></>
    }
    </>
   );
}
 
export default StyleEditor;
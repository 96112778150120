import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import logo_icon from '../icons/majoto-logo-icon.svg'
import google_auth from '../icons/google-auth-icon.svg'
import axios from 'axios'

const  ResetPasswordPage = () =>  {
  const [formData, setFormData] = useState({
    newPassword: '',
    confirmPassword: '',
  })

  const location = useLocation();
  const isValid = location.state?.isValid;
  const email = location.state?.email;

  useEffect(() => {
    if (!isValid) {
      // If isValid is not true, redirect the user back to the verification page
      navigate('/forgot-password');
    }
  }, [isValid]);

  const {  newPassword, confirmPassword } = formData

  const [isLoading, setIsLoading] = useState(false)

  const navigate = useNavigate()
  const dispatch = useDispatch()


  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const changePassword = async(e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      toast.error('New password and confirm password do not match');
      return;
    }
    setIsLoading(true);

    try {
      const response = await axios.post('/api/reset-password', { email, newPassword });
      if (response.data.success) {
        toast.success('Password changed successfully');
        setIsLoading(false);
        setTimeout( () => {
          navigate('/login');
        }, [1000])
      } else {
        setIsLoading(false);
        toast.error('Failed to change password');
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error('Failed to change password');
      console.error(error);
    }
  };

  return (
    <div className='auth-background'>
      <div className='auth-body'>
        {
          isLoading ?
            <Spinner />
          :
          <>
            <div>
              <img src={logo_icon} alt="logo"/>
            </div>
            <div className='auth-content'>
              <div className='auth-form-name'>
                Enter new password
              </div>
              <form onSubmit={changePassword}>
                <div className='auth-form-group'>
                  <label htmlFor="new_password">New password</label>
                  <input
                    type='password'
                    className="input-field col s6"
                    id='new_password' name='newPassword'
                    value={newPassword}
                    placeholder=''
                    onChange={onChange}
                    autoComplete="off"
                  />
                </div>
                <div className='auth-form-group'>
                  <label htmlFor="confirm_password">Confirm password</label>
                  <input
                    type='password'
                    className="input-field col s6"
                    id='confirm_password' name='confirmPassword'
                    value={confirmPassword}
                    placeholder=''
                    onChange={onChange}
                    autoComplete="off"
                  />
                </div>
                <div className='auth-form-bottom'>
                  
                  <div className="auth-form-buttons">
                    <button type='submit' className='auth-btn'>
                      Change password
                    </button>
                    <form action="https://majoto.app/api/googleAuth/" method="get" className='auth-form-icon' >
                      <label  htmlFor="google_auth">
                        <img src={google_auth}/>
                      </label>
                      <input id='google_auth' type="submit"/>
                    </form>
                  </div>
                </div>
              </form>
            </div>
          </>
        }
        
      </div>
    </div>
  )
}

export default ResetPasswordPage
import { useDrop } from 'react-dnd'
import React, {  useEffect, useRef } from "react";
import storeBasket from "../../icons/right-panel-archive.svg"
import closeButton from "../../icons/right-panel-close.svg"
import Spinner from "../Spinner"
import { useDispatch, useSelector} from 'react-redux'
import { addItemToStore, deleteItemFromMap } from "../../features/localDocument/editingDocumentSlice"
import MapTrashComponent from './MapTrashComponent';


const MapStore = ({panelOption, setIsPanelOpen}) => {

  const dispatch = useDispatch()
  const ref = useRef(null)
  const { store } = useSelector(
    (state) => state.editingDocument
  )

  const [{  }, drop] = useDrop({
    accept: ['part', 'level1', 'level2', 'level3'],
    drop( item ){
      if(item.hasOwnProperty('module')){
        dispatch(deleteItemFromMap([item.module,  'module']))
        dispatch(addItemToStore(item.module))
      } else {
        if(item.hasOwnProperty('part')){
          dispatch(deleteItemFromMap([item.part,  item.part.typeOfContent]))
          dispatch(addItemToStore(item.part))
        }
      }
    }
  })
  drop(ref)

  return ( 
    <div ref={ref} className='agreement-map-right-panel'>
      <div className='agreement-map-right-panel-header'>
        <div className='agreement-map-right-panel-header-tittle'>
          <img src={storeBasket} alt="basket"/>
          <div>Store</div>
        </div>
        <div className="agreement-map-right-panel-closer"
        onClick={() => setIsPanelOpen({...panelOption, isOpen: false})}>
        <img src={closeButton} alt="close"/>
        </div>
      </div>
      {store ? 
        <div className='agreement-map-right-panel-content'>
          {store.map((storeItem, index) => 
          <MapTrashComponent key={index} item={storeItem} index={index}/>
          )}
        </div>
      :
      <Spinner/> 
      }
    </div>
  );
}
 
export default MapStore;

export const MapStoreSmall = ({panelOption, setIsPanelOpen}) => {

  const ref = useRef(null)

  const [{ isOverCurrent }, drop] = useDrop({
    accept: ['part', 'level1', 'level2', 'level3'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
      }
    },
    drop( ){
    },
  })
  useEffect( () => {
    setTimeout(() => {
      isOverCurrent && setIsPanelOpen({...panelOption, option:'store', isOpen: true})
    }, 200)
  }, [isOverCurrent])

  drop(ref)
  return (  
  <div ref={ref} className='agreement-map-right-panel-option'
    onClick={() => setIsPanelOpen({...panelOption, option:'store', isOpen: true})}>
    <img src={storeBasket} alt="basket"/>
  </div>
  )
}
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import invitationService from './invitationService'

const initialState = {
  invitedUser: {},
  isErrorIU: false,
  isSuccessIU: false,
  isLoadingIU: false,
  messageIU: '',
}

// get invited user
export const getInvitedUser = createAsyncThunk(
  'invitedUser/put',
  async (token,  thunkAPI) => {
    try {
      return await invitationService.getInvitedUser(token)
    } catch (error) {
      const messageIU =
        (error.response &&
          error.response.data &&
          error.response.data.messageIU) ||
        error.messageIU ||
        error.toString()
      return thunkAPI.rejectWithValue(messageIU)
    }
  }
)

export const invitationSlice = createSlice({
  name: 'invitedUser',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getInvitedUser.pending, (state) => {
        state.isLoadingIU = true
      })
      .addCase(getInvitedUser.fulfilled, (state, action) => {
        state.isLoadingIU = false
        state.isSuccessIU = true
        state.invitedUser = action.payload 
      })
      .addCase(getInvitedUser.rejected, (state, action) => {
        state.isLoadingIU = false
        state.isErrorIU = true
        state.messageIU = action.payload
      })
  },
})

export const { reset } = invitationSlice.actions
export default invitationSlice.reducer
import React, { useCallback, useEffect, useState } from 'react'
import update from 'immutability-helper'
import MapSideBarItem from './MapSideBarItem';
import ModuleForm from '../ModuleForm';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import MapModule from './MapModule';
import MapRightPanel from './MapRightPanel';


const DocumentMap = ({editingDocument}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector( (state) => state.auth)
  
  // const { editingDocument } = useSelector(
  //   (state) => state.editingDocument
  // )

  const [partList, setPartList] = useState('no')
  const [moduleForm, setModuleForm] = useState(false)
  const [renderNewState, setRenderNewState] = useState(false)

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
    if(editingDocument) {
      setPartList(prev => editingDocument.body)
    }
  }, [user, navigate, dispatch, editingDocument])

  const [deleteChecker, setDeleteChecker] = useState(false)

  useEffect(() => {
    renderNewState && addNewModuleonPage()   
  }, [renderNewState])

  useEffect(() => {
    deleteChecker &&  rerenderAndNulling()
  }, [deleteChecker])

  const addNewModuleonPage = () =>{
    setPartList(prevState => editingDocument.body);
    renderNewPart();
   }

  const rerenderAndNulling = () => {
    setPartList(prevState => editingDocument.body);
    setDeleteChecker(false);
  }

  const renderNewPart = () => {
    console.log('Local state after dispatch : ', partList)
    renderModule(partList.slice(-1), partList.slice(-1).index);
    setModuleForm(false);
    setRenderNewState(false);
  }
 
  const moveModule = useCallback(
    (dragIndex, hoverIndex) => {
    setPartList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, [])
  const renderModule = useCallback((part, index) => {
    return (
      <MapModule
        key={part.id}
        index={index}
        module={part}
        moveModule={moveModule}
      />
    )
  }, [])
  return (
    <>
    { editingDocument && partList !== undefined && partList !== 'no' ? 
      <>
        <div className='document-map'>
          <ModuleForm active ={moduleForm} setActive ={setModuleForm}
          setRenderNewState={setRenderNewState}/>
            <div className='map-buttons-container'> 
              <div className='map-button-add' onClick={() => setModuleForm(true) }>
                + Add module </div>
                <MapSideBarItem type={'level1'}/>
                <MapSideBarItem type={'level2'} />
                <MapSideBarItem type={'level3'} />
            </div>
            <div className="agreement-map-container">
              {partList.map((part, i) => renderModule(part, i))}
            </div>
        </div>
        <MapRightPanel/>
          
      </>
  : <></>}
  </>
    
  )
    }
export default DocumentMap;

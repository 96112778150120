import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import moment from 'moment'
import template_img_1 from "../icons/template-img-1.svg"
import more from "../icons/more-horizontal-icon.svg"
import plus from '../icons/plus-icon.svg'
import check from '../icons/stop-circle-icon.svg'
import duplicate from '../icons/duplicate-icon.svg'
import share from '../icons/share-icon.svg'
import trash from '../icons/trash-icon.svg'
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import DeleteDocumentForm from "./DeleteDocumentForm";
import { getDocuments} from "../features/documents/documentSlice";
import useOnclickOutside from "react-cool-onclickoutside";
import { useCreateNewDocument } from "../hooks/useCreateNewDocument";
import { useLocationChange } from "../hooks/useLocationChange";


const DocumentsBoard = ({documents, isLoading}) => {

  const [dropActions, setDropActions ] = useState('no')
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [deleteForm, setDeleteForm] = useState(false)
  const [chosenDocument, setChosenDocument] = useState('')

  const dispatch = useDispatch()

  const ref = useOnclickOutside(() => {
    setDropActions(prev=> 'no')
  })
  useLocationChange((location) => { 
    setUpdateTrigger(prev=> true)
  })
  
  const createNewDocument = useCreateNewDocument('templates')

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getDocuments())
        setUpdateTrigger(prev=>false)
      },300)
    }
  }, [updateTrigger])

  const deleteDocument = (document) =>{
    setChosenDocument(prev => document); 
    setDropActions(prev => false); 
    setDeleteForm(prev=>true);  
  }

  return (
		<>
      {isLoading || updateTrigger || documents === undefined ? 
      <div className="documents-board-loading-container">
        <Spinner /> 
      </div> 
      : documents.length === 0 ? 
      <div className="create-template">
        <div className="create-template-description">
          You haven’t built a template yet. You can build a new template from scratch, or drag & drop an existing template into this space as your starting point.
        </div>
        <div className="create-new" onClick={() => createNewDocument()}>
          Create new
        </div>
      </div> 
      :
      <>
      <div className="templates-list">
       {documents.map(document => (
        <div className="template" key={document._id}>
          <div className="template-plate">
            <img src={template_img_1} alt="template" />
            <div className="template-plate-content">
              <div className="template-plate-name">
                { document.name }
                <div className="template-droplist-header" ref={ref}
                onClick={() => dropActions === 'no' ? setDropActions(prev => document._id) 
                : setDropActions(prev => 'no')}>
                  <img src={more} alt="more"/>
                  { dropActions !== '' && dropActions=== document._id ?
                    <div className="template-droplist-body">
                      <div style={{position:'relative'}}>
                        <div>
                          <Link className="template-droplist-option" to={"/d/"+document._id+"/edit"}>Open</Link>
                        </div>
                        <div className="template-droplist-option">
                          <img src={plus}/>
                          Create new draft
                        </div>
                        <div className="template-droplist-option">
                          <img src={check}/>Check status
                        </div>
                        <div className="template-droplist-option">
                          <img src={duplicate}/>  Duplicate
                        </div>
                        <div className="template-droplist-option">
                          <img src={share}/>  Share
                        </div>
                        <div className="template-droplist-option" onClick={() => {deleteDocument(document)}}>
                          <img src={trash}/>  
                          Delete
                        </div>
                      </div>
                    </div> 
                  :<></>}
                </div>
              </div>
              <div className="template-plate-date">
                {moment(document.updatedAt).format('D MMMM, YYYY')}
              </div>
            </div>
          </div>
        </div>  
        ))}
      </div>
      <DeleteDocumentForm active={deleteForm} setActive={setDeleteForm} 
      doc={chosenDocument} setUpdate={setUpdateTrigger}/>
    </>
    }
  </>
  );
}
 
export default DocumentsBoard;
import moment from "moment";
import { useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import AgreementDropdownOptions from "./AgreementDropdownOptions";
import more from "../../icons/more-horizontal-icon.svg"

const AgreementTile = ({tile}) => {
  
  const [ isChosen, setIsChosen] = useState(false)
  const [isDropList, setIsDropList] = useState(false)
  
  const ref = useOnclickOutside(() => {
    isChosen && setIsChosen(prev => false);
  })

  return ( 
    <div className="agreement-plate" key={tile._id}>
      <div className="agreement-name-holder">
        <div className="agreement-name">
          <div className="name">
            { tile.name }
          </div>
          <div className="agreement-three-dots" ref={ref}
            onClick={() =>{setIsChosen(prev=>!prev); setIsDropList(prev => true)}}>
            <img src={more} alt="more"/>
            {isDropList && isChosen && 
              <AgreementDropdownOptions id={tile._id}/>
              }
          </div>
        </div>
        <div className="plate-content">
          <div id='agreement-date'>
            { moment(tile.updatedAt).format('D MMMM, YYYY') }
            </div>
        </div>
      </div>
      <div className="plate-content">
        <div className="agreement-company"/>
        <div className="agreement-company-name">
          {tile.parties}
        </div>
      </div>
    </div>
   );
}
 
export default AgreementTile;
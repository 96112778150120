import { useEffect} from "react";
import { useDrag } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { getDesignItems } from "../../features/designItems/designItemSlice";
import Spinner from "../Spinner";

const design_item_style = {
  cursor:'grab',
  padding: '12px ',
  background: '#FEF7F8',
  textAlign: 'center',
  boxShadow: '0px 1px 6px 1px rgba(0, 0, 0, 0.15)',
  borderRadius: '12px',
}

const DesignItem = ({item}) => {

  const typeToDrag = (item.body.typeOfContent === 'module' || item.body.typeOfContent.startsWith('level')) ?
  item.body.typeOfContent : 'component'

  const [{ opacity }, drag] = useDrag({
    type: typeToDrag,
    item: () => { return {
      name: item.name,
      body: item.body,
      type : 'design_item'
    }},
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  return (
    <div className="select-option"  
    ref={drag} style={{...design_item_style, opacity}}
    >
      <div className="select-option-tittle">
        { item.name }
      </div>
    </div> 
  )


}


const DesignItemsList = () => {

  const dispatch = useDispatch()

  const { designItems, isLoading, isError, message } = useSelector(
    (state) => state.designItems
  )

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }
    dispatch(getDesignItems())

  }, [ isError, message, dispatch])

  return ( 
    designItems.length !== 0 ?
    <div className="dropdown-styles-container">
      {isLoading ? <Spinner/> 
        : designItems.map((item, id) =>
          <DesignItem key={id} item={item}/>
        )}
    </div>
    : 
    <div className="select-option">
        There are no design items yet
    </div>
   );
}
 
export default DesignItemsList;
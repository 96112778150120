import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import arrow from "../../icons/arrow-icon.svg"
import plus from '../../icons/plus-grey-icon.svg'
import save from '../../icons/save-admin-row-icon.svg'
import upload from '../../icons/upload-logo-icon.svg'
import close from '../../icons/right-panel-close.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { createCustomerSpace } from "../../features/customerSpaces/customerSpaceSlice";
import CustomerSpacesTableRow from "./CustomerSpacesTableRow";
import { convertImageToBase64viaFileReader } from "./admin_page_functions";
import CustomerDurationSelector from "./CustomerDurationSelector";

const CustomerSpacesTableBody = ({tableData, setUpdateTrigger, customerGroupToggle}) => {

  const dispatch = useDispatch()

  const [customerSpacesData, setCustomerSpacesData] = useState([])

  useEffect(() => {
    setCustomerSpacesData(tableData)
  }, [tableData]);

  const [customerSpaceName, setCustomerSpaceName] = useState('');
  const [customerSpaceStartDate, setCustomerSpaceStartDate] = useState('');
  const [customerSpaceDuration, setCustomerSpaceDuration] = useState({ duration: "3", type: "months"});
  const [isDurationSelector, setIsDurationSelector] = useState(false)
  const [customerSpacePayment, setCustomerSpacePayment] = useState('');
  const [customerSpaceType, setCustomerSpaceType] = useState('Sandbox');
  const [customerSpaceComments, setCustomerSpaceComments] = useState('');
  const [customerSpaceLogoUpload, setCustomerSpaceLogoUpload] = useState('');
  const [customerSpaceLogo, setCustomerSpaceLogo] = useState({
    small: '',
    site: '',
    origin: ''
  });
  
  const [isCustomerTypeDown, setIsCustomerTypeDown] = useState(false)
  const refCustomerType = useOnclickOutside(() => {
    isCustomerTypeDown && setIsCustomerTypeDown(false);
  })
  
  const imageUploadHandler = (e) => {
    setCustomerSpaceLogoUpload(e.target.files[0])
  }

  useEffect(() => {
    if (!customerSpaceLogoUpload) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(customerSpaceLogoUpload)
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      // Create resized images
      const canvasSmall = document.createElement('canvas');
      const canvasSite = document.createElement('canvas');

      const contextSmall = canvasSmall.getContext('2d');
      const contextSite = canvasSite.getContext('2d');

      canvasSmall.width = 36;
      canvasSmall.height = 36;
      contextSmall.drawImage(img, 0, 0, 36, 36);

      canvasSite.width = 120;
      canvasSite.height = 120;
      contextSite.drawImage(img, 0, 0, 120, 120);

      // Convert images to base64
      const base64Small = canvasSmall.toDataURL(customerSpaceLogoUpload.type);
      const base64Site = canvasSite.toDataURL(customerSpaceLogoUpload.type);

      // Update logo object
      convertImageToBase64viaFileReader(objectUrl, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, origin: base64Img}))
      })
      convertImageToBase64viaFileReader(base64Small, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, small: base64Img}))
      });
      convertImageToBase64viaFileReader(base64Site, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, site: base64Img}))
      })
    };

    // free memory when ever this component is unmounted
    return () => {
      setCustomerSpaceLogoUpload(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [customerSpaceLogoUpload])
  
  const [isCustomerSpaceAdding, setIsCustomerSpaceAdding] = useState(false)
  const [customerSpaceSavingStatus, setCustomerSpaceSavingStatus] = useState(false)

  const customerSpaceHandleSave = () => {
    if(customerSpaceName === '' || customerSpaceDuration === ''
    || customerSpacePayment === '' || customerSpaceType === ''){
      toast.error('Fill all inputs !', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
      setCustomerSpaceSavingStatus('Please, fill all fields')
    }else{
      setCustomerSpaceSavingStatus('Saving a new customer space')
      const spaceData = {
        users: [],
        name: customerSpaceName,
        start_date: customerSpaceStartDate,
        duration: customerSpaceDuration,
        payment_status: customerSpacePayment,
        type: customerSpaceType,
        comments: customerSpaceComments,
        logo: customerSpaceLogo,
      };
      toast.promise(
        dispatch(createCustomerSpace(spaceData)),
        {
          pending: 'Saving..',
          success: 'Saved new customer space',
          error: 'Promise rejected 🤯'
        }
      )
      setUpdateTrigger(true)
      setIsCustomerSpaceAdding(false)
      setCustomerSpaceName('')
      setCustomerSpaceStartDate('')
      setCustomerSpaceDuration('')
      setCustomerSpacePayment('')
      setCustomerSpaceType('Sandbox')
      setCustomerSpaceComments('')
      setCustomerSpaceLogo(({
        small: '',
        site: '',
        origin: ''
      }))
    }
  };

  return ( 
    <tbody>
      { customerSpacesData.length !== 0 ? 
        <>
        {customerSpacesData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((space, index) => 
          (<CustomerSpacesTableRow key={index} space={space} index={index} 
            customerGroupToggle={customerGroupToggle} 
            setUpdateTrigger={setUpdateTrigger}/>)
        )}
        {
          !isCustomerSpaceAdding ?
          <tr className="admin-control-usual-row"> 
            <td className="admin-control-space-selector" onClick={() => setIsCustomerSpaceAdding(true)}>
              <div className="admin-control-adding-row">
                <img src={plus} alt='yes'/>
              </div>
            </td>
          </tr>
          :
          <tr className="admin-control-usual-row">
          <td className="admin-control-form-save-td">
            <img className="admin-control-form-save" 
            onClick={customerSpaceHandleSave}
            src={save} alt="save"/>
          </td>
          <td>
            <input
              type="text"
              value={customerSpaceName}
              onChange={(e) => setCustomerSpaceName(e.target.value)}
              required
            />
          </td>
          <td>
            <DatePicker selected={customerSpaceStartDate} 
            onChange={(date) => setCustomerSpaceStartDate(date)} dateFormat="d MMMM yyyy"/>
          </td>
          <td onClick={() => {if(!isDurationSelector)setIsDurationSelector(true)}} >
            <CustomerDurationSelector isSelected={isDurationSelector} setIsSelected={setIsDurationSelector}
            editingHandler={()=> {}} 
            customerDuration={customerSpaceDuration} setCustomerDuration={setCustomerSpaceDuration}/>
            {!customerSpaceDuration.type ?
              ' '
              :
              customerSpaceDuration.type !== 'months' ?
              customerSpaceDuration.duration
              :
              customerSpaceDuration.duration+' months'
            }
            {/* <input
              type="text"
              value={customerSpaceDuration}
              onChange={(e) => setCustomerSpaceDuration(e.target.value)}
              required
            /> */}
          </td>
          <td>
            <input
              type="text"
              value={customerSpacePayment}
              onChange={(e) => setCustomerSpacePayment(e.target.value)}
              required
            />
          </td>
          <td>
            <div ref={refCustomerType} className="admin-selector-container">
              <div className="admin-selector-header" onClick={ () => setIsCustomerTypeDown(prev => !prev)}>
                {customerSpaceType}
                <img style={{marginLeft:'8px', transform:( isCustomerTypeDown && 'rotate(-180deg)'), 
                transition:'260ms linear all'}} src={arrow} alt="arrow"/>
              </div>
              { isCustomerTypeDown && 
              <div className="admin-selector-body">
                <div className="admin-selector-body-option" onClick={() =>  setCustomerSpaceType('Sandbox')}>
                  Sandbox
                </div>
                <div className="admin-selector-body-option" onClick={() =>  setCustomerSpaceType('Subscription')}>
                  Subscription
                </div>
              </div> }
            </div>
          </td>
          <td>
            <input
              type="text"
              value={customerSpaceComments}
              onChange={(e) => setCustomerSpaceComments(e.target.value)}
            />
          </td>
          <td className="admin-control-form-upload-td">
            <label className="admin-control-form-upload-label" htmlFor="upload_image">
              {customerSpaceLogo.small ? 
                <img className="admin-control-form-upload-logo" src={customerSpaceLogo.small} alt="Small Logo" />
                :
                <img className="admin-control-form-upload" src={upload} alt="upload"/>
              } 
            </label>
            <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
            className="admin-control-form-upload-input" type="file" accept="image/*"/>
          </td>
          <td>
            <img className="admin-control-form-close" 
            onClick={() => setIsCustomerSpaceAdding(false)}
            src={close} alt="close"/>
          </td>
        </tr>
        }
      </>
      : 
      <tr className="admin-control-usual-row">
        <td className="admin-control-form-save-td">
          <img className="admin-control-form-save" 
          onClick={customerSpaceHandleSave}
          src={save} alt="save"/>
        </td>
        <td>
          <input
            type="text"
            value={customerSpaceName}
            onChange={(e) => setCustomerSpaceName(e.target.value)}
            required
          />
        </td>
        <td>
          <DatePicker selected={customerSpaceStartDate} 
          onChange={(date) => setCustomerSpaceStartDate(date)} dateFormat="d MMMM yyyy"/>
        </td>
        <td onClick={() => {if(!isDurationSelector)setIsDurationSelector(true)}} >
          <CustomerDurationSelector isSelected={isDurationSelector} setIsSelected={setIsDurationSelector}
          editingHandler={()=> {}} 
          customerDuration={customerSpaceDuration} setCustomerDuration={setCustomerSpaceDuration}/>
          {!customerSpaceDuration.type ?
            ' '
            :
            customerSpaceDuration.type !== 'months' ?
            customerSpaceDuration.duration
            :
            customerSpaceDuration.duration+' months'
          }
          {/* <input
            type="text"
            value={customerSpaceDuration}
            onChange={(e) => setCustomerSpaceDuration(e.target.value)}
            required
          /> */}
        </td>
        <td>
          <input
            type="text"
            value={customerSpacePayment}
            onChange={(e) => setCustomerSpacePayment(e.target.value)}
            required
          />
        </td>
        <td>
          <div ref={refCustomerType} className="admin-selector-container" style={{position:'relative'}}>
            <div className="admin-selector-header" onClick={ () => setIsCustomerTypeDown(prev => !prev)}>
              {customerSpaceType}
              <img style={{marginLeft:'8px', transform:( isCustomerTypeDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isCustomerTypeDown && 
            <div className="admin-selector-body" >
              <div>
                <div className="admin-selector-body-option" onClick={() =>  setCustomerSpaceType('Sandbox')}>
                  Sandbox
                </div>
                <div className="admin-selector-body-option" onClick={() =>  setCustomerSpaceType('Subscription')}>
                  Subscription
                </div>
              </div>
            </div> }
          </div>
        </td>
        <td>
          <input
            type="text"
            value={customerSpaceComments}
            onChange={(e) => setCustomerSpaceComments(e.target.value)}
          />
        </td>
        <td className="admin-control-form-upload-td">
          <label className="admin-control-form-upload-label" htmlFor="upload_image">
            {customerSpaceLogo.small ? 
              <img className="admin-control-form-upload-logo" src={customerSpaceLogo.small} alt="Small Logo" />
              :
              <img className="admin-control-form-upload" src={upload} alt="upload"/>
            } 
          </label>
          <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
          className="admin-control-form-upload-input" type="file" accept="image/*"/>
        </td>
        <td>
          <img className="admin-control-form-close" 
          onClick={() => setIsCustomerSpaceAdding(false)}
          src={close} alt="close"/>
        </td>
      </tr> 
      }
    </tbody>
   );
}
 
export default CustomerSpacesTableBody;
import { View, StyleSheet } from '@react-pdf/renderer';
import { useCallback, useEffect } from 'react';
import TextPDF from './Text';
import LineFragment from './LineFragment';

const LevelFrament = ({part, index}) => {

  const styles = StyleSheet.create({
    level: part.style
  })

  const renderText = useCallback((child, index) => {
    return (
      <TextPDF 
        key={child.position}
        part = {child}
        index={index}/>
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <LineFragment 
        key={child.position}
        part = {child}
        parentStyle = {styles.level}
        index={index}/>
    )
  }, [])
  useEffect(() => {
    //console.log('styles.level  ',styles.level)

  }, [part])

  return (
    <View style={styles.level}>
      { 
     part.content && part.content.length !== 0 ?
      part.content.map((child, index) => 
        child.typeOfContent === 'text_field' ?
        renderText(child, index)
        : 
        // child.typeOfContent === "level1" ? 
        // renderLevel(child, index)
        // : 
        // child.typeOfContent === "columns_holder" ? 
        // renderColumnsHolder(child, index)
        // : 
        child.typeOfContent === 'heading' ? 
        renderText(child, index)
        : 
        child.typeOfContent === 'subheading' ? 
        renderText(child, index)
        : 
        child.typeOfContent === 'line'?
        renderLine(child, index) 
        :
        ''
      ) : ''
      }   
    </View>
  )
};

export default LevelFrament
import React, { useState } from "react";
import undo from "../../icons/right-panel-undo.svg"
import redo from "../../icons/right-panel-redo.svg"
import MapStore, { MapStoreSmall } from './MapStore';
import MapTrashBasket, { MapTrashSmall } from './MapTrash';


const MapRightPanel = () => {

  const [panelOption, setPanelOption ] = useState({
    isOpen: false,
    option: 'trash'
  })

  return ( 
    <>
      {
      panelOption.isOpen && panelOption.option === 'trash' ?
      <MapTrashBasket panelOption={panelOption} setIsPanelOpen={setPanelOption}/>
      :
      panelOption.isOpen && panelOption.option === 'store' ?
      <MapStore panelOption={panelOption} setIsPanelOpen={setPanelOption}/>
      :
      !panelOption.isOpen ?
      <div className='agreement-map-right-panel-small'>
        <MapTrashSmall panelOption={panelOption} setIsPanelOpen={setPanelOption}/> 
        <MapStoreSmall panelOption={panelOption} setIsPanelOpen={setPanelOption}/> 
        <div className='agreement-map-right-panel-hr'/>
        <div className='agreement-map-right-panel-option'><img src={undo} alt="undo"/></div>
        <div className='agreement-map-right-panel-option'><img src={redo} alt="redo"/></div>
      </div>
      : <></>
      }
    </>
  );
}
 
export default MapRightPanel;
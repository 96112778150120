import logout_icon from '../icons/logout-icon.svg'
import settings_icon from '../icons/settings-icon.svg'
import library_icon from '../icons/user-library-icon.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { getCustomerSpaces } from '../features/customerSpaces/customerSpaceSlice';

const IframeUserSideMenu = ({user, onLogout, iframeButtonTitle, 
  setIsSettingsOpen, setIsLibraryOpen}) => {

  const dispatch = useDispatch()
  const [isIframeMenuOpen, setIsIframeMenuOpen] = useState(false)

  const openUserSideMenu = () => {
    isIframeMenuOpen ? setIsIframeMenuOpen(false)
    :
    setIsIframeMenuOpen(true)
  }
  
  const sideMenuRef = useOnclickOutside(() => {
    setIsIframeMenuOpen(prev => false);
  })

  const [userCustomerSpace, setUserCustomerSpace] = useState('')
  const  { customerSpaces } = useSelector( state => state.customerSpaces)

  useEffect(() => {
    dispatch(getCustomerSpaces())
  }, [])

  const colors = [
    '#65C7ED',
    '#FEF2F6',
    '#FED86F',
    '#B0E0E6',
    '#FFA07A',
    '#98FB98',
  ];
  
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const colorMapping = {};
  
  for (let i = 0; i < alphabet.length; i++) {
    const letter = alphabet[i];
    const colorIndex = i % colors.length;
    colorMapping[letter] = colors[colorIndex];
  }
  
  useEffect(() => {
    if(customerSpaces && customerSpaces.length!== 0 && user.userCustomerSpace){
      const customerSpace = customerSpaces.find(group => group._id === user.userCustomerSpace)
      //console.log('user customer space : ',customerSpace)
      setUserCustomerSpace(customerSpace)
    }
  }, [customerSpaces])

  return ( 
  <div ref={sideMenuRef}>
    <div className="iframe-menu-button" onClick={openUserSideMenu}>
      {iframeButtonTitle}
    </div>
    { isIframeMenuOpen && 
    <div className="iframe-menu-container">
    <div className="iframe-menu-top-part" style={{backgroundColor: colorMapping[user.name.charAt(0).toUpperCase()]}}>
      {/* <div className="iframe-menu-user-logo">
        {userCustomerSpace && userCustomerSpace.logo && <img src={userCustomerSpace.logo.small} alt='logo'/>}
      </div> */}
      <div className="iframe-menu-letter">{user.name.charAt(0).toUpperCase()}</div>
    </div>
    <div className="iframe-menu-content">
      <div className="iframe-menu-container-title">
        <div className="iframe-menu-container-title-name">
          {user.name}
        </div>
        <div className="iframe-menu-container-title-email">
          {user.email}
        </div>
      </div>
      <div className="iframe-menu-footer">
        <div className="iframe-menu-footer-option-disabled" 
          //onClick={() => {setIsLibraryOpen(true); setIsSettingsOpen(false)}}
        >
          <div className="iframe-menu-footer-option-img">
            <img src={library_icon} alt="library"/>
          </div>
          Library
        </div>
        <div className="iframe-menu-footer-option-disabled" 
          //onClick={() => {setIsSettingsOpen(true); setIsLibraryOpen(false)}}
        >
          <div className="iframe-menu-footer-option-img">
            <img src={settings_icon} alt="settings"/>
          </div>
          Settings
        </div>
        <div className="iframe-menu-footer-option" onClick={onLogout}>
          <div className="iframe-menu-footer-option-img">
            <img src={logout_icon} alt="logout"/>
          </div>
          Logout
        </div>
      </div>
    </div>
    </div>
    }
    
  </div>
 );
}
 
export default IframeUserSideMenu;
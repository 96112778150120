import axios from 'axios'

const API_URL = '/api/agreements/'

// Create new agreement
const createAgreement = async (agreementData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, agreementData, config)

  return response.data
}

// Get user agreements
const getAgreements = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// change user document
const changeAgreement = async (agreementData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let agreementBody = agreementData
  let agreementId = agreementData._id;
  const response = await axios.post(API_URL + agreementId, agreementBody, config)

  return response.data
}
// update user document
const updateAgreement = async (agreementData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let agreementBody = agreementData
  let agreementId = agreementData._id;
  const response = await axios.put(API_URL + agreementId, agreementBody, config)

  return response.data
}

// Delete user agreement
const deleteAgreement = async (agreementId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + agreementId, config)

  return response.data
}

const agreementService = {
  createAgreement,
  getAgreements,
  changeAgreement,
  updateAgreement,
  deleteAgreement,
}

export default agreementService
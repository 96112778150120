import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getAgreements, reset } from '../features/agreements/agreementSlice';
import ShortMenu from '../components/ShortMenu';
import AgreementsList from '../components/agreements_components/AgreementsList';
import AgreementsTiles from '../components/agreements_components/AgreementsTiles';
import AgreementsGraph from '../components/agreements_components/AgreementsGraph';
import LaunchpadHeader from '../components/LaunchpadHeader';
import { useLocationChange } from '../hooks/useLocationChange';

const  Agreements = () => {

  const user = useSelector( (state) => state.auth)

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [agreementsView, setAgreementsView] = useState('tiles')

  useLocationChange((location, prevLocation) => { 
    console.log('changed from', prevLocation, 'to', location) 
  })

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }

    dispatch(getAgreements())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, dispatch])

  
  // const { error, isPending, data  } = useFetch('https://script.google.com/macros/s/AKfycbwRX0aPtNjUtalmxm255ldZnuz2z9zi_yZm4LPq/exec?func=getWebDocsList&user_id=test')
  // https://jsonplaceholder.typicode.com/users
  // isPending ? console.log(error) : console.log(data)
  
  return (
    <div className='content-board'>
      <ShortMenu chosenPage={'launchpad'}/>
      <div className='board-body'>
        {user.user ?
        <>
        <LaunchpadHeader stage={'agreements'} view={agreementsView} setView={setAgreementsView}/>
        {
          agreementsView === 'list' ? <AgreementsList/> 
          :
          agreementsView === 'tiles' ? <AgreementsTiles/>
          :
          agreementsView === 'graph' ? <AgreementsGraph/>
          :
          <></>
        }
        </>
        :
        <div className="message">Log in to get access to data</div>}
      </div>
    </div>
  );
}

export default Agreements;




const agreem = {
  incomplete : 0, 
  in_progress : 0, 
  sent : 0, 
  signed : 0, 
}

 class AnalyticsChart {
//   constructor(props) {
//     super(props);
//     console.log("Agreements inside props: "+props.agreements)
//     props.agreements.forEach((agreement)=>{
//       agreem[agreement.state]++
//     })


//     this.state = {
//       optionsBar: {
//         chart: {
//           stacked: true,
//           stackType: "100%",
//           toolbar: {
//             show: false
//           }
//         },
//         plotOptions: {
//           bar: {
//             horizontal: true
//           }
//         },
//         dataLabels: {
//           dropShadow: {
//             enabled: true
//           }
//         },
//         stroke: {
//           width: 0
//         },
//         xaxis: {
//           categories: ["Agreements in progress"],
//           labels: {
//             show: false
//           },
//           axisBorder: {
//             show: false
//           },
//           axisTicks: {
//             show: false
//           }
//         },
//         fill: {
//           colors: ['#D56858', '#7CAFDD', '#FBBF27', '#ED9A1F']
//         },
//         legend: {
//           position: "bottom",
//           horizontalAlign: "right"
//         }
//       },
//       seriesBar: [
//         {
//           name: "incomplete",
//           data: [agreem.incomplete]
//         },
//         {
//           name: "in_progress",
//           data: [agreem.in_progress]
//         },
//         {
//           name: "sent",
//           data: [agreem.sent]
//         },
//         {
//           name: "signed",
//           data: [agreem.signed]
//         }
//       ]
//     };
//   }


//   render() {
//     return (
//       <div id="chart">
       
//       </div>
//       );
//     }
  }

export default AnalyticsChart;
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux"
import moment from 'moment'
import style_img_1 from "../icons/style_image_1.svg"
import more from "../icons/more-horizontal-icon.svg"
import plus from '../icons/plus-icon.svg'
import check from '../icons/stop-circle-icon.svg'
import duplicate from '../icons/duplicate-icon.svg'
import share from '../icons/share-icon.svg'
import trash from '../icons/trash-icon.svg'
import Spinner from "./Spinner";
import { Link } from "react-router-dom";
import DeleteStyleDocForm from "./style_editor_components/DeleteStyleDocForm";
import { getStyles } from "../features/styles/styleSlice";
import useOnclickOutside from "react-cool-onclickoutside";
import { useCreateNewDocument } from "../hooks/useCreateNewDocument";
import { useLocationChange } from "../hooks/useLocationChange";


const StylesBoard = ({styles, isLoadingStyle}) => {

  const [dropActions, setDropActions ] = useState('no')
  const [deleteForm, setDeleteForm] = useState(false)
  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [activeDocument, setActiveDocument] = useState('')

  const dispatch = useDispatch()

  const ref = useOnclickOutside(() => {
    setDropActions(prev=> 'no')
  })
  useLocationChange((location) => { 
    setUpdateTrigger(prev=> true)
  })

  const createNewDocument = useCreateNewDocument('styles')

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getStyles())
        setUpdateTrigger(prev=>false)
      },300)
    }
  }, [updateTrigger])

  const deleteStyle = (document) =>{
    setActiveDocument(prev => document); 
    setDropActions(prev => false); 
    setDeleteForm(prev=>true);  
  }

  return (
    <>
      {isLoadingStyle || updateTrigger || styles === undefined ? 
      <div className="documents-board-loading-container">
        <Spinner /> 
      </div>
      : styles.length === 0 ?
      <div className="create-template">
        <div className="create-template-description">
          You haven’t built a style template yet. You can build a new style template from scratch, or drag & drop an existing template into this space as your starting point.
        </div>
        <div className="create-new" onClick={() => createNewDocument()}>
          Create new
        </div>
      </div>
      : 
      <>
      <div className="templates-list">
        {styles.map(style => (
        <div className="template" key={style._id}>
          <div className="template-plate">
            <img src={style_img_1} alt="style" />
            <div className="template-plate-content">
              <div className="template-plate-name">
                { style.name }
                <div className="template-droplist-header" ref={ref}
                onClick={() => dropActions === 'no' ? setDropActions(prev => style._id) 
                : setDropActions(prev => 'no')}>
                  <img src={more} alt="more"/>
                  { dropActions !== '' && dropActions=== style._id ?
                    <div className="template-droplist-body">
                      <div style={{position:'relative'}}>
                        <div>
                          <Link className="template-droplist-option" to={"/s/"+style._id+'/design'}>Open</Link>
                        </div>
                        <div className="template-droplist-option">
                          <img src={plus}/>
                          Create new draft
                        </div>
                        <div className="template-droplist-option">
                          <img src={check}/>Check status
                        </div>
                        <div className="template-droplist-option">
                          <img src={duplicate}/>  Duplicate
                        </div>
                        <div className="template-droplist-option">
                          <img src={share}/>  Share
                        </div>
                        <div className="template-droplist-option" onClick={() => {deleteStyle(style)}}>
                          <img src={trash}/>  
                          Delete
                        </div>
                      </div>
                    </div> 
                  :<></>} 
                </div>
              </div>
              <div className="template-plate-date">
                {moment(style.updatedAt).format('D MMMM, YYYY')}
              </div>
            </div>
          </div>
        </div>  
        ))}
      </div>
      <DeleteStyleDocForm active={deleteForm} setActive={setDeleteForm} 
        doc={activeDocument} setUpdate={setUpdateTrigger}/>
      </>
      }
    </>
  );
}
 
export default StylesBoard;
import { useState } from "react";

function getDefaultSorting(defaultTableData, columns) {
  //console.log(defaultTableData, columns)
  const pinned = [...defaultTableData].filter(row => row.isPinned === true)
  const unpinned = [...defaultTableData].filter(row => row.isPinned === false)

  const sortedPinned = [...pinned].sort((a, b) => {
    const filterColumn = columns.filter((column) => column.sortbyOrder);

    // Merge all array objects into single object and extract accessor and sortbyOrder keys 
    let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
      {},
      ...filterColumn
    );

    if (a[accessor] === undefined) return 1;
    if (b[accessor] === undefined) return -1;
    if (a[accessor] === undefined && b[accessor] === undefined) return 0;

    const ascending = a[accessor]
      .toString()
      .localeCompare(b[accessor].toString(), "en", {
        numeric: true,
      });

    return sortbyOrder === "asc" ? ascending : -ascending;
  });
  const sortedUnpinned = [...unpinned].sort((a, b) => {
    const filterColumn = columns.filter((column) => column.sortbyOrder);

    // Merge all array objects into single object and extract accessor and sortbyOrder keys 
    let { accessor = "id", sortbyOrder = "asc" } = Object.assign(
      {},
      ...filterColumn
    );

    if (a[accessor] === undefined) return 1;
    if (b[accessor] === undefined) return -1;
    if (a[accessor] === undefined && b[accessor] === undefined) return 0;

    const ascending = a[accessor]
      .toString()
      .localeCompare(b[accessor].toString(), "en", {
        numeric: true,
      });

    return sortbyOrder === "asc" ? ascending : -ascending;
  });
  const sorted = [...sortedPinned, ...sortedUnpinned]
  console.log('merged sorted ',sorted)
  return sorted;
}

export const useSortableTable = (data, columns) => {
  const [tableData, setTableData] = useState(getDefaultSorting(data, columns));

  const handleSorting = (sortField, sortOrder) => {
    if (sortField) {
      const pinned = [...tableData].filter(row => row.isPinned === true)
      const unpinned = [...tableData].filter(row => row.isPinned === false)
      const sortedPinned = [...pinned].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      const sortedUnpinned = [...unpinned].sort((a, b) => {
        if (a[sortField] === null) return 1;
        if (b[sortField] === null) return -1;
        if (a[sortField] === null && b[sortField] === null) return 0;
        return (
          a[sortField].toString().localeCompare(b[sortField].toString(), "en", {
            numeric: true,
          }) * (sortOrder === "asc" ? 1 : -1)
        );
      });
      const sorted = [...sortedPinned, ...sortedUnpinned]
      setTableData(sorted);
    }
  };

  return [tableData, handleSorting];
};
import { useCallback, useEffect, useRef, useState } from "react"
import { useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import StyleHeading from "./StyleHeading";
import StyleSubHeading from "./StyleSubHeading";
import StyleTextArea from "./StyleTextArea";
import StyleLevel1 from "./StyleLevel1";
import StyleLine from "./StyleLine";
import StyleImageHolder from "./StyleImageHolder";
import StyleColumnsHolder from "./StyleColumnsHolder";
import StyleDynamicBlock from "./StyleDynamicBlock";
import { mergeRefs } from "react-merge-refs";
import { addComponentInModuleStyleDoc, setChosenComponent, updatingComponentsOrderInStyleModule } from "../../../features/localDocument/editingStyleDocumentSlice"

const module_style = {
  position: 'relative',
}
const StyleModule = ({ part, 
  setSidePanel, setStylingComponent, 
  }) => {

  const [modulePages, setModulePages] = useState(1)
  
	const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const [isResizingOver, setIsResizingOver] = useState(true)

  const changeClickability = () => {
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver :',isResizingOver)
  }

  const useResize = (myRef) => {
    const [height, setHeight] = useState(0)
  
    const handleResize = () => {
      setHeight(prev => myRef.current.offsetHeight)
      if(myRef.current.offsetHeight>300){
        setModulePages(prev => 2)
      }
    }
  
    useEffect(() => {
      myRef.current && myRef.current.addEventListener('mouseover', handleResize)
      return () => {
        myRef.current && myRef.current.removeEventListener('mouseover', handleResize)
      }
    }, [myRef])
  
    return {height}
  }

  const componentRef = useRef()
  const {height} = useResize(componentRef)

  const dispatch = useDispatch()
  const [isClickable, setIsClickable] = useState(false)

  const ref = useRef(null)
  const [{isOverCurrent, handlerId }, drop] = useDrop({
    accept: [ 'component', 'level','columns_holder'],
    collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }),
    drop( item ){
      if(isOverCurrent){
        console.log('dropped in module: ', item)
        if(item.type !== undefined ){
          dispatch(addComponentInModuleStyleDoc([item, part.id ]))
        }
      }
    },
  })
  const hoverRes = isOverCurrent
  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
      (child.part === undefined && child.parent === part.id) &&
      dispatch(updatingComponentsOrderInStyleModule(
        [dragIndex, hoverIndex, part.id, child]))
    }, [])

  const renderLevel = useCallback((child, index) => {
    return (
      <StyleLevel1 
        key={index} part = {child} index={index}
        moduleId = {part.id} 
        changeClickability={changeClickability} 
        setSidePanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderDynamicBlock = useCallback((child, index) => {
    return (
      <StyleDynamicBlock 
        key={child.position} part={child} index={index}
        moduleId={part.id}
        parentData = {part.id} 
        parentType={'module'}
        changeClickability={changeClickability} 
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback((child, index) => {
    return (
      <StyleColumnsHolder 
        key={child.position} part = {child} index={index}
        moduleId = {part.id} 
        parentType = {'module'}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <StyleHeading 
        key={index} part = {child} index={index}
        parentData = {part.id} 
        parentType = {part.type}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <StyleSubHeading 
        key={index} part = {child} index={index}
        parentData = {part.id} 
        parentType = {part.type}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <StyleTextArea 
        key={index} part = {child} index={index}
        parentData = {part.id} 
        parentType = {part.type}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return ( 
      <StyleLine
        key={index} part = {child} index={index}
        parentData = {part.id} 
        parentType = {part.type}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <StyleImageHolder 
        key={child.position} part={child} index={index}
        changeClickability={changeClickability}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  drop(ref)

  useEffect(() => {
    const onModuleClick = () => {
      if(isResizingOver){
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, part.id]);
        setSidePanel(prev=> true)
      }
    }
    const moduleRef = ref.current;
    moduleRef.addEventListener("click", onModuleClick);

    return () => {
      moduleRef.removeEventListener("click", onModuleClick);
    };
  }, [isResizingOver])

  return (
    <div ref={mergeRefs([componentRef, ref])} data-handler-id={handlerId}  
    style={{...module_style, ...part.style,border: hoverRes || chosenComponent.id === part.id ? '2px solid blue' : '2px solid rgba(229,225,225,255)'}}
    >
    <div className="style-element-tittle-module">Module {part.name.slice(-1)}</div>
      {part.content.length !== 0 ?
         part.content.map( (child, index) => 
          child.type === "level1" ? 
            renderLevel(child, index)
            : child.type === "columns_holder" ? 
            renderColumnsHolder(child, index)
            : child.type === "dynamic_block" ? 
            renderDynamicBlock(child, index)
            : child.type === "heading" ? 
            renderHeading(child, index)
            : child.type === "subheading" ? 
            renderSubHeading(child, index)
            : child.type === "text_field" ? 
            renderTextArea(child, index)
            : child.type === "line" ? 
            renderLine(child, index)
            : <></>
          )
        :
        <></>}
    </div>
  )
}

export default StyleModule
import { useDispatch, useSelector } from "react-redux"
import React, { useEffect, useRef, useState } from "react";
import { useDrop } from "react-dnd";
import { addDesignedModule, newUpdateDocumentStyle, setDefaultDocumentStyle, updateDocumentClasses} from "../../features/localDocument/editingDocumentSlice";
import ModuleForm from "../ModuleForm";
import { getStyles } from "../../features/styles/styleSlice";
import ComponentsPanel from "./ComponentsPanel";
import Module from "./document_parts/Module";
import ImageUploadForm from "./ImageUploadForm";
import SidePanel from "./SidePanel";
import PageSizesForm from "./PageSizesForm";

const default_level_heading_style = {
  color: "#4A9DAC",
  fontSize: "20px",
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: "normal",
  textAlign: "center",
  padding: '8px',
  isUnicStyle: false,
}
const default_subheading_style = {
  color: "#4A9DAC",
  fontSize: "16px",
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: "normal",
  textAlign: "center",
  padding: '8px',
  isUnicStyle: false,
}
const default_component_style = {
  background: "#FFFFFF",
  color: "#000",
  fontSize: "14px",
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: "normal",
  alignItems: "center",
  padding: '20px 10px',
  isUnicStyle: false,
}
const default_border_component_style = {
  background: "#FFFFFF",
  color: "#000",
  fontSize: "14px",
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: "normal",
  alignItems: "center",
  padding: '20px 10px',
  outline:'1px solid rgb(229, 225, 225)',
  borderRadius:'8px',
  isUnicStyle: false,
}
const default_column_component_style = {
  background: "#FFFFFF",
  color: "#000",
  fontSize: "14px",
  fontStyle: "normal",
  fontFamily: 'Montserrat',
  fontWeight: "normal",
  alignItems: "center",
  padding: '20px 10px',
  isUnicStyle: false,
}
const DocumentEditor = ({editingDocument, viewEditorMode}) => {

  const dispatch = useDispatch()
  const [chosenStyle, setChosenStyle] = useState('undefined')
  const [changeClassTrigger, setChangeClassTrigger] = useState(false)
  
  const { styles } = useSelector(state => state.styles)

  const [pageSizesForm, setPageSizesForm] = useState(false)
  const [moduleForm, setModuleForm] = useState(false)
  const [imageForm, setImageForm] = useState(false)

  const [sidePanel, setSidePanel] = useState(false)

  const [stylingComponent, setStylingComponent] = useState(['no',])
  const [uploadImageData, setUploadImageData] = useState()
  const [uploadImageParent, setUploadImageParent] = useState()
  const [applyingStyle, setApplyingStyle] = useState('default')


  useEffect(() => {
    dispatch(getStyles())
  }, [dispatch, editingDocument])

  
  const applyChosenStyle = () => {
    if(chosenStyle !== 'default'){
      const indexOfStyle = styles.findIndex(style => style._id === chosenStyle)
      console.log('indexOfStyle  ',indexOfStyle, chosenStyle)
      const newStyle = {...styles[indexOfStyle].classes, _id: styles[indexOfStyle]._id}
      //dispatch(newUpdateDocumentStyle([newStyle]))
      dispatch(updateDocumentClasses([newStyle]))
    } else {
      dispatch(setDefaultDocumentStyle())
    }
  }

  useEffect(() => {
    if(editingDocument.body && chosenStyle !== undefined && chosenStyle !== 'undefined'){
      applyChosenStyle()
      console.log('apply new style   ')
    }
  }, [changeClassTrigger])

  const ref = useRef(null)
  const [{isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['module'],// 'component' ],
    collect: (monitor) => ({
      isOverCurrent: monitor.isOver({ shallow: true }),
      handlerId: monitor.getHandlerId(),
    }),
    drop( item ){
      if(isOverCurrent){
        if(item.type === 'module') {
          setModuleForm(prevState => true)
        } else {
          if(item.body.typeOfContent === 'module'){
            dispatch(addDesignedModule(item))
          }
        }
      }
    }
  })
  drop(ref)

  return (
     <>
     { editingDocument ?
    <>
      <PageSizesForm active={pageSizesForm} setActive={setPageSizesForm}/>
      <ImageUploadForm active={imageForm} setActive={setImageForm} 
        parentData={uploadImageParent} imageData={uploadImageData}/>
      <ModuleForm active ={moduleForm} setActive ={setModuleForm}/>
      <div className="document-editor">
        {editingDocument.body !== undefined ?
        <div ref={ref} data-handler-id={handlerId}>
          {editingDocument.body.length !== 0 ?
            <div className="document-editor-body" 
            style={{border: isOverCurrent ? '2px solid blue' : ''}}>
              {editingDocument.body.map( (part, index) => {
                switch(part.typeOfContent){
                  case 'module': return <Module part={part} key={index}
                  setSidePanel={setSidePanel} setStylingComponent={setStylingComponent}
                  setImageForm={setImageForm}
                  setUploadImageData={setUploadImageData} setUploadImageParent={setUploadImageParent}/>
                }
              })}
            </div>
            :
            <div className="document-editor-body" 
            style={{border: isOverCurrent ? '2px solid blue' : ''}}>
              Drop module here
            </div>}
          </div>
          : <></>}
        { sidePanel ?
          <SidePanel active={sidePanel} setSidePanel={setSidePanel} 
            stylingComponent={stylingComponent} classes={editingDocument.classes}/>
          :
          <ComponentsPanel chosenStyle={chosenStyle} setChosenStyle={setChosenStyle} 
          viewEditorMode={viewEditorMode} setChangeClassTrigger={setChangeClassTrigger}/>
          }
      </div>
    </>
    :<></>
    }
    </>
   );
}
 
export default DocumentEditor;


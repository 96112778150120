import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import folderIdService from './folderIdService'

const initialState = {
  folderIds: [],
  isErrorFId: false,
  isSuccessFId: false,
  isLoadingFId: false,
  messageF: '',
}

// Create new folderId
export const createFolderId = createAsyncThunk(
  'folderIds/create',
  async (folderIdData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderIdService.createFolderId(folderIdData, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Get user folderIds
export const getFolderIds = createAsyncThunk(
  'folderIds/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderIdService.getFolderIds(token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Change folderId
export const changeFolderId = createAsyncThunk(
  'folderIds/change',
  async (folderId,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderIdService.changeFolderId(folderId,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Update folderId
export const updateFolderId = createAsyncThunk(
  'folderIds/update',
  async (folderId,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderIdService.updateFolderId(folderId,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Delete user folderId
export const deleteFolderId = createAsyncThunk(
  'folderIds/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderIdService.deleteFolderId(id, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

export const folderIdSlice = createSlice({
  name: 'folderId',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFolderId.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(createFolderId.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.folderIds.push(action.payload)
      })
      .addCase(createFolderId.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(getFolderIds.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(getFolderIds.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.folderIds = action.payload
      })
      .addCase(getFolderIds.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(changeFolderId.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(changeFolderId.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        let indexOfFolderId = state.folderIds.findIndex((element) => element._id === action.payload.folderIdId)
        state.folderIds[indexOfFolderId] = action.payload.folderIdBody
      })
      .addCase(changeFolderId.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(updateFolderId.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(updateFolderId.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        let indexOfFolderId = state.folderIds.findIndex((element) => element._id === action.payload.folderIdId)
        state.folderIds[indexOfFolderId] = action.payload.folderIdBody
      })
      .addCase(updateFolderId.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(deleteFolderId.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(deleteFolderId.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.folderIds = state.folderIds.filter(
          (folderId) => folderId._id !== action.payload.id
        )
      })
      .addCase(deleteFolderId.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
  },
})

export const { reset } = folderIdSlice.actions
export default folderIdSlice.reducer
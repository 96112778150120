import { useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import choserArrows from '../../icons/arrows-left-right-icon.svg'

const PDFSettingsPanel = ({}) => {

  const orientations = {
    portrait: "Portrait", 
    landscape: "Landscape"
  };
  const sizes = ["A3", "A4", "A5"];

  const [orientation, setOrientation] = useState("portrait");
  const [size, setSize] = useState("A4");
  const [isOrientation, setIsOrientation] = useState(false);
  const [isSize, setIsSize] = useState(false);


  const refOrientation = useOnclickOutside(() => {
    isOrientation && setIsOrientation(prev => false);
  })

  const refSize = useOnclickOutside(() => {
    isSize && setIsSize(prev => false);
  })

  return ( 
    <div className="pdf-settings-panel">
      <div className="pdf-settings-panel-orientation">
        <div className="styling-panel-body-option-name">
          Page orientation
        </div>
        <div ref={refOrientation} className="pdf-settings-panel-orientation-picker" 
        onClick={() => setIsOrientation(prev => !prev)}>
          {orientations[orientation]}
          { isOrientation && 
            <div className="pdf-settings-panel-orientation-droplist">
              <div className={orientation === 'portrait' ? 'fonts-droplist-option-selected' :"fonts-droplist-option"}
              onClick={() => setOrientation(prev => 'portrait')}>
                <div>
                  Portrait
                </div>
              </div>
              <div className={orientation === 'landscape' ? 'fonts-droplist-option-selected' :"fonts-droplist-option"}
              onClick={() => setOrientation(prev => 'landscape')}>
                <div>
                  Landscape
                </div>
              </div>
            </div>
          }
          <div className="pdf-settings-panel-choser-arrows">
            <img style={{width:'22px', rotate:'90deg'}} src={choserArrows} alt='arrows'/>
          </div>
        </div>
      </div>
      <div className="pdf-settings-panel-orientation">
        <div className="styling-panel-body-option-name">
          Page sizing
        </div>
        <div ref={refSize} className="pdf-settings-panel-orientation-picker" 
        onClick={() => setIsSize(prev => !prev)}>
          {size}
          { isSize && 
            <div className="pdf-settings-panel-size-droplist">
              <div className={size === 'A3' ? 'fonts-droplist-option-selected' : "fonts-droplist-option"}
              onClick={() => setSize(prev => 'A3')}>
                <div>
                  A3
                </div>
              </div>
              <div className={size === 'A4' ? 'fonts-droplist-option-selected' : "fonts-droplist-option"}
              onClick={() => setSize(prev => 'A4')}>
                <div>
                  A4
                </div>
              </div>
              <div className={size === 'A5' ? 'fonts-droplist-option-selected' : "fonts-droplist-option"}
              onClick={() => setSize(prev => 'A5')}>
                <div>
                  A5
                </div>
              </div>
            </div>
          }
          <div className="pdf-settings-panel-choser-arrows">
            <img style={{width:'22px', rotate:'90deg'}} src={choserArrows} alt='arrows'/>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default PDFSettingsPanel;
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DocumentMap from "../components/document_map_components/DocumentMap";
import EditorHeader from "../components/document_editor_components/DocumentEditorHeader";
import DocumentEditor from "../components/document_editor_components/DocumentEditor";
import ShortMenu from "../components/ShortMenu";
import { addActiveDocument, removeActiveDocument, setDocument } from "../features/localDocument/editingDocumentSlice";
import { getDocuments, updateDocument } from "../features/documents/documentSlice";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import DeleteDocumentForm from "../components/DeleteDocumentForm";
import SelectForm from "../components/SelectForm";
import SelectBody from "../components/SelectBody";
import DocumentSavingForm from "../components/DocumentSavingForm";

const in_dev_content = {
  position:'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  gap: '40px',
  margin: '25vh 12vw',
	fontSize: '18px',
}

const DocumentEditorPage = () => {

	const navigate = useNavigate()
	const dispatch = useDispatch()

	const {user} = useSelector(state => state.auth)
	const [saveForm , setSaveForm] = useState(false)
  const [deleteForm, setDeleteForm] = useState(false)
  const [closeTrigger, setCloseTrigger] = useState(false)
  const [saveTrigger, setSaveTrigger] = useState(false)
	const [selectForm, setSelectForm] = useState(false)
  const [isDesignItem, setIsDesignItem] = useState(false)
	const [viewEditorMode, setViewEditorMode] = useState(false)

	const { documents, isLoading, isError, message } = useSelector(
    (state) => state.documents
  )
	const { editingDocument } = useSelector(state => state.editingDocument)
	const {id, stage} = useParams()

	useEffect(() => {
    dispatch(getDocuments())
	}, [])

	const saveDocument = () => {
		console.log('saving gooo')
		dispatch(updateDocument(editingDocument))
		//toast.success("Saved "+ editingDocument.name+' !', { toastId: 'success1', autoClose: 2000 })
    setSaveTrigger(false)
  }

	const closeDocument = () => {
    dispatch(removeActiveDocument(editingDocument))
    setCloseTrigger(false)
    navigate('/launch/templates')
  }

	//setting editingDocument function
	const choosingDocument = (id) => {
    const chosenDoc = documents.filter(doc => doc._id === id)[0]

		if(chosenDoc !== undefined){
			dispatch(setDocument(chosenDoc))
			dispatch(addActiveDocument([chosenDoc, 'template']))
		}
	}

	useEffect(() => {
		if(closeTrigger){
			closeDocument()}
	},[closeTrigger])

	useEffect(() => {
		if(saveTrigger){
			saveDocument()}
	},[saveTrigger])

	useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }
    if (!user) {
      navigate('/login')
    }

  }, [user, navigate, isError, message, dispatch])

	//setting editingDocument while isLoading
	useEffect(() => {
		!isLoading && choosingDocument(id)
	}, [isLoading, id])

	//realtime updating document in db 
	useEffect(() => {
    if(editingDocument._id !== undefined ){
			const chosenDoc = documents.filter(doc => doc._id === id)[0]
			if(chosenDoc !== editingDocument && chosenDoc !== undefined){
				saveDocument()
				//console.log("we are updating tjis",chosenDoc, editingDocument) 
			}
    }
  }, [editingDocument])

	return ( 
		<div className='content-board'>
			{ isLoading ? <Spinner/> 
			: user  ?
			documents && editingDocument &&
			<>
				<ShortMenu chosenPage={id}/>
				<div className='board-body'>
					<DocumentSavingForm active={saveForm} setActive={setSaveForm}/>
					<DeleteDocumentForm active={deleteForm} setActive={setDeleteForm} 
					doc={editingDocument} setUpdate={()=> {}}/>
					<SelectForm active={selectForm} setActive={setSelectForm}/>
					<EditorHeader editingDocument={editingDocument} documentData={{name:editingDocument.name, date:editingDocument.updatedAt}} 
					setSaveDocForm={setSaveForm} setDeleteForm={setDeleteForm} 
					setSelectForm={setSelectForm} setIsDesignItem={setIsDesignItem}
					viewEditorMode={viewEditorMode} setViewEditorMode={setViewEditorMode}
					setCloseDoc={setCloseTrigger}/>
					{
						stage === 'select' ?
						<SelectBody/>
						:
						stage === 'design' ?
						<DocumentMap editingDocument={editingDocument}/> 
						:
						stage === 'edit' ?
						<DocumentEditor editingDocument={editingDocument} viewEditorMode={viewEditorMode} />
						: 
						stage === 'sign' ?
						<div style={in_dev_content}>In development</div>
						:
						stage === 'data' ?
						<div style={in_dev_content}>In development</div>
						:
						<></>
					}
				</div>
			</>
			:
			<div className="message">Log in to get access to data</div>}
		</div>
		);
}
 
export default DocumentEditorPage;
import { useState } from "react"
import { useDispatch } from 'react-redux'
import { addNewModule } from "../features/localDocument/editingDocumentSlice"
import closeIcon from "../icons/right-panel-close.svg"

const details = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0',
  pointerEvents: 'none',
  transition: '0.2s',
  zIndex:'10',
}
const form_input ={
  padding: '10px',
  border:' 1px solid #e6e6e6',
  borderRadius: '5px',
  marginBottom: '10px',
}

const ModuleForm = ({active, setActive}) => {

  const [name, setName] = useState('')
  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()
    dispatch(addNewModule(name))
    setName('')
    setActive(prev => false)
  }
  return ( 
    <div style={active ? { ...details, opacity: '1', pointerEvents: 'all'} : details}>
      <div className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
      <div className="form-header">
          <div >New module </div>
          <div className="close_button" onClick={() => setActive(false)}><img src={closeIcon} alt="close"/></div> 
        </div>
        <form className="style-form" onSubmit={onSubmit}>
          <div style={{marginBottom:'10px'}}>
            <label style={{display: 'block', marginBottom:'10px'}}>Name of module:</label>
            <input
              style={form_input}
              type='text'
              name='name'
              id='name'
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>
            <button className="form-bottom-button" type='submit'>
              Add module
            </button>
        </form>
      </div>
    </div>);
}
 
export default ModuleForm;
import { useDrop } from 'react-dnd'
import React, {  useRef } from "react";
import trashBasket from "../../icons/trash-icon.svg"
import closeButton from "../../icons/right-panel-close.svg"
import Spinner from "../Spinner"
import { useDispatch, useSelector} from 'react-redux'
import { addItemToTrashCan, deleteItemFromMap } from "../../features/localDocument/editingDocumentSlice"
import MapTrashComponent from './MapTrashComponent';
import { useEffect } from 'react';


const MapTrashBasket = ({panelOption, setIsPanelOpen}) => {

  const dispatch = useDispatch()
  const ref = useRef(null)
  const { trashCan } = useSelector(
    (state) => state.editingDocument
  )

  const [{ handlerId }, drop] = useDrop({
    accept: ['part', 'level1', 'level2', 'level3'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      console.log("item in trash ", item )
      if(item.hasOwnProperty('module')){
        dispatch(deleteItemFromMap([item.module,  'module']))
        dispatch(addItemToTrashCan(item.module))
      } else {
        if(item.hasOwnProperty('part')){
          dispatch(deleteItemFromMap([item.part,  item.part.typeOfContent]))
          dispatch(addItemToTrashCan(item.part))
        }
      }
    }
  })

  drop(ref)

  return ( 
    <div ref={ref} className='agreement-map-right-panel'>
      <div className='agreement-map-right-panel-header'>
        <div className='agreement-map-right-panel-header-tittle'>
          <img src={trashBasket}/>
          <div>Trash</div>
        </div>
        <div className="agreement-map-right-panel-closer"
        onClick={() => setIsPanelOpen({...panelOption, isOpen: false})}>
        <img src={closeButton}/>
        </div>
      </div>
      {trashCan ? 
        <div className='agreement-map-right-panel-content'>
          {trashCan.map((trashItem, index) => 
          <MapTrashComponent key={index} item={trashItem} index={index}/>
          )}
        </div>
      :
      <Spinner/> 
      }
    </div>
  );
}
 
export default MapTrashBasket;

export const MapTrashSmall = ({panelOption, setIsPanelOpen}) => {

  const ref = useRef(null)

  const [{ isOverCurrent }, drop] = useDrop({
    accept: ['part', 'level1', 'level2', 'level3'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
      }
    },
    drop( ){
    },
  })
  useEffect( () => {
    setTimeout(() => {
      isOverCurrent && setIsPanelOpen({...panelOption, option:'trash', isOpen: true})
    }, 200)
  }, [isOverCurrent])

  drop(ref)
  return (  
  <div ref={ref} className='agreement-map-right-panel-option'
    onClick={() => setIsPanelOpen({...panelOption, option:'trash', isOpen: true})}>
    <img src={trashBasket}/>
  </div>
  )
}
import { useState } from "react";
import { useDispatch } from "react-redux";
import { add, getAll } from "../features/auth/authSlice";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "./Spinner";


const iframe = {
  //display: 'none',
}

const InvitationForm = () => {

  const dispatch = useDispatch()

  const [recipientEmail, setRecipientEmail] = useState('')

  const [tempViewFoldersIds, setTempViewFoldersIds] = useState([])
  const [tempEditingFoldersIds, setTempEditingFoldersIds] = useState([])
  const [docViewFoldersIds, setDocViewFoldersIds] = useState([])
  const [docEditingFoldersIds, setDocEditingFoldersIds] = useState([])

  const [ isIframe, setIsIframe] = useState(false)
  const [filteredUsers, setFilteredUsers] = useState([])
  const [isUsersVisible, setIsUsersVisible] = useState(false)
  const [buttonText, setButtonText] = useState("Send a link")
  const [isReload, setIsReload] = useState(false)
  let req = ''

  useEffect(() => {
    dispatch(getAll({}))
    console.log('isIframe : ',isIframe)
  }, [isIframe])
  useEffect(() => {
    checkUsers()
  }, [isUsersVisible, dispatch])

  useEffect(() => {
    if(filteredUsers.length !== 0){
      let indexOfUser = filteredUsers.findIndex(user => user.email === recipientEmail)
      if( indexOfUser !== -1){
        filteredUsers[indexOfUser].folderIds.map( folder => {
          Object.hasOwn(folder, 'temp_view_folder_ids') ?
          setTempViewFoldersIds(prev => folder.temp_view_folder_ids.join(', '))
          :
          Object.hasOwn(folder, 'temp_editing_folder_ids') ?
          setTempEditingFoldersIds(prev => folder.temp_editing_folder_ids.join(', '))
          :
          Object.hasOwn(folder, 'doc_view_folder_ids') ?
          setDocViewFoldersIds(prev => folder.doc_view_folder_ids.join(', '))
          :
          Object.hasOwn(folder, 'doc_editing_folder_ids') ?
          setDocEditingFoldersIds(prev => folder.doc_editing_folder_ids.join(', '))
          :
          console.log('no folders ')
        })
        setButtonText(prev => "Save changes")
      }else{
        setIsUsersVisible(prev => false);
        setButtonText(prev => "Send a link")
      }
    }
  }, [recipientEmail])

  const checkUsers = () => {
    const users = JSON.parse(sessionStorage.getItem('users'))
    let arrayOfUsers = []
    if(users !== null){
      users.protected_users.forEach(user => {
        arrayOfUsers.push({
          "email" : user.email,
          "folderIds" : user.folderIds
        })
      });
      setFilteredUsers(prev => arrayOfUsers)
    }
  }

  const useOnSubmit = (e) => {
    e.preventDefault()

    if (!recipientEmail.match(
      /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/
    )) {
      toast.error('Invalid email')
    } else {
      const splitedTempViewFolders = typeof tempViewFoldersIds === 'string' ?
      tempViewFoldersIds.split(',').map(id => id.replace(/\s/g,'')) 
       : tempViewFoldersIds.map(id => id.replace(/\s/g,''))
      const splitedTempEditingFolders = typeof tempEditingFoldersIds === 'string' ?
      tempEditingFoldersIds.split(',').map(id => id.replace(/\s/g,'')) 
        : tempEditingFoldersIds.map(id => id.replace(/\s/g,''))
      const splitedDocViewFolders = typeof docViewFoldersIds === 'string' ?
      docViewFoldersIds.split(',').map(id => id.replace(/\s/g,'')) 
       : docViewFoldersIds.map(id => id.replace(/\s/g,''))
      const splitedDocEditingFolders = typeof docEditingFoldersIds === 'string' ?
      docEditingFoldersIds.split(',').map(id => id.replace(/\s/g,'')) 
        : docEditingFoldersIds.map(id => id.replace(/\s/g,''))


      //creating user with email and folder ids
      const userData = {
        name: 'User',
        email: recipientEmail,
        password: 'default',
        role: 'user',
        folderIds: [],
        isRegistered:false,
      }
      const temp_view_ids_string = splitedTempViewFolders
      const temp_editing_ids_string = splitedTempEditingFolders
      const doc_view_ids_string = splitedDocViewFolders
      const doc_editing_ids_string = splitedDocEditingFolders
      //console.log(majoto_ids_string, custom_ids_string, doc_ids_string)
      let folder_ids_string =''
      if(temp_view_ids_string !== ''){
        userData.folderIds.push({temp_view_folder_ids: splitedTempViewFolders}) 
        folder_ids_string= folder_ids_string.concat('&tfv=', temp_view_ids_string)
      }else{
        userData.folderIds.push({temp_view_folder_ids: splitedTempViewFolders})
      }
      if(temp_editing_ids_string !== ''){
        userData.folderIds.push({temp_editing_folder_ids: splitedTempEditingFolders}) 
        folder_ids_string= folder_ids_string.concat('&tfe=', temp_editing_ids_string)
      }else{
        userData.folderIds.push({temp_editing_folder_ids: splitedTempEditingFolders})
      }
      if(doc_view_ids_string !== ''){
        userData.folderIds.push({doc_view_folder_ids: splitedDocViewFolders}) 
        folder_ids_string= folder_ids_string.concat('&dfv=', doc_view_ids_string)
      }else{
        userData.folderIds.push({doc_view_folder_ids: splitedDocViewFolders})
      }
      if(doc_editing_ids_string !== ''){
        userData.folderIds.push({doc_editing_folder_ids: splitedDocEditingFolders}) 
        folder_ids_string= folder_ids_string.concat('&dfe=', doc_editing_ids_string)
      }else{
        userData.folderIds.push({doc_editing_folder_ids: splitedDocEditingFolders})
      }
      dispatch(add(userData));
      
      setIsReload(true)
      if(buttonText === "Send a link"){
        // making req for iframe with this user 
        setTimeout(() => {
          let userId = JSON.parse(sessionStorage.getItem('invite_user'))
          console.log('real user id ',userId)
         // console.log(`https://script.google.com/a/yourdomain.com/macros/s/AKfycbz5xEAR79-imLhqhuPqvGltAs00NcUenIvwwEGi/exec?user_id=${userId._id}&email=${recipientEmail}${folder_ids_string}`)
          if(userId){
            req = `https://script.google.com/a/yourdomain.com/macros/s/AKfycbz5xEAR79-imLhqhuPqvGltAs00NcUenIvwwEGi/exec?user_id=${userId._id}&email=${recipientEmail}${folder_ids_string}`
            setIsIframe(true)
            console.log(req)
            toast.success('Invitation sent !')
          }
        }, 3000);
      }else{
        toast.success('Saved changes !')
      }
      setTimeout(() => {
        setIsReload(false)
        setIsIframe(false)
        dispatch(getAll({}))
      }, 4000)
    }
  }

  useEffect(() => {
    if(isReload){
      setRecipientEmail(prev => '')
      setTempEditingFoldersIds([]);
      setTempViewFoldersIds([]);
      setDocEditingFoldersIds([]);
      setDocViewFoldersIds([]);
    }
  },[isReload])

  return ( 
    filteredUsers === null || isReload ? <Spinner/>
      :
      <div className="invitation-container">
      <form onSubmit={useOnSubmit} className="invitation-form">
        <input
          type='email'
          name='email'
          id='email'
          value={recipientEmail}
          autoComplete="off"
          onChange={(e) => setRecipientEmail(e.target.value)}
          onClick={() => setIsUsersVisible(prev => !prev) }
        />
        <label htmlFor='email'>Email of recipient</label>
        { isUsersVisible ? 
        filteredUsers.length !== 0 ?
        <div style={{position:'relative'}}>
          <div onClick={() => setIsUsersVisible(prev => false)}
          className="invitation-emails-droplist-body">
              {filteredUsers.map(user => (
                <div className="invitation-droplist-option" 
                onClick={() => setRecipientEmail(user.email)}>
                  {user.email}
                </div>
              ))}
            </div>
          </div>
        : <></>
        : <></>}
        <input
          type='text'
          name='temp_edit_ids'
          id='temp_edit_ids'
          value={tempEditingFoldersIds}
          autoComplete="off"
          onChange={(e) => setTempEditingFoldersIds(e.target.value.split(','))}
        />
        <label htmlFor='temp_edit_ids'>Templates folders ids for editing</label>
        <input
          type='text'
          name='temp_view_ids'
          id='temp_view_ids'
          value={tempViewFoldersIds}
          autoComplete="off"
          onChange={(e) => setTempViewFoldersIds(e.target.value.split(','))}
        />
        <label htmlFor='temp_view_ids'>Templates folders ids for view</label>
        <input
          type='text'
          name='doc_edit_ids'
          id='doc_edit_ids'
          value={docEditingFoldersIds}
          autoComplete="off"
          onChange={(e) => setDocEditingFoldersIds(e.target.value.split(','))}
        />
        <label htmlFor='doc_edit_ids'>Documents folders ids for editing</label>
        <input
          type='text'
          name='doc_view_ids'
          id='doc_view_ids'
          value={docViewFoldersIds}
          autoComplete="off"
          onChange={(e) => setDocViewFoldersIds(e.target.value.split(','))}
        />
        <label htmlFor='doc_view_ids'>Documents folders ids for view</label>
        <button className="auth" style={{width:'100px'}}
        type="submit">{buttonText}</button>
      </form>
      {/* <button className="auth" onClick={() => checkUsers()}>Check users</button> */}
      {
        isIframe ? <iframe style={iframe} src={req}/> && console.log(req) : <></>
      }
    </div>
   );
}
 
export default InvitationForm;
import React, { useCallback } from "react";
import PreviewColumnsHolder from "./PreviewColumnsHolder";
import PreviewLevel from "./PreviewLevel";
import PreviewLine from "./PreviewLine";
import PreviewText from "./PreviewText";

const module_style = {
  height:'fit-content',
  width: '100%',
  display:'flex',
  flexDirection:'column',
  border: '2px solid rgba(229,225,225,255)',
  borderRadius: '8px',
  position: 'relative',
}
const PreviewModule = ({ part}) => {

  const renderLevel = useCallback((content, index) => {
    return (
      <PreviewLevel 
        key={content.position}
        index={index}
        part = {content} 
      />
    )
  }, [])
  const renderTextField = useCallback(content => {
    return (
      <PreviewText 
        key={content.position}
        part = {content}
      />
    )
  }, [])
  const renderLine = useCallback(content => {
    return (
      <PreviewLine 
        key={content.position}
        part = {content}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback(content => {
    return (
      <PreviewColumnsHolder 
        key={content.position}
        part = {content}
      />
    )
  }, [])

  return (
    <div 
    style={{...module_style, ...part.style}}
      className='level-holder'>
        {part.length !== 0 ?
         part.content.map( content => 
         content.typeOfContent === "level1" ? 
          renderLevel(content)
          : content.typeOfContent === 'columns_holder' ?
          renderColumnsHolder(content)
          :
          content.typeOfContent === "heading" || content.typeOfContent === "subheading" || content.typeOfContent === "text_field" ? 
          renderTextField(content)
          : content.typeOfContent === 'line' ?
          renderLine(content)
          : <></>
          )
        :
        <></>}
    </div> 
  )
}
export default PreviewModule
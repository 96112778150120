import axios from 'axios'

const API_URL = '/api/editorPanels/'

// Create new editorPanel
const createEditorPanel = async (editorPanelData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, editorPanelData, config)

  return response.data
}

// Get user editorPanels
const getEditorPanels = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// Get default user`s editorPanel
const getDefaultEditorPanel = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+"default", config)

  return response.data
}

// update user editorPanel
const updateEditorPanel = async (editorPanel,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let editorPanelBody = editorPanel.body
  let editorPanelId = editorPanel._id;
  const response = await axios.put(API_URL + editorPanelId, editorPanelBody, config)

  return response.data
}
// Delete user editorPanel
const deleteEditorPanel = async (editorPanelId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + editorPanelId, config)

  return response.data
}

const editorPanelService = {
  createEditorPanel,
  getEditorPanels,
  getDefaultEditorPanel,
  updateEditorPanel,
  deleteEditorPanel,
}
 
export default editorPanelService
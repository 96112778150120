import { useCallback, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useDispatch, useSelector } from 'react-redux'
import {useDropzone} from 'react-dropzone'
import go_back from '../icons/return-arrow.svg'
import explore from '../icons/majoto-image-library/explore-icon.svg'
import options from '../icons/majoto-image-library/options-icon.svg'
import axios from 'axios'
import { changeUser, updateUser } from '../features/users/userSlice'


const LibraryUploadForm = ({active, setActive, setUpdateTrigger, userToken, userRefreshToken}) => {

  const dispatch = useDispatch()
  const {user} = useSelector(state => state.auth)

  const [uploadImage, setUploadImage] = useState()
  const [isLibrary, setIsLibrary] = useState(false)
  const [isDriveFiles, setIsDriveFiles] = useState(false)
	const [driveFiles, setDriveFiles] = useState([])

	const filesPerPage = 10;

	const [currentPage, setCurrentPage] = useState(1);

  const [indexOfLastFile, setIndexOfLastFile] = useState(filesPerPage);
  const [indexOfFirstFile, setIndexOfFirstFile] = useState(0);
  const [currentFiles, setCurrentFiles] = useState([])

  const [totalPages, setTotalPages] = useState(3);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
		setIndexOfLastFile(newPage * filesPerPage);
		setIndexOfFirstFile(indexOfLastFile - filesPerPage)
		setCurrentFiles(driveFiles.slice((newPage * filesPerPage) - filesPerPage, newPage * filesPerPage))
  };

  const ref = useOnclickOutside(() => {
    if(active){
      setActive(false);
      setIsDriveFiles(false)
    };
  })

  const imageUploadHandler = (e) => {
    setUploadImage(e.target.files[0])
    console.log('file ', e.target.files[0])
  }

  // const loadPicker = async () => {
  //   const response = await fetch('http://localhost:8000/api/googleDrive/picker-config');
  //   const config = await response.json();

  //   createPicker(config);
  // };

  // const createPicker = (config) => {
  //   const picker = new google.picker.PickerBuilder()
  //     .addView(google.picker.ViewId.DOCS)
  //     .setOAuthToken(userToken) // Replace with the actual access token
  //     .setDeveloperKey(config.apiKey)
  //     .setCallback(pickerCallback)
  //     .build();
  //   picker.setVisible(true);
  // };

  // const pickerCallback = (data) => {
  //   if (data.action === window.google.picker.Action.PICKED) {
  //     const selectedFiles = data.docs;
  //     console.log('Selected Files:', selectedFiles);
  //     // Handle selected files
  //   }
  // };

  // useEffect(() => {
  //   if(userToken){
  //     loadPicker();
  //   }
  // }, [userToken]);


  const fetchDriveFiles = async (accessToken, refreshToken) => {
		try {
			const response = await axios.post('http://majoto.app/api/googleDrive/getFiles', {
				token: accessToken,
        refreshToken: refreshToken
			});
			setDriveFiles(response.data.userDriveData.files);
      setIsDriveFiles(true)
		} catch (error) {
			console.error('Error fetching drive files:', error);
		}
	};

  const downloadSelectedFiles = async (file) => {
    try {
      setUpdateTrigger(true)
      await axios.post('http://majoto.app/api/googleDrive/downloadFile', {
        accessToken: user.integrations[0].accessToken,
        fileId: file.id,
        fileName: file.name,
        fileType: file.mimeType,
				userId: user._id
      }).then(() => {
        setUpdateTrigger(false);
        setActive(false);
        setIsDriveFiles(false);
      })
    } catch (error) {
      console.error('Error downloading files:', error);
    }
  };

  //function to convert image into base64
  function convertImageToBase64viaFileReader(url, callback){
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {
      let reader  = new FileReader();
      reader.onloadend = function () {
          callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.send();
  }

  useEffect(() => {
    if (!uploadImage) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(uploadImage)
    convertImageToBase64viaFileReader(objectUrl, function(base64Img){
      const imageData = {
        name: uploadImage.name,
        type: 'image',
        content:base64Img
      }
      let userLibraryData = [...user.userLibrary, imageData]
      console.log('userLibraryData : ',userLibraryData)
      let userUpdateData = { ...user, userLibrary: userLibraryData };
      console.log('userUpdateData to dispatch: ', userUpdateData)
      dispatch(changeUser(userUpdateData))
      setActive(prev=> false)
    });
 
    // free memory when ever this component is unmounted
    return () => {
      setUploadImage(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [uploadImage])

  useEffect(() => {
    !active && setUploadImage('')
  },[active])

  const onDrop = useCallback(acceptedFiles => {
    setUploadImage(acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  useEffect(() => {
    if(driveFiles.length !== 0 ){
      setDriveFiles(driveFiles)
      setCurrentFiles(driveFiles.slice(indexOfFirstFile, indexOfLastFile))
      setTotalPages(Math.ceil(driveFiles.length / filesPerPage))
    }
  }, [driveFiles])

  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
     { isDriveFiles && driveFiles.length !== 0 ?
     <div ref={ref} className='upload-image-form-content' style={{opacity: active && '1'}}>
      <div className="upload-image-files-list">
        {currentFiles.map((file, index) => (
          <div key={index} className="library-upload-form-tile" onClick={() => downloadSelectedFiles(file)}>
            {file.name}
          </div>
        ))}
      </div>
      <div className="library-page-pagination">
        <button className='library-page-pagination-button' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>{currentPage} of {totalPages}</span>
        <button className='library-page-pagination-button' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
      :
     <div ref={ref} className='upload-image-form-content' style={{opacity: active && '1'}}>
        <div className="upload-image-form-header">
          Upload a new image 
        </div>
        <div className="upload-image-form-buttons">
          <div className={userToken ? 'upload-image-form-button' : 'upload-image-form-button disable-button'} 
            onClick={() => {
              //loadPicker()
              if(userToken)fetchDriveFiles(userToken, userRefreshToken)
            }}>
            Upload from Google Drive
          </div>
          <label htmlFor="upload_image" className='upload-image-form-button'>
            Upload from storage
          <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
          style={{visibility:'hidden', width: '0px', height:'0px'}} type="file" accept="image/*"/>
          </label>
        </div>
        <div className="upload-image-form-or">
          or
        </div>
        <div className="upload-image-form-drop-file" {...getRootProps()}>
          <input {...getInputProps()}/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>
      </div>
      } 
    </div>
   );
}
 
export default LibraryUploadForm
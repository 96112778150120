import check from '../../icons/stop-circle-icon.svg'
import edit from '../../icons/edit-agreement-icon.svg'
import duplicate from '../../icons/duplicate-icon.svg'
import convert from '../../icons/convert-to-template-icon.svg'
import connected from '../../icons/connected-processes-icon.svg'

const AgreementDropdownOptions = ({id}) => {

	return (
  <div className="agreement-list-droplist-body">
    <div style={{position:'relative'}}>
      <div className="template-droplist-option">
        <img src={check} alt="check"/>
        View details
      </div>
      <div className="template-droplist-option">
        <img src={edit} alt="edit"/>
        Amend, renew or terminate
      </div>
      <div className="template-droplist-option">
        <img src={duplicate} alt="duplicate"/>  
        Duplicate
      </div>
      <div className="template-droplist-option">
        <img src={convert} alt="convert"/>  
        Convert to template
      </div>
      <div className="template-droplist-option">
        <img src={connected} alt="connected"/>  
        Connected processes
      </div>
    </div>
  </div>
	)	
}

export default AgreementDropdownOptions
import { useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { setChosenComponent } from "../../../features/localDocument/editingStyleDocumentSlice";


const text_style ={
  width: '100%',
  height: '100%',
  overflow: 'hidden',
}

const StyleTextArea = ({part, index, 
  parentData, columnData, parentType, 
  setStylingPanel, setStylingComponent,
  moveChildFromParent,}) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()

  const [text, setText ] = useState('A lot of test text to see styling changes')
  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...text_style, ...part.style}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...text_style, ...part.style}))
  }, [part.style])

  const ref = useRef(null)  
  useEffect(() => {
    const onHeaderClick = (event) => {
      console.log('clicked on header')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([{...part, style: style, content: text}, parentData, parentType, columnData]);
      setStylingPanel(prev=> true)
    }
  
    const headerRef = ref.current;
    headerRef.addEventListener("click", onHeaderClick);
  
    return () => {
      headerRef.removeEventListener("click", onHeaderClick);
    };
  }, [])

  const [{ handlerId }, drop] = useDrop({
    accept: ['part'],
    collect(smonitor) {
      return {  
        handlerId: smonitor.getHandlerId(),
      }
    },
    drop( ){ 
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(columnData !== undefined && item.columnData !== undefined){
        if(columnData.column === item.columnData.column){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }else{
        if(item.part !== undefined && item.parentType === parentType){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType, columnData }
    },
    collect: (smonitor) => ({
      isDragging: smonitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

 
  return (
    <div className={chosenComponent.position === part.position ? "textarea-component-chosen-hover":"textarea-component-hover"} 
    style={{opacity}}>
      <div className="textarea-component"ref={ref} data-handler-id={handlerId} 
      style={style}>
        {text}
      </div>
    </div>
  )
}
export default StyleTextArea
import { useDispatch } from 'react-redux'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useEffect, useState } from 'react'
import closeIcon from "../../icons/right-panel-close.svg"
import arrow from "../../icons/arrow-icon.svg"
import { handlePostRequest } from './admin_page_functions'
import Spinner from '../Spinner'
import { createFolderId } from '../../features/folderIds/folderIdSlice'
import { toast } from 'react-toastify'

const FolderIdCreatingWindow = ({active, closeWindow, customerSpaceList, isLoadingCS, setFolderUpdateTrigger}) => {

  const dispatch = useDispatch()
  const [customerSpacesData, setCustomerSpacesData] = useState([])

  const [folderCustomerSpace, setFolderCustomerSpace] = useState('not selected')
  const [folderName, setFolderName] = useState('')
  const [folderType, setFolderType] = useState('Templates')
  const [folderId, setFolderId] = useState('')

  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    setCustomerSpacesData(customerSpaceList)
  }, [customerSpaceList]);

  // useEffect(() => {
  //   console.log('folderId: ',folderId)
  // }, [folderId]);

  const [isFolderIdTypeDown, setIsFolderIdTypeDown] = useState(false)
  const refFolderIdType = useOnclickOutside(() => {
    isFolderIdTypeDown && setIsFolderIdTypeDown(false);
  })
  const [isFolderIdCustomerSpaceDown, setIsFolderIdCustomerSpaceDown] = useState(false)
  const refFolderIdCustomerSpace = useOnclickOutside(() => {
    isFolderIdCustomerSpaceDown && setIsFolderIdCustomerSpaceDown(false);
  })
    
  const ref = useOnclickOutside(() => {
    active && closeWindow();
  })

  const onSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    const folderDataToFetch = {
      folder_name: folderName,
      folder_type: folderType,
      functionName: "createFolder",
    };
  
    try {
      const response = await handlePostRequest(folderDataToFetch);
      const newFolderId = await response;
      setFolderId(newFolderId);
      const folderIdData = {
        customerSpace: folderCustomerSpace,
        name: folderName,
        id: newFolderId.folder_id,
        folderType: folderType,
        comments: '',
      };
      toast.promise(
        dispatch(createFolderId(folderIdData)),
        {
          pending: 'Saving..',
          success: 'Saved new folder id',
          error: 'Promise rejected 🤯'
        }
      )
      setFolderUpdateTrigger(true)
    } catch (error) {
      console.error('An error occurred:', error);
      // Handle the error if needed
    } finally {
      setIsLoading(false);
      closeWindow();
    }
  };
  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
      <div ref={ref} className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
        <div className="creation-folderid-form-header">
          <div>Create and add a new folder id</div>
          <div className="close_button" onClick={() => closeWindow()}>
            <img src={closeIcon} alt="close"/>
          </div> 
        </div>
        {
          isLoading ? 
          <div className='form-spinner-container'>
            <Spinner/>
          </div>
          :
          <form className="creation-folderid-form" onSubmit={onSubmit}>
            <div>
              <label>Name of new folder :</label>
              <input
                type='text'  id='name'
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                required
              />
            </div>
            <div className='form-selectors-container'>
              <div>
                <label style={{display: 'block', marginBottom:'10px'}}>Customer space of new folder :</label>
                <div ref={refFolderIdCustomerSpace} className="admin-selector-container">
                  <div className="admin-selector-header" onClick={ () => setIsFolderIdCustomerSpaceDown(prev => !prev)}>
                    {folderCustomerSpace}
                    <img style={{marginLeft:'8px', transform:( isFolderIdCustomerSpaceDown && 'rotate(-90deg)'), 
                    transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                  </div>
                  { isFolderIdCustomerSpaceDown && 
                    <div className="creating-form-selector-body">
                      {
                        isLoadingCS ? <Spinner/> : customerSpacesData.length !== 0 ?
                        <>
                          {customerSpacesData.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                          <div key={index} className="admin-selector-body-option" onClick={() =>  setFolderCustomerSpace(space.name)}>
                            {space.name}
                          </div>
                          ))}
                        </>
                        : <div>There are no customer spaces yet </div>
                      }
                    </div>
                  }
                </div>
              </div>
              <div style={{marginBottom:'10px'}}>
                <label style={{display: 'block', marginBottom:'10px'}}>Type of new folder :</label>
                
                <div ref={refFolderIdType} className="admin-selector-container">
                    <div className="admin-selector-header" onClick={ () => setIsFolderIdTypeDown(prev => !prev)}>
                      {folderType}
                      <img style={{marginLeft:'8px', transform:( isFolderIdTypeDown && 'rotate(-90deg)'), 
                      transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                    </div>
                    { isFolderIdTypeDown && 
                    <div className="creating-form-selector-body">
                      <div className="admin-selector-body-option" onClick={() => setFolderType('Docs')}>
                        Docs
                      </div>
                      <div className="admin-selector-body-option" onClick={() => setFolderType('Templates')}>
                        Templates
                      </div>
                    </div> }
                  </div>
              </div>
            </div>
            <div className="operation-form-buttons">
              <button className="operation-form-save-button" type='submit'>
                Save 
              </button>
              <div onClick={() => closeWindow()} className="operation-form-cancel-button">
                Cancel 
              </div>
            </div>
          </form>
        }
      </div>
    </div>
  );
}
 
export default FolderIdCreatingWindow;
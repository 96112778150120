import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomerSpaces } from "../../features/customerSpaces/customerSpaceSlice";
import { changeFolderId, getFolderIds } from "../../features/folderIds/folderIdSlice";
import FolderIdsTableBody from "./FolderIdsTableBody";
import SortableTh from "./SortableTh";
import FolderIdCreatingWindow from "./FolderIdCreatingWindow";


const FolderIdsTable = () => {

  const dispatch = useDispatch()
  const  { customerSpaces, isLoadingCS, isErrorCS, messageCS} = useSelector( 
    state => state.customerSpaces)
  const  { folderIds, isLoadingFId, isErrorFId, messageFId} = useSelector( 
    state => state.folderIds)

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [folderUpdateTrigger, setFolderUpdateTrigger] = useState(false)
  const [folderIdsData, setFolderIdsData] = useState([])

  const [folderIdCreatingWindow, setFolderIdCreatingWindow] = useState(false)
  
  const [sortedColumn, setSortedColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  useEffect(() =>{
    setFolderIdsData(folderIds.filter(folder => folder.folderStatus !== 'archive'))
    //console.log('folderIdsData: ',folderIdsData)
  },[folderIds])

  const handleSort = (columnName) => {
    setSortedColumn(columnName);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    setFolderIdsData(prev => prev.sort((a, b) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        if (sortDirection === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    )
    //console.log('folder ids data: ',folderIdsData)
  }

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getFolderIds())
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  useEffect(() => {
    if(folderUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderIds())
        setFolderUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [folderUpdateTrigger])

  const [chosenFolderIds, setChosenFolderIds] = useState([]);

  const folderIdToggle = (index) => {
    setChosenFolderIds((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }
  const [isFIActionsMenu, setIsFIActionsMenu] = useState(false)

  useEffect(() => {
    chosenFolderIds.length !== 0 ? setIsFIActionsMenu(prev => true) : setIsFIActionsMenu(prev => false) 
  }, [chosenFolderIds])

  const fiActionsHandler = (option) => {
    chosenFolderIds.forEach((folderId) => {
      const folderToUpdate = folderIds.find((folder) => folder._id === folderId);
  
      if (folderToUpdate) {
        const updatedFolder = { ...folderToUpdate, folderStatus: option };
        toast.promise(
          dispatch(changeFolderId(updatedFolder)),
          {
            pending: 'Applying changes',
            success: `Changed folder id state to ${option}`,
            error: 'Promise rejected 🤯'
          }
        )
      }
    })
    if (chosenFolderIds.length !== 0){
      setFolderUpdateTrigger(prev => true)
      setChosenFolderIds(prev => [])
    }
  } 

  return ( 
    <div className="admin-register-part">
      <FolderIdCreatingWindow active={folderIdCreatingWindow} closeWindow={setFolderIdCreatingWindow}
      customerSpaceList={customerSpaces} isLoadingCS={isLoadingCS}/>
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Spaces and folders
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <SortableTh columnName="customerSpace" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Customer space
              </SortableTh>
              <SortableTh columnName="id" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Folder ID
              </SortableTh>
              <SortableTh columnName="name" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Name
              </SortableTh>
              <SortableTh columnName="createdAt" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Creation date
              </SortableTh>
              <SortableTh columnName="folderType" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Folder type
              </SortableTh>
              <th>Status</th>
              <SortableTh columnName="comments" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Comments
              </SortableTh>
            </tr>
          </thead>
          { isLoadingFId || folderUpdateTrigger ? 
            <div className="admin-control-loading">
              <Spinner/>
            </div>
            : 
            <FolderIdsTableBody tableData={folderIdsData} customerSpaceList={customerSpaces}
            isLoadingCS={isLoadingCS} updateTrigger={updateTrigger} 
            setFolderUpdateTrigger={setFolderUpdateTrigger}
            folderIdToggle={folderIdToggle} openCreatingWindow={setFolderIdCreatingWindow}/>
          }
        </table>
      </div>
      <>
        { isFIActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => fiActionsHandler('archive')}>
                Archive
              </div>
          </div>
        }
      </>
    </div>
   );
}
 
export default FolderIdsTable;
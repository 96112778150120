import { useEffect, useState } from "react"
import more from "../../icons/more-horizontal-icon.svg"
import moment from "moment"
import useOnclickOutside from "react-cool-onclickoutside"
import AgreementDropdownOptions from "./AgreementDropdownOptions"

const AgreementsGraphItem = ({agreement, status, monthes}) => {

  const [isChosen, setIsChosen] = useState(false)
  const [isDropList, setIsDropList] = useState(false)
  const ref = useOnclickOutside(() => {
    isChosen && setIsChosen(prev => false);
  })

  const [xPosition, setXPosition] = useState(0)

  const startPosition = 80
  const agreementDate = new Date(agreement.updatedAt)

  useEffect( () => {  
    if(monthes !== undefined){
      const monthesPositions = monthes.map( (date, position) => {
        return {date: date.date, position: startPosition + 97*position }
      })
      monthesPositions.map(month => {
        if(agreementDate.getMonth() === month.date.getMonth() && agreementDate.getFullYear() === month.date.getFullYear()){
          const daysFromStartOfMonth = agreementDate.getDate()-1
          setXPosition(prev => month.position + daysFromStartOfMonth*3)
        }
      })
    }
  }, [monthes])

  return ( 
<>
  {  xPosition !== 0 ?
   <div className="agreements-graph-row-item-container"
    style={{left: xPosition}} >
    {
      isChosen &&         
      <div className="agreement-item-description" >
        <div className="agreement-name-holder">
          <div className="agreement-name">
            <div className="name">{ agreement.name }</div>
            <div className="agreement-three-dots" 
            onClick={() => setIsDropList(prev => true)}>
              <img src={more} alt="more"/>
              {isDropList && 
              //chosenAgreement === agreement._id && 
                <AgreementDropdownOptions id={agreement._id}/>
                }
            </div>
          </div>
          <div className="plate-content">
            <div id='agreement-date'>{moment(agreement.updatedAt).format('D MMMM, YYYY')}</div>
          </div>
        </div>
        <div className="plate-content">
          <div className="agreement-company"/>
          <div className="agreement-company-name">{agreement.parties}</div>
        </div>
      </div>
      }
      <div ref={ref} className={isChosen ? "agreements-graph-row-item-chosen" : "agreements-graph-row-item"}
        onClick={() =>{setIsChosen(prev=>!prev)}}
        id={status+'-item'}>
      </div>
    </div>
    :
    <></>
    }
    </>
   );
}
 
export default AgreementsGraphItem;
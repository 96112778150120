import axios from 'axios'

const API_URL = '/api/styleEditorPanels/'

// Create new styleEditorPanel
const createStyleEditorPanel = async (styleEditorPanelData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, styleEditorPanelData, config)

  return response.data
}

// Get user styleEditorPanels
const getStyleEditorPanels = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// Get default user`s styleEditorPanel
const getDefaultStyleEditorPanel = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL+"default", config)

  return response.data
}

// update user styleEditorPanel
const updateStyleEditorPanel = async (styleEditorPanel,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let styleEditorPanelBody = styleEditorPanel.body
  let styleEditorPanelId = styleEditorPanel._id;
  const response = await axios.put(API_URL + styleEditorPanelId, styleEditorPanelBody, config)

  return response.data
}
// Delete user styleEditorPanel
const deleteStyleEditorPanel = async (styleEditorPanelId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + styleEditorPanelId, config)

  return response.data
}

const styleEditorPanelService = {
  createStyleEditorPanel,
  getStyleEditorPanels,
  getDefaultStyleEditorPanel,
  updateStyleEditorPanel,
  deleteStyleEditorPanel,
}
 
export default styleEditorPanelService
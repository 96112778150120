import { useCallback, useEffect, useRef, useState } from "react"
import { useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInModule, addDesignItemInModule, setChosenComponent, updateComponentsOrder, updatingComponentsOrder } from "../../../features/localDocument/editingDocumentSlice"
import Level1 from "./Level1";
import Heading from "./Heading";
import SubHeading from "./SubHeading";
import TextArea from "./TextArea";
import { mergeRefs } from "react-merge-refs";
import EmptyComponent from "./EmptyComponent";
import ColumnsHolder from "./ColumnsHolder";
import Line from "./Line";
import ImageHolder from "./ImageHolder";
import Image from "./Image";
import DynamicBlock from "./DynamicBlock";
import ModuleSeparator from "./ModuleSeparator";
import { useDeleteComponent } from "./deleteComponentFromTemplate";

const module_style = { 
  position: 'relative',
}
const Module = ({ part, 
  setSidePanel, setStylingComponent, 
  setImageForm,
  setUploadImageData, setUploadImageParent,
  }) => {

  const [modulePages, setModulePages] = useState(1) 

  const chosenPageHeight = 900
  
	const { chosenComponent } = useSelector(state => state.editingDocument)
  const [isResizingOver, setIsResizingOver] = useState(true)

  const changeClickability = () => {
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver :',isResizingOver)
  }

  
  const [height, setHeight] = useState(0);
  const incrementCount = () => {
    // Update state with incremented value
    setModulePages(modulePages + 1);
  };
  const deincrementCount = () => {
    // Update state with incremented value
    setModulePages(modulePages - 1 );
  };
  const measuredRef = useCallback(node => {    
    if (node !== null) {      
      setHeight(node.getBoundingClientRect().height); 
      console.log(node.getBoundingClientRect().height)
      console.log(chosenPageHeight * modulePages)
      if(node.getBoundingClientRect().height > chosenPageHeight * modulePages){
        console.log('go bigger')
        incrementCount()
      }
      if((node.getBoundingClientRect().height < chosenPageHeight *(modulePages-1)) && modulePages !== 1){
        console.log('go smaller')
        deincrementCount()
      }
      console.log(modulePages)
    }  
  }, []);

  const renderSeparators = useCallback(() => {
    for (let step = 1; step < modulePages; step++) {
      console.log('step: ',step)
      return( <ModuleSeparator num={step} pageHeight={chosenPageHeight}/>)
    }
  }, [modulePages])

  const useResize = (myRef) => {
    const [height, setHeight] = useState(0)
  
    const handleResize = () => {
      setHeight(prev => myRef.current.offsetHeight)
      if(myRef.current.offsetHeight>300){
        setModulePages(prev => 2)
      }
    }
  
    useEffect(() => {
      myRef.current && myRef.current.addEventListener('mouseover', handleResize)
      return () => {
        myRef.current && myRef.current.removeEventListener('mouseover', handleResize)
      }
    }, [myRef])
  
    return {height}
  }

  const componentRef = useRef()
  //const {height} = useResize(componentRef)

  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setSidePanel
  });
  const [isClickable, setIsClickable] = useState(false)
  const [imageUploadForm, setImageUploadForm] = useState(false)
  const [positionOfImage, setPositionOfImage] = useState(false)

  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }

  const ref = useRef(null)
  const [{ isOverCurrent, handlerId, }, drop] = useDrop({
    accept: ['component', 'part', 'level1','columns_holder'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent && item.columnData === undefined){
        if(item.type !== undefined  && 
          (item.parentType !== 'level1' || item.parentType !== 'level2' || item.parentType === 'column')){
            if(item.type === 'new_image'){
              setUploadImageData('new_image');
              setUploadImageParent({position: part.id, type: 'module'}); 
              setImageForm(prev => true)
            }else{
              if(item.type === 'design_item'){
                dispatch(addDesignItemInModule([item, part.id]))
              }else{
                dispatch(addComponentInModule([ item, part.id ]))
              }
            }
        }
      }
    },
  })
  const hoverRes = isOverCurrent
  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
      (child.part === undefined && child.parent === part.id) &&
      dispatch(updatingComponentsOrder(
        [dragIndex, hoverIndex, part.id, child]))
    }, [])
  const changeChild = useCallback(
    (child) => {
    dispatch(updateComponentsOrder(
      [child.position, child, part.id]))
  }, []) 

  const renderLevel1 = useCallback((child, index) => {
    return (
      <Level1 
        key={child.position} part={child} index={index}
        moduleId = {part.id} 
        changeClickability={changeClickability} 
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderDynamicBlock = useCallback((child, index) => {
    return (
      <DynamicBlock 
        key={child.position} part = {child} index={index}
        moduleId={part.id}
        parentData = {part.id} 
        parentType={'module'}
        changeClickability={changeClickability} 
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback((child, index) => {
    return (
      <ColumnsHolder 
        key={child.position}
        part = {child} 
        index={index}
        moduleId = {part.id} 
        parentType = {'module'}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <Heading 
        key={child.position}
        part = {child}
        index={index} 
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <SubHeading 
        key={child.position}
        part = {child} 
        index={index}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <TextArea 
        key={child.position}
        part={child} 
        index={index}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <ImageHolder 
        key={child.position} part={child} index={index}
        changeClickability={changeClickability}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImage = useCallback((child, index) => {
    return (
      <Image 
        key={child.position} part={child} index={index}
        changeClickability={changeClickability}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <Line 
        key={child.position}
        part = {child} 
        index={index}
        parentData = {part.id} 
        parentType = {"module"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderEmptyComponent = useCallback((child, index, visibility) => {
    return (
      <EmptyComponent 
        key={child.position}
        part = {child} 
        index={index}
        parentData = {part.id} 
        parentType = {"module"}
        changeChildFromParent = {changeChild}
        visibility={visibility}
      />
    )
  }, [])
  drop(ref)

  useEffect(() => {
    const onModuleClick = (event) => {
      if (event.target === event.currentTarget){
        if(isResizingOver){
          dispatch(setChosenComponent([part]))
          setStylingComponent([part, part.id]);
          setSidePanel(prev=> true)
        }
        console.log('click on module   !')
      } 
    }
    const moduleRef = ref.current;
    moduleRef.addEventListener("click", onModuleClick);

    return () => {
      moduleRef.removeEventListener("click", onModuleClick);
    };
  }, [isResizingOver])

  return (
    <div ref={mergeRefs([measuredRef, ref])} data-handler-id={handlerId}  
      style={{...module_style, ...part.style, border: hoverRes || chosenComponent.id === part.id ? '2px solid blue' : '2px solid rgba(229,225,225,255)'}}
      onKeyDown={keyPressHandler}
      tabIndex="0">
      { renderSeparators()
      }
      {/* {modulePages === 2 && <div style={hr}/>} */}
        {part.content.length !== 0 ?
         part.content.map( (child, index) => 
         child.typeOfContent === "level1" ? 
          renderLevel1(child, index)
          : child.typeOfContent === "columns_holder" ? 
          renderColumnsHolder(child, index)
          : child.typeOfContent === "heading" ? 
          renderHeading(child, index)
          : child.typeOfContent === "subheading" ? 
          renderSubHeading(child, index)
          : child.typeOfContent === "text_field" ?
          renderTextArea(child, index)
          : child.typeOfContent === "image_holder" ?
          renderImageHolder(child, index)
          : child.typeOfContent === "image" ?
          renderImage(child, index)
          : child.typeOfContent === "line" ? 
          renderLine(child, index)
          : child.typeOfContent === "dynamic_block" ? 
          renderDynamicBlock(child, index)
          : child.position === 0 ? 
          renderEmptyComponent(child, index, true)
          : <></>
          )
        :
        <></>}
    </div>
  )
}

export default Module
import { useEffect, useState } from 'react';
import save from '../../icons/save-admin-row-icon.svg'
import checker from '../../icons/selected-checker-icon.svg'
import upload from '../../icons/upload-logo-icon.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import DatePicker from 'react-datepicker';
import moment from "moment";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { changeCustomerSpace } from '../../features/customerSpaces/customerSpaceSlice';
import { convertImageToBase64viaFileReader } from './admin_page_functions';
import CustomerDurationSelector from './CustomerDurationSelector';

const CustomerSpacesTableRow = ({space, index, customerGroupToggle, setUpdateTrigger}) => {

  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [isChosen, setIsChosen] = useState(false)
  const [isDurationSelector, setIsDurationSelector] = useState(false)

  const [customerSpaceName, setCustomerSpaceName] = useState(space.name);
  const [customerSpaceStartDate, setCustomerSpaceStartDate] = useState(space.start_date);
  const [customerSpaceDuration, setCustomerSpaceDuration] = useState(space.duration);
  const [customerSpacePayment, setCustomerSpacePayment] = useState(space.payment_status);
  const [customerSpaceType, setCustomerSpaceType] = useState(space.type);
  const [customerSpaceComments, setCustomerSpaceComments] = useState(space.comments);
  const [customerSpaceLogoUpload, setCustomerSpaceLogoUpload] = useState('');
  const [customerSpaceLogo, setCustomerSpaceLogo] = useState({
    small: space.logo.small,
    site: space.logo.site,
    origin: space.logo.origin
  });

  useEffect(()=>{
    setCustomerSpaceName(space.name)
    setCustomerSpaceStartDate(space.start_date)
    setCustomerSpaceDuration(space.duration)
    setCustomerSpacePayment(space.payment_status)
    setCustomerSpaceType(space.type)
    setCustomerSpaceComments(space.comments)
    setCustomerSpaceLogo({
      small: space.logo.small,
      site: space.logo.site,
      origin: space.logo.origin
    })
  },[space])
  
  const [isCustomerTypeDown, setIsCustomerTypeDown] = useState(false)
  const refCustomerType = useOnclickOutside(() => {
    isCustomerTypeDown && setIsCustomerTypeDown(false);
  })

  const imageUploadHandler = (e) => {
    setCustomerSpaceLogoUpload(e.target.files[0])
  }
  
  useEffect(() => {
    if (!customerSpaceLogoUpload) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(customerSpaceLogoUpload)
    const img = new Image();
    img.src = objectUrl;
    img.onload = () => {
      // Create resized images
      const canvasSmall = document.createElement('canvas');
      const canvasSite = document.createElement('canvas');

      const contextSmall = canvasSmall.getContext('2d');
      const contextSite = canvasSite.getContext('2d');

      canvasSmall.width = 36;
      canvasSmall.height = 36;
      contextSmall.drawImage(img, 0, 0, 36, 36);

      canvasSite.width = 120;
      canvasSite.height = 120;
      contextSite.drawImage(img, 0, 0, 120, 120);

      // Convert images to base64
      const base64Small = canvasSmall.toDataURL(customerSpaceLogoUpload.type);
      const base64Site = canvasSite.toDataURL(customerSpaceLogoUpload.type);

      // Update logo object
      convertImageToBase64viaFileReader(objectUrl, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, origin: base64Img}))
      })
      convertImageToBase64viaFileReader(base64Small, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, small: base64Img}))
      });
      convertImageToBase64viaFileReader(base64Site, (base64Img) => {
        setCustomerSpaceLogo(prev => ({...prev, site: base64Img}))
      })
      setIsEditing(true)
    };

    // free memory when ever this component is unmounted
    return () => {
      setCustomerSpaceLogoUpload(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [customerSpaceLogoUpload])

  const customerSpaceHandleUpdate = () => {
    if(customerSpaceName === '' || customerSpaceDuration === ''
    || customerSpacePayment === ''){
      toast.error('You have empty inputs !', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{
      const updateData =  {...space, 
        name: customerSpaceName,
        start_date: customerSpaceStartDate,
        duration: customerSpaceDuration,
        payment_status: customerSpacePayment,
        type: customerSpaceType,
        comments: customerSpaceComments,
        logo: customerSpaceLogo,
      }
      console.log('is updated')
      dispatch(changeCustomerSpace(updateData))
      setUpdateTrigger(true)
      setIsEditing(false)
    }
  };

  const choseHandler = (id) => {
    customerGroupToggle(id)
    setIsChosen(prev => !prev)
  }

  const editingHandler = (property, value) => {
    if(space[property] !== value){
      setIsEditing(true)
    } else{
      setIsEditing(false)
    }
  }

  return ( 
    <tr className={isChosen ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
      {isEditing ?
        <td className="admin-control-form-update-td">
          <img className="admin-control-form-update" 
          onClick={customerSpaceHandleUpdate}
          src={save} alt="save"/>
        </td>
        :
        <td key={index} className="admin-control-space-selector" onClick={() => choseHandler(space._id)}>
          <div className={isChosen ? "admin-control-space-selected" : "admin-control-space-unselected"}>
            {isChosen && <img src={checker} alt='yes'/>}
          </div>
        </td>
      }
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={customerSpaceName}
          onChange={(e) => {
            editingHandler('name', e.target.value);
            setCustomerSpaceName(e.target.value);
          }}/>
      </td>
      <td className='admin-control-usual-row-date'>
        {moment.utc(customerSpaceStartDate).format('D MMMM yyyy')}
        <DatePicker wrapperClassName="admin-control-usual-row-datePicker"
        onChange={(date) => {
          editingHandler('start_date', date);
          setCustomerSpaceStartDate(date);
        }} dateFormat="d MMMM yyyy"/>
      </td>
      <td onClick={() => {if(!isDurationSelector)setIsDurationSelector(true)}} 
        style={{cursor:'pointer', backgroundColor: isDurationSelector ? 'rgb(219, 226, 228)' : ''}}>
        <CustomerDurationSelector isSelected={isDurationSelector} setIsSelected={setIsDurationSelector}
        editingHandler={editingHandler} 
        customerDuration={customerSpaceDuration} setCustomerDuration={setCustomerSpaceDuration}/>
        {!customerSpaceDuration.type ?
          ' '
          :
          customerSpaceDuration.type !== 'months' ?
          customerSpaceDuration.duration
          :
          customerSpaceDuration.duration+' months'
        }
        {/* <input 
          type="text"
          className="admin-control-usual-row-input"
          value={customerSpaceDuration}
          onChange={(e) => {
            editingHandler('duration', e.target.value);
            setCustomerSpaceDuration(e.target.value);
          }}/> */}
      </td>
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={customerSpacePayment}
          onChange={(e) => {
            editingHandler('payment_status', e.target.value);
            setCustomerSpacePayment(e.target.value);
          }}/>
      </td>
      <td ref={refCustomerType} className="admin-selector-container-editing"
      onClick={() => setIsCustomerTypeDown(prev => !prev)}>
        {customerSpaceType}
        { isCustomerTypeDown && 
        <div className="admin-selector-body-editing">
          <div className="admin-selector-body-option" 
          onClick={() => {editingHandler('type', 'Sandbox'); setCustomerSpaceType('Sandbox')}}>
            Sandbox
          </div>
          <div className="admin-selector-body-option" 
          onClick={() => {editingHandler('type', 'Subscription'); setCustomerSpaceType('Subscription')}}>
            Subscription
          </div>
        </div> }
      </td>
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={customerSpaceComments}
          onChange={(e) => {
            editingHandler('comments', e.target.value);
            setCustomerSpaceComments(e.target.value);
          }}/>
      </td>
      <td className="admin-control-form-upload-td">
        <label className="admin-control-form-upload-label" htmlFor={'upload_image'+space._id}>
          {customerSpaceLogo.small ? 
            <img className="admin-control-form-upload-logo" src={customerSpaceLogo.small} alt="Small Logo" />
            :
            <img className="admin-control-form-upload" src={upload} alt="upload"/>
          } 
        </label>
        <input id={'upload_image'+space._id} onChange={(e) => imageUploadHandler(e)} 
        className="admin-control-form-upload-input" type="file" accept="image/*"/>
      </td>
    </tr>
   );
}
 
export default CustomerSpacesTableRow;
import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel2StyleDoc, setChosenComponent } from "../../../features/localDocument/editingStyleDocumentSlice"
import StyleHeading from "./StyleHeading"
import StyleSubHeading from "./StyleSubHeading"
import StyleTextArea from "./StyleTextArea"
import StyleLevel3 from "./StyleLevel3"

const level2_style = {
  minHeight:'40px',
  height:'fit-content',
  display:'flex',
  flexDirection:'column',
  position: 'relative',
  borderRadius: '8px',
}

const StyleLevel2 = ({part, index, moduleId, 
  changeClickability,
  parentData, parentType,
  setStylingPanel, setStylingComponent}) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()

  const [isResizingOver, setIsResizingOver] = useState(true)

  const changeLevelClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }

  const [style, setStyle] = useState({})
  useEffect(()=> {
    setStyle(prev => ({...level2_style, ...part.style}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...level2_style, ...part.style}))
  }, [part.style])


  const ref = useRef(null)
  const [{isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'level'],
    collect(smonitor) {
      return {
        isOverCurrent: smonitor.isOver({ shallow: true }),
        handlerId: smonitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        dispatch(addComponentInLevel2StyleDoc([item, part.position]))
      }
    },
  })
  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType}
    },
    canDrag: () => {
      return  chosenComponent.position=== part.position
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const hoverRes = isOverCurrent 
  const renderHeading = useCallback((content, index) => {
    return (
      <StyleHeading 
        key={index}
        part = {content} 
        index={index}
        parentData = {part.position} 
        parentType = {part.type}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
      />
    )
  }, [])
  const renderSubHeading = useCallback((content, index) => {
    return (
      <StyleSubHeading 
        key={index}
        part = {content} 
        index={index}
        parentData = {part.position} 
        parentType = {part.type}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
      />
    )
  }, [])
  const renderTextField = useCallback((content, index) => {
    return (
      <StyleTextArea 
        key={index}
        part = {content} 
        index={index}
        parentData = {part.position} 
        parentType = {part.type}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
      />
    )
  }, [])
  const renderLevel3 = useCallback((content, index) => {
    return (
      <StyleLevel3 
        key={index} part = {content} index={index}
        changeClickability={changeLevelClickability}
        moduleId={moduleId}
        parentData = {part.position} 
        parentType = {part.type}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
      />
    )
  }, [])
  
  useEffect(() => {
    const onLevelClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, parentData, parentType]);
        setStylingPanel(prev=> true)
      }
    }

    const levelRef = ref.current;
    levelRef.addEventListener("click", onLevelClick);

    return () => {
      levelRef.removeEventListener("click", onLevelClick);
    };
  }, [isResizingOver])

  return (
    <div className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{width: style.width, height: style.height}}>
      <div ref={ref} data-handler-id={handlerId}
      style={{...style, height: '100%', width:'100%',
        opacity}} 
      >
      <div className="style-element-tittle-level2">Level {part.name.slice(-1)}</div>
      {part.content.length !== 0 ?
         part.content.map( (child, index) => 
          child.type === "heading" ? 
          renderHeading(child, index)
          :
          child.type === "subheading" ? 
          renderSubHeading(child, index)
          :
          child.type === "text_field" ? 
          renderTextField(child, index)
          :
          child.type === "level3" ? 
          renderLevel3(child, index)
          : console.log('ceivo')
          )
        :
        <></>}
    </div>
    </div>
  )
}

export default StyleLevel2
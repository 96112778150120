import { useState } from "react";
import { useSortableTable } from "../../hooks/useSortableTable";
import AgreementsListTableBody from "./AgreementsListTableBody";
import AgreementsListTableHead from "./AgreementsListTableHead";

const AgreementsListTable = ({ data, selectedNum, setSelectedNum, setSelectedAgreements, chosenAgreement, setChosenAgreement}) => {
  const columns = [
    { label: " ", accessor: "selector", sortable: false },
    { label: "Agreement name", accessor: "name", sortable: true },
    { label: "Parties", accessor: "parties", sortable: true },
    { label: "Last edit", accessor: "updatedAt", sortable: false },
    { label: "Status", accessor: "status", sortable: true },
    { label: "Shared with", accessor: "compliance", sortable: true },
    { label: " ", accessor: "three_dots", sortable: false },
  ]
  
  const [tableData, handleSorting] = useSortableTable(data, columns);
  const [isSelectedAll, setIsSelectedAll] = useState(false)
  const [isSelectedAllTrigger, setIsSelectedAllTrigger] = useState(false)

  return (
    <>
      <table className="agreements-list-table">
        <AgreementsListTableHead {...{ columns, handleSorting, setSelectedAgreements, isSelectedAll, setIsSelectedAll, setIsSelectedAllTrigger, tableData, selectedNum}} />
        <AgreementsListTableBody {...{ columns, tableData, setSelectedAgreements, setSelectedNum, isSelectedAll, isSelectedAllTrigger, chosenAgreement, setChosenAgreement}} />
      </table>
    </>
  );
};

export default AgreementsListTable;
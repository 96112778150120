import { Link } from 'react-router-dom';
import logo_icon from '../icons/majoto-logo-icon.svg'

const IntegrationPage = () => {
  return ( 
    <div className='auth-background'>
      <div className='auth-body'>
        <div><img src={logo_icon} alt="logo"/></div>
        <div className='auth-content'>
          <div className='auth-form-name'>
            There is no user associated with this Google account. 
            Please sign up for an account to continue.
          </div>
          
          <Link to="/register">
            To the sign up page..
          </Link>
        
        
        </div>
      </div>
    </div>
   );
}
 
export default IntegrationPage;
import AnalyticsHeader from "../components/analytics_components/AnalyticsHeader";
import { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getAgreements, reset } from '../features/agreements/agreementSlice';
import ShortMenu from "../components/ShortMenu";

const Portfolio = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector( (state) => state.auth)
  const { agreements, isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }

    if (!user) {
      navigate('/login')
    }

    dispatch(getAgreements())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])


	return ( 
		<div className='content-board'>
			<ShortMenu chosenPage={'analytics'}/>
			<div className='board-body'>
				<AnalyticsHeader stage={'portfolio'}/>
        <div className="message-holder">
          { isLoading ? <div className="message">Loading...</div>
          :
          <div className="message">
            You have no active agreements yet.<br/> 
            Go to Launchpad to create new agreements
          </div> }
        </div>
			</div>
		</div>
		);
}
 
export default Portfolio;
import { useEffect } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { getStyles, reset } from '../features/styles/styleSlice';
import { useNavigate } from "react-router-dom";
import ShortMenu from '../components/ShortMenu';
import StylesBoard from "../components/StylesBoard";
import LaunchpadHeader from "../components/LaunchpadHeader";
import { useCreateNewDocument } from "../hooks/useCreateNewDocument";

const Styles = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

	const {user} = useSelector((state) => state.auth)  
  const { styles, isLoadingStyle, isErrorStyle, messageStyle } = useSelector(state=> state.styles)

  const createNewDocument = useCreateNewDocument('styles')

  useEffect(() => {
    if (!user) {
      navigate('/login')
    }
    if (isErrorStyle) {
      console.log("Error: "+messageStyle)
    }

    dispatch(getStyles())
    return () => {
      dispatch(reset())
    }
  }, [user, isErrorStyle, messageStyle, navigate, dispatch])


  return ( 
    <div className='content-board'>
      <ShortMenu chosenPage={'launchpad'}/> 
      <div className='board-body'>
        <LaunchpadHeader stage={'styles'}/>
        {user ?
        <div className="board-content">
          <div>
            <div className="create-template-label">
              <div id="red-label"/>
              <div>Majoto Styles</div>
            </div>
            <StylesBoard styles={styles} isLoadingStyle={isLoadingStyle}/>
          </div>
          <div>
            <div className="create-template-label">
              <div id="blue-label"/>
              <div>My custom styles</div>
            </div>
            <div className="create-template">
              <div className="create-template-description">
                You haven’t built a style yet. You can build a new style from scratch, or drag & drop an existing template into this space as your starting point.
              </div>
              <div className="create-new" onClick={() => createNewDocument()}>
                Create new
              </div>
            </div>
          </div>
        </div>
        :
        <div className="message">Log in to get access to data</div>}
			</div>
    </div>
  )
}
 
export default Styles;
import axios from 'axios'

const API_URL = '/api/documents/'

// Create new document
const createDocument = async (documentData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, documentData, config)

  return response.data
}

// Get user documents
const getDocuments = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}

// update user document
const updateDocument = async (document,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let documentName = document.name
  let documentBody = document.body
  let documentClasses = document.classes
  let documentId = document._id;
  const response = await axios.put(API_URL + documentId, {name: documentName, body: documentBody, classes: documentClasses}, config)

  return response.data
}
// Delete user document
const deleteDocument = async (documentId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + documentId, config)

  return response.data
}

const documentService = {
  createDocument,
  getDocuments,
  updateDocument,
  deleteDocument,
}

export default documentService
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {reset } from '../features/agreements/agreementSlice';
import ShortMenu from "../components/ShortMenu";
import LaunchpadHeader from '../components/LaunchpadHeader';

const LaunchPage = () => {

	const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector( (state) => state.auth)
  const { isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }

    if (!user) {
      navigate('/login')
    }

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])
	return ( 
		<div className='content-board'>
			<ShortMenu chosenPage={'launchpad'}/>
			<div className='board-body'>
				<LaunchpadHeader chosenStage={'no'}/>
				{ isLoading && <div className="message">Loading...</div>}
				{user ? ( 
				<div className="home">
					<div id="info" >This is a launch page. You can check your templates and agreements here :) </div> 
					</div>
				) : !isLoading && <div className="message">Log in to get access to data</div>}
			</div>
		</div>
		);
}
 
export default LaunchPage;
import AnalyticsHeader from "../components/analytics_components/AnalyticsHeader";
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { getAgreements, reset } from '../features/agreements/agreementSlice';
import AnalyticsChart from "../components/analytics_components/AnalyticsChart";
import DonutChart from "../components/analytics_components/DonutChart";
import ShortMenu from "../components/ShortMenu";

const Analytics = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const user = useSelector( (state) => state.auth)
  const { agreements, isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }

    if (!user) {
      navigate('/login')
    }

    dispatch(getAgreements())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, isError, message, dispatch])


	return ( 
		<div className='content-board'>
			<ShortMenu chosenPage={'analytics'}/>
			<div className='board-body'>
				<AnalyticsHeader stage={'compliance'}/>
        <div className="message-holder">
          { isLoading ? <div className="message">Loading...</div>
          :
          <div className="message">
            You have no active compliance reports yet. This means you don’t have any templates with playbooks, or no agreements created from those templates.
          </div>}
      </div>
      {/* <div className="analytics">
        <div className="row">
        <DonutChart agreements={agreements}/>
        </div>
      </div> */}
			</div>
		</div>
		);
}
 
export default Analytics;
import React, {useState } from "react";
import { useDrag} from 'react-dnd'
import { useDispatch } from "react-redux"
import { removeEmptyComponents } from "../../features/localDocument/editingDocumentSlice";


const MapTrashComponent = ({ item, index }) => {
  
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)


  const [{ isDragging }, drag] = useDrag({
    type: item.typeOfContent,
    item: () => {
      return { part: item }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      dispatch(removeEmptyComponents())
    }
  })
  
  const opacity = isDragging ? 0 : 1
  return (
    item.typeOfContent === 'module' ?
    <div ref={drag} className="module-item">
        { item.name }
    </div>
     : item.typeOfContent === 'level1' ?
      <>
      <div ref={drag} className='agreement-map-level-heading' onClick={() => setIsOpen(prev => !prev)}>
        <div className="agreement-map-level1-num">{index+1}</div>
        <div className="agreement-map-level-name-container">
          <div className="agreement-map-level1-name" >{item.name}</div>
        </div>
      </div>
      {isOpen && item.content.filter(part => part.typeOfContent === "level2").length !== 0 ?
      <div className="agreement-map-level2-list">
        {item.content.filter(part => part.typeOfContent === "level2").map((part, i) => 
        <div className='agreement-map-level-heading'>
          <div className="agreement-map-level2-num">{i+1}</div>
          <div className="agreement-map-level-name-container">
            <div className="agreement-map-level2-name" >{part.name}</div>
          </div>
        </div> 
        )}
      </div>
        : <></>}
      </>
    : item.typeOfContent === 'level2' ?
      <>
        <div ref={drag} className='agreement-map-level-heading' onClick={() => setIsOpen(prev => !prev)}>
          <div className="agreement-map-level2-num">{index+1}</div>
          <div className="agreement-map-level-name-container">
            <div className="agreement-map-level2-name" >{item.name}</div>
          </div>
        </div>
        {item.content.filter(part => part.typeOfContent === "level3").length !== 0 ?
        <div className="agreement-map-level3-list">
          {item.content.filter(part => part.typeOfContent === "level3").map((part, i) => 
          <div className='agreement-map-level-heading'>
            <div className="agreement-map-level3-num">{i+1}</div>
            <div className="agreement-map-level-name-container">
              <div className="agreement-map-level3-name" >{part.name}</div>
            </div>
          </div> 
          )}
        </div>
        : <></>} 
      </>
    : item.typeOfContent === 'level3' ?
      <div ref={drag} className='agreement-map-level-heading'>
        <div className="agreement-map-level3-num">{index+1}</div>
        <div className="agreement-map-level-name-container">
          <div className="agreement-map-level3-name" >{item.name}</div>
        </div>
      </div> 
    : <></>
  )
}


export default MapTrashComponent;
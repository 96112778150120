import axios from 'axios'

const API_URL = '/api/styles/'

// Create new style
const createStyle = async (styleData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, styleData, config)

  return response.data
}

// Get user styles
const getStyles = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}

// update user style
const updateStyle = async (style,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let styleName = style.name
  let styleBody = style.body
  let styleClasses = style.classes
  let styleId = style._id;
  const response = await axios.put(API_URL + styleId, {name: styleName, body: styleBody, classes: styleClasses}, config)

  return response.data
}
// Delete user style
const deleteStyle = async (styleId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + styleId, config)

  return response.data
}

const styleService = {
  createStyle,
  getStyles,
  updateStyle,
  deleteStyle,
}

export default styleService
import { useCallback, useEffect, useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useDispatch } from 'react-redux'
import {useDropzone} from 'react-dropzone'
import { addComponentInLevel1, addComponentInLevel1Column, addComponentInLevel2, addComponentInLevel2Column, addComponentInModule, addComponentInModuleColumn, 
  addImageInLevel1, addImageInLevel1Column, addImageInLevel2, addImageInLevel2Column, addImageInModule, addImageInModuleColumn } from '../../features/localDocument/editingDocumentSlice'
import go_back from '../../icons/return-arrow.svg'
import explore from '../../icons/majoto-image-library/explore-icon.svg'
import options from '../../icons/majoto-image-library/options-icon.svg'


const ImageUploadForm = ({active, setActive, parentData, imageData}) => {

  const [uploadImage, setUploadImage] = useState()
  const [isLibrary, setIsLibrary] = useState(false)

  const dispatch = useDispatch()

  const ref = useOnclickOutside(() => {
    active && setActive(prev => false);
  })

  const imageUploadHandler = (e) => {
    setUploadImage(prev => e.target.files[0])
  }

  //function to convert image into base64
  function convertImageToBase64viaFileReader(url, callback){
    let xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.onload = function() {
      let reader  = new FileReader();
      reader.onloadend = function () {
          callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.send();
  }

  useEffect(() => {
    if (!uploadImage) {
      return
    }
    // create the image and placing it in image holder
    const  objectUrl = URL.createObjectURL(uploadImage)
    convertImageToBase64viaFileReader(objectUrl, function(base64Img){
      if(imageData === 'new_image'){
        imageData = {
          type: 'image',
          content:base64Img
        }
        console.log(parentData.columnData)
        if(parentData.type === "module" && parentData.columnData === undefined){
          dispatch(addComponentInModule([imageData, parentData.position]))
        }
        if(parentData.type === "module" && parentData.columnData !== undefined){
          dispatch(addComponentInModuleColumn([imageData, parentData.columnData.moduleId, parentData.columnData.holder, parentData.columnData.column]))
        }
        if(parentData.type === "level1" && parentData.columnData === undefined){
          dispatch(addComponentInLevel1([imageData, parentData.position]))
        }
        if(parentData.type === "level1" && parentData.columnData !== undefined){
          dispatch(addComponentInLevel1Column([imageData, parentData.columnData.moduleId, parentData.columnData.holder, parentData.columnData.column]))
        }
        if(parentData.type === 'level2' && parentData.columnData === undefined){
          dispatch(addComponentInLevel2([imageData, parentData.position]))
        }
        if(parentData.type === "level2" && parentData.columnData !== undefined){
          dispatch(addComponentInLevel2Column([imageData, parentData.columnData.moduleId, parentData.columnData.parent, parentData.columnData.holder, parentData.columnData.column]))
        }
      }else{
        if(parentData.type === "module" && parentData.columnData === undefined){
          dispatch(addImageInModule([parentData.position, imageData, base64Img]))
        }
        if(parentData.type === "module" && parentData.columnData !== undefined){
          dispatch(addImageInModuleColumn([parentData.columnData.parent, parentData.columnData, imageData, base64Img]))
        }
        if(parentData.type === "level1" && parentData.columnData === undefined){
          dispatch(addImageInLevel1([parentData.position, imageData, base64Img]))
        }
        if(parentData.type === "level1" && parentData.columnData !== undefined){
          dispatch(addImageInLevel1Column([parentData.columnData.parent, parentData.columnData, imageData, base64Img]))
        }
        if(parentData.type === 'level2' && parentData.columnData === undefined){
          dispatch(addImageInLevel2([parentData.position, imageData, base64Img]))
        }
        if(parentData.type === "level2" && parentData.columnData !== undefined){
          dispatch(addImageInLevel2Column([parentData.columnData.parent, parentData.columnData, imageData, base64Img]))
        }
      }
      setActive(prev=> false)
    });
 
    // free memory when ever this component is unmounted
    return () => {
      setUploadImage(prev=> false)
      URL.revokeObjectURL(objectUrl)}
  }, [uploadImage, parentData])

  useEffect(() => {
    !active && setUploadImage(prev => '')
  },[active])

  const onDrop = useCallback(acceptedFiles => {
    setUploadImage(prev => acceptedFiles[0])
  }, [])
  const {getRootProps, getInputProps, isDragActive} = useDropzone({onDrop})

  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
     {/* { isLibrary ?
      <div ref={ref} className='majoto-image-library-form-content' style={{opacity: active && '1'}}>
        <div className="form-go-back-image">
          <img src={go_back} alt="return" onClick={() => setIsLibrary(prev=> false)}/>
        </div>
        <div className="majoto-image-library-image">
          <img src={options} alt="options" />
        </div>
        <div className="majoto-image-library-image">
          <img src={explore} alt="explore" />
        </div>
      </div>
      : */}
     <div ref={ref} className='upload-image-form-content' style={{opacity: active && '1'}}>
        <div className="upload-image-form-header">
          Upload a new image 
        </div>
        <div className="upload-image-form-buttons">
          <div className='upload-image-form-button' onClick={() => setIsLibrary(prev=> true)}>
            Choose from library
          </div>
          <label htmlFor="upload_image" className='upload-image-form-button'>
            Upload a new image
          <input id="upload_image" onChange={(e) => imageUploadHandler(e)} 
          style={{visibility:'hidden', width: '0px', height:'0px'}} type="file" accept="image/*"/>
          </label>
        </div>
        <div className="upload-image-form-or">
          or
        </div>
        <div className="upload-image-form-drop-file" {...getRootProps()}>
          <input {...getInputProps()}/>
          {
            isDragActive ?
              <p>Drop the files here ...</p> :
              <p>Drag 'n' drop some files here, or click to select files</p>
          }
        </div>
      </div>
      {/* } */}
    </div>
   );
}
 
export default ImageUploadForm
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import AccountHeader from "../components/AccountHeader";
import InvitationForm from "../components/InvitationForm";
import ShortMenu from "../components/ShortMenu";
import {logout, reset} from '../features/auth/authSlice'

const AccountPage = () => {
  const navigate = useNavigate()
	const dispatch = useDispatch()


  const user = useSelector( (state) => state.auth)

	const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  useEffect(() => {

    if (!user) {
      navigate('/login')
    }

  }, [user, navigate])


	return (
		<div className='content-board'>
			<ShortMenu chosenPage={'account'}/>
			<div className='board-body'>
				<AccountHeader chosenStage={'no'}/>
        <div className='account-body'>
        {user.user ? 
        <div className="account-info">
          <div id="info" >Account info: </div> 
          <div id="name" >Name: {user.user.name}</div> 
          <div> Email: {user.user.email}</div>
          <div> Role: {user.user.role}</div>
          { user ? 
            <div className="auth" onClick={onLogout}>
              Logout
            </div>
            : 
            <>			
              <div className="auth"><Link to='/register'>Sign  up</Link></div>
              <div className="auth"><Link to='/login'>Login</Link></div>
            </> }
          </div> 
          : <div >Login or register please</div>}
          <div style={{display:'flex', flexDirection:'column',width: '400px', gap:'10px'} }>
            <div id="info" >Invitation form </div>
            <InvitationForm/>
          </div>
          </div>
      </div>
		</div>
  );
}
 
export default AccountPage;
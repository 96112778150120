import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  editingDocument: [],
  trashCan:[],
  store: [],
  classes:{},
  chosenComponent:{},
  croppingImage:{},
  styles: {
    style_module: {
      display:'flex',
      flexDirection: 'column',
      backgroundColor: "#FFFFFF",
      color: "#000", fontSize: "14px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      alignItems: "center",
      padding: '20px 10px',
      gap: '8px',
      outline:'1px solid rgb(229, 225, 225)',
      borderRadius:'10px',
      height:'fit-content',
      isUnicStyle: false,
    },
    style_level_holder: {
      backgroundColor: "#FFFFFF",
      color: "#000", fontSize: "14px",
      width: '300px', height:'120px',
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      alignItems: "center",
      padding: '20px 10px',
      borderRadius:'8px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      isUnicStyle: false,
    },
    style_heading: {
      color: "#4A9DAC", fontSize: "22px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: "center",
      padding: '8px',
      isUnicStyle: false,
    },
    style_subheading: {
      color: "#4A9DAC", fontSize: "16px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: "center",
      padding: '8px',
      isUnicStyle: false,
    },
    style_text_field: {
      color: '#000', fontSize: '14px',
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: 'center',
      padding: '8px',
      isUnicStyle:false,
    },
    style_line: { 
      height: '0px', border: '1px solid #000',
      applied: false, isUnicStyle:false,
    },
    style_level1: {
      display:'flex', padding: '20px 16px',
      gap:'8px',
      width: '300px', height:'120px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_level1_default: {
      display:'flex', padding: '20px 16px',
      gap:'8px', backgroundColor: "#FFFFFF",
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_level2: {
      display:'flex', padding: '20px 16px',
      gap:'8px', backgroundColor: "#FFFFFF",  
      width: '250px', minHeight:'70px', height:'fit-content',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_level3: {
      display:'flex', padding: '12px',
      gap:'8px', backgroundColor: "#FFFFFF",
      width: '140px', minHeight:'60px', height:'fit-content',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_holder: {
      display:'flex', padding: '20px 16px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_columns_holder: {
      display:'flex', gap: '24px',
      width:'100%',
      justifyContent:'',
      fontSize: '14px', textAlign: 'center',
      border: '0px solid #000',
      isUnicStyle:false,
    },
    style_column:{
      display:'flex', flexDirection:'column', 
      gap: '12px',
      fontSize: '14px', textAlign: 'center',
      border: '0px solid #000',
      isUnicStyle:false,
    },
    style_dynamic_block: {
      display: 'grid',
      gridTemplateColumns: '1fr 10px 1fr', 
      width: '300px', height:'120px',
      outlineWidth:'1px',
      outlineOffset:'-1px',
      outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      position: 'relative',
      isUnicStyle:false,
    },
    style_dynamic_part: {
      isUnicStyle:false,
    },
    style_image_holder: {
      width: 70, height:70, 
      isUnicStyle:false,
    },
    style_image: {
      isUnicStyle:false,
    },
  }
}

export const editingDocumentSlice = createSlice({
  name: 'editingDocument',
  initialState,
  reducers: {
    setModulePageSize: (state, action) =>{
      const size = action.payload[0]
      const {height, width} = size
      state.editingDocument.body.forEach(module => {
        if(height){
          module.style.pageHeight = height
        }
        if(width){
          module.style.pageWidth = width
        }
      }) 
    },
    updateModuleOrder: (state, newOrder) =>{
      const dragI = newOrder.payload[0]
      const hoverI = newOrder.payload[1]
      const newcards = state.editingDocument.body; 
      const dragCard = newcards[dragI]; 
      newcards.splice(dragI, 1);
      newcards.splice(hoverI, 0, dragCard);
      state.editingDocument.body = newcards
      state.editingDocument.body.forEach((module, index) => {
        module.index = index
      }) 
    },
    addNewModule: ( state, moduleName) => {
      let moduleStyle = state.styles.style_module
      if(state.editingDocument.classes){
        if(state.editingDocument.classes.module_1){
          moduleStyle = state.classes.module_1
        }
      }
      if(state.editingDocument.body.length !== 0 ){
        let lastIndex = state.editingDocument.body[0].id
        state.editingDocument.body.forEach(module=>{
          if(module.id>lastIndex){lastIndex=module.id}
        })
        lastIndex+=1
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: moduleName.payload,
          typeOfContent:'module',
          style: moduleStyle,
          content: [],
        };
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }else{
        let lastIndex = 0
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: moduleName.payload,
          typeOfContent:'module',
          style: moduleStyle,
          content: [],
        };
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }   
    },
    addOldModule: ( state, action) => {
      const moduleData = action.payload
      if(state.editingDocument.body.length !== 0 ){
        let lastIndex = state.editingDocument.body[0].id
        state.editingDocument.body.forEach(module=>{
          if(module.id>lastIndex){lastIndex=module.id}
        })
        lastIndex+=1
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: moduleData.name,
          typeOfContent:'module',
          style: moduleData.style,
          content: moduleData.content,
        }
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }else{
        let lastIndex = 0
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: moduleData.name,
          typeOfContent:'module',
          style: moduleData.style,
          content: moduleData.content,
        }
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }   
    },
    changeModuleName: (state, action) => {
      const moduleId = action.payload[0]
      const newName = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
         module.id === moduleId)
      state.editingDocument.body[indexOfModule].name = newName
    }, 
    addDesignedModule: (state, action) =>{
      const newItem = action.payload
      if(state.editingDocument.body.length !== 0 ){
        let lastIndex = state.editingDocument.body[0].id
        state.editingDocument.body.forEach(module=>{
          if(module.id>lastIndex){lastIndex=module.id}
        })
        lastIndex+=1
        let newParentContent = newItem.body.content
        newParentContent = newParentContent.map(part => 
          part = {...part, parent: lastIndex}
        )
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: newItem.name,
          typeOfContent:'module',
          style: newItem.body.style,
          content: newParentContent,
        };
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }else{
        let lastIndex = 0
        let newParentContent = newItem.body.content
        newParentContent = newParentContent.map(part => 
          part = {...part, parent: lastIndex}
        )
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: newItem.name,
          typeOfContent:'module',
          style: newItem.body.style,
          content: newParentContent,
        };
        state.editingDocument.body = [...state.editingDocument.body, newModule];
        newModule = {}
      }
    },
    updatingLevel1Order: (state, action) =>{
      const dragPosition = action.payload[0]
      const hoverPosition = action.payload[1]
      const dropParentId = action.payload[2]
      const dragParentId = action.payload[3].parent

      const indexOfDropModule = state.editingDocument.body.findIndex(module => module.id === dropParentId)
      const hoverId = state.editingDocument.body[indexOfDropModule].content.findIndex(level => 
        level.position === hoverPosition) 
      const indexOfDragModule = state.editingDocument.body.findIndex(module => module.id === dragParentId)
      const dragId = state.editingDocument.body[indexOfDragModule].content.findIndex(level => 
        level.position === dragPosition)

      if(dragParentId !== dropParentId){
        const emptyComponent = {
          value:{},
          typeOfContent:'level1',
          position: 0,
        }
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === dropParentId)
        // deleting empties in other modules
        state.editingDocument.body.forEach(module => {
          if(module.id !== dropParentId){
            module.content = module.content.filter(part => 
              part.position !== 0)
          }
        })
        let newContent = state.editingDocument.body[indexOfModule].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content = newContent 
        }
      } else {
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === dropParentId)
        const newContent = state.editingDocument.body[indexOfModule].content; 
        const dragCard = newContent[dragId]; 
        newContent.splice(dragId, 1);
        newContent.splice(hoverId, 0, dragCard);
        state.editingDocument.body[indexOfModule].content = newContent 
      }
    },
    updateLevel1Order: (state, action) => {
      const part = action.payload[0]
      const dropParentId = action.payload[1]
      const dragParentId = part.parent

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === dropParentId)
      let newContent = state.editingDocument.body[indexOfModule].content;
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      const newSection = {...part, parent: dropParentId}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newSection);
      state.editingDocument.body[indexOfModule].content = newContent
      
      if(part.parent !== dropParentId){
        const indexOfOldModule = state.editingDocument.body.findIndex(module => module.id === dragParentId)
        const oldContent = state.editingDocument.body[indexOfOldModule].content;
        state.editingDocument.body[indexOfOldModule].content = oldContent.filter(
          component => component.position !== part.position )
      }
    },
    addingNewLevel1: (state, action) => {
      const hoverPosition = action.payload[0]
      const parentId = action.payload[1]

      const indexOfDropModule = state.editingDocument.body.findIndex(module => module.id === parentId)
      const hoverId = state.editingDocument.body[indexOfDropModule].content.findIndex(level => 
        level.position === hoverPosition) 

      const emptyComponent = {
        value:{},
        typeOfContent:'level1',
        position: 0,
      }
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === parentId)
      let newContent = state.editingDocument.body[indexOfModule].content;
      // deleting empties in other modules
      state.editingDocument.body.forEach(module => {
        if(module.id !== parentId){
          module.content = module.content.filter(part => 
            part.position !== 0)
        }
      })
      //looking for empty in droppable module
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      if( emptyIndex === -1){ //didnt find
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[indexOfModule].content = newContent 
      }else{ //finded
        newContent.splice(emptyIndex, 1)
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[indexOfModule].content = newContent 
      }
    },
    addNewLevel1: (state, action) => {
      let newLevel = { ...action.payload[0], origin: 'root'}
      const parentId = action.payload[1]
      newLevel.parent = parentId

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === parentId)
      let newContent = state.editingDocument.body[indexOfModule].content;
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newLevel);
      state.editingDocument.body[indexOfModule].content = newContent
    },  
    changeLevel1Name: (state, action) => {
      const levelPosition = action.payload[0]
      const newName = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === levelPosition))
      
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => level1.position === levelPosition)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].name = newName
    }, 
    changeLevel2Name: (state, action) => {
      const leve1Position = action.payload[0]
      const leve2Position = action.payload[1]
      const newName = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === leve1Position))
      
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => level1.position === leve1Position)
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 => level2.position === leve2Position)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].name = newName
    },
    changeLevel3Name: (state, action) => {
      const level2Position = action.payload[0]
      const level3Position = action.payload[1]
      const newName = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => 
            level2.position === level2Position)
        )
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => 
          level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 => level2.position === level2Position)
      const indexOfLevel3 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(level3 => level3.position === level3Position)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfLevel3].name = newName
    },
    updatingLevel2Order: (state, action) =>{
      const dragPosition = action.payload[0]
      const hoverPosition = action.payload[1]
      const dropParentPosition = action.payload[2]
      const dragParentPosition = action.payload[3].parent
      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === dropParentPosition))
      const dragParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === dragParentPosition))

      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => level1.position === dropParentPosition)
      const hoverId = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === hoverPosition) 
      const indexOfDragLevel1 = state.editingDocument.body[dragParentModuleIndex].content.findIndex(level1 => level1.position === dragParentPosition)
      const dragId = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content.findIndex(level2 => 
        level2.position === dragPosition)

      if(dragParentPosition !== dropParentPosition){
        const emptyComponent = {
          value:{ },
          typeOfContent:'level2',
          position: 0,
        }
        const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => level1.position === dropParentPosition))
        const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => level1.position === dropParentPosition)
        // deleting empties in other levels in all modules
        state.editingDocument.body.forEach(module => {
          module.content.forEach( part => {
            if(part.typeOfContent === 'level1' && part.position !== dropParentPosition){
              part.content = part.content.filter(level2 => level2.position !== 0)
            }
          })
        })
        let newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content = newContent 
        }
      } else {
        const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => level1.position === dropParentPosition))
        const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => level1.position === dropParentPosition)
        const newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content; 
        const dragCard = newContent[dragId]; 
        newContent.splice(dragId, 1);
        newContent.splice(hoverId, 0, dragCard);
        state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content = newContent 
      }
    },
    updateLevel2Order: (state, action) => {
      const part = action.payload[0]
      const dropParentPosition = action.payload[1]
      const dragParentPosition = part.parent

      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === dropParentPosition))
      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => level1.position === dropParentPosition)
      let newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content; 
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      const newLevel = {...part, parent: dropParentPosition}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newLevel);
      state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content = newContent
      
      if(part.parent !== dropParentPosition){
        const dragParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => level1.position === dragParentPosition))
        const indexOfDragLevel1 = state.editingDocument.body[dragParentModuleIndex].content.findIndex(level1 => level1.position === dragParentPosition)
        const oldContent = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content; 
        state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content = oldContent.filter(
          component => component.position !== part.position )
      }
    },
    addingNewLevel2: (state, action) => {
      const hoverPosition = action.payload[0]
      const dropParentPosition = action.payload[1]

      const indexOfDropModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === dropParentPosition))
      const indexOfDropLevel1 = state.editingDocument.body[indexOfDropModule].content.findIndex(level1 => level1.position === dropParentPosition)
      const newContent = state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content; 

      const hoverId = state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === hoverPosition) 

      const emptyComponent = {
        value:{},
        typeOfContent:'level2',
        position: 0,
      }
        // deleting empties in other levels in all modules
        state.editingDocument.body.forEach(module => {
          module.content.forEach( part => {
            if(part.typeOfContent === 'level1' && part.position !== dropParentPosition){
              part.content = part.content.filter(level2 => level2.position !== 0)
            }
          })
        })

      //looking for empty in droppable module
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      if( emptyIndex === -1){ //didnt find
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content = newContent 
      }else{ //finded
        newContent.splice(emptyIndex, 1)
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content = newContent 
      }
    },
    addNewLevel2: (state, action) => {
      let newLevel = { ...action.payload[0], origin: 'root'}

      const parentPosition = action.payload[1]
      newLevel.parent = parentPosition
      console.log('added new level 2 ', newLevel)

      const indexOfDropModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => level1.position === parentPosition))
      const indexOfDropLevel1 = state.editingDocument.body[indexOfDropModule].content.findIndex(level1 => level1.position === parentPosition)
      let newContent = state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content;
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newLevel);
      state.editingDocument.body[indexOfDropModule].content[indexOfDropLevel1].content = newContent
    },  
    updatingLevel3Order: (state, action) =>{
      const dragPosition = action.payload[0]
      const hoverPosition = action.payload[1]
      const dropParentPosition = action.payload[2]
      const dragParentPosition = action.payload[3].parent
      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
      )
      const dragParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === dragParentPosition))
      )

      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
      const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === dropParentPosition) 
      const hoverId = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content.findIndex(level3 => 
        level3.position === hoverPosition) 

      const indexOfDragLevel1 = state.editingDocument.body[dragParentModuleIndex].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === dragParentPosition))
      const indexOfDragLevel2 = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content.findIndex(level2 => 
        level2.position === dragParentPosition) 
      const dragId = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content[indexOfDragLevel2].content.findIndex(level3 => 
        level3.position === dragPosition) 

      if(dragParentPosition !== dropParentPosition){
        const emptyComponent = {
          value:{ },
          typeOfContent:'level3',
          position: 0,
        }
        const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => 
            level1.typeOfContent === 'level1' && 
            -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
        )
        const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
        const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
          level2.position === dropParentPosition) 
        // deleting empties in other levels 2 in all modules
        state.editingDocument.body.forEach(module => {
          module.content.forEach( level1 => {
            if(level1.typeOfContent === 'level1' && level1.position !== dropParentPosition){
              level1.content.forEach( level2 => {
                if(level2.typeOfContent === 'level2' && level2.position !== dropParentPosition){
                  level2.content = level2.content.filter(level3 => level3.position !== 0)
                }
              })
            }
          })
        })
        let newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(hoverId, 0, emptyComponent);
          state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent 
        }
      } else {
        const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => 
            level1.typeOfContent === 'level1' && 
            -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
        )
        const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
        const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
          level2.position === dropParentPosition) 
        const newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content; 
        const dragCard = newContent[dragId]; 
        newContent.splice(dragId, 1);
        newContent.splice(hoverId, 0, dragCard);
        state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent 
      }
    },
    updateLevel3Order: (state, action) => {
      const part = action.payload[0]
      const dropParentPosition = action.payload[1]
      const dragParentPosition = part.parent

      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
      )
      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
      const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === dropParentPosition) 
        let newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content; 
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      const newLevel = {...part, parent: dropParentPosition}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newLevel);
      state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent
      
      if(part.parent !== dropParentPosition){
        const dragParentModuleIndex = state.editingDocument.body.findIndex(module => 
          -1 !== module.content.findIndex(level1 => 
            level1.typeOfContent === 'level1' && 
            -1 !== level1.content.findIndex(level2 => level2.position === dragParentPosition))
        )
        const indexOfDragLevel1 = state.editingDocument.body[dragParentModuleIndex].content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === dragParentPosition))
        const indexOfDragLevel2 = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content.findIndex(level2 => 
          level2.position === dragParentPosition) 
        const oldContent = state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content[indexOfDragLevel2].content; 
        state.editingDocument.body[dragParentModuleIndex].content[indexOfDragLevel1].content[indexOfDragLevel2].content = oldContent.filter(
          component => component.position !== part.position )
      }
    },
    addingNewLevel3: (state, action) => {
      console.log('adding level3..')
      const hoverPosition = action.payload[0]
      const dropParentPosition = action.payload[1]

      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' &&
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition))
      )
      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' &&
          -1 !== level1.content.findIndex(level2 => level2.position === dropParentPosition)
      )
      const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === dropParentPosition) 
      const newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content; 

      const hoverId = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content.findIndex(level3 => 
        level3.position === hoverPosition) 

      const emptyComponent = {
        value:{},
        typeOfContent:'level3',
        position: 0,
      }
      // deleting empties in other levels in all modules
      state.editingDocument.body.forEach(module => {
        module.content.forEach( level1 => {
          if(level1.typeOfContent === 'level1' && level1.position !== dropParentPosition){
            level1.content.forEach( level2 => {
              if(level2.typeOfContent === 'level2' && level2.position !== dropParentPosition){
                level2.content = level2.content.filter(level3 => level3.position !== 0)
              }
            })
          }
        })
      })

      //looking for empty in droppable module
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      if( emptyIndex === -1){ //didnt find
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent 
      }else{ //finded
        newContent.splice(emptyIndex, 1)
        newContent.splice(hoverId, 0, emptyComponent);
        state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent 
      }
    },
    addNewLevel3: (state, action) => {
      let newLevel = { ...action.payload[0], origin: 'root'}

      const parentPosition = action.payload[1]
      newLevel.parent = parentPosition
      const dropParentModuleIndex = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === parentPosition))
      )
      const indexOfDropLevel1 = state.editingDocument.body[dropParentModuleIndex].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === parentPosition))
      const indexOfDropLevel2 = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content.findIndex(level2 => 
        level2.position === parentPosition) 
      const newContent = state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content; 

      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newLevel);
      state.editingDocument.body[dropParentModuleIndex].content[indexOfDropLevel1].content[indexOfDropLevel2].content = newContent
    },  
    updatingComponentsOrder: (state, action) =>{
      const sdragI = action.payload[0]
      const shoverI = action.payload[1]
      const dropParentId = action.payload[2]
      const dragParentId = action.payload[3].parent

      if(dragParentId !== dropParentId){
        const emptyComponent = {
          value:{ },
          typeOfContent:'empty',
          position: 0,
        }
        console.log('Adding empty...')
        const indexOfDropModule = state.editingDocument.body.findIndex((module) => module.id === dropParentId)
        // deleting empties in other modules
        state.editingDocument.body.forEach(module => {
          if(module.id !== dropParentId){
            module.content = module.content.filter(part => 
              part.position !== 0)
          }
        })
        let newContent = state.editingDocument.body[indexOfDropModule].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(shoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfDropModule].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(shoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfDropModule].content = newContent 
        }
      } else {
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === dropParentId)
        let newContent = state.editingDocument.body[indexOfModule].content; 
        const dragCard = newContent[sdragI]; 
        newContent.splice(sdragI, 1);
        newContent.splice(shoverI, 0, dragCard);
        state.editingDocument.body[indexOfModule].content = newContent 
      }
    },
    addingNewComponent: (state, action) => {
      console.log('adding new component..')
      const indexToDropping = action.payload[0]
      const parentId = action.payload[1]

      const emptyComponent = {
        value:{},
        typeOfContent:'level1',
        position: 0,
      }

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === parentId)
      let newContent = state.editingDocument.body[indexOfModule].content;
      // deleting empties in other modules
      state.editingDocument.body.forEach(module => {
        if(module.id !== parentId){
          module.content = module.content.filter(part => 
            part.position !== 0)
        }
      })

      //looking for empty in droppable module
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      if( emptyIndex === -1){ //didnt find
        newContent.splice(indexToDropping, 0, emptyComponent);
        state.editingDocument.body[indexOfModule].content = newContent 
      }else{ //finded
        newContent.splice(emptyIndex, 1)
        newContent.splice(indexToDropping, 0, emptyComponent);
        state.editingDocument.body[indexOfModule].content = newContent 
      }
    },
    updateComponentsOrder: (state, action) => {
      const part = action.payload[0]

      const parentName = action.payload[1]
      const dragParentId = part.parent
      const dropParentId = action.payload[2]

      const indexOfModule = state.editingDocument.body.findIndex((module) => module.name === parentName)
      let newContent = state.editingDocument.body[indexOfModule].content;
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      
      const newSection = {...part, parent: dropParentId}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newSection);
      state.editingDocument.body[indexOfModule].content = newContent
      
      if(part.parent !== dropParentId){
        const indexOfOldModule = state.editingDocument.body.findIndex((module) => module.id === dragParentId)
        const oldContent = state.editingDocument.body[indexOfOldModule].content;
        state.editingDocument.body[indexOfOldModule].content = oldContent.filter(
          component => component.position !== part.position )
      }
    },
    updatingComponentsOrderInModuleColumn: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const holderData = action.payload[3]
      const dropParentPosition = action.payload[4]
      //const draggingChild= action.payload[5]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => 
        part.position === holderData )
      const indexOfDropColumn = state.editingDocument.body[indexOfModule].content[indexOfHolder].content.findIndex(column => 
        column.position === dropParentPosition)
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfDropColumn].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfDropColumn].content = newContent 
    },
    updatingColumnsOrderInModuleColumns: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const holderData = action.payload[3]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => 
        part.position === holderData )
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfHolder].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfHolder].content = newContent 
    },
    updatingComponentsOrderInLevel1: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const dropParentPosition = action.payload[3]
      const dragParentPosition= action.payload[4].parent

      if(dragParentPosition !== dropParentPosition){
        const emptyComponent = {
          value:{},
          typeOfContent:'empty',
          position: 0,
        }
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
        // deleting empties in other levels
        state.editingDocument.body[indexOfModule].content.forEach(level => {
          if(level.position !== dropParentPosition){
            if(level.content) {
              level.content = level.content.filter(part => part.position !== 0)
            }
          }
        })
        const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
          level.position === dropParentPosition )
        let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(hoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(hoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = newContent 
        }
      } else {
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
        const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
          level.position === dragParentPosition )
        let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content; 
        const dragCard = newContent[dragI]; 
        newContent.splice(dragI, 1);
        newContent.splice(hoverI, 0, dragCard);
        state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = newContent 
      }
    },
    updatingComponentsOrderInLevel1Column: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const level1Position = action.payload[3]
      const holderData = action.payload[4]
      const dropParentPosition = action.payload[5]
      //const draggingChild= action.payload[6]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(part => 
        part.position === level1Position )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => 
        part.position === holderData )
      const indexOfDropColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content.findIndex(column => 
        column.position === dropParentPosition)
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfDropColumn].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfDropColumn].content = newContent 
    },
    updatingColumnsOrderInLevel1Columns: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const level1Position = action.payload[3]
      const holderData = action.payload[4]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(part => 
        part.position === level1Position )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => 
        part.position === holderData )
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content = newContent 
    },
    updateComponentsOrderInLevel1: (state, action) => {
      const dropParentPosition = action.payload[0]
      const dropComponent = action.payload[1]
      const levelParent = action.payload[2]
      const dragParentPosition = dropComponent.parent

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === levelParent)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
        level.position === dropParentPosition )
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content; 
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      const newComponent = {...dropComponent, parent: dropParentPosition}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newComponent);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = newContent 
      
      //delete drag component from old parent
      if(dropComponent.parent !== dropParentPosition){
        const indexOfOldLevel = state.editingDocument.body[indexOfModule].content.findIndex( level =>
           level.position === dragParentPosition)
        const oldContent = state.editingDocument.body[indexOfModule].content[indexOfOldLevel].content
        state.editingDocument.body[indexOfModule].content[indexOfOldLevel].content = oldContent.filter(
          component => component.position !== dropComponent.position )
      }
    },
    updatingComponentsOrderInLevel2: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const dropParentPosition = action.payload[3]
      const dragParentPosition= action.payload[4].parent
      const dropLevel1Position = action.payload[5]
      console.log("we are updating this level: ", dragParentPosition, dropParentPosition)

      if(dragParentPosition !== dropParentPosition){
        const emptyComponent = {
          value:{ },
          typeOfContent:'empty',
          position: 0,
        }
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
        // deleting empties in other levels 2
        state.editingDocument.body[indexOfModule].content.forEach(level => {
          if(level.typeOfContent === 'level1' && level.position !== dropLevel1Position){
            level.content.forEach( level2 => {
              if(level2.position !== dropParentPosition){
                if(level2.content) {
                  level2.content = level2.content.filter(part => part.position !== 0)
                }
              }
            })
          }
        })
        const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
          level.position === dropLevel1Position )
        const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 =>
          level2.position === dropParentPosition)
        let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(hoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(hoverI, 0, emptyComponent);
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = newContent 
        }
      } else {
        const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
        const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
          level.typeOfContent === 'level1' && 
          -1 !== level.content.findIndex(level2 => level2.position === dragParentPosition))
        const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 =>
          level2.position === dragParentPosition)
        let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content ; 
        const dragCard = newContent[dragI]; 
        newContent.splice(dragI, 1);
        newContent.splice(hoverI, 0, dragCard);
        state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = newContent 
      }
    },
    updatingComponentsOrderInLevel2Column: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const level2Position = action.payload[3]
      const holderData = action.payload[4]
      const dropParentPosition = action.payload[5]
      //const draggingChild= action.payload[6]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position))
        
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => 
        part.position === level2Position )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => 
        part.position === holderData )
      const indexOfDropColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content.findIndex(column => 
        column.position === dropParentPosition)
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfDropColumn].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfDropColumn].content = newContent 
    },
    updatingColumnsOrderInLevel2Columns: (state, action) => {
      const dragI = action.payload[0]
      const hoverI = action.payload[1]
      const moduleId = action.payload[2]
      const level2Position = action.payload[3]
      const holderData = action.payload[4]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position))
        
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => 
        part.position === level2Position )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => 
        part.position === holderData )
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content; 
      const dragCard = newContent[dragI]; 
      newContent.splice(dragI, 1);
      newContent.splice(hoverI, 0, dragCard);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content = newContent 
    },
    updateComponentsOrderInLevel2: (state, action) => {
      const dropParentPosition = action.payload[0]
      const dropComponent = action.payload[1]
      const moduleId = action.payload[2]
      const dragParentPosition = dropComponent.parent
      const dropLevel1Position = action.payload[3]

      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level => 
        level.position === dropLevel1Position )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 =>
        level2.position === dropParentPosition)
      let newContent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content; 
      const emptyIndex = newContent.findIndex(part => part.position === 0)
      const newComponent = {...dropComponent, parent: dropParentPosition}
      newContent.splice(emptyIndex, 1)
      newContent.splice(emptyIndex, 0, newComponent);
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = newContent 
      
      //delete drag component from old parent
      if(dropComponent.parent !== dropParentPosition){
        const indexOfOldLevel = state.editingDocument.body[indexOfModule].content.findIndex(level => 
          level.typeOfContent === 'level1' && 
          -1 !== level.content.findIndex(level2 => level2.position === dragParentPosition))
        console.log('index old level1 : ', indexOfOldLevel, dragParentPosition )
        const indexOfOldLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 =>
          level2.position === dragParentPosition)
        const oldContent = state.editingDocument.body[indexOfModule].content[indexOfOldLevel].content[indexOfOldLevel2].content
        state.editingDocument.body[indexOfModule].content[indexOfOldLevel].content[indexOfOldLevel2].content = oldContent.filter(
          component => component.position !== dropComponent.position )
      }
    },
    removeEmptyComponents: (state) => {
      const newOne = state.editingDocument.body.map(module => {
        module.content = module.content.filter(part => 
          part.position !== 0)
      })
    },
    deleteItemFromMap: (state, action ) => {
      const item = action.payload[0]
      const type = action.payload[1]
      let afterDeletingDoc = []
      if(type === 'module'){
        afterDeletingDoc = state.editingDocument.body.filter(module =>  module.id !== item.id || module.name !== item.name)
      }
      if(type === 'level1'){
        state.editingDocument.body.forEach(module => {
          module.content = module.content.filter( part => part.position !== item.position)
          afterDeletingDoc.push(module)
        })
      }
      if(type === 'level2'){
        state.editingDocument.body.forEach(module => {
          module.content.forEach( part => {
            if(part.typeOfContent === 'level1'){
              part.content = part.content.filter(level2 => level2.position !== item.position)
            }
          })
          afterDeletingDoc.push(module)
        })
      }
      if(type === 'level3'){
        state.editingDocument.body.forEach(module => {
          module.content.forEach( part => {
            if(part.typeOfContent === 'level1'){
              part.content.forEach( level2 => {
                if(level2.typeOfContent === 'level2'){ 
                  level2.content= level2.content.filter(level3 => level3.position !== item.position)
                }
              })
            }
          })
        afterDeletingDoc.push(module)
        })
      }
      state.editingDocument.body = afterDeletingDoc
    },
    addItemToTrashCan: ( state, action) => {
      const item ={ ...action.payload, origin: 'trashcan'}
      state.trashCan.push(item)
    },
    addItemToStore: ( state, action) => {
      const item ={ ...action.payload, origin: 'store'}
      state.store.push(item)
    },
    addActiveDocument: (state, action) => {
      const doc = action.payload[0]
      const type = action.payload[1]
      let newDocument = {}
      if(!sessionStorage.getItem('active'+doc._id)){
        newDocument = {name: state.editingDocument.name , id: state.editingDocument._id, type: type}
        sessionStorage.setItem('active'+doc._id, JSON.stringify(newDocument))
        console.log('new active doc added..')
      }
    },
    removeActiveDocument: (state, action) => {
      const doc = action.payload
      console.log('removed  ')
      sessionStorage.removeItem('active'+doc._id)
    },
    setDocument: (state, action) => {
      const doc = action.payload
      state.editingDocument = doc
    },
    addComponentInModule: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingDocument.body[indexOfModule].style
      
      let componentStyle = state.styles["style_"+ newComponentType]
      if(Object.keys(state.editingDocument.classes).length !== 0){
        if(state.editingDocument.classes["module_"+ newComponentType]){
          componentStyle = state.editingDocument.classes["module_"+ newComponentType][0].class
        }
      } 
      let valueOfNewComponent = ''
      if(newComponentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => {
          col = {
              typeOfContent: 'column', 
              style: state.styles.style_column, 
              parent: positionOfNewComponent,
              origin:'root',
              content:[], 
              position: Math.round(Math.random()*1000)
          };
          // Check if there is a column style in the classes
          if (Object.keys(state.editingDocument.classes).length !== 0) {
              if(state.editingDocument.classes["column"]){
                  col.style = state.editingDocument.classes["column"][0].class;
              }
          }
          return col;
      });
      } else{
        valueOfNewComponent = newComponentType === 'level1' ? []
        : newComponentType === 'heading' ? 'Heading' 
        : newComponentType === 'subheading' ? 'Subheading'
        : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
        : newComponentType === 'dynamic_block' ? 
        Array.from({length: 2}).map(part => 
          part = {
            typeOfContent: 'dynamic_part', 
            style: state.styles.style_dynamic_part,
            parent: positionOfNewComponent,
            origin:'root',
            content:[], 
            position: Math.round(Math.random()*1000)}
        )
        : ' ' }
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle,  
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: newComponentType,
        typeOfContent: newComponentType, 
        style: componentStyle,
        parent: moduleId,
        origin:'root', 
        content: valueOfNewComponent,
        position: positionOfNewComponent,
      }
      console.log(newComponent)
      state.editingDocument.body[indexOfModule].content.push(newComponent)
    },
    addDesignItemInModule: (state, action) => {
      const newComponentData = action.payload[0]
      const moduleId = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const positionOfNewComponent = Math.round(Math.random()*10000)
      
      let updatedContent = newComponentData.body.content
      if(Array.isArray(updatedContent)){
        updatedContent = newComponentData.body.content.map(component => ({...component, parent: positionOfNewComponent}))
      }
      const newComponent = {
        name: newComponentData.name,
        typeOfContent: newComponentData.body.typeOfContent, 
        style: newComponentData.body.style,
        parent: moduleId,
        origin:'root', 
        content: updatedContent,
        position: positionOfNewComponent,
      }
      state.editingDocument.body[indexOfModule].content.push(newComponent)
    },
    addImageInModule: (state, action) => {
      const moduleId = action.payload[0]
      const imageHolderData = action.payload[1]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style
      const newComponentData = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === imageHolderPosition)
      const parentStyle = state.editingDocument.body[indexOfModule].style
      
      const valueOfNewComponent = {data: newComponentData, cropped:null}
      let styleOfNewComponent = {width:imageHolderStyle.width, height:imageHolderStyle.height}
      if(parentStyle.isUnicStyle){
        styleOfNewComponent = {
          ...styleOfNewComponent, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: styleOfNewComponent,
        parent: moduleId,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfImageHolder] = newComponent
    },
    addComponentInLevel1: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const level1Position = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === level1Position))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === level1Position)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingDocument.body[indexOfModule].content[indexOfLevel1].style
            
      let componentStyle = state.styles["style_"+ newComponentType]
      if(Object.keys(state.editingDocument.classes).length !== 0){
        if(state.editingDocument.classes["level1_"+ newComponentType]){
          componentStyle = state.editingDocument.classes["level1_"+ newComponentType][0].class
        }
      } 
      let valueOfNewComponent = ''
      if(newComponentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => {
          col = {
              typeOfContent: 'column', 
              style: state.styles.style_column, 
              parent: positionOfNewComponent,
              origin:'root',
              content:[], 
              position: Math.round(Math.random()*1000)
          };
          // Check if there is a column style in the classes
          if (Object.keys(state.editingDocument.classes).length !== 0) {
              if(state.editingDocument.classes["column"]){
                  col.style = state.editingDocument.classes["column"][0].class
              }
          }
          return col;
      });
      } else{
        valueOfNewComponent = newComponentType === 'level2' ? []
        : newComponentType === 'heading' ? 'Heading' 
        : newComponentType === 'subheading' ? 'Subheading'
        : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
        : ' '}
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: newComponentType,
        typeOfContent: newComponentType, 
        style: componentStyle,
        parent: level1Position, 
        origin:'root', 
        content: valueOfNewComponent,
        position: positionOfNewComponent
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.push(newComponent)
    },
    addDesignItemInLevel1: (state, action) => {
      const newComponentData = action.payload[0]
      const level1Position = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === level1Position))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === level1Position)
      const positionOfNewComponent = Math.round(Math.random()*10000)
          
      let updatedContent = newComponentData.body.content
      if(Array.isArray(updatedContent)){
        updatedContent = newComponentData.body.content.map(component => ({...component, parent: positionOfNewComponent}))
      }
      const newComponent = {
        name: newComponentData.name,
        typeOfContent: newComponentData.body.typeOfContent, 
        style: newComponentData.body.style,
        parent: level1Position,
        origin:'root', 
        content: updatedContent,
        position: newComponentData.body.position,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.push(newComponent)
    },
    addImageInLevel1: (state, action) => {
      const level1Position = action.payload[0]
      const imageHolderData = action.payload[1]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style
      const newComponentData = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === level1Position))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === level1Position)
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === imageHolderPosition)
      const parentStyle = state.editingDocument.body[indexOfModule].content[indexOfLevel1].style

      const valueOfNewComponent = {data: newComponentData, cropped:null}
      let styleOfNewComponent = {width:imageHolderStyle.width, height:imageHolderStyle.height}
      if(parentStyle.isUnicStyle){
        styleOfNewComponent = {
          ...styleOfNewComponent, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: styleOfNewComponent,
        parent: level1Position,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfImageHolder] = newComponent
    },
    addComponentInLevel2: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const level2Position = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
        ))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style  
      let componentStyle = state.styles["style_"+ newComponentType]
      if(Object.keys(state.editingDocument.classes).length !== 0){
        if(state.editingDocument.classes["level2_"+ newComponentType]){
          componentStyle = state.editingDocument.classes["level2_"+ newComponentType][0].class
        }
      } 
      let valueOfNewComponent = ''
      if(newComponentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => {
          col = {
              typeOfContent: 'column', 
              style: state.styles.style_column, 
              parent: positionOfNewComponent,
              origin:'root',
              content:[], 
              position: Math.round(Math.random()*1000)
          };
          // Check if there is a column style in the classes
          if (Object.keys(state.editingDocument.classes).length !== 0) {
              if(state.editingDocument.classes["column"]){
                  col.style = state.editingDocument.classes["column"][0].class
              }
          }
          return col;
      });
      } else{
        valueOfNewComponent = newComponentType === 'level3' ? []
        : newComponentType === 'heading' ? 'Heading' 
        : newComponentType === 'subheading' ? 'Subheading'
        : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
        : ' ' }
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: newComponentType,
        typeOfContent: newComponentType, 
        style: componentStyle,
        parent: level2Position, 
        content: valueOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.push(newComponent)
    },
    addDesignItemInLevel2: (state, action) => {
      const newComponentData = action.payload[0]
      const level2Position = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
        ))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position)
      const positionOfNewComponent = Math.round(Math.random()*10000)
          
      let updatedContent = newComponentData.body.content
      if(Array.isArray(updatedContent)){
        updatedContent = newComponentData.body.content.map(component => ({...component, parent: positionOfNewComponent}))
      }
      const newComponent = {
        name: newComponentData.name,
        typeOfContent: newComponentData.body.typeOfContent, 
        style: newComponentData.body.style,
        parent: level2Position,
        origin:'root', 
        content: updatedContent,
        position: newComponentData.body.position,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.push(newComponent)
    },
    addImageInLevel2: (state, action) => {
      const level2Position = action.payload[0]
      const imageHolderData = action.payload[1]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style
      const newComponentData = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
        ))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position)
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === imageHolderPosition)
      const parentStyle = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style

      const valueOfNewComponent = {data: newComponentData, cropped:null}
      let styleOfNewComponent = {width:imageHolderStyle.width, height:imageHolderStyle.height}
      if(parentStyle.isUnicStyle){
        styleOfNewComponent = {
          ...styleOfNewComponent, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: styleOfNewComponent,
        parent: level2Position,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfImageHolder] = newComponent
    },
    addComponentInLevel3: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const level3Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(
          level2 => level2.typeOfContent === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position)))
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.typeOfContent === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position))
      const indexLevel3 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        child => child.position === level3Position)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexLevel3].style  
      let componentStyle = state.styles["style_"+ newComponentType]
      if(Object.keys(state.editingDocument.classes).length !== 0){
        if(state.editingDocument.classes["level3_"+ newComponentType]){
          componentStyle = state.editingDocument.classes["level3_"+ newComponentType][0].class
        }
      } 
      let valueOfNewComponent = ''
      if(newComponentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => {
          col = {
              typeOfContent: 'column', 
              style: state.styles.style_column, 
              parent: positionOfNewComponent,
              origin:'root',
              content:[], 
              position: Math.round(Math.random()*1000)
          };
          // Check if there is a column style in the classes
          if (Object.keys(state.editingDocument.classes).length !== 0) {
              if(state.editingDocument.classes["column"]){
                  col.style = state.editingDocument.classes["column"][0].class
              }
          }
          return col;
      });
      } else{
        valueOfNewComponent = newComponentType === 'level4' ? []
        : newComponentType === 'heading' ? 'Heading' 
        : newComponentType === 'subheading' ? 'Subheading'
        : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
        : ' '}
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: newComponentType,
        typeOfContent: newComponentType, 
        style: componentStyle,
        parent: level3Position, 
        content: valueOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexLevel3].content.push(newComponent)
    },
    addDesignItemInLevel3: (state, action) => {
      const newComponentData = action.payload[0]
      const moduleId = action.payload[1]
      const level3Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(
          level2 => level2.typeOfContent === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position)))
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.typeOfContent === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position))
      const indexLevel3 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        child => child.position === level3Position)
      const positionOfNewComponent = Math.round(Math.random()*10000)
          
      let updatedContent = newComponentData.body.content
      if(Array.isArray(updatedContent)){
        updatedContent = newComponentData.body.content.map(component => ({...component, parent: positionOfNewComponent}))
      }
      const newComponent = {
        name: newComponentData.name,
        typeOfContent: newComponentData.body.typeOfContent, 
        style: newComponentData.body.style,
        parent: level3Position,
        origin:'root', 
        content: updatedContent,
        position: newComponentData.body.position,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexLevel3].content.push(newComponent)
    },
    addComponentInModuleColumn: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const holderPosition = action.payload[2]
      const columnPosition = action.payload[3]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content.findIndex(
        part => part.position === holderPosition)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      if(newComponentType === 'design_item'){
        const newComponent = {
          name: newComponentData.name,
          typeOfContent: newComponentData.body.typeOfContent, 
          style: newComponentData.body.style,
          parent: moduleId,
          origin:'root', 
          content: newComponentData.body.content,
          position: positionOfNewComponent,
        }
        state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }else{
        const valueOfNewComponent = newComponentType.startsWith('level') ? []
          : newComponentType === 'heading' ? 'Heading' 
          : newComponentType === 'subheading' ? 'Subheading'
          : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
          : ' '
        const styleOfNewComponent = newComponentType === 'heading' ? state.styles.style_heading 
        : newComponentType === 'subheading' ? state.styles.style_subheading 
        : newComponentType === 'text_field' ? state.styles.style_text_field 
        : newComponentType === 'line' ? state.styles.style_line
        : newComponentType === 'image' ? state.styles.style_image
        : newComponentType === 'image_holder' ? state.styles.style_image_holder
        : newComponentType === 'columns_holder' ? {...newComponentData.style, ...state.styles.style_columns_holder} 
        : newComponentType === 'level1' ? state.styles.style_level1
        : state.styles.style_holder
        const newComponent = {
          typeOfContent: newComponentType, 
          style: styleOfNewComponent,
          parent: columnPosition, 
          content: valueOfNewComponent,
          origin:'root', 
          position: positionOfNewComponent
        }
        state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }
    },
    addImageInModuleColumn: (state, action) => {
      const moduleId = action.payload[0]
      const columnData = action.payload[1]
      const holderPosition = columnData.holder
      const columnPosition = columnData.column
      const imageHolderData = action.payload[2]
      const newComponentData = action.payload[3]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content.findIndex(
        part => part.position === holderPosition)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition)
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === imageHolderPosition)

      const valueOfNewComponent = {data: newComponentData, cropped:null}
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: {width:imageHolderStyle.width, height:imageHolderStyle.height},
        parent: columnPosition,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content[indexOfImageHolder] = newComponent
    },
    addComponentInLevel1Column: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const holderData = action.payload[2]
      const parentPosition = holderData.parent
      const holderPosition = holderData.holder
      const columnPosition = action.payload[3]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( 
        part => part.position === parentPosition
      )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        part => part.position === holderPosition)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      const positionOfNewComponent = Math.round(Math.random()*1000)
      if(newComponentType === 'design_item'){
        const newComponent = {
          name: newComponentData.name,
          typeOfContent: newComponentData.body.typeOfContent, 
          style: newComponentData.body.style,
          parent: moduleId,
          origin:'root', 
          content: newComponentData.body.content,
          position: positionOfNewComponent,
        }
        state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }else{
        const valueOfNewComponent = newComponentType.startsWith('level') ? []
          : newComponentType === 'heading' ? 'Heading' 
          : newComponentType === 'subheading' ? 'Subheading'
          : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
          : ' '
        const styleOfNewComponent = newComponentType === 'heading' ? state.styles.style_heading 
        : newComponentType === 'subheading' ? state.styles.style_subheading 
        : newComponentType === 'text_field' ? state.styles.style_text_field 
        : newComponentType === 'line' ? state.styles.style_line
        : newComponentType === 'image' ? state.styles.style_image
        : newComponentType === 'image_holder' ? state.styles.style_image_holder
        : newComponentType === 'columns_holder' ? {...newComponentData.style, ...state.styles.style_columns_holder} 
        : newComponentType === 'level2' ? state.styles.style_level2
        : state.styles.style_holder
        const newComponent = {
          typeOfContent: newComponentType, 
          style: styleOfNewComponent,
          parent: columnPosition, 
          content: valueOfNewComponent,
          origin:'root', 
          position: positionOfNewComponent
        }
        state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }
    },
    addImageInLevel1Column: (state, action) => {
      const level1Position = action.payload[0]
      const columnData = action.payload[1]
      const holderPosition = columnData.holder
      const columnPosition = columnData.column
      const imageHolderData = action.payload[2]
      const newComponentData = action.payload[3]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style

      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === level1Position))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level1 =>
        level1.position === level1Position
      )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        part => part.position === holderPosition)

      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === imageHolderPosition)
        
      const valueOfNewComponent = {data: newComponentData, cropped:null}
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: {width:imageHolderStyle.width, height:imageHolderStyle.height},
        parent: columnPosition,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content[indexOfImageHolder] = newComponent
    },
    addComponentInLevel2Column: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const level2Position = action.payload[2]
      const holderPosition = action.payload[3]
      const columnPosition = action.payload[4]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level1 =>
        level1.typeOfContent === 'level1' && 
        level1.content && -1 !== level1.content.findIndex(part => part.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( child =>
        child.position === level2Position
      )
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === holderPosition
      )
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      
      const positionOfNewComponent = Math.round(Math.random()*1000)
      if(newComponentType === 'design_item'){
        const newComponent = {
          name: newComponentData.name,
          typeOfContent: newComponentData.body.typeOfContent, 
          style: newComponentData.body.style,
          parent: moduleId,
          origin:'root', 
          content: newComponentData.body.content,
          position: positionOfNewComponent,
        }
        state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }else{
        const valueOfNewComponent = newComponentType.startsWith('level') ? []
          : newComponentType === 'heading' ? 'Heading' 
          : newComponentType === 'subheading' ? 'Subheading'
          : newComponentType === 'image' ? {data: newComponentData.content, cropped:null}
          : ' '
        const styleOfNewComponent = newComponentType === 'heading' ? state.styles.style_heading 
        : newComponentType === 'subheading' ? state.styles.style_subheading 
        : newComponentType === 'text_field' ? state.styles.style_text_field 
        : newComponentType === 'line' ? state.styles.style_line
        : newComponentType === 'image' ? state.styles.style_image
        : newComponentType === 'image_holder' ? state.styles.style_image_holder
        : newComponentType === 'columns_holder' ? {...newComponentData.style, ...state.styles.style_columns_holder} 
        : newComponentType === 'level3' ? state.styles.style_level3
        : state.styles.style_holder
        const newComponent = {
          typeOfContent: newComponentType, 
          style: styleOfNewComponent,
          parent: columnPosition, 
          content: valueOfNewComponent,
          origin:'root', 
          position: positionOfNewComponent
        }
        state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      }
    },
    
    addImageInLevel2Column: (state, action) => {
      const level2Position = action.payload[0]
      const columnData = action.payload[1]
      const holderPosition = columnData.holder
      const columnPosition = columnData.column
      const imageHolderData = action.payload[2]
      const newComponentData = action.payload[3]
      const imageHolderPosition = imageHolderData.position
      const imageHolderStyle= imageHolderData.style
      
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.typeOfContent === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
        ))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level1 => 
        level1.typeOfContent === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( level2 => level2.position === level2Position)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === holderPosition)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      const indexOfImageHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === imageHolderPosition)
        
      const valueOfNewComponent = {data: newComponentData, cropped:null}
      const newComponent = {
        name: 'Image',
        typeOfContent: 'image', 
        style: {width:imageHolderStyle.width, height:imageHolderStyle.height},
        parent: columnPosition,
        origin:'root', 
        content: valueOfNewComponent,
        position: imageHolderPosition,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content[indexOfImageHolder] = newComponent
    },
    changeComponentInModule: (state, action) => {
      const component = action.payload[0]
      const moduleId = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexComponent = state.editingDocument.body[indexOfModule].content.findIndex(
        (moduleContent) => moduleContent.position === componentPosition)
      state.editingDocument.body[indexOfModule].content[indexComponent].content = component
    },
    changeComponentInLevel1: (state, action) => {
      const component = action.payload[0]
      const level1Position = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => -1 !== (module.content.findIndex(level => level.position === level1Position)))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === level1Position)
      const indexOfComponent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        (sectionContent) => sectionContent.position === componentPosition)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfComponent].content = component
    },
    changeComponentInLevel2: (state, action) => {
      const component = action.payload[0]
      const level2Position = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module =>
        -1 !== (module.content.findIndex(level1 => 
          level1.content &&
            -1 !== (level1.content.findIndex(level2 => level2.position === level2Position)) 
        ))
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(level1 =>
        level1.content &&
          -1 !== (level1.content.findIndex(level2 => level2.position === level2Position))
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(level2 => 
        level2.position === level2Position)
      const indexOfComponent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        component => component.position === componentPosition)
        state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfComponent].content = component
    },
    changeComponentInModuleColumn: (state, action) => {
      const component = action.payload[0]
      const columnData = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( part => part.position === columnData.holder))
      const indexOfHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => 
        part.position === columnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfHolder].content.findIndex(part => 
        part.position === columnData.column)
      const indexOfComponent = state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === componentPosition)
      state.editingDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content[indexOfComponent].content = component
    },
    changeComponentInLevel1Column: (state, action) => {
      const component = action.payload[0]
      const columnData = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level1 =>
          level1.position === columnData.parent))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level1 =>
        level1.position === columnData.parent)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => 
        part.position === columnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content.findIndex(part => 
        part.position === columnData.column)
      const indexOfComponent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === componentPosition)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content[indexOfComponent].content = component
    },
    changeComponentInLevel2Column: (state, action) => {
      const component = action.payload[0]
      const columnData = action.payload[1]
      const componentPosition = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level1 =>
          level1.content &&
          -1 !== level1.content.findIndex( level2 =>
            level2.position === columnData.parent)))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level1 => 
        level1.content &&
        -1 !== level1.content.findIndex( level2 =>
          level2.position === columnData.parent))
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( level2 => 
        level2.position === columnData.parent)
      const indexOfHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => 
        part.position === columnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content.findIndex(part => 
        part.position === columnData.column)
      const indexOfComponent = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content.findIndex(part => 
        part.position === componentPosition)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content[indexOfComponent].content = component
    },
    updateModuleStyle: (state, action) => {
      const applyName = action.payload[0]
      const newStyle = action.payload[1]
      let indexNew = 0;
      applyName === 'all' ?
        state.editingDocument.body.map(part => part.style = newStyle)
      : indexNew = state.editingDocument.body.findIndex((element) => element.name === applyName)
        state.editingDocument.body[indexNew].style = newStyle
    },
    setDefaultDocumentStyle:(state, action) => {
      const default_class = {
        _id: 'default',
        module : [{class: state.styles.style_module}],
        module_heading : [{class: state.styles.style_heading}],
        module_subheading : [{class: state.styles.style_subheading}],
        module_text_field: [{class: state.styles.style_text_field}],
        module_level1: [{class: state.styles.style_level1_default}],
        level1_heading : [{class: state.styles.style_heading}],
        level1_subheading : [{class: state.styles.style_subheading}],
        level1_text_field: [{class: state.styles.style_text_field}],
        level1_level2: [{class: state.styles.style_level2}],
        level2_heading : [{class: state.styles.style_heading}],
        level2_subheading : [{class: state.styles.style_subheading}],
        level2_text_field: [{class: state.styles.style_text_field}],
        level2_level3: [{class: state.styles.style_level3}],
      }
      state.editingDocument.classes = default_class
      state.editingDocument.body = state.editingDocument.body.map(module => {
        return {...module, style : default_class.module[0].class}
      })
      state.editingDocument.body.map(module =>{
        module.content.map(part =>{ 
          if(part.typeOfContent === 'heading' && default_class.module_heading){
            part.style = {...part.style, ...default_class.module_heading[0].class}
          }
          if(part.typeOfContent === 'subheading' && default_class.module_subheading){
            part.style = {...part.style, ...default_class.module_subheading[0].class}
          }
          if(part.typeOfContent === 'text_field' && default_class.module_text_field){
            part.style = {...part.style, ...default_class.module_text_field[0].class}
          }
          
          if(part.typeOfContent === 'level1' && default_class.module_level1){
            //changed level1 style
            part.style = {...part.style, ...default_class.module_level1[0].class}
            //changing level1 childs` style
            part.content.forEach(level1Part => {
              if(level1Part.typeOfContent === 'level2' && default_class.level1_level2){
                level1Part.style = default_class.level1_level2[0].class;
                //changing level2 childs` style
                level1Part.content.forEach(level2Part => {
                  if(level2Part.typeOfContent === 'level3' && default_class.level2_level3){
                    level2Part.style = default_class.level2_level3[0].class;
                    //changing level3 childs` style
                    // write code for level3
                  }else{
                    if (default_class[`level2_${level2Part.typeOfContent}`]) {
                      level2Part.style = default_class[`level2_${level2Part.typeOfContent}`][0].class;
                    }
                  }
                });
              } else{
                if (default_class[`level1_${level1Part.typeOfContent}`]) {
                  level1Part.style = default_class[`level1_${level1Part.typeOfContent}`][0].class;
                }
              }
            });
          }
        })
      })
    },
    newUpdateDocumentStyle:(state, action) => {
      const newStyle = action.payload[0]
      console.log('new style to apply', newStyle)
      state.editingDocument.classes = newStyle
      state.editingDocument.body = state.editingDocument.body.map(module => {
        return {...module, style : newStyle.module_1}
      })
      state.editingDocument.body.map(module =>{
        module.content.map(part =>{ 
          if(part.typeOfContent === 'heading' && newStyle.module_heading){
            part.style = {...part.style, ...newStyle.module_heading}
          }
          if(part.typeOfContent === 'subheading' && newStyle.module_subheading){
            part.style = {...part.style, ...newStyle.module_subheading}
          }
          if(part.typeOfContent === 'text_field' && newStyle.module_textfield){
            part.style = {...part.style, ...newStyle.module_textfield}
          }
          if(part.typeOfContent === 'level1' && newStyle.module_level1){
            //changed level1 style
            part.style = {...part.style, ...newStyle.module_level1}
            //changing level1 childs` style
            part.content.map(level1Part => {
              if(level1Part.typeOfContent === 'heading' && newStyle.level1_heading){
                level1Part.style = newStyle.level1_heading
              }
              if(level1Part.typeOfContent === 'subheading' && newStyle.level1_subheading){
                level1Part.style = newStyle.level1_subheading
              }
              if(level1Part.typeOfContent === 'text_field' && newStyle.level1_text_field){
                level1Part.style = newStyle.level1_text_field
              }
              if(level1Part.typeOfContent === 'level2' && newStyle.level1_level2){
                //changed level2 style
                level1Part.style = {...part.style, ...newStyle.level1_level2}
                //changing level2 childs` style
                level1Part.content.map(level2Part => {
                  if(level2Part.typeOfContent === 'heading' && newStyle.level2_heading){
                    level2Part.style = newStyle.level2_heading
                  }
                  if(level2Part.typeOfContent === 'subheading' && newStyle.level2_subheading){
                    level2Part.style = newStyle.level2_subheading
                  }
                  if(level2Part.typeOfContent === 'text_field' && newStyle.level2_text_field){
                    level2Part.style = newStyle.level2_text_field
                  }
                  if(level1Part.typeOfContent === 'level3' && newStyle.module_level3){
                    //changed level3 style
                    //level1Part.style = styleModule.childs[indexOfLevel1Style].childs[indexOfLevel2Level1Style].style
                    //changing level3 childs` style
                  }
                })
              }
            })
          }
        })
      })
    },
    updateDocumentClasses: (state, action) => {
      const newStyle = action.payload[0]
      state.editingDocument.classes = newStyle
      state.editingDocument.body = state.editingDocument.body.map(module => {
        return {...module, style : newStyle.module[0].class}
      })
      state.editingDocument.body.map(module =>{
        module.content.map(part =>{ 
          if(part.typeOfContent === 'heading' && newStyle.module_heading){
            part.style = {...part.style, ...newStyle.module_heading[0].class}
          }
          if(part.typeOfContent === 'subheading' && newStyle.module_subheading){
            part.style = {...part.style, ...newStyle.module_subheading[0].class}
          }
          if(part.typeOfContent === 'text_field' && newStyle.module_textfield){
            part.style = {...part.style, ...newStyle.module_textfield[0].class}
          }
          if(part.typeOfContent === 'level1' && newStyle.module_level1){
            //changed level1 style
            part.style = {...part.style, ...newStyle.module_level1[0].class}
            //changing level1 childs` style
            part.content.forEach(level1Part => {
              if(level1Part.typeOfContent === 'level2' && newStyle.level1_level2){
                level1Part.style = newStyle.level1_level2[0].class;
                //changing level2 childs` style
                level1Part.content.forEach(level2Part => {
                  if(level2Part.typeOfContent === 'level3' && newStyle.level2_level3){
                    level2Part.style = newStyle.level2_level3[0].class;
                    //changing level3 childs` style
                    // write code for level3
                  }else{
                    if (newStyle[`level2_${level2Part.typeOfContent}`]) {
                      level2Part.style = newStyle[`level2_${level2Part.typeOfContent}`][0].class;
                    }
                  }
                });
              } else{
                if (newStyle[`level1_${level1Part.typeOfContent}`]) {
                  level1Part.style = newStyle[`level1_${level1Part.typeOfContent}`][0].class;
                }
              }
            });
          }
        })
      })

    },
    setStyleForModule: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module=> module.id === idOfModule)
      const newStyle = {...state.editingDocument.body[indexOfModule].style, ...newStyleProperties}

      state.editingDocument.body[indexOfModule].style = newStyle

      //setting style for all unset parts in module
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        alignItems: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content = state.editingDocument.body[indexOfModule].content.map((part, index) => {
        if(part.typeOfContent ==='level1' && !part.style.isUnicStyle){
          state.editingDocument.body[indexOfModule].content[index].content = state.editingDocument.body[indexOfModule].content[index].content.map((part, index1) => {   
            if(part.typeOfContent ==='level2' && !part.style.isUnicStyle){
              state.editingDocument.body[indexOfModule].content[index].content[index1].content = state.editingDocument.body[indexOfModule].content[index].content[index1].content.map((part, index) => {
                if(!part.style.isUnicStyle){
                  return{...part, style: {...part.style, ...childsStyle}}
                } else {return part}
              })   
            }
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForLevel1: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level1Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === idOfModule)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(moduleContent => moduleContent.position === level1Position)
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].style, ...newStyleProperties}

      console.log('this style will be ', newStyle)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].style = newStyle

      //setting style for all unset parts in level
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        alignItems: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.map((part, index) => {
        if(part.typeOfContent ==='level2' && !part.style.isUnicStyle){
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content.map((part, index1) => {   
            if(part.typeOfContent ==='level3' && !part.style.isUnicStyle){
              state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content.map((part, index) => {
                if(!part.style.isUnicStyle){
                  return{...part, style: {...part.style, ...childsStyle}}
                } else {return part}
              })   
            }
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    updateSizeForLevel1: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level1Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === idOfModule)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(moduleContent => moduleContent.position === level1Position)

      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].style = newStyle
    },
    updateStyleForLevel1: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level1Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === idOfModule)
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(moduleContent => moduleContent.position === level1Position)

      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].style, ...newStyleProperties}
      console.log('this style will be ', newStyle)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].style = newStyle

      //setting style for all unset parts in level
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        alignItems: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.map((part, index) => {
        if(part.typeOfContent ==='level2' && !part.style.isUnicStyle){
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content.map((part, index1) => {   
            if(part.typeOfContent ==='level3' && !part.style.isUnicStyle){
              state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content.map((part, index) => {
                if(!part.style.isUnicStyle){
                  return{...part, style: {...part.style, ...childsStyle}}
                } else {return part}
              })   
            }
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    updateStyleForPartInModule: (state, action) => {
      const idOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex( module => module.id === idOfParent)
      const indexOfPart = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === positionOfPart)

      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfPart].style, ...newStyleProperties}
      console.log('this new style for dyn block  will be ', newStyle)
      state.editingDocument.body[indexOfModule].content[indexOfPart].style = newStyle

    },
    setStyleForLevel2: (state, action) => {
      const positionOfParent = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level2Position = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.position === positionOfParent
        ))
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level => level.position === positionOfParent
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position
      )
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style = newStyle

      //setting style for all unset parts in level
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        alignItems: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.map((part, index) => {
        if(part.typeOfContent ==='level3' && !part.style.isUnicStyle){
          state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[index].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[index].content.map((part, index1) => {
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInModule: (state, action) => {
      const idOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const isClass = action.payload[3]
      const indexOfModule = state.editingDocument.body.findIndex( module => module.id === idOfParent)
      const indexOfPart = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfPart].style, ...newStyleProperties}
      if(isClass){
        state.editingDocument.body[indexOfModule].content[indexOfPart].style = newStyleProperties
      }else{
        state.editingDocument.body[indexOfModule].content[indexOfPart].style = newStyle;
      }
    },
    setStyleForModuleColumn: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in module column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInModuleColumn: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const indexOfPart = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      
      state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    setStyleForPartInLevel1: (state, action) => {
      const positionOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === positionOfParent)))
      const indexOfLevel = state.editingDocument.body[indexOfModule].content.findIndex(level => level.position === positionOfParent)
      const indexOfPart = state.editingDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfPart].style, ...newStyleProperties}
      
      state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfPart].style = newStyle
    },
    setStyleForLevel1Column: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === parentColumnData.parent))
      )
      const indexOfLevel = state.editingDocument.body[indexOfModule].content.findIndex(level => level.position === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in module column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInLevel1Column: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === parentColumnData.parent))
      )
      const indexOfLevel = state.editingDocument.body[indexOfModule].content.findIndex(level => level.position === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const indexOfPart = state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content.findIndex(part => part.position === positionOfPart)
      
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    setStyleForPartInLevel2: (state, action) => {
      const positionOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      console.log()
      const indexOfModule = state.editingDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === positionOfParent)
        ))
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === positionOfParent)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === positionOfParent
      )
      const indexOfPart = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === positionOfPart
      )
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].style = newStyle
    },
    setStyleForLevel2Column: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
        ))
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === parentColumnData.parent
      )
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === parentColumnData.holder
      )
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(
        column => column.position === parentColumnData.column
      )
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in level2 column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        }else{
          return part
        }
      })
    },
    setStyleForPartInLevel2Column: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
        ))
      )
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level => level.typeOfContent === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
      )
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === parentColumnData.parent
      )
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === parentColumnData.holder
      )
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(
        column => column.position === parentColumnData.column
      )
      const indexOfPart = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.findIndex(
        part => part.position === positionOfPart
      )
      const newStyle = {...state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    setChosenComponent: (state, action) => {
      state.chosenComponent = action.payload[0]
    },
    setCroppingImage: (state, action) => {
      state.croppingImage = action.payload[0]
    }, 
    cropImageInModule: (state, action) => {
      const imagePosition = action.payload[0]
      const imageCropData = action.payload[1]
      const moduleId = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfImage = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === imagePosition)
      
      state.editingDocument.body[indexOfModule].content[indexOfImage].content.cropped = imageCropData
    },
    cropImageInLevel1: (state, action) => {
      const imagePosition = action.payload[0]
      const imageCropData = action.payload[1]
      const parentData = action.payload[2]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === parentData))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === parentData)
      const indexOfImage = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === imagePosition)
      
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfImage].content.cropped = imageCropData
    },
    resetImageInModule: (state, action) => {
      const imagePosition = action.payload[0]
      const moduleId = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === moduleId)
      const indexOfImage = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === imagePosition)
      
      state.editingDocument.body[indexOfModule].content[indexOfImage].content.cropped = null
    },
    deleteModule: (state, action) => {
      const partPosition = action.payload[0]

      state.editingDocument.body = state.editingDocument.body.filter(part => part.id !== partPosition)
    },
    deleteComponentInModule: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => module.id === parentPosition)

      state.editingDocument.body[indexOfModule].content = state.editingDocument.body[indexOfModule].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInModuleColumn: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInLevel1: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === parentPosition))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === parentPosition)

      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.filter(
        part => part.position !== partPosition)
    },
    deleteComponentInLevel1Column: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === parentColumnData.parent))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInLevel2: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      console.log(action.payload)
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level => level.typeOfContent === 'level1' &&
          -1 !== level.content.findIndex(level2 => level2.position === parentPosition)))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level => level.typeOfContent === 'level1' &&
        -1 !== level.content.findIndex(level2 => level2.position === parentPosition))
      const indexOfLevel2 =  state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( level2 => level2.position === parentPosition)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.filter(
        part => part.position !== partPosition)
    },
    deleteComponentInLevel2Column: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level => level.typeOfContent === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === parentColumnData.parent)))
      const indexOfLevel1 = state.editingDocument.body[indexOfModule].content.findIndex( level => level.typeOfContent === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === parentColumnData.parent))
      const indexOfLevel2 = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === parentColumnData.parent)
      const indexOfColHolder = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content = state.editingDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    updateDocumentName: (state, action) => {
      const newName = action.payload[0]
      state.editingDocument = {...state.editingDocument, name: newName}
      sessionStorage.removeItem('active'+ state.editingDocument._id)
      const newDocument = {name: state.editingDocument.name , id: state.editingDocument._id, type: 'template'}
      sessionStorage.setItem('active'+state.editingDocument._id, JSON.stringify(newDocument))
    },
  },
})  

export const { 
  setModulePageSize,
  updateModuleStyle, 
  updateModuleOrder, 
  addNewModule,
  addOldModule,
  changeModuleName,
  addDesignedModule, 
  addNewLevel1,
  addingNewLevel1,
  changeLevel1Name,
  updatingLevel2Order,
  updateLevel2Order,
  addNewLevel2,
  addingNewLevel2,
  changeLevel2Name,
  changeLevel3Name,
  updatingLevel3Order,
  updateLevel3Order,
  addNewLevel3,
  addingNewLevel3,
  removeEmptyComponents,
  deleteItemFromMap,
  addItemToTrashCan,
  addItemToStore,
  updatingLevel1Order,
  updateLevel1Order,
  updatingComponentsOrder,
  addingNewComponent, 
  updateComponentsOrder,
  updatingComponentsOrderInModuleColumn,
  updatingColumnsOrderInModuleColumns,
  updatingComponentsOrderInLevel1Column,
  updatingColumnsOrderInLevel1Columns,
  updatingComponentsOrderInLevel2Column,
  updatingColumnsOrderInLevel2Columns,
  updatingComponentsOrderInLevel1,
  updateComponentsOrderInLevel1,
  updatingComponentsOrderInLevel2,
  updateComponentsOrderInLevel2,
  addActiveDocument,
  removeActiveDocument,
  setDocument,
  addComponentInModule,
  addDesignItemInModule,
  addImageInModule,
  addComponentInLevel1,
  addDesignItemInLevel1,
  addImageInLevel1,
  addComponentInLevel2,
  addDesignItemInLevel2,
  addImageInLevel2,
  addComponentInLevel3,
  addDesignItemInLevel3,
  addImageInLevel3,
  addComponentInModuleColumn,
  addImageInModuleColumn,
  addComponentInLevel1Column,
  addImageInLevel1Column,
  addComponentInLevel2Column,
  addImageInLevel2Column,
  changeComponentInModule,
  changeComponentInLevel1,
  changeComponentInLevel2,
  changeComponentInModuleColumn,
  changeComponentInLevel1Column,
  changeComponentInLevel2Column,
  setDefaultDocumentStyle,
  newUpdateDocumentStyle,
  updateDocumentClasses,
  setStyleForModule,
  setStyleForLevel1,
  updateStyleForLevel1,
  updateSizeForLevel1,
  setStyleForLevel2,
  setStyleForPartInModule,
  updateStyleForPartInModule,
  setStyleForModuleColumn,
  setStyleForPartInModuleColumn,
  setStyleForPartInLevel1,
  setStyleForLevel1Column,
  setStyleForPartInLevel1Column,
  setStyleForPartInLevel2,
  setStyleForLevel2Column,
  setStyleForPartInLevel2Column,
  setChosenComponent,
  setCroppingImage,
  cropImageInModule,
  cropImageInLevel1,
  resetImageInModule,
  setImageInModuleBackground,
  deleteModule,
  deleteComponentInModule,
  deleteComponentInModuleColumn,
  deleteComponentInLevel1,
  deleteComponentInLevel1Column,
  deleteComponentInLevel2,
  deleteComponentInLevel2Column,
  updateDocumentName,
 } = editingDocumentSlice.actions
export default editingDocumentSlice.reducer
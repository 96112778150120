import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch } from "react-redux";
import { addingNewLevel3, addNewLevel3, changeLevel2Name, removeEmptyComponents, updateLevel3Order, updatingLevel3Order } from "../../features/localDocument/editingDocumentSlice";
import MapLevel3 from "./MapLevel3";

const empty_container = {
  display: 'flex',
  cursor: 'grab',
  borderRadius: '8px',
  border: '1px dashed blue',
  background: '#FFFFFF',
  opacity:'0.5',
}
const empty_row ={
  padding: '8px',
  paddingRight: '12px',
  width: '9vw',
  height: '30px',
}
const drop_holder = {
  paddingTop:'30px',
  borderRadius: '8px',
  border: '1px dashed #7A7E7E',
  width: '9vw',
  zIndex: '0',
}

const MapLevel2 = ({ part, index, parentIndex, level2Position, moveLevel2, dropLevel2}) => {
  
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState(part.value === 'no' ? '' : part.name)


  const [{ handlerId }, drop] = useDrop({
    accept: ['level2', 'level3'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    drop( item ){ 
      if(item.part.typeOfContent !=='level3'){
        if(item.part.origin === 'sidebar' || item.part.parent !== part.parent){
          dropLevel2(item.part )
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragPosition = item.level2Position
      const hoverPosition = level2Position
      if (dragPosition === hoverPosition && item.part.parent === part.parent) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined ){
        if(item.part.typeOfContent === 'level3'){
          setIsOpen(prev => true)
        }
        else{
          moveLevel2(dragPosition, hoverPosition, item.part)
        }
      }
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: part.value !== 'no' ? 'level2' : 'undraggable',
    item: () => {
      return {  level2Position, part }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      //dispatch(removeEmptyComponents())
    }
  })
  drag(drop(ref))
  const moveLevel3 = useCallback(
    (dragPosition, hoverPosition, newLevel) => {
      newLevel.origin === 'root' ?  
      dispatch(updatingLevel3Order([dragPosition, hoverPosition, level2Position , newLevel])) 
      : dispatch(addingNewLevel3([hoverPosition, level2Position]))  
    }, [])
  
  const changeOrder = useCallback(
    newLevel => {
      newLevel.origin !== 'root' ? 
      dispatch(addNewLevel3([newLevel, level2Position]))  
      :  dispatch(updateLevel3Order([newLevel, level2Position]))  
    }, [])
  const renderLevel3 = useCallback((part, index) => {
    return (
      <MapLevel3
        key={part.position}
        index={index}
        parentIndex={index}
        level3Position={part.position}
        part = {part}
        moveLevel3={moveLevel3}
        dropLevel3={changeOrder}
      />
    )
  }, [])
  
  const opacity = isDragging ? 0 : 1
  return (
    <div className="agreement-map-level-container">
      <div ref={ref} data-handler-id={handlerId}
      style={{opacity}}
      onClick={() => setIsOpen(prev => !prev)}
      className='agreement-map-level-heading'>
        {part !== undefined ?
          (part.value === 'no' ?
            <div style={drop_holder}/>
          : part.position === 0 ?
          <div style={empty_container}>
            <div style={empty_row}>     </div>
          </div>
          :
          <>
            <div className="agreement-map-level2-num">{parentIndex+1}.{index+1}</div>
            <div className="agreement-map-level-name-container">
              <textarea className="agreement-map-level2-name" value={name} 
              onChange={(e) => {
                setName(e.target.value)
                dispatch(changeLevel2Name([part.parent, level2Position, e.target.value]))}}
              /> 
            </div>
          </>
          ) 
        : <></>}
      </div>
      {isOpen && part !== undefined && part.content !== undefined ?
          part.content !== undefined ?
          <div className="agreement-map-level3-list">
            {part.content.length !== 0 ? 
              part.content.filter(part => part.typeOfContent === "level3").length !== 0 ?
                part.content.filter(part => part.typeOfContent === "level3").map((part, i) => 
                  renderLevel3(part, i) 
                )
              : renderLevel3({value:'no'}, 0) 
            : renderLevel3({value:'no'}, 0)}
          </div>
          : <></>
        :<></> 
        }
    </div>
  )
}


export default MapLevel2;
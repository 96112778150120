import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import arrow from "../../icons/arrow-icon.svg"
import plus from '../../icons/plus-grey-icon.svg'
import save from '../../icons/save-admin-row-icon.svg'
import close from '../../icons/right-panel-close.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import 'react-datepicker/dist/react-datepicker.css';
import { updateUser } from "../../features/users/userSlice";
import { createFolderRegister } from "../../features/folderRegister/folderRegisterSlice";
import RegisterRowsTableRow from "./RegisterRowsTableRow";


const RegisterRowsTableBody = ({tableData,
  customerSpaceList, isLoadingCS,
  usersList, isLoadingU,
  folderIdsList, isLoadingFId,
  updateTrigger, setRegisterUpdateTrigger, registerToggle}) => {

  const dispatch = useDispatch()

  const [folderRegisterData, setFolderRegisterData] = useState([])
  const [customerSpacesData, setCustomerSpacesData] = useState([])
  const [usersData, setUsersData] = useState([])
  const [folderIdsData, setFolderIdsData] = useState([])
  
  useEffect(() => {
    setFolderRegisterData(tableData)
  }, [tableData]); 
  useEffect(() => {
    setCustomerSpacesData(customerSpaceList)
  }, [customerSpaceList]);
  useEffect(() => {
    setUsersData(usersList)
  }, [usersList]);
  useEffect(() => {
    setFolderIdsData(folderIdsList)
  }, [folderIdsList]);
  
  const [registerCustomerSpace, setRegisterCustomerSpace] = useState('not selected')
  const [registerCustomerSpaceName, setRegisterCustomerSpaceName] = useState('not selected')
  const [registerUserEmail, setRegisterUserEmail] = useState('not selected')
  const [registerUserEmailName, setRegisterUserEmailName] = useState('not selected')
  const [registerTemplateFolder, setRegisterTemplateFolder] = useState('not selected')
  const [registerTemplateFolderName, setRegisterTemplateFolderName] = useState('not selected')
  const [registerDocFolder, setRegisterDocFolder] = useState('not selected')
  const [registerDocFolderName, setRegisterDocFolderName] = useState('not selected')
  const [registerFolderType, setRegisterFolderType] = useState('View')
  
  const [isRegisterCustomerSpaceDown, setIsRegisterCustomerSpaceDown] = useState(false)
  const refRegisterCustomerSpace = useOnclickOutside(() => {
    isRegisterCustomerSpaceDown && setIsRegisterCustomerSpaceDown(prev => false);
  })
  const [isRegisterUserEmailDown, setIsRegisterUserEmailDown] = useState(false)
  const refRegisterUserEmail = useOnclickOutside(() => {
    isRegisterUserEmailDown && setIsRegisterUserEmailDown(prev => false);
  })
  const [isRegisterTemplateFolderDown, setIsRegisterTemplateFolderDown] = useState(false)
  const refRegisterTemplateFolder = useOnclickOutside(() => {
    isRegisterTemplateFolderDown && setIsRegisterTemplateFolderDown(prev => false);
  })
  const [isRegisterDocFolderDown, setIsRegisterDocFolderDown] = useState(false)
  const refRegisterDocFolder = useOnclickOutside(() => {
    isRegisterDocFolderDown && setIsRegisterDocFolderDown(prev => false);
  })
  const [isRegisterFolderTypeDown, setIsRegisterFolderTypeDown] = useState(false)
  const refRegisterFolderType = useOnclickOutside(() => {
    isRegisterFolderTypeDown && setIsRegisterFolderTypeDown(prev => false);
  })

  const [isRegisterRowAdding, setIsRegisterRowAdding] = useState(false)
  const [registerRowSavingStatus, setRegisterRowSavingStatus] = useState(false)

  const generateFolderId = (folderType, type) => {
    let prefix = folderType === 'template' ? 'tf' : 'df';
    if (type === 'View') {
      prefix += 'v';
    } else if (type === 'Edit') {
      prefix += 'e';
    }
    return prefix;
  };

  const registerRowHandleSave = () => {
    if(registerCustomerSpace === 'not selected' || registerUserEmail === 'not selected'
    || registerTemplateFolder === 'not selected' || registerDocFolder === 'not selected'
    || registerFolderType === 'not selected' ){
      toast.error('Fill all inputs !', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
      setRegisterRowSavingStatus('Please, fill all fields')
    }else{
      setRegisterRowSavingStatus('Saving a new folder id ')
      const registerRowData = {
        customerSpace: registerCustomerSpace,
        user: registerUserEmail,
        templateFolder: registerTemplateFolder,
        docFolder: registerDocFolder,
        type: registerFolderType,
      };
      let userFoldersData = []
      userFoldersData.push({
        folderId: registerTemplateFolder,
        prefix: generateFolderId('template', registerFolderType),
      })
      userFoldersData.push({
        folderId: registerDocFolder,
        prefix: generateFolderId('document', registerFolderType),
      })
      toast.promise(
        dispatch(createFolderRegister(registerRowData)),
        {
          pending: 'Saving..',
          success: 'Saved new folder register',
          error: 'Promise rejected 🤯'
        }
      )
      const userUpdateData = {
        _id: registerUserEmail,
        userCustomerSpace: registerCustomerSpace,
        folderIds: userFoldersData, 
        type: 'folders',
      }
      //console.log('userUpdateData  ',userUpdateData)
      dispatch(updateUser(userUpdateData))

      setRegisterUpdateTrigger(true)
      setIsRegisterRowAdding(false)
      setRegisterCustomerSpace('not selected')
      setRegisterCustomerSpaceName('not selected')
      setRegisterUserEmail('not selected')
      setRegisterUserEmailName('not selected')
      setRegisterTemplateFolder('not selected')
      setRegisterTemplateFolderName('not selected')
      setRegisterDocFolder('not selected')
      setRegisterDocFolderName('not selected')
      setRegisterFolderType('View')
    }
  };

  return ( 
    <tbody>
      { folderRegisterData.length !== 0 ? 
        <>
          {folderRegisterData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((row, index) => 
            (<RegisterRowsTableRow key={index} row={row} index={index} 
              registerToggle={registerToggle} 
              setUpdateTrigger={setRegisterUpdateTrigger}/>)
          )}
          {
            !isRegisterRowAdding ?
            <tr className="admin-control-usual-row"> 
              <td className="admin-control-space-selector" onClick={() => setIsRegisterRowAdding(prev => true)}>
                <div className="admin-control-adding-row">
                  <img src={plus} alt='yes'/>
                </div>
              </td>
            </tr>
            :
            <tr className="admin-control-usual-row">
            <td className="admin-control-form-save-td">
              <img className="admin-control-form-save" 
              onClick={registerRowHandleSave}
              src={save} alt="save"/>
            </td>
            <td>
              <div ref={refRegisterCustomerSpace} className="admin-selector-container">
                <div className="admin-selector-header" onClick={() => setIsRegisterCustomerSpaceDown(prev => !prev)}>
                  {registerCustomerSpaceName}
                  <img style={{marginLeft:'8px', transform:( isRegisterCustomerSpaceDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isRegisterCustomerSpaceDown && 
                  <div className="admin-selector-body">
                    {
                      isLoadingCS || updateTrigger ? <Spinner/> : customerSpacesData.length !== 0 ?
                      <>
                        {customerSpacesData.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                        <div className="admin-selector-body-option" onClick={() => {setRegisterCustomerSpace(space._id); setRegisterCustomerSpaceName(space.name)}}>
                          {space.name}
                        </div>
                        ))}
                      </>
                      : <div>There are no customer spaces yet </div>
                    }
                  </div>
                }
              </div>
            </td>
            <td>
              <div ref={refRegisterUserEmail} className="admin-selector-user-container">
                <div className="admin-selector-header" onClick={() => setIsRegisterUserEmailDown(prev => !prev)}>
                  {registerUserEmailName}
                  <img style={{marginLeft:'8px', transform:( isRegisterUserEmailDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isRegisterUserEmailDown && 
                  <div className="admin-selector-body">
                    {
                      isLoadingU || updateTrigger ? <Spinner/> : usersData.length !== 0 ?
                      <>
                        {usersData.filter(user => user.userStatus !== 'archive').map((user, index) => (
                        <div className="admin-selector-body-option" onClick={() => {setRegisterUserEmail(user._id); setRegisterUserEmailName(user.email)}}>
                          {user.email}
                        </div>
                        ))}
                      </>
                      : <div>There are no users yet </div>
                    }
                  </div>
                }
              </div>
            </td>
            <td className={`register-row-status-New`}>
              New 
            </td>
            <td>
              <div ref={refRegisterTemplateFolder} className="admin-selector-container">
                <div className="admin-selector-header" onClick={() => setIsRegisterTemplateFolderDown(prev => !prev)}>
                  {registerTemplateFolderName}
                  <img style={{marginLeft:'8px', transform:( isRegisterTemplateFolderDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isRegisterTemplateFolderDown && 
                  <div className="admin-selector-body">
                    {
                      isLoadingFId || updateTrigger ? <Spinner/> : folderIdsData.length !== 0 ?
                      <>
                        {folderIdsData.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Templates').map((folder, index) => (
                        <div className="admin-selector-body-option" onClick={() => {setRegisterTemplateFolder(folder._id); setRegisterTemplateFolderName(folder.name)}}>
                          {folder.name}
                        </div>
                        ))}
                      </>
                      : <div>There are no templates folders yet </div>
                    }
                  </div>
                }
              </div>
            </td>
            <td>
              <div ref={refRegisterDocFolder} className="admin-selector-container">
                <div className="admin-selector-header" onClick={() => setIsRegisterDocFolderDown(prev => !prev)}>
                  {registerDocFolderName}
                  <img style={{marginLeft:'8px', transform:( isRegisterDocFolderDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isRegisterDocFolderDown && 
                  <div className="admin-selector-body">
                    {
                      isLoadingFId || updateTrigger ? <Spinner/> : folderIdsData.length !== 0 ?
                      <>
                        {folderIdsData.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Docs').map((folder, index) => (
                        <div className="admin-selector-body-option" onClick={() => {setRegisterDocFolder(folder._id); setRegisterDocFolderName(folder.name)} }>
                          {folder.name}
                        </div>
                        ))}
                      </>
                      : <div>There are no doc folders yet </div>
                    }
                  </div>
                }
              </div>
            </td>
            <td>
              <div ref={refRegisterFolderType} className="admin-selector-container">
                <div className="admin-selector-header" onClick={ () => setIsRegisterFolderTypeDown(prev => !prev)}>
                  {registerFolderType}
                  <img style={{marginLeft:'8px', transform:( isRegisterFolderTypeDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isRegisterFolderTypeDown && 
                  <div className="admin-selector-body">
                    <div className="admin-selector-body-option" onClick={() => setRegisterFolderType('View')}>
                      View
                    </div>
                    <div className="admin-selector-body-option" onClick={() => setRegisterFolderType('Edit')}>
                      Edit
                    </div>
                  </div>
                }
              </div>
            </td>
            <td>
              <img className="admin-control-form-close" 
              onClick={() => setIsRegisterRowAdding(prev => false)}
              src={close} alt="close"/>
            </td>
            </tr>
          }
      </>
      :
      <tr className="admin-control-usual-row">
        <td className="admin-control-form-save-td">
          <img className="admin-control-form-save" 
          onClick={registerRowHandleSave}
          src={save} alt="save"/>
        </td>
        <td>
          <div ref={refRegisterCustomerSpace} className="admin-selector-container">
            <div className="admin-selector-header" onClick={() => setIsRegisterCustomerSpaceDown(prev => !prev)}>
              {registerCustomerSpaceName}
              <img style={{marginLeft:'8px', transform:( isRegisterCustomerSpaceDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isRegisterCustomerSpaceDown && 
              <div className="admin-selector-body">
                {
                  isLoadingCS || updateTrigger ? <Spinner/> : customerSpacesData.length !== 0 ?
                  <>
                    {customerSpacesData.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                    <div className="admin-selector-body-option" onClick={() => {setRegisterCustomerSpace(space._id); setRegisterCustomerSpaceName(space.name)}}>
                      {space.name}
                    </div>
                    ))}
                  </>
                  : <div>There are no customer spaces yet </div>
                }
              </div>
            }
          </div>
        </td>
        <td>
          <div ref={refRegisterUserEmail} className="admin-selector-user-container">
            <div className="admin-selector-header" onClick={() => setIsRegisterUserEmailDown(prev => !prev)}>
              {registerUserEmailName}
              <img style={{marginLeft:'8px', transform:( isRegisterUserEmailDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isRegisterUserEmailDown && 
              <div className="admin-selector-body">
                {
                  isLoadingU || updateTrigger ? <Spinner/> : usersData.length !== 0 ?
                  <>
                    {usersData.filter(user => user.userStatus !== 'archive').map((user, index) => (
                    <div className="admin-selector-body-option" onClick={() => {setRegisterUserEmail(user._id); setRegisterUserEmailName(user.email)}}>
                      {user.email}
                    </div>
                    ))}
                  </>
                  : <div>There are no users yet </div>
                }
              </div>
            }
          </div>
        </td>
        <td className={`register-row-status-New`}>
          New 
        </td>
        <td>
          <div ref={refRegisterTemplateFolder} className="admin-selector-container">
            <div className="admin-selector-header" onClick={() => setIsRegisterTemplateFolderDown(prev => !prev)}>
              {registerTemplateFolderName}
              <img style={{marginLeft:'8px', transform:( isRegisterTemplateFolderDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isRegisterTemplateFolderDown && 
              <div className="admin-selector-body">
                {
                  isLoadingFId || updateTrigger ? <Spinner/> : folderIdsData.length !== 0 ?
                  <>
                    {folderIdsData.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Templates').map((folder, index) => (
                    <div className="admin-selector-body-option" onClick={() => {setRegisterTemplateFolder(folder._id); setRegisterTemplateFolderName(folder.name)}}>
                      {folder.name}
                    </div>
                    ))}
                  </>
                  : <div>There are no templates folders yet </div>
                }
              </div>
            }
          </div>
        </td>
        <td>
          <div ref={refRegisterDocFolder} className="admin-selector-container">
            <div className="admin-selector-header" onClick={() => setIsRegisterDocFolderDown(prev => !prev)}>
              {registerDocFolderName}
              <img style={{marginLeft:'8px', transform:( isRegisterDocFolderDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isRegisterDocFolderDown && 
              <div className="admin-selector-body">
                {
                  isLoadingFId || updateTrigger ? <Spinner/> : folderIdsData.length !== 0 ?
                  <>
                    {folderIdsData.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Docs').map((folder, index) => (
                    <div className="admin-selector-body-option" onClick={() => {setRegisterDocFolder(folder._id); setRegisterDocFolderName(folder.name)} }>
                      {folder.name}
                    </div>
                    ))}
                  </>
                  : <div>There are no doc folders yet </div>
                }
              </div>
            }
          </div>
        </td>
        <td>
          <div ref={refRegisterFolderType} className="admin-selector-container">
            <div className="admin-selector-header" onClick={ () => setIsRegisterFolderTypeDown(prev => !prev)}>
              {registerFolderType}
              <img style={{marginLeft:'8px', transform:( isRegisterFolderTypeDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isRegisterFolderTypeDown && 
              <div className="admin-selector-body">
                <div className="admin-selector-body-option" onClick={() => setRegisterFolderType('View')}>
                  View
                </div>
                <div className="admin-selector-body-option" onClick={() => setRegisterFolderType('Edit')}>
                  Edit
                </div>
              </div>
            }
          </div>
        </td>
        <td>
          <img className="admin-control-form-close" 
          onClick={() => setIsRegisterRowAdding(prev => false)}
          src={close} alt="close"/>
        </td>
        </tr>
      }
    </tbody>
   );
}
 
export default RegisterRowsTableBody;
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import logo_icon from '../icons/majoto-logo-icon.svg'
import google_auth from '../icons/google-auth-icon.svg'

const  Login = () =>  {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
  })

  const {  email, password } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || user) {
      navigate('/welcome')
    }

    dispatch(reset())
  }, [user, isError, isSuccess, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    const userData = {
      email,
      password,
    }

    dispatch(login(userData))
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='auth-background'>
      <div className='auth-body'>
        <div><img src={logo_icon} alt="logo"/></div>
        <div className='auth-content'>
          <div className='auth-form-name'>
            Log in
          </div>
        <form onSubmit={onSubmit}>
          <div className='auth-form-group'>
            <label htmlFor="email">Email address</label>
            <input
              type='email'
              className="input-field col s6"
              id='email' name='email'
              value={email}
              placeholder='Email address'
              onChange={onChange}
            />
          </div>
          <div className='auth-form-group'>
            <label htmlFor="password">Password</label>
            <input
              type='password'
              className="input-field col s6"
              id='password' name='password'
              value={password}
              placeholder='Minimum 8 characters'
              onChange={onChange}
            />
            <a href="/forgot-password">Forgot password?</a>
          </div>
          <div className='auth-form-bottom'>
            <div className="auth-form-buttons">
              <button type='submit' className='auth-btn'>
                Log in
              </button>
              <form action="https://majoto.app/api/googleAuth/" method="get" className='auth-form-icon' >
                <label  htmlFor="google_auth">
                  <img src={google_auth}/>
                </label>
                <input id='google_auth' type="submit"/>
              </form>
            </div>
            <div className='auth-form-variation'>
              <div>No account? </div>
              <a href='/register'>Sign up</a>
            </div>
          </div>
        </form>
        
        </div>
      </div>
    </div>
  )
}

export default Login
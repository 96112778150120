import { useEffect, useRef } from "react"
import { useLocation } from "react-router-dom"

const usePrevious = (value) => {
  const ref = useRef()
  useEffect(() => { ref.current = value })

  return ref.current
}

export const useLocationChange = (action) => {
  const location = useLocation();
  const prevLocationRef = useRef();
  
  useEffect(() => {
    action(location, prevLocationRef.current);
    prevLocationRef.current = location;
  }, [location, action]);

  return prevLocationRef.current;
}
import React, { useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';

const CustomerDurationSelector = ({isSelected, setIsSelected, editingHandler, customerDuration, setCustomerDuration}) => {

  const selectorRef = useOnclickOutside(() => {
    isSelected && setIsSelected(false);
    if(customerDuration.duration !== duration || customerDuration.type !== durationType){
      editingHandler('duration', {duration: duration, type:durationType});
      setCustomerDuration({duration: duration, type:durationType})
    }
  })

  const [duration, setDuration] = useState(customerDuration.duration);
  const [durationType, setDurationType] = useState(customerDuration.type);

  const handleDurationChange = (event) => {
    const value = event.target.value;
    if (value === '14_day_trial' || value === '30_day_trial') {
      setDuration(value === '14_day_trial' ? '14 day Trial' : '30 day Trial');
      setDurationType(value);
    } else {
      setDuration(value);
      setDurationType('months');
    }
  };

  return (
    <div className='customer-duration-form-container'>
      {isSelected &&
      <>
        <form className='customer-duration-form' ref={selectorRef}>
          <div className="duration-slider">
            {/* <input
              type="radio"
              id="14_day_trial"
              name="duration"
              value="14_day_trial"
              checked={durationType === '14_day_trial'}
              onChange={handleDurationChange}
            />
            <label className={`duration-form-label-trial${duration === '14 day Trial' ? '-selected' : ''}`} htmlFor="14_day_trial">
              14-day Trial
            </label> */}

            <input
              type="radio"
              id="30_day_trial"
              name="duration"
              value="30_day_trial"
              checked={durationType === '30_day_trial'}
              onChange={handleDurationChange}
            />
            <label className={`duration-form-label-trial${duration === '30 day Trial' ? '-selected' : ''}`} htmlFor="30_day_trial">
              30-day Trial
            </label>

            <input
              type="range"
              id="duration-range"
              className="duration-slider-range"
              name="duration"
              min="1"
              max="24"
              value={durationType === 'months' ? duration : '1'}
              onChange={handleDurationChange}
            />
            <label className='duration-form-label' htmlFor="duration-range">{durationType === 'months' ? `${duration} months` : duration}</label>
          </div>
        </form>
      </>
      }
    </div>
  );
};

export default CustomerDurationSelector;
import sidebar_menu from '../icons/sidebar-icon.svg';
import analytics from '../icons/analytics-icon.svg'
import launchpad from '../icons/launch-icon.svg'
import file_icon from '../icons/file-icon.svg';
import style_icon from "../icons/style-doc-icon.svg"
import logo_icon from '../icons/majoto-logo-icon.svg'
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import support from "../icons/sidebar_phone.svg";
import account from "../icons/sidebar_account.svg";

const ShortMenu = ({chosenPage}) => {

  const [ openMenu, setOpenMenu ] = useState(false)
  const [activeDocuments, setActiveDocuments] = useState([])

  const getActiveDocuments = () =>{
    let activeDocs =[]
    for(let key in sessionStorage ) {
      if(key.startsWith('active')){
        let item= JSON.parse(sessionStorage.getItem(key))
        activeDocs.push(item)
      }
    }
    setActiveDocuments(prev => activeDocs)
  }
  
  useEffect(()=>{
    getActiveDocuments()
  }, [chosenPage])

  const handleClick = () => {
    setOpenMenu(prev => !openMenu)
  }

  return (
    <>
    {!openMenu ? 
      <div className="short-menu">
        <div className="menu-body">
          <div className='short-menu-sidebar' onClick={() => handleClick()}> 
            <div><img src={sidebar_menu} alt="sidebar"/></div>
          </div>
          <div className='menu-images' >
            {chosenPage === 'launchpad' && <div id="sidebar-sign"/>}
            <a href="/launch/templates"  id={chosenPage === 'launchpad' ? "chosen-page": ''}>
              <img src={launchpad} alt="Launch"/>
            </a>
          </div>
          <div className='menu-images'> 
            {chosenPage === 'analytics' && <div id="sidebar-sign"/>}
            <a href="/analytics" id={chosenPage === 'analytics' ? "chosen-page" : ''}>
              <img src={analytics} alt="analytics"/>
            </a> 
          </div>
          <div style={{paddingTop:'41px'}}>
            {activeDocuments ? activeDocuments.map((document, index) => (
              <div key={index} className='menu-images'> 
                {chosenPage === document.id && <div id="sidebar-sign"/>}
                <NavLink to={document.type === 'style' ? "/s/"+document.id+"/design" : "/d/"+document.id+"/edit"} 
                id={chosenPage === document.id && "chosen-page"}>
                  <img src={document.type === 'style' ? style_icon : file_icon} alt="doc"/>
                </NavLink> 
              </div>
            )): <></>}
          </div> 
        </div>
        <div className="menu-bottom">
          <div className="menu-bottom-option"><img src={support} alt="support"/></div>
          <div className="menu-bottom-option">
          {chosenPage === 'account' && <div id="sidebar-sign"/>}
            <NavLink to='/administration' id={chosenPage === 'account' ? "chosen-page" : ''}>
              <img src={account} alt="account"/>
            </NavLink> 
          </div>
        </div>
      </div>
    :
      <div className="menu-open">
        <div className="menu-body">
          <div className='menu-sidebar-open' onClick={() => handleClick()}> 
            <div><img src={logo_icon} alt="logo"/></div>
          </div>
          <div className='menu-images-open' >
            {chosenPage === 'launchpad' && <div id="sidebar-sign"/>}
            <a href="/launch/templates" id={chosenPage === 'launchpad' ? "chosen-page" : ''}>
              <img src={launchpad} alt="Launch"/>
              <div>Launchpad</div>
            </a>
          </div>
          <div className='menu-images-open'> 
            {chosenPage === 'analytics' && <div id="sidebar-sign"/> }
            <a href="/analytics" id={chosenPage === 'analytics' ? "chosen-page" : ''}>
              <img src={analytics} alt="analytics"/>
              <div>Analytics</div>
            </a> 
          </div>
          <div style={{paddingTop:'18px'}}>
            <div className='menu-active-documents'>
              Active spaces</div>
              {activeDocuments ? activeDocuments.map((document, index) => (
              <div key={index} className='menu-images-open'> 
                {chosenPage === document.id && <div id="sidebar-sign"/>}
                <NavLink to={document.id && document.type === 'style' ? "/s/"+document.id+"/design" : "/d/"+document.id+"/edit"} 
                id={chosenPage === document.id && "chosen-page"}>
                  <img src={document.type === 'style' ? style_icon : file_icon} alt="doc"/>
                  <div>{document.name}</div>
                </NavLink> 
              </div>
            )): <></>}
          </div>
        </div>
        <div className="menu-open-bottom">
          <div className="menu-bottom-option">
            <img src={support} alt="support"/>
            Support
          </div>
          <div className="menu-bottom-option">
          {chosenPage === 'account' && <div id="sidebar-sign"/>}
            <NavLink to='/administration' id={chosenPage === 'account' ? "chosen-page" : ''}>
              <img src={account} alt="account"/>
              Account
            </NavLink> 
          </div>
        </div>
      </div>
    }
    <div className={openMenu ? "menu-open-bones" : 'short-menu-bones'}/>
    </>
  );
}
 
export default ShortMenu;
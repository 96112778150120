import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import emailjs from '@emailjs/browser';
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomerSpaces} from "../../features/customerSpaces/customerSpaceSlice";
import { changeUser, getUsers } from "../../features/users/userSlice";
import {getFolderIds } from "../../features/folderIds/folderIdSlice";
import UsersTableBody from "./UsersTableBody";
import SortableTh from "./SortableTh";


const UsersTable = () => {
  
  const template_id = 'template_3w75the'
  const service_id = 'service_cdtgthm'
  const public_key = '3WYeIk6ploNZtWWLi'

  const dispatch = useDispatch()
  const  { users, isLoadingU, isErrorU, messageU} = useSelector( 
    state => state.users)

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [userUpdateTrigger, setUserUpdateTrigger] = useState(false)
  const [userData, setUserData] = useState([])
  
  const [sortedColumn, setSortedColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');
  
  useEffect(() =>{
    setUserData(users.filter(user => user.userStatus !== 'archive'))
    //console.log('userDataData: ',userData)
  },[users])

  const handleSort = (columnName) => {
    setSortedColumn(columnName);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    setUserData(prev => prev.sort((a, b) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        if (sortDirection === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    )
    //console.log('customerSpacesData: ',userData)
  }

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getFolderIds())
        dispatch(getUsers())  
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  useEffect(() => {
    if(userUpdateTrigger){
      setTimeout(() => {
        dispatch(getUsers())  
        setUserUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [userUpdateTrigger])
  const [chosenUsers, setChosenUsers] = useState([]);

  const userToggle = (index) => {
    setChosenUsers((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }
  const [isUActionsMenu, setIsUActionsMenu] = useState(false)

  useEffect(() => {
    chosenUsers.length !== 0 ? setIsUActionsMenu(true) : setIsUActionsMenu(false) 
  }, [chosenUsers])

  const uActionsHandler = (option) => {
    console.log('chosenUsers : ', chosenUsers)
    chosenUsers.forEach((userId) => {
      const userToUpdate = users.find((user) => user._id === userId);
  
      if (userToUpdate) {
        const updatedUser = { ...userToUpdate, userStatus: option };
        toast.promise(
          dispatch(changeUser(updatedUser)),
          {
            pending: 'Applying changes',
            success: `Changed user state to ${option}`,
            error: 'Promise rejected 🤯'
          }
        )
      }
    })
    if (chosenUsers.length !== 0){
      setUserUpdateTrigger(true)
      setChosenUsers([])
    }
  }
  const sendInvitationUserHandler = () => {
    if(chosenUsers.length > 1){
      toast.error('Chose one user please', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{ 
      const userId = chosenUsers[0]
      const userToUpdate = users.find((user) => user._id === userId);
      if (userToUpdate.userStatus !== 'Active' & userToUpdate.userStatus !== 'Invite sent') {
        const emailData = {
          user_name: userToUpdate.name,
          user_email: userToUpdate.email,
          user_link: userToUpdate.invitationLink,
        }
        const ccEmailData = {
          user_name: 'Denis',
          user_email: 'denis@majoto.io',
          user_link: userToUpdate.invitationLink,
        }
        toast.promise( () => 
          emailjs.send(service_id, template_id, emailData, public_key),
          {
            pending: 'Sending invitation..',
            success: 'Invitation sent',
            error: 'Promise rejected 🤯'
          }
        )
        emailjs.send(service_id, template_id, ccEmailData, public_key)
        const updatedUser = { ...userToUpdate, userStatus: 'Invite sent' };
        dispatch(changeUser(updatedUser))
        setUserUpdateTrigger(true)
        setChosenUsers([])
      } else {
        toast.error('This user is already invited', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        })
      }
    }
  }

  return ( 
    <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Users
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <SortableTh columnName="name" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                User name
              </SortableTh>
              <SortableTh columnName="email" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Email
              </SortableTh>
              <SortableTh columnName="createdAt" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Added on
              </SortableTh>
              <SortableTh columnName="lastAccess" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Last access
              </SortableTh>
              <SortableTh columnName="userStatus" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Status
              </SortableTh>
              <th>Invitation link</th>
              <th>Comments</th>
              <th>Logo</th>
            </tr>
          </thead>
          {isLoadingU || updateTrigger || userUpdateTrigger ? 
            <div className="admin-control-loading">
              <Spinner/>
            </div>
            : 
            <UsersTableBody tableData={userData} setUserUpdateTrigger={setUserUpdateTrigger}
            userToggle={userToggle}/>
          }
        </table>
      </div>
      <>
        { isUActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('disactivate')}>
                Disactivate
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('Active')}>
                Activate
              </div>
              <div className="admin-register-actions-option"
                onClick={() => sendInvitationUserHandler()}>
                Send invitation
              </div>
              <div className="admin-register-actions-option"
                onClick={() => uActionsHandler('archive')}>
                Archive
              </div>
          </div>
        }
      </>
    </div>
   );
}
 
export default UsersTable;
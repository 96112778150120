import axios from 'axios'

const API_URL = '/api/customerSpaces/'

// Create new customerSpace
const createCustomerSpace = async (customerSpaceData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, customerSpaceData, config)

  return response.data
}

// Get user customerSpaces
const getCustomerSpaces = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// change user document
const changeCustomerSpace = async (customerSpaceData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let customerSpaceBody = customerSpaceData
  let customerSpaceId = customerSpaceData._id;
  const response = await axios.post(API_URL + customerSpaceId, customerSpaceBody, config)

  return response.data
}
// update user document
const updateCustomerSpace = async (customerSpaceData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let customerSpaceBody = customerSpaceData
  let customerSpaceId = customerSpaceData._id;
  const response = await axios.put(API_URL + customerSpaceId, customerSpaceBody, config)

  return response.data
}

// Delete user customerSpace
const deleteCustomerSpace = async (customerSpaceId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + customerSpaceId, config)

  return response.data
}

const customerSpaceService = {
  createCustomerSpace,
  getCustomerSpaces,
  changeCustomerSpace,
  updateCustomerSpace,
  deleteCustomerSpace,
}

export default customerSpaceService
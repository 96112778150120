import { NavLink, useNavigate, useParams } from "react-router-dom";
import moment from 'moment'
import arrow from "../../icons/arrow-icon.svg"
import whip_in from "../../icons/zoom-in-icon.svg"
import whip_out from "../../icons/zoom-out-icon.svg"
import list_icon from "../../icons/list-header-icon.svg"
import save_icon from "../../icons/save-icon.svg"
import trash_icon from "../../icons/trash-icon.svg"
import logout_icon from '../../icons/logout-icon.svg'
import settings_icon from '../../icons/settings-icon.svg'
import library_icon from '../../icons/user-library-icon.svg'
import { useEffect, useState } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { logout, reset } from "../../features/auth/authSlice";
import checker from '../../icons/selected-checker-icon.svg'
import { updateStyle } from "../../features/styles/styleSlice";
import { updateStyleDocumentName } from "../../features/localDocument/editingStyleDocumentSlice";
import UserSideMenu from "../UserSideMenu";

const doc_time = {
  fontWeight: '400',
  fontSize: '10px',
  color: '#7A8199',
}

const StyleEditorHeader = ({ editingStyleDocument, documentData, 
  setSaveStyleForm, setDeleteForm, viewEditorMode, setViewEditorMode,
  setCloseDoc}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)

  const [isDown, setIsDown] = useState(false)
  const [isViewListDown, setIsViewListDown] = useState(false)
  const [documentName, setDocumentName] = useState('Default name')
	const [iframeButtonTitle, setIframeButtonTitle] = useState('U')
	const {id, stage} = useParams()
  const ref = useOnclickOutside(() => {
    isDown && setIsDown(prev => false);
  })
  const refView = useOnclickOutside(() => {
    setIsViewListDown(prev => false);
  })

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])
	
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  useEffect(() => {
    if(documentData) {
      setDocumentName(documentData.name)
    }
  }, [documentData])

  const refDocumentHeader = useOnclickOutside(() => {
    if(documentName !== editingStyleDocument.name && documentName !== undefined){
      const newDoc = {...editingStyleDocument, name: documentName}
      dispatch(updateStyle(newDoc))
      dispatch(updateStyleDocumentName([documentName]))
    }
  })

  return ( 
    <div className="new-editor-header">
      <div style={{display: 'flex', padding:'0px 12px'}}>
      <div className="nav-div"> 
        <NavLink to={"/s/"+id+'/design'}
          className={stage === 'design' ? "nav-link-s" : "nav-link"}>
          Style Editor
        </NavLink>
        {stage === 'design' ? <div className="editor-stage"/> : <></>}
      </div>
      </div>

      {documentData !== undefined ?
        <div style={{display: 'flex', flexFlow: 'column', 
        alignItems: 'center', justifyContent: 'center'}}>
          <input ref={refDocumentHeader} 
          className="header-document-name-input" type="text" 
          value={documentName}
          onChange={(e)=> setDocumentName(prev => e.target.value)}/>
          <div style={doc_time}>Last updated: {moment(documentData.date).format('MMM DD')}, {moment(documentData.date).format('h:mma')}</div>
        </div> 
        : <div style={{display: 'flex', flexFlow: 'column', 
        alignItems: 'center', justifyContent: 'center'}}>
           </div> 
      }

      <div className="editor-header-options">
        <div ref={ref} className="actions-droplist">
          <div className="actions-droplist-header" onClick={ () => setIsDown(prev => !isDown)}>
            Actions
            <img style={{marginLeft:'8px', transform:( isDown && 'rotate(-180deg)'), 
            transition:'260ms linear all'}} src={arrow} alt="arrow"/>
          </div>
          { isDown && 
          <div className="actions-droplist-body">
            <div style={{position:'relative'}}>
            {/* <div className="actions-droplist-option" onClick={()=>setSaveTrigger(prev => true)}>
              <img src={save_icon} alt='save'/>Save
            </div> */}
            <div className="actions-droplist-option" onClick={()=>setSaveStyleForm(prev => true)}>
              <img src={save_icon} alt='save'/>Save As New Style
            </div>
            <div className="actions-droplist-option" onClick={()=>setDeleteForm(prev=>true)}>
              <img src={trash_icon} alt='save'/>Delete
            </div>
            <div className="actions-droplist-option" onClick={()=>setCloseDoc(prev => true)}>
              Close Style
            </div>
            </div>
          </div> }
        </div>
        <hr className="editor-header-vertical-hr"/>
        <div className="editor-options-column">
          <div style={{display:'inline-flex', marginRight: '12px', borderRadius: '8px',boxShadow: '0px 1px 8px 1px rgba(0, 0, 0, 0.15)'}}>
            <div className="small-button-in">
              <img src={whip_in} alt="in"/>
            </div>
            <div className="small-button-out">
              <img src={whip_out} alt="out"/>
            </div>
          </div>
          <div ref={refView} className="view-droplist">
            <div className="small-button-list" onClick={ () => setIsViewListDown(prev => !isViewListDown)}>
              <img src={list_icon} alt="list"/>
            </div>
            { isViewListDown && 
            <div className="view-droplist-body">
              <div>
                <div className={viewEditorMode ? "view-droplist-option-selected" :"view-droplist-option"} 
                  onClick={() => setViewEditorMode(prev=> !viewEditorMode)}>
                  <img style={{visibility: !viewEditorMode && 'hidden'}} src={checker} alt='checked'/>
                  <div>View-editor mode</div>
                </div>
              </div>
            </div> }
          </div>
          <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/>
        </div>
      </div>
    </div>
   );
}
 
export default StyleEditorHeader;
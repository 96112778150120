import { useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import { changeComponentInLevel2, changeComponentInModule, changeComponentInLevel1, changeComponentInModuleColumn, changeComponentInLevel1Column, changeComponentInLevel2Column, setChosenComponent } from "../../../features/localDocument/editingDocumentSlice";
import { useDeleteComponent } from "./deleteComponentFromTemplate";

const heading_style = {
  overflow: 'hidden',
  position: 'relative',
}

const Heading = ({part, index, 
  parentData, columnData, parentType, 
  setStylingPanel, setStylingComponent,
  moveChildFromParent, changeChildFromParent }) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    positionOfParent: parentData,
    typeOfParent: parentType,
    columnData: columnData,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setStylingPanel
  });

  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }

  const [text, setText ] = useState(parentType === "module" ? "Module heading" : 'Heading')
  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...heading_style, ...part.style, width: '100%', height: '100%'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...heading_style, ...part.style, width: '100%', height: '100%'}))
  }, [ part.style])

  useEffect(() => {
    setText(prevText => part.content)
  }, [])

  const ref = useRef(null)  
  useEffect(() => {
    const onHeaderClick = (event) => {
      console.log('clicked on header')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([{...part, style: style, content: text}, parentData, parentType, columnData]);
      setStylingPanel(prev=> true)
    }
  
    const headerRef = ref.current;
    headerRef.addEventListener("click", onHeaderClick);
  
    return () => {
      headerRef.removeEventListener("click", onHeaderClick);
    };
  }, [])

  const [{ handlerId }, drop] = useDrop({
    accept: ['part'],
    collect(smonitor) {
      return {  
        handlerId: smonitor.getHandlerId(),
      }
    },
    drop( ){ 
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(columnData !== undefined && item.columnData !== undefined){
        if(columnData.column === item.columnData.column){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }else{
        if(item.part !== undefined && item.parentType === parentType){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType, columnData }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  return (
    <div className={chosenComponent.position === part.position ? "textarea-component-chosen-hover":"textarea-component-hover"} 
    style={{opacity}}
    onKeyDown={keyPressHandler}
    tabIndex="0">
      <TextareaAutosize className="textarea-component"
      ref={ref} data-handler-id={handlerId} 
      style={style} value={text}  
        onChange={(e) => {
          setText(prevText => e.target.value)
          if(columnData !== undefined){
            if(parentType === 'module') {
              dispatch(changeComponentInModuleColumn([e.target.value, columnData, part.position]))
            }
            if(parentType === 'level1'){
              dispatch(changeComponentInLevel1Column([e.target.value, columnData, part.position]))
            }
            if(parentType === 'level2'){
              dispatch(changeComponentInLevel2Column([e.target.value, columnData, part.position]))
            }
          }else{
            if(parentType === 'module') {
              dispatch(changeComponentInModule([e.target.value, parentData, part.position]))
            }
            if(parentType === 'level1'){
              dispatch(changeComponentInLevel1([e.target.value, parentData, part.position]))
            }
            if(parentType === 'level2'){
              dispatch(changeComponentInLevel2([e.target.value, parentData, part.position]))
            }
          }
        }
      }/>
    </div>
  )
}

export default Heading
import {  useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import Cookies from 'js-cookie' 
import { loginGoogle } from '../features/auth/authSlice'

const  GoogleAuthPage = () =>  {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )
  let userData

  useEffect(() => {
    userData = Cookies.get('userData')

  }, [ ])
  useEffect(() => {
    userData = JSON.parse(Cookies.get('userData'))
    console.log(userData) 
    console.log(userData._id)
    if (isError) {
      toast.error(message)
    }
    if(userData){
      dispatch(loginGoogle(userData))
    }

    if (isSuccess || user) {
      navigate('/welcome')
    }
  }, [user, isError, isSuccess, message, navigate, dispatch])

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='auth-background'>
      <Spinner />
    </div>
  )
}

export default GoogleAuthPage
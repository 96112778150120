import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { setStyleForModule, updateStyleForLevel1, 
  setStyleForPartInModule, setStyleForPartInLevel1, setStyleForPartInLevel2, 
  setStyleForPartInModuleColumn, setStyleForPartInLevel1Column, setStyleForPartInLevel2Column, 
  setStyleForModuleColumn, setStyleForLevel1Column, setStyleForLevel2Column} from '../../features/localDocument/editingDocumentSlice'
import { useDisplayChosenComponent } from "../../hooks/useDisplayChosenComponent";

const ClassesPanel = ({chosenComponent, classes}) => {

  const component = chosenComponent[0]
  const componentStyle = component.style
  const positionOfParent = chosenComponent[1]
  const typeOfParent = chosenComponent[2]
  const columnData = chosenComponent[3]
  
  const dispatch = useDispatch()

  const [componentClasses, setComponentClasses] = useState([])
  const [className,setClassName] = useState('')

  useEffect(() => {
    if(typeOfParent === undefined){
      setClassName(Object.keys(classes).filter(cls => cls === component.typeOfContent)[0])
      if(Object.keys(classes).filter(cls => cls === component.typeOfContent)[0]){
        setClassName(prev => {
          let words = prev.split('_').map(word => word.toLowerCase());
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
          return words.join(' ');
        });
      }
      setComponentClasses(prev => classes[Object.keys(classes).filter(cls => cls === component.typeOfContent)[0]])
    }else{
      setClassName(Object.keys(classes).filter(cls => cls === typeOfParent+'_'+component.typeOfContent)[0])
      if(Object.keys(classes).filter(cls => cls === typeOfParent+'_'+component.typeOfContent)[0]){
        setClassName(prev => {
          let words = prev.split('_').map(word => word.toLowerCase());
          words[0] = words[0].charAt(0).toUpperCase() + words[0].slice(1);
          return words.join(' ');
        });
      }
      setComponentClasses(prev => classes[Object.keys(classes).filter(cls => cls === typeOfParent+'_'+component.typeOfContent)[0]])
    }
  }, [chosenComponent])

  const [chosenClass, setChosenClass] = useState(0)

  const changeClassHandler = (option) => {
    setChosenClass(prev => option)
    const style = {...option.class, isUnicStyle: true}
    component.typeOfContent === 'module' ?  dispatch(setStyleForModule([positionOfParent, style]))
    :
    component.typeOfContent === 'level1' ? dispatch(updateStyleForLevel1([positionOfParent, chosenClass.class, component.position]))
    :
    typeOfParent === 'module' && columnData === undefined ? 
    dispatch(setStyleForPartInModule([positionOfParent, component.position, style, true]))
    :
    typeOfParent === 'module' && (component.typeOfContent === 'column' || component.typeOfContent === 'dynamic_part') ? 
    dispatch(setStyleForModuleColumn([columnData, style]))
    :
    typeOfParent === 'module' && columnData !== undefined && component.typeOfContent !== 'column' ? 
    dispatch(setStyleForPartInModuleColumn([columnData, component.position, style]))
    :
    typeOfParent === 'level1' && columnData === undefined ?
    dispatch(setStyleForPartInLevel1([positionOfParent, component.position, style]))
    :
    typeOfParent === 'level1' && component.typeOfContent === 'column' ? 
    dispatch(setStyleForLevel1Column([columnData, style]))
    :
    typeOfParent === 'level1' && columnData !== undefined && component.typeOfContent !== 'column' ?
    dispatch(setStyleForPartInLevel1Column([columnData, component.position, style]))
    :
    typeOfParent === 'level2' && columnData === undefined ?
    dispatch(setStyleForPartInLevel2([positionOfParent, component.position, style]))
    :
    typeOfParent === 'level2' && component.typeOfContent === 'column' ? 
    dispatch(setStyleForLevel2Column([columnData, style]))
    :
    typeOfParent === 'level2' && columnData !== undefined && component.typeOfContent !== 'column' ?
    dispatch(setStyleForPartInLevel2Column([columnData, component.position, style]))
    :
    console.log('') 
  }


  return ( 
    <div className="side-panel-classes">
    {componentClasses && componentClasses.length !== 0 ?
      componentClasses.map((option, index) => (
        <div className="classes-option" key={index}>
          <div className={chosenClass === option ? "classes-option-tittle-chosen": "classes-option-tittle"}>
            { className+' '+index }
          </div>
          <div className={chosenClass === option ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => changeClassHandler(option)}>
            {chosenClass === option ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div> 
      ))
      :
      <div className="styling-panel-development-message">
        There are no classes for this component
      </div> 
    }
    </div>
  );
}
 
export default ClassesPanel;
    
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import customerSpaceService from './customerSpaceService'

const initialState = {
  customerSpaces: [],
  isErrorCS: false,
  isSuccessCS: false,
  isLoadingCS: false,
  messageCS: '',
}

// Create new customerSpace
export const createCustomerSpace = createAsyncThunk(
  'customerSpaces/create',
  async (customerSpaceData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await customerSpaceService.createCustomerSpace(customerSpaceData, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Get user customerSpaces
export const getCustomerSpaces = createAsyncThunk(
  'customerSpaces/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await customerSpaceService.getCustomerSpaces(token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Change customerSpace
export const changeCustomerSpace = createAsyncThunk(
  'customerSpaces/change',
  async (customerSpace,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await customerSpaceService.changeCustomerSpace(customerSpace,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Update customerSpace
export const updateCustomerSpace = createAsyncThunk(
  'customerSpaces/update',
  async (customerSpace,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await customerSpaceService.updateCustomerSpace(customerSpace,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Delete user customerSpace
export const deleteCustomerSpace = createAsyncThunk(
  'customerSpaces/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await customerSpaceService.deleteCustomerSpace(id, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

export const customerSpaceSlice = createSlice({
  name: 'customerSpace',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createCustomerSpace.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(createCustomerSpace.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.customerSpaces.push(action.payload)
      })
      .addCase(createCustomerSpace.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(getCustomerSpaces.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(getCustomerSpaces.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.customerSpaces = action.payload
      })
      .addCase(getCustomerSpaces.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(changeCustomerSpace.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(changeCustomerSpace.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        let indexOfCustomerSpace = state.customerSpaces.findIndex((element) => element._id === action.payload.customerSpaceId)
        state.customerSpaces[indexOfCustomerSpace] = action.payload.customerSpaceBody
      })
      .addCase(changeCustomerSpace.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(updateCustomerSpace.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(updateCustomerSpace.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        let indexOfCustomerSpace = state.customerSpaces.findIndex((element) => element._id === action.payload.customerSpaceId)
        state.customerSpaces[indexOfCustomerSpace] = action.payload.customerSpaceBody
      })
      .addCase(updateCustomerSpace.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
      .addCase(deleteCustomerSpace.pending, (state) => {
        state.isLoadingCS = true
      })
      .addCase(deleteCustomerSpace.fulfilled, (state, action) => {
        state.isLoadingCS = false
        state.isSuccessCS = true
        state.customerSpaces = state.customerSpaces.filter(
          (customerSpace) => customerSpace._id !== action.payload.id
        )
      })
      .addCase(deleteCustomerSpace.rejected, (state, action) => {
        state.isLoadingCS = false
        state.isErrorCS = true
        state.messageCS = action.payload
      })
  },
})

export const { reset } = customerSpaceSlice.actions
export default customerSpaceSlice.reducer
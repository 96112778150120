import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  editingStyleDocument: [],
  chosenComponent:{},
  styles: {
    style_module: {
      display:'flex',
      backgroundColor: "#FFFFFF",
      color: "#000", fontSize: "14px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      alignItems: "center",
      padding: '20px 10px',
      gap: '8px',
      outline:'1px solid rgb(229, 225, 225)',
      borderRadius:'8px',
      isUnicStyle: false,
    },
    style_level_holder: {
      backgroundColor: "#FFFFFF",
      color: "#000", fontSize: "14px",
      width: '300px', height:'120px',
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      alignItems: "center",
      padding: '20px 10px',
      borderRadius:'8px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      isUnicStyle: false,
    },
    style_heading: {
      color: "#4A9DAC", fontSize: "22px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: "center",
      padding: '8px',
      isUnicStyle: false,
    },
    style_subheading: {
      color: "#4A9DAC", fontSize: "16px",
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: "center",
      padding: '8px',
      isUnicStyle: false,
    },
    style_text_field: {
      color: '#000', fontSize: '14px',
      fontStyle: "normal", fontFamily: 'Montserrat',
      fontWeight: "normal",
      textAlign: 'center',
      padding: '8px',
      isUnicStyle:false,
    },
    style_line: { 
      height: '0px', border: '1px solid #000',
      applied: false, isUnicStyle:false,
    },
    style_level1: {
      display:'flex', padding: '20px 16px',
      gap:'8px', backgroundColor: "#FFFFFF",
      width: '300px', height:'120px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_level2: {
      display:'flex', padding: '20px 16px',
      gap:'8px', backgroundColor: "#FFFFFF",
      width: '250px', minHeight:'70px', height:'fit-content',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_level3: {
      display:'flex', padding: '12px',
      gap:'8px', backgroundColor: "#FFFFFF",
      width: '140px', minHeight:'60px', height:'fit-content',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_holder: {
      display:'flex', padding: '20px 16px',
      outlineWidth:'1px', outlineOffset:'-1px', outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      borderRadius: '8px', alignItems:'center',
      flexDirection:'column', isUnicStyle:false,
      position: 'relative',
      overflow: 'hidden',
    },
    style_columns_holder: {
      display:'flex', gap: '24px',
      width:'100%', borderRadius: '8px',
      justifyContent:'',
      fontSize: '14px', textAlign: 'center',
      border: '0px solid #000',
      isUnicStyle:false,
    },
    style_column:{
      display:'flex', flexDirection:'column', 
      gap: '12px', borderRadius: '8px',
      fontSize: '14px', textAlign: 'center',
      border: '0px solid #000',
      isUnicStyle:false,
    },
    style_dynamic_block: {
      display: 'grid',
      gridTemplateColumns: '1fr 10px 1fr', 
      width: '300px', height:'120px',
      outlineWidth:'1px',
      outlineOffset:'-1px',
      outlineStyle: 'solid',
      outlineColor: 'rgba(229,225,225,255)',
      position: 'relative',
      isUnicStyle:false,
    },
    style_dynamic_part: {
      isUnicStyle:false,
    },
    style_image_holder: {
      width: 70, height:70, 
      isUnicStyle:false,
    },
  }
}

export const editingStyleDocumentSlice = createSlice({
  name: 'editingStyleDocument',
  initialState,
  reducers: {
    setEditingStyleDoc: (state, action) => {
      const doc = action.payload
      state.editingStyleDocument = doc
    },
    addActiveStyleDocument: (state, action) => {
      const doc = action.payload[0]
      const type = action.payload[1]
      let newDocument = {}
      if(!sessionStorage.getItem('active'+doc._id)){
        newDocument = {name: state.editingStyleDocument.name , id: state.editingStyleDocument._id, type: type}
        sessionStorage.setItem('active'+doc._id, JSON.stringify(newDocument))
        console.log('new active doc added..')
      }
    },
    removeActiveStyleDocument: (state, action) => {
      const doc = action.payload
      console.log('removed  ')
      sessionStorage.removeItem('active'+doc._id)
    },
    setActiveStyle: (state, action) => {
      const new_style = action.payload[0]
      const part = action.payload[1]
      state.editingStyleDocument.body[part] = new_style
    },
    setChosenComponent: (state, action) => {
      state.chosenComponent = action.payload[0]
    },
    addModuleStyleDoc: (state, action) => {
      const numOfModule = state.editingStyleDocument.body.filter(part => part.type === "module").length +1;
      if(state.editingStyleDocument.body.length !== 0 ){
        let lastIndex = state.editingStyleDocument.body[0].id
        state.editingStyleDocument.body.forEach(module=>{
          if(module.id>lastIndex){lastIndex=module.id}
        })
        lastIndex+=1
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: 'Module '+numOfModule,
          type:'module',
          style: state.styles.style_module,
          content: [],
        };
        state.editingStyleDocument.body = [...state.editingStyleDocument.body, newModule];
        if(state.editingStyleDocument.classes[newModule.type]){
          state.editingStyleDocument.classes[newModule.type].push({class: newModule.style, position:newModule.id}) 
        } else{
          state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
            [newModule.type] : [{class: newModule.style, position:newModule.id}] }; 
        }
        newModule = {}
      }else{
        let lastIndex = 0
        let newModule = {
          id: lastIndex,
          index: lastIndex,
          name: 'Module '+numOfModule,
          type:'module',
          style: state.styles.style_module,
          content: [],
        };
        state.editingStyleDocument.body = [...state.editingStyleDocument.body, newModule];
        if(state.editingStyleDocument.classes[newModule.type]){
          state.editingStyleDocument.classes[newModule.type].push({class: newModule.style, position:newModule.id}) 
        } else{
          state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
            [newModule.type] : [{class: newModule.style, position:newModule.id}] }; 
        }
        newModule = {}
      } 
    },
    addComponentStyleDoc: (state, action) => {
      const newComponentType = action.payload
      let newComponent
      if(newComponentType === 'module'){
        const numOfModule = state.editingStyleDocument.body.filter(part => part.type === "module").length +1;
        if(state.editingStyleDocument.body.length !== 0 ){
          let lastIndex = state.editingStyleDocument.body[0].id
          state.editingStyleDocument.body.forEach(module=>{
            if(module.id>lastIndex){lastIndex=module.id}
          })
          lastIndex+=1
          let newModule = {
            id: lastIndex,
            index: lastIndex,
            name: 'Module '+numOfModule,
            type:'module',
            style: state.styles.style_module,
            content: [],
          };
          state.editingStyleDocument.body = [...state.editingStyleDocument.body, newModule];
          if(state.editingStyleDocument.classes[newModule.type]){
            state.editingStyleDocument.classes[newModule.type].push({class: newModule.style, position:newModule.id}) 
          } else{
            state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
              [newModule.type] : [{class: newModule.style, position:newModule.id}] }; 
          }
          newModule = {}
        }else{
          let lastIndex = 0
          let newModule = {
            id: lastIndex,
            index: lastIndex,
            name: 'Module '+numOfModule,
            type:'module',
            style: state.styles.style_module,
            content: [],
          };
          state.editingStyleDocument.body = [...state.editingStyleDocument.body, newModule];
          if(state.editingStyleDocument.classes[newModule.type]){
            state.editingStyleDocument.classes[newModule.type].push({class: newModule.style, position:newModule.id}) 
          } else{
            state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
              [newModule.type] : [{class: newModule.style, position:newModule.id}] }; 
          }
          newModule = {}
        } 
      }
      if(newComponentType === 'level'){
        //const numOfLevel = state.editingStyleDocument.body.filter(part => part.type === "level").length +1; 
        newComponent = {
          name: 'Level 1',//+numOfLevel,
          type: newComponentType+'1',
          style: state.styles.style_holder,
          content: [],
          position: Math.round(Math.random()*1000)
        }
        state.editingStyleDocument.body = [...state.editingStyleDocument.body, newComponent];
        state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
          based:'level1', level1:newComponent.style };
      }
    },
    addComponentInModuleStyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === moduleId)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const componentType = newComponentType === 'level' ? 'level1': newComponentType
      const parentStyle = state.editingStyleDocument.body[indexOfModule].style
    
      let componentStyle = state.styles["style_"+ componentType]
      let valueOfNewComponent = ''
      if(componentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => 
          col = {type: 'column', 
          style: state.styles.style_column,
          parent: positionOfNewComponent,
          name: componentType, 
          origin:'root',
          content:[], 
          position: Math.round(Math.random()*1000)}
        )
      } else{
        valueOfNewComponent = componentType === 'level1' ? []
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : componentType === 'dynamic_block' ? 
        Array.from({length: 2}).map(part => 
          part = {
            type: 'dynamic_part', 
            style: state.styles.style_dynamic_part,
            parent: positionOfNewComponent,
            origin:'root',
            content:[], 
            position: Math.round(Math.random()*1000)}
        )
        : ' ' }
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: componentType,
        type: componentType, 
        style: componentStyle,
        parent: moduleId,
        origin:'root', 
        content: valueOfNewComponent,
        class:'module_'+componentType+'_'+positionOfNewComponent,
        position: positionOfNewComponent,
      }
      state.editingStyleDocument.body[indexOfModule].content.push(newComponent)
      if(state.editingStyleDocument.classes['module_'+newComponent.type]){
        state.editingStyleDocument.classes['module_'+newComponent.type].push({class: newComponent.style, position: newComponent.position}) 
      } else{
        state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
          ['module_'+newComponent.type] : [{class: newComponent.style, position: newComponent.position}] }; 
      }
    },
    addComponentInLevel1StyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const level1Position = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === level1Position))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === level1Position)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const componentType = newComponentType === 'level' ? 'level2': newComponentType
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].style
      
      
      let componentStyle = state.styles["style_"+ componentType]
      let valueOfNewComponent = ''
      if(componentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => 
          col = {type: 'column', 
          style: state.styles.style_column,
          parent: positionOfNewComponent,
          origin:'root', 
          content:[],
          position: Math.round(Math.random()*1000)}
        )
      } else{
        valueOfNewComponent = componentType === 'level2' ? []
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' '}
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: componentType,
        type: componentType, 
        style: componentStyle,
        parent: level1Position, 
        origin:'root', 
        content: valueOfNewComponent,
        class:'level1_'+componentType+'_'+positionOfNewComponent,
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.push(newComponent)
      if(state.editingStyleDocument.classes['level1_'+newComponent.type]){
        state.editingStyleDocument.classes['level1_'+newComponent.type].push({class: newComponent.style, position: newComponent.position}) 
      } else{
        state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
          ['level1_'+newComponent.type] : [{class: newComponent.style, position: newComponent.position}] }; 
      }
    },
    addComponentInLevel2StyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const level2Position = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(level1 => 
          level1.type === 'level1' && 
          -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
        ))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(level1 => 
        level1.type === 'level1' && 
        -1 !== level1.content.findIndex(level2 => level2.position === level2Position)
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const componentType = newComponentType === 'level' ? 'level3': newComponentType
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style
      
      let componentStyle = state.styles["style_"+ componentType]
      let valueOfNewComponent = ''
      if(componentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => 
          col = {type: 'column', 
          style: state.styles.style_column,
          parent: positionOfNewComponent,
          origin:'root', 
          content:[],
          position: Math.round(Math.random()*1000)}
        )
      } else{
        valueOfNewComponent = componentType === 'level3' ? []
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' ' }
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: componentType,
        type: componentType, 
        style: componentStyle,
        parent: level2Position, 
        content: valueOfNewComponent,
        class:'level2_'+componentType+'_'+positionOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.push(newComponent)
      if(state.editingStyleDocument.classes['level2_'+newComponent.type]){
        state.editingStyleDocument.classes['level2_'+newComponent.type].push({class: newComponent.style, position: newComponent.position}) 
      } else{
        state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
          ['level2_'+newComponent.type] : [{class: newComponent.style, position: newComponent.position}] }; 
      }
    },
    addComponentInLevel3StyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const level3Position = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level1 => level1.type === 'level1' && 
        -1 !== level1.content.findIndex(
          level2 => level2.type === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position)))
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.type === 'level2' && 
          -1 !== level2.content.findIndex(level3 => level3.position === level3Position))
      const indexLevel3 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        child => child.position === level3Position)
      const componentType = newComponentType === 'level' ? 'level4': newComponentType
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexLevel3].style

      let componentStyle = state.styles["style_"+ componentType]
      let valueOfNewComponent = ''
      if(componentType ==='columns_holder'){
        componentStyle = {...componentStyle, ...newComponentData.style}
        valueOfNewComponent = Array.from({length: newComponentData.num}).map(col => 
          col = {type: 'column', 
          style: state.styles.style_column,
          parent: positionOfNewComponent,
          origin:'root', 
          content:[],
          position: Math.round(Math.random()*1000)}
        )
      } else{
        valueOfNewComponent = componentType === 'level4' ? []
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' '}
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        name: componentType,
        type: componentType, 
        style: componentStyle,
        parent: level3Position, 
        content: valueOfNewComponent,
        class:'level3_'+componentType+'_'+positionOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexLevel3].content.push(newComponent)
      state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
        ['level3_'+newComponent.type+'_'+positionOfNewComponent] : newComponent.style };
    },
    addComponentInModuleColumnStyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const holderPosition = action.payload[2]
      const columnPosition = action.payload[3]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === moduleId)
      const indexOfHolder = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === holderPosition)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition)
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].style

      const componentType = newComponentType === 'level' ? 'level1': newComponentType
      const valueOfNewComponent = componentType === 'level1' ? {content:[], name:'Level 1'}
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' '
      let componentStyle = state.styles["style_"+ componentType]
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        type: componentType, 
        style: componentStyle,
        parent: columnPosition, 
        content: valueOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
        ['module_columns_holder_'+newComponent.type+'_'+positionOfNewComponent] : newComponent.style };
    },
    addComponentInLevel1ColumnStyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const holderData = action.payload[2]
      const parentPosition = holderData.parent
      const holderPosition = holderData.holder
      const columnPosition = action.payload[3]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex( part => part.position === parentPosition)
      const indexOfHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        part => part.position === holderPosition)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].style

      const componentType = newComponentType === 'level' ? 'level2': newComponentType
      const valueOfNewComponent = componentType === 'level1' ? {content:[], name:'Level 1'}
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' '
      let componentStyle = state.styles["style_"+ componentType]
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        type: componentType, 
        style: componentStyle,
        parent: columnPosition, 
        content: valueOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
        ['level1_columns_holder_'+newComponent.type+'_'+positionOfNewComponent] : newComponent.style };
    },
    addComponentInLevel2ColumnStyleDoc: (state, action) => {
      const newComponentData = action.payload[0]
      const newComponentType = newComponentData.type
      const moduleId = action.payload[1]
      const level2Position = action.payload[2]
      const holderPosition = action.payload[3]
      const columnPosition = action.payload[4]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === moduleId)
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex( level1 =>
        level1.type === 'level1' && 
        level1.content && -1 !== level1.content.findIndex(part => part.position === level2Position)
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( child => child.position === level2Position)
      const indexOfHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === holderPosition
      )
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content.findIndex(
        column => column.position === columnPosition
      )
      
      const componentType = newComponentType === 'level' ? 'level3': newComponentType
      const positionOfNewComponent = Math.round(Math.random()*1000)
      const parentStyle = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].style

      const valueOfNewComponent = componentType === 'level1' ? {content:[], name:'Level 1'}
        : componentType === 'heading' ? 'Heading' 
        : componentType === 'subheading' ? 'Subheading'
        : ' '
      let componentStyle = state.styles["style_"+ componentType]
      if(parentStyle.isUnicStyle){
        componentStyle = {
          ...componentStyle, 
          color: parentStyle.color, 
          backgroundColor: parentStyle.backgroundColor,
          textAlign: parentStyle.textAlign,
        }
      }
      const newComponent = {
        type: componentType, 
        style: componentStyle,
        parent: columnPosition, 
        content: valueOfNewComponent,
        origin:'root', 
        position: positionOfNewComponent
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfHolder].content[indexOfColumn].content.push(newComponent)
      state.editingStyleDocument.classes = {...state.editingStyleDocument.classes, 
        ['level2_columns_holder_'+newComponent.type+'_'+positionOfNewComponent] : newComponent.style };
    },
    updatingComponentsOrderInStyleModule: (state, action) =>{
      const sdragI = action.payload[0]
      const shoverI = action.payload[1]
      const dropParentId = action.payload[2]
      const dragParentId = action.payload[3].parent

      if(dragParentId !== dropParentId){
        const emptyComponent = {
          value:{ },
          type:'empty',
          position: 0,
        }
        console.log('Adding empty...')
        const indexOfDropModule = state.editingStyleDocument.body.findIndex((module) => module.id === dropParentId)
        // deleting empties in other modules
        state.editingStyleDocument.body.forEach(module => {
          if(module.id !== dropParentId){
            module.content = module.content.filter(part => 
              part.position !== 0)
          }
        })
        let newContent = state.editingStyleDocument.body[indexOfDropModule].content; 
        const emptyIndex = newContent.findIndex(part => part.position === 0)
        if( emptyIndex === -1){ //didnt find
          newContent.splice(shoverI, 0, emptyComponent);
          state.editingStyleDocument.body[indexOfDropModule].content = newContent 
        } else{ //finded
          newContent.splice(emptyIndex, 1)
          newContent.splice(shoverI, 0, emptyComponent);
          state.editingStyleDocument.body[indexOfDropModule].content = newContent 
        }
      } else {
        const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === dropParentId)
        let newContent = state.editingStyleDocument.body[indexOfModule].content; 
        const dragCard = newContent[sdragI]; 
        newContent.splice(sdragI, 1);
        newContent.splice(shoverI, 0, dragCard);
        state.editingStyleDocument.body[indexOfModule].content = newContent 
      }
    },
    updateSizeForLevel1StyleDoc: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level1Position = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === idOfModule)
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(moduleContent => moduleContent.position === level1Position)

      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].style, ...newStyleProperties}
      console.log('this style will be ', newStyle)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].style = newStyle
      const classIndex = state.editingStyleDocument.classes.module_level1.findIndex(part => part.position === level1Position)
      state.editingStyleDocument.classes.module_level1[classIndex].class = newStyle
    },
    setStyleForModuleStyleDoc: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module=> module.id === idOfModule)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].style, ...newStyleProperties}

      state.editingStyleDocument.body[indexOfModule].style = newStyle
      const classIndex = state.editingStyleDocument.classes.module.findIndex(part => part.position === idOfModule)
      state.editingStyleDocument.classes.module[classIndex].class = newStyle

      //setting style for all unset parts in module
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content = state.editingStyleDocument.body[indexOfModule].content.map((part, index) => {
        if(part.type ==='level1' && !part.style.isUnicStyle){
          state.editingStyleDocument.body[indexOfModule].content[index].content = state.editingStyleDocument.body[indexOfModule].content[index].content.map((part, index1) => {   
            if(part.type ==='level2' && !part.style.isUnicStyle){
              state.editingStyleDocument.body[indexOfModule].content[index].content[index1].content = state.editingStyleDocument.body[indexOfModule].content[index].content[index1].content.map((part, index) => {
                if(!part.style.isUnicStyle){
                  return{...part, style: {...part.style, ...childsStyle}}
                } else {return part}
              })   
            }
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    updateStyleForLevel1StyleDoc: (state, action) => {
      const idOfModule = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level1Position = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === idOfModule)
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(moduleContent => moduleContent.position === level1Position)

      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].style, ...newStyleProperties}
      console.log('this style will be ', newStyle)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].style = newStyle
      const classIndex = state.editingStyleDocument.classes.module_level1.findIndex(part => part.position === level1Position)
      state.editingStyleDocument.classes.module_level1[classIndex].class = newStyle

      //setting style for all unset parts in level
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.map((part, index) => {
        if(part.type ==='level2' && !part.style.isUnicStyle){
          state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[index].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[index].content.map((part, index1) => {   
            if(part.type ==='level3' && !part.style.isUnicStyle){
              state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[index].content[index1].content.map((part, index) => {
                if(!part.style.isUnicStyle){
                  return{...part, style: {...part.style, ...childsStyle}}
                } else {return part}
              })   
            }
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForLevel2StyleDoc: (state, action) => {
      const positionOfParent = action.payload[0]
      const newStyleProperties = action.payload[1]
      const level2Position = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.position === positionOfParent
        ))
      )
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level => level.position === positionOfParent
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === level2Position
      )
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].style = newStyle
      const classIndex = state.editingStyleDocument.classes.level1_level2.findIndex(part => part.position === level2Position)
      state.editingStyleDocument.classes.level1_level2[classIndex].class = newStyle

      //setting style for all unset parts in level
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.map((part, index) => {
        if(part.type ==='level3' && !part.style.isUnicStyle){
          state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[index].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[index].content.map((part, index1) => {
            if(!part.style.isUnicStyle){
              return{...part, style: {...part.style, ...childsStyle}}
            } else {return part}
          })
        }
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInModuleStyleDoc: (state, action) => {
      const idOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex( module => module.id === idOfParent)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfPart].style = newStyle;

      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['module_'+typeOfPart].findIndex(part => part.position === positionOfPart)
      state.editingStyleDocument.classes['module_'+typeOfPart][classIndex].class = newStyle
    },
    setStyleForModuleColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in module column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInModuleColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    setStyleForPartInLevel1StyleDoc: (state, action) => {
      const positionOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === positionOfParent)))
      const indexOfLevel = state.editingStyleDocument.body[indexOfModule].content.findIndex(level => level.position === positionOfParent)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === positionOfPart)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfPart].style = newStyle

      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['level1_'+typeOfPart].findIndex(part => part.position === positionOfPart)
      state.editingStyleDocument.classes['level1_'+typeOfPart][classIndex].class = newStyle
    },
    setStyleForLevel1ColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === parentColumnData.parent))
      )
      const indexOfLevel = state.editingStyleDocument.body[indexOfModule].content.findIndex(level => level.position === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in module column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        } else {return part}
      })
    },
    setStyleForPartInLevel1ColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== (module.content.findIndex(level => level.position === parentColumnData.parent))
      )
      const indexOfLevel = state.editingStyleDocument.body[indexOfModule].content.findIndex(level => level.position === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content.findIndex(part => part.position === positionOfPart)
      
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    setStyleForPartInLevel2StyleDoc: (state, action) => {
      const positionOfParent = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      console.log()
      const indexOfModule = state.editingStyleDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === positionOfParent)
        ))
      )
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === positionOfParent)
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === positionOfParent
      )
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === positionOfPart
      )
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].style = newStyle
      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['level2_'+typeOfPart].findIndex(part => part.position === positionOfPart)
      state.editingStyleDocument.classes['level2_'+typeOfPart][classIndex].class = newStyle
    },
    setStyleForLevel2ColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const newStyleProperties = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
        ))
      )
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === parentColumnData.parent
      )
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === parentColumnData.holder
      )
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(
        column => column.position === parentColumnData.column
      )
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].style = newStyle

      //setting style for all unset part in level2 column
      const childsStyle = {
        color: newStyle.color,
        backgroundColor: newStyle.backgroundColor,
        textAlign: newStyle.textAlign,
        fontStyle: newStyle.fontStyle,
      }
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.map((part, index) => {
        if(!part.style.isUnicStyle){
          return{...part, style: {...part.style, ...childsStyle}}
        }else{
          return part
        }
      })
    },
    setStyleForPartInLevel2ColumnStyleDoc: (state, action) => {
      const parentColumnData = action.payload[0]
      const positionOfPart = action.payload[1]
      const newStyleProperties = action.payload[2]
      const indexOfModule = state.editingStyleDocument.body.findIndex(
        module => -1 !== (module.content.findIndex(
          level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
        ))
      )
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level => level.type === 'level1' && -1 !== level.content.findIndex(level2 => level2.position === parentColumnData.parent)
      )
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level2 => level2.position === parentColumnData.parent
      )
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(
        part => part.position === parentColumnData.holder
      )
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(
        column => column.position === parentColumnData.column
      )
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.findIndex(
        part => part.position === positionOfPart
      )
      
      const newStyle = {...state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style, ...newStyleProperties}
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content[indexOfPart].style = newStyle
    },
    deleteModuleStyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      state.editingStyleDocument.body = state.editingStyleDocument.body.filter(part => part.id !== partPosition)
      
      const classIndex = state.editingStyleDocument.classes.module.findIndex(part => part.position === partPosition)
      state.editingStyleDocument.classes.module.splice(classIndex, 1)
    },
    deleteComponentInModuleStyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => module.id === parentPosition)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === partPosition)

      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['module_'+typeOfPart].findIndex(part => part.position === partPosition)
      state.editingStyleDocument.body[indexOfModule].content.splice(indexOfPart, 1)
      state.editingStyleDocument.classes['module_'+typeOfPart].splice(classIndex, 1)
    },
    deleteComponentInModuleColumnStyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex( module => module.id === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInLevel1StyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === parentPosition))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === parentPosition)
      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === partPosition)


      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['level1_'+typeOfPart].findIndex(part => part.position === partPosition)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.splice(indexOfPart, 1)
      state.editingStyleDocument.classes['level1_'+typeOfPart].splice(classIndex, 1)
    },
    deleteComponentInLevel1ColumnStyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex(part => part.position === parentColumnData.parent))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex(
        level1 => level1.position === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInLevel2StyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level => level.type === 'level1' &&
          -1 !== level.content.findIndex(level2 => level2.position === parentPosition)))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex( level => level.type === 'level1' &&
        -1 !== level.content.findIndex(level2 => level2.position === parentPosition))
      const indexOfLevel2 =  state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( level2 => level2.position === parentPosition)

      const indexOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => part.position === partPosition)


      const typeOfPart = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfPart].type 
      const classIndex = state.editingStyleDocument.classes['level2_'+typeOfPart].findIndex(part => part.position === partPosition)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.splice(indexOfPart, 1)
      state.editingStyleDocument.classes['level2_'+typeOfPart].splice(classIndex, 1)
    },
    deleteComponentInLevel2ColumnStyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentColumnData = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level => level.type === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === parentColumnData.parent)))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex( level => level.type === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === parentColumnData.parent))
      const indexOfLevel2 = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === parentColumnData.parent)
      const indexOfColHolder = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => part.position === parentColumnData.holder)
      const indexOfColumn = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(part => part.position === parentColumnData.column)
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.filter(part => part.position !== partPosition)
    },
    deleteComponentInLevel3StyleDoc: (state, action) => {
      const partPosition = action.payload[0]
      const parentPosition = action.payload[1]
      const indexOfModule = state.editingStyleDocument.body.findIndex(module => 
        -1 !== module.content.findIndex( level => level.type === 'level1' &&
          -1 !== level.content.findIndex(level2 => level2.position === parentPosition)))
      const indexOfLevel1 = state.editingStyleDocument.body[indexOfModule].content.findIndex( level => level.type === 'level1' &&
        -1 !== level.content.findIndex(level2 => level2.position === parentPosition))
      const indexOfLevel2 =  state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex( level2 => level2.position === parentPosition)
      const componentToDeleteClass = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.find(part => part.position === partPosition).class
      delete state.editingStyleDocument.classes[componentToDeleteClass];
      state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content = state.editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.filter(
        part => part.position !== partPosition)
    },
    updateStyleDocumentName: (state, action) => {
      const newName = action.payload[0]
      state.editingStyleDocument = {...state.editingStyleDocument, name: newName}
      console.log(state.editingStyleDocument.name)
      sessionStorage.removeItem('active'+ state.editingStyleDocument._id)
      const newDocument = {name: state.editingStyleDocument.name , id: state.editingStyleDocument._id, type: 'style'}
      sessionStorage.setItem('active'+state.editingStyleDocument._id, JSON.stringify(newDocument))
    },
  }
})  

export const { 
  addActiveStyleDocument,
  removeActiveStyleDocument,
  setEditingStyleDoc,
  setActiveStyle,
  setChosenComponent,
  addModuleStyleDoc,
  addComponentStyleDoc,
  updatingComponentsOrderInStyleModule,
  addComponentInModuleStyleDoc,
  addComponentInLevel1StyleDoc,
  addComponentInLevel2StyleDoc,
  addComponentInLevel3StyleDoc,
  addComponentInModuleColumnStyleDoc,
  addComponentInLevel1ColumnStyleDoc,
  addComponentInLevel2ColumnStyleDoc,
  setStyleForModuleStyleDoc,
  updateSizeForLevel1StyleDoc,
  updateStyleForLevel1StyleDoc,
  setStyleForLevel2StyleDoc,
  setStyleForPartInModuleStyleDoc,
  setStyleForModuleColumnStyleDoc,
  setStyleForPartInModuleColumnStyleDoc,
  setStyleForPartInLevel1StyleDoc,
  setStyleForLevel1ColumnStyleDoc,
  setStyleForPartInLevel1ColumnStyleDoc,
  setStyleForPartInLevel2StyleDoc,
  setStyleForLevel2ColumnStyleDoc,
  setStyleForPartInLevel2ColumnStyleDoc,
  deleteModuleStyleDoc,
  deleteComponentInModuleStyleDoc,
  deleteComponentInModuleColumnStyleDoc,
  deleteComponentInLevel1StyleDoc,
  deleteComponentInLevel1ColumnStyleDoc,
  deleteComponentInLevel2StyleDoc,
  deleteComponentInLevel2ColumnStyleDoc,
  updateStyleDocumentName,
  } = editingStyleDocumentSlice.actions
export default editingStyleDocumentSlice.reducer
import { NavLink, useNavigate } from "react-router-dom";
import {useSelector, useDispatch} from 'react-redux'
import arrow from "../../icons/arrow-icon.svg"
import whip_in from "../../icons/zoom-in-icon.svg"
import whip_out from "../../icons/zoom-out-icon.svg"
import list_icon from "../../icons/list-header-icon.svg"
import logout_icon from '../../icons/logout-icon.svg'
import settings_icon from '../../icons/settings-icon.svg'
import library_icon from '../../icons/user-library-icon.svg'
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useOnclickOutside from "react-cool-onclickoutside";
import { logout, reset } from "../../features/auth/authSlice";
import UserSideMenu from "../UserSideMenu";

const AnalyticsHeader = ({stage}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)

  const [isDown, setIsDown] = useState(false)
	const [iframeButtonTitle, setIframeButtonTitle] = useState('U')

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])
	
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }


	return (   
		<div className="launchpad-header">
			<div className="launchpad-header-stages">
				<div className="launch-div">
					<NavLink to="/analytics/portfolio"
						className={stage === 'portfolio' ? "launch-link-s" : "launch-link"}>
						My portfolio
					</NavLink>
				</div>
				<div className="launch-div"> 
					<NavLink to="/analytics"
						className={stage === 'compliance' ? "launch-link-s" : "launch-link"}>
						Compliance
					</NavLink>
				</div>
			</div>

			<div className="launchpad-header-options">
				<div className="editor-options-column">
					<div className="actions-droplist">
						<div className="actions-droplist-header" onClick={ () => setIsDown(prev => !isDown)}>
							Actions
							<img style={{marginLeft:'8px', transform:( isDown && 'rotate(-180deg)'), 
							transition:'260ms linear all'}} src={arrow} alt="arrow"/>
						</div>
						{ isDown && 
						<div className="actions-droplist-body">
							<div style={{position:'relative'}}>
								<div className="actions-droplist-option" >
									Action 1
								</div>
								<div className="actions-droplist-option" >
									Action 2
								</div>
								<div className="actions-droplist-option" >
									Action 3
								</div>
							</div>
						</div> }
					</div>
				</div>   
				<hr className="header-vertical-hr"/>
				<div className="editor-options-column">
					<div style={{display:'inline-flex', marginRight: '12px', borderRadius: '8px',boxShadow: '0px 1px 8px 1px rgba(0, 0, 0, 0.15)'}}>
						<div className="small-button-in">
							<img src={whip_in} alt="in"/>
						</div>
						<div className="small-button-out">
							<img src={whip_out} alt="out"/>
						</div>
					</div>
					<div className="small-button-list">
						<img src={list_icon} alt="list"/>
					</div>
          <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/> 
				</div>
			</div>
		</div>  
	)
}
 
export default AnalyticsHeader;
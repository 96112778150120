import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch, useSelector } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import UserSideMenu from "./UserSideMenu";


const LibraryHeader = ({chosenFilesType, setChosenFilesType, setIsLibraryOpen, isFromWave1}) => {

  const {user} = useSelector( (state) => state.auth)

  const dispatch = useDispatch()
	const navigate = useNavigate()
  const [iframeButtonTitle, setIframeButtonTitle] = useState('U')
  const [isIframeMenuOpen, setIsIframeMenuOpen] = useState(false)

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])
  

  
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  const goBackFunction = () =>{
    if(isFromWave1){
      setIsLibraryOpen(prev => false)
    } else{
      navigate('/launch/templates')
    }
  }

  const [isDown, setIsDown] = useState(false)
  const refActions = useOnclickOutside(() => {
    setIsDown(prev => false);
  })

  return ( 
    <div className="settings-header">
        <div className="launch-div">
          <div className="launch-link" onClick={goBackFunction}>
            Go back
          </div>
        </div>
      <div className="launchpad-header-stages">
        {/* <div className="launch-div">
          <div className={"settings-header-stage" + (chosenFilesType.name === 'Documents' ? ' chosen-header-stage' : '')}
          onClick={() => setChosenFilesType({name: 'Documents', type: 'document'})}>
            Documents
          </div>
        </div>
        <div className="launch-div">
          <div className={"settings-header-stage" + (chosenFilesType.name === 'Images' ? ' chosen-header-stage' : '')}
          onClick={() => setChosenFilesType({name: 'Images', type: 'image'})}>
            Images
          </div>
        </div> */}
      </div>
      <div className="editor-options-column">
        <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/> 
      </div>
    </div>
   )
}
 
export default LibraryHeader;
import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel3, addDesignItemInLevel3, setChosenComponent } from "../../../features/localDocument/editingDocumentSlice"
import Heading from "./Heading"
import SubHeading from "./SubHeading"
import TextArea from "./TextArea"
import Line from "./Line"
import ImageHolder from "./ImageHolder"
import Image from "./Image"
import { useDeleteComponent } from "./deleteComponentFromTemplate"

const level3_style = {
  minHeight:'40px',
  height:'fit-content',
  display:'flex',
  flexDirection:'column',
  position: 'relative',
  borderRadius: '8px',
}

const Level3 = ({part, index, moduleId, 
  columnData,  
  changeClickability,
  parentData, parentType, 
  setStylingPanel, setStylingComponent, 
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveChildFromParent}) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    positionOfParent: parentData,
    typeOfParent: parentType,
    columnData: columnData,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setStylingPanel
  });

  const [isResizingOver, setIsResizingOver] = useState(true)

  const changeLevelClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }
  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }
  
  const [style, setStyle] = useState({})
  useEffect(()=> {
    setStyle(prev => ({...level3_style, ...part.style}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...level3_style, ...part.style}))
  }, [part.style])

  const ref = useRef(null)
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'columns_holder'],
    collect(monitor) {
      return {  
        isOver: monitor.isOver(),
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        if(item.type !== undefined && item.parentType !== 'module' && item.parentType !== 'level1' && item.parentType !== 'level2' && item.columnData === undefined){
          if(item.type === 'design_item'){
            dispatch(addDesignItemInLevel3([ item, moduleId, part.position]))
          }else{
            dispatch(addComponentInLevel3([ item, moduleId, part.position]))   
          }
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined && item.parentType === parentType){
        console.log('changed  ')
        moveChildFromParent(dragIndex, hoverIndex, item.part)
        item.index = hoverIndex
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  // const moveChild = useCallback(
  //   (dragIndex, hoverIndex, child) => {
  //   dispatch(updatingComponentsOrder(
  //     [dragIndex, hoverIndex, part.position, child, moduleId ]))
  // }, []) //зробити dispatch updatingComponentOrderInLevel2

  const hoverRes = isOverCurrent 
  const renderTextArea = useCallback((child, index) => {
    return (
      <TextArea 
        key={child.position} part={child} index={index}
        parentData = {part.position} 
        parentType = {"level3"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {()=>{}}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <Heading 
        key={child.position} part = {child} index={index}
        parentData = {part.position} 
        parentType = {"level3"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {()=>{}}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <SubHeading 
        key={child.position} part = {child} index={index}
        parentData = {part.position}  
        parentType = {"level3"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {()=>{}}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <Line 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        parentType = {'level3'}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        //moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <ImageHolder
        key={child.position} part={child} index={index}
        changeClickability={changeLevelClickability}
        parentData = {part.position} 
        parentType = {"level3"}
        setImageForm={setImageForm}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent}  
        moveChildFromParent = {() => {}}
      />
    )
  }, [])
  const renderImage = useCallback((child, index) => {
    return (
      <Image
        key={child.position} part={child} index={index}
        changeClickability={changeLevelClickability}
        parentData = {part.position} 
        parentType = {"level3"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {() => {}}
      />
    )
  }, [])

  useEffect(() => {
    const onLevelClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, parentData, parentType, columnData]);
        setStylingPanel(prev=> true)
      }
    }

    const levelRef = ref.current;
    levelRef.addEventListener("click", onLevelClick);

    return () => {
      levelRef.removeEventListener("click", onLevelClick);
    };
  }, [isResizingOver])

  return (
  <div className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
  hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
  style={{width: style.width, height: style.height}}
  onKeyDown={keyPressHandler}
  tabIndex="0">
    <div ref={ref} data-handler-id={handlerId} 
    style={{...style,  height: '100%', width:'100%',
      opacity}} 
    >
      {part.content.length !== 0 ? 
        part.content.map( (child, index) => 
          child.typeOfContent === 'text_field' ?
          renderTextArea(child, index)
          : 
          child.typeOfContent === "heading" ? 
          renderHeading(child, index)
          : 
          child.typeOfContent === "subheading" ? 
          renderSubHeading(child, index)
          : 
          child.typeOfContent === "image_holder" ?
          renderImageHolder(child, index)
          : 
          child.typeOfContent === "image" ?
          renderImage(child, index)
          :
          child.typeOfContent === "line" ? 
          renderLine(child, index)
          : <></>
        )
      : <></>}
    </div>
  </div>
  )
}

export default Level3
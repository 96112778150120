import { useCallback, useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import Cropper from 'react-cropper';
import "cropperjs/dist/cropper.css";
import { Resizable } from "re-resizable";
import { mergeRefs } from "react-merge-refs";
import { useDispatch, useSelector } from "react-redux";
import {setStyleForPartInModule, setStyleForPartInLevel1, setStyleForPartInLevel2, 
  setStyleForPartInModuleColumn, setStyleForPartInLevel1Column, setStyleForPartInLevel2Column, setChosenComponent, setCroppingImage, cropImageInModule, resetImageInModule, cropImageInLevel1} from '../../../features/localDocument/editingDocumentSlice'
import ImageCropDialog from "../ImageCropDialog";
import ReactCrop, {makeAspectCrop, Crop, PixelCrop} from 'react-image-crop'
import { canvasPreview } from './canvasPreview'
import { useDebounceEffect } from './useDebounceEffect'
import 'react-image-crop/dist/ReactCrop.css'
import useOnclickOutside from "react-cool-onclickoutside";
import { useDeleteComponent } from "./deleteComponentFromTemplate";


const Image = ({part, index, 
  changeClickability,  parentSize,
  parentData, columnData, parentType,
  setStylingPanel, setStylingComponent, 
  moveChildFromParent }) => {

  const dispatch = useDispatch()
  const { chosenComponent, croppingImage } = useSelector(state => state.editingDocument)
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    positionOfParent: parentData,
    typeOfParent: parentType,
    columnData: columnData,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setStylingPanel
  });

  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }
  
  const [style, setStyle] = useState({})

  const refBox = useRef(null);
  const refLeft = useRef(null);
  const refTopLeft = useRef(null);
  const refTop = useRef(null);
  const refTopRight = useRef(null);
  const refRight = useRef(null);
  const refBottomRight = useRef(null);
  const refBottom = useRef(null);
  const refBottomLeft = useRef(null);
  const minWidth = 40
  const [maxWidth, setMaxWidth] = useState(500)
  const minHeight = 40
  const [maxHeight, setMaxHeight] = useState(500)

  useEffect(()=> {
    setStyle(prev => ({ ...part.style, position: 'relative',}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style, position: 'relative',}))
  }, [part.style])


  const updateImageStyle = (newStyle) => {
    parentType === 'module' && columnData === undefined ? 
    dispatch(setStyleForPartInModule([parentData, part.position, newStyle]))
    :
    parentType === 'module' && columnData !== undefined && part.typeOfContent !== 'column' ? 
    dispatch(setStyleForPartInModuleColumn([columnData, part.position, newStyle]))
    :
    parentType === 'level1' && columnData === undefined ?
    dispatch(setStyleForPartInLevel1([parentData, part.position, newStyle]))
    :
    parentType === 'level1' && columnData !== undefined && part.typeOfContent !== 'column' ?
    dispatch(setStyleForPartInLevel1Column([columnData, part.position, newStyle]))
    :
    parentType === 'level2' && columnData === undefined ?
    dispatch(setStyleForPartInLevel2([parentData, part.position, newStyle]))
    :
    parentType === 'level2' && columnData !== undefined && part.typeOfContent !== 'column' ?
    dispatch(setStyleForPartInLevel2Column([columnData, part.position, newStyle]))
    :
    console.log('error')
  }
  
  useEffect(() => {
    if(parentSize){
      setMaxWidth(prev => parentSize.width)
      setMaxHeight(prev => parentSize.height)
    }
  }, [updateImageStyle, dispatch, changeClickability])

  useEffect(() => {
    const resizableComponent = refBox.current;
    const size = {width: style.width ? parseInt(style.width) : resizableComponent.clientWidth, 
      height: style.height ? parseInt(style.height) : resizableComponent.clientHeight};
    let width = parseInt( size.width, 10);
    let height = parseInt( size.height, 10);
    let x = 0;
    let y = 0;

    // Top Left resize
    const onMouseMoveTopLeftResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      let proportionDifference = 0
      if(dx > 0 && dy < 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width - proportionDifference;
          height = height - proportionDifference;
        } else {
          proportionDifference = dy
          width = width + proportionDifference;
          height = height + proportionDifference;
        }
      }
      if(dx < 0 && dy < 0){
        console.log('yep we here')
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width - proportionDifference;
          height = height - proportionDifference;
        } else {
          proportionDifference = dy
          width = width - proportionDifference;
          height = height - proportionDifference;
        }
      }
      if(dx > 0 && dy > 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width - proportionDifference;
          height = height - proportionDifference;
        } else {
          proportionDifference = dy
          width = width - proportionDifference;
          height = height - proportionDifference;
        }
      }
      if(dx < 0 && dy > 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width - proportionDifference;
          height = height - proportionDifference;
        } else {
          proportionDifference = dy
          width = width + proportionDifference;
          height = height + proportionDifference;
        }
      }
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px` && setStyle(prev => ({...prev, height: height, width: width}))
    };

    const onMouseUpTopLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopLeftResize);
      document.removeEventListener("mouseup", onMouseUpTopLeftResize);
    };
    const onMouseDownTopLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = size.right;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveTopLeftResize);
      document.addEventListener("mouseup", onMouseUpTopLeftResize);
    };
    // Top resize
    const onMouseMoveTopResize = (event) => {
      const dy = event.clientY - y;
      y = event.clientY;
      height = height - dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };
    const onMouseUpTopResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopResize);
      document.removeEventListener("mouseup", onMouseUpTopResize);
    };
    const onMouseDownTopResize = (event) => {
      changeClickability()

      y = event.clientY;
      const  size = window.getComputedStyle(resizableComponent);
      resizableComponent.style.bottom =  size.bottom;
      resizableComponent.style.top = null;
      document.addEventListener("mousemove", onMouseMoveTopResize);
      document.addEventListener("mouseup", onMouseUpTopResize);
    };

    // Top Right resize
    const onMouseMoveTopRightResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      let proportionDifference = 0
      if((dx > 0 && dy < 0) || (dx < 0 && dy > 0)){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width + proportionDifference;
          height = height + proportionDifference;
        } else {
          proportionDifference = dy
          width = width - proportionDifference;
          height = height - proportionDifference;
        }
      }
      if(dx < 0 && dy < 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width + proportionDifference;
          height = height + proportionDifference;
        } else {
          proportionDifference = dy
          width = width + proportionDifference;
          height = height + proportionDifference;
        }
      }
      if(dx > 0 && dy > 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width + proportionDifference;
          height = height + proportionDifference;
        } else {
          proportionDifference = dy
          width = width - proportionDifference;
          height = height - proportionDifference;
        }
      }
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px` && setStyle(prev => ({...prev, height: height, width: width}))
    };

    const onMouseUpTopRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopRightResize);
      document.removeEventListener("mouseup", onMouseUpTopRightResize);
    };
    const onMouseDownTopRightResize = (event) => {
      changeClickability()
      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  size.left;
      resizableComponent.style.right = null;
      document.addEventListener("mousemove", onMouseMoveTopRightResize);
      document.addEventListener("mouseup", onMouseUpTopRightResize);
    };

    // Right resize
    const onMouseMoveRightResize = (event) => {
      console.log('trigger ')
      const dx = event.clientX - x;
      x = event.clientX;
      width = width + dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
    };

    const onMouseUpRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.width !== style.width){
        console.log('will be dispatch right ')
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveRightResize);
      document.removeEventListener("mouseup", onMouseUpRightResize);
    };
    const onMouseDownRightResize = (event) => {
      changeClickability()
        
      x = event.clientX;
      resizableComponent.style.left =  size.left;
      resizableComponent.style.right = null;
      document.addEventListener("mousemove", onMouseMoveRightResize);
      document.addEventListener("mouseup", onMouseUpRightResize);
    };
    // Bottom Right resize
    const onMouseMoveBottomRightResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      let proportionDifference = 0
      if(Math.abs(dx) >= Math.abs(dy)){
        proportionDifference = dx
      } else {
        proportionDifference = dy
      }
      width = width + proportionDifference;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height = height + proportionDifference;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px` && setStyle(prev => ({...prev, height: height, width: width}))
    };

    const onMouseUpBottomRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomRightResize);
      document.removeEventListener("mouseup", onMouseUpBottomRightResize);
    };
    const onMouseDownBottomRightResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = null;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomRightResize);
      document.addEventListener("mouseup", onMouseUpBottomRightResize);
    };

    // Bottom resize
    const onMouseMoveBottomResize = (event) => {
      const dy = event.clientY - y;
      y = event.clientY;
      height = height + dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };
    const onMouseUpBottomResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomResize);
      document.removeEventListener("mouseup", onMouseUpBottomResize);
    };
    const onMouseDownBottomResize = (event) => {
      changeClickability()
      
      y = event.clientY;
      const  size = window.getComputedStyle(resizableComponent);
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomResize);
      document.addEventListener("mouseup", onMouseUpBottomResize);
    };
    // Bottom Left resize
    const onMouseMoveBottomLeftResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      let proportionDifference = 0
      if((dx > 0 && dy < 0) || (dx < 0 && dy > 0)){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width - proportionDifference;
          height = height - proportionDifference;
        } else {
          proportionDifference = dy
          width = width + proportionDifference;
          height = height + proportionDifference;
        }
      }
      if(dx < 0 && dy < 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width + proportionDifference;
          height = height + proportionDifference;
        } else {
          proportionDifference = dy
          width = width + proportionDifference;
          height = height + proportionDifference;
        }
      }
      if(dx > 0 && dy > 0){
        if(Math.abs(dx) >= Math.abs(dy)){
          proportionDifference = dx
          width = width + proportionDifference;
          height = height + proportionDifference;
        } else {
          proportionDifference = dy
          width = width - proportionDifference;
          height = height - proportionDifference;
        }
      }
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px` && setStyle(prev => ({...prev, height: height, width: width}))
    };

    const onMouseUpBottomLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomLeftResize);
      document.removeEventListener("mouseup", onMouseUpBottomLeftResize);
    };
    const onMouseDownBottomLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = size.right;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
      document.addEventListener("mouseup", onMouseUpBottomLeftResize);
    };

    // Left resize
    const onMouseMoveLeftResize = (event) => {
      const dx = event.clientX - x;
      x = event.clientX;
      width = width - dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
    };
    const onMouseUpLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.width !== style.width){
        updateImageStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveLeftResize);
      document.removeEventListener("mouseup", onMouseUpLeftResize);
      
    };
    const onMouseDownLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      resizableComponent.style.right =  size.right;
      resizableComponent.style.left = null;
      document.addEventListener("mousemove", onMouseMoveLeftResize);
      document.addEventListener("mouseup", onMouseUpLeftResize);
    };
  
    // Add mouse down event listener
    const resizerTopLeft = refTopLeft.current;
    resizerTopLeft.addEventListener("mousedown", onMouseDownTopLeftResize);
    const resizerTop = refTop.current;
    resizerTop.addEventListener("mousedown", onMouseDownTopResize);
    const resizerTopRight = refTopRight.current;
    resizerTopRight.addEventListener("mousedown", onMouseDownTopRightResize);

    const resizerRight = refRight.current;
    resizerRight.addEventListener("mousedown", onMouseDownRightResize);

    const resizerBottomRight = refBottomRight.current;
    resizerBottomRight.addEventListener("mousedown", onMouseDownBottomRightResize);
    const resizerBottom = refBottom.current;
    resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);
    const resizerBottomLeft = refBottomLeft.current;
    resizerBottomLeft.addEventListener("mousedown", onMouseDownBottomLeftResize);

    const resizerLeft = refLeft.current;
    resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);
  
    return () => {
      resizerTopLeft.removeEventListener("mousedown", onMouseDownTopLeftResize)
      resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
      resizerTopRight.removeEventListener("mousedown", onMouseDownTopRightResize)

      resizerRight.removeEventListener("mousedown", onMouseDownRightResize);

      resizerBottomRight.removeEventListener("mousedown", onMouseDownBottomRightResize)
      resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
      resizerBottomLeft.removeEventListener("mousedown", onMouseDownBottomLeftResize)

      resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
    };
   }, [style, maxWidth, maxHeight]);

  const ref = useRef(null)
  const [{ }, drop] = useDrop({
    accept: ['part'],
    drop( ){ 
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(columnData !== undefined && item.columnData !== undefined){
        if(columnData.column === item.columnData.column){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }else{
        if(item.part !== undefined && item.parentType === parentType){
          moveChildFromParent(dragIndex, hoverIndex, item.part)
          item.index = hoverIndex
        }
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType, columnData }
    },
    collect: (smonitor) => ({
      isDragging: smonitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1

  const renderImage = useCallback((child, isCropping) => {
    return (
      <img 
      ref={imgRef}
      src={child.content.cropped  ?  child.content.cropped : child.content.data} 
      style={style}
      onLoad={onImageLoad}
      alt='image'/>
    )
  }, [style, dispatch])
  
  useEffect(() => {
    const onImageClick = (event) => {
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      console.log('clicked on uncropping image..')
      setStylingComponent([part, parentData, parentType, columnData]);
      setStylingPanel(prev=> true)
    }
    const onImageDoubleClick = (event) => {
      event.stopPropagation();
      console.log('double clicked..')
      dispatch(setCroppingImage([part.position]))
    }

    const imageRef = refBox.current;
    imageRef.addEventListener("click", onImageClick);
    imageRef.addEventListener("dblclick", onImageDoubleClick);

    return () => {
      imageRef.removeEventListener("click", onImageClick);
      imageRef.removeEventListener("dblclick", onImageDoubleClick);
    };
  }, [])

  // This is to demonstate how to make and center a % aspect crop
  // which is a bit trickier so we use some helper functions.
  function centerAspectCrop(
    mediaWidth,
    mediaHeight,
    aspect,
  ) {
    return makeAspectCrop(
      {
        unit: '%',
        width: 100,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    )
  }
  const previewCanvasRef = useRef(null)
  const imgRef = useRef(null)
  const [crop, setCrop] = useState()
  const [completedCrop, setCompletedCrop] = useState()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState(1)

  function onImageLoad(e) {
    const { width, height } = e.currentTarget
    setCrop(centerAspectCrop(width, height, aspect))
  }

  useEffect(() => {
    if(croppingImage === part.position && completedCrop){
      // const cropObj = previewCanvasRef.current
      // console.log(cropObj)
     // setCrop(centerAspectCrop(completedCrop.width, completedCrop.height,  aspect))  
    }
  },[croppingImage, completedCrop])

  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        canvasPreview(
          imgRef.current,
          previewCanvasRef.current,
          completedCrop,
          scale,
          rotate,
        )
        //croppingImageHandler(previewCanvasRef.current.toDataURL())
      }
    },
    100,
    [completedCrop, scale, rotate],
  )

  drag(drop(ref))

  const onCancel = () => {
    dispatch(setCroppingImage([0]))
  };

  const croppingImageHandler = (croppedImage) => {
    if(completedCrop){
      parentType === 'module' && columnData === undefined ? 
      dispatch(cropImageInModule([part.position, croppedImage, parentData]))
      // :
      // parentType === 'module' && columnData !== undefined && part.typeOfContent !== 'column' ? 
      // dispatch(setStyleForPartInModuleColumn([columnData, part.position, newStyle]))
      :
      parentType === 'level1' && columnData === undefined ?
      dispatch(cropImageInLevel1([part.position, croppedImage, parentData]))
      :
      // parentType === 'level1' && columnData !== undefined && part.typeOfContent !== 'column' ?
      // dispatch(setStyleForPartInLevel1Column([columnData, part.position, newStyle]))
      // :
      // parentType === 'level2' && columnData === undefined ?
      // dispatch(setStyleForPartInLevel2([parentData, part.position, newStyle]))
      // :
      // parentType === 'level2' && columnData !== undefined && part.typeOfContent !== 'column' ?
      // dispatch(setStyleForPartInLevel2Column([columnData, part.position, newStyle]))
      // :
      console.log('error')
      const newSizes = {width: completedCrop.width, height: completedCrop.height}
      updateImageStyle(newSizes)
    }
    dispatch(setCroppingImage([0]))
    
  }

  const containerRef = useOnclickOutside(() => {
    if(previewCanvasRef.current){
      croppingImageHandler(previewCanvasRef.current.toDataURL())
    }
    onCancel()
  })

  const resetCropping = () => {
    dispatch(resetImageInModule([part.position, parentData]))
    dispatch(setCroppingImage([0]))
  }

  return (
    <>
    {part.content &&
    <div ref={containerRef} className="image-resizable-container">
    <div ref={mergeRefs([refBox, ref])} className={chosenComponent.position=== part.position && croppingImage !== part.position ? "resizeable-box-chosen" : 'resizeable-box'}
      style={croppingImage=== part.postion ? {height: style.height, width: style.width, position:'absolute'} : {height: style.height, width: style.width}}
      onKeyDown={keyPressHandler}
      tabIndex="0">
      {/* {croppingImage === part.position ? (
        <ImageCropDialog
          id={part.id} completedCrop.width ? completedCrop.width : 
          imageUrl={part.content.data}
          cropInit={part.content.cropped}
          zoomInit={part.zoom}
          aspectInit={part.aspect}
          onCancel={onCancel}
          croppingHandler={croppingImageHandler}
          resetImage={resetCropping}
          changeClickability={changeClickability}
        />
      ) : 
      <div className="image" ref={ref}>
        {renderImage(part)}
      </div>
    } */}
    {croppingImage === part.position ? (
        <ReactCrop
          crop={crop}
          onChange={(_, percentCrop) => setCrop(percentCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          aspect={undefined}
        >
          {renderImage(part, true)}
        </ReactCrop>
      ) :  renderImage(part)
      }
      {
        completedCrop &&
        <div style={{visibility:'hidden',position:'absolute'}} >
          <canvas
            ref={previewCanvasRef}
            style={{
              width: completedCrop.width ,
              height:completedCrop.height,
            }}
          />
        </div>
      }
      

      <div ref={refLeft} className="resizer resizer-l"  draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>

      <div ref={refTopLeft} className="resizer resizer-t-l"  draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTop} className="resizer resizer-t" draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTopRight} className="resizer resizer-t-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>

      <div ref={refRight} className="resizer resizer-r" draggable="false" 
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>

      <div ref={refBottomRight} className="resizer resizer-b-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottom} className="resizer resizer-b" draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottomLeft} className="resizer resizer-b-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position && croppingImage !== part.position ? 'visible' : 'hidden'}}/>
    </div>
  </div>
  }
  </>
  )
}

export default Image
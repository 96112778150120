import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import ShortMenu from "../components/ShortMenu";
import Spinner from "../components/Spinner";
import LaunchpadHeader from "../components/LaunchpadHeader";
import { getFolderIds } from "../features/folderIds/folderIdSlice";
import { getUpToDateUser, logout, reset } from "../features/auth/authSlice";
import SettingsPage from "./SettingsPage";
import { getCustomerSpaces } from "../features/customerSpaces/customerSpaceSlice";
import LibraryPage from "./LibraryPage";
import IframeUserSideMenu from "../components/IframeUserSideMenu";

const HomePage = () => {

	const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)
  const [isUserUpToDate, setIsUserUpToDate] = useState(false)
  const { folderIds, isLoadingFId } = useSelector( state => state.folderIds)
  const [srcIframe, setSrcIframe] = useState('')
  const [isIframeLoaded, setIsIframeLoaded] = useState(false)
  const [iframeButtonTitle, setIframeButtonTitle] = useState('U')

  const [isSettingsOpen, setIsSettingsOpen] = useState(false)
  const [isLibraryOpen, setIsLibraryOpen] = useState(false)
  
  const colors = [
    '#65C7ED',
    '#FEF2F6',
    '#FED86F',
    '#B0E0E6',
    '#FFA07A',
    '#98FB98',
  ];
  
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const colorMapping = {};
  
  for (let i = 0; i < alphabet.length; i++) {
    const letter = alphabet[i];
    const colorIndex = i % colors.length;
    colorMapping[letter] = colors[colorIndex];
  }

  useEffect(() => {
    if(user){
      dispatch(getFolderIds())
      if(user.userCustomerSpace){
        dispatch(getCustomerSpaces())
      }
    }
  }, [user])


  useEffect(() => {
    if(user) {
      const handleUnload = (event) => {
        console.log('yes, user is renewed: ',user)
      };

      window.addEventListener('beforeunload', handleUnload);

      return () => {
        window.removeEventListener('beforeunload', handleUnload);
      };
    }
  }, []);

  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  useEffect(() => {

    if (!user) {
      navigate('/login')
    }
    if(user) {
      if(!isUserUpToDate){
        dispatch(getUpToDateUser({_id: user._id}));
        setIsUserUpToDate(true)
      }
      if (user && folderIds.length !== 0) {
        setIframeButtonTitle(user.name.charAt(0))
        let temp_view_folder_ids = '';
        let temp_editing_folder_ids = '';
        let doc_view_folder_ids = '';
        let doc_editing_folder_ids = '';

        let folder_ids_string = '';
      
        user.folderIds.forEach(userFolderId => {
          const folder = folderIds.find(folder => folder._id === userFolderId.folderId)
          if(folder){
            
            const folderId = folder.id
            const prefix = userFolderId.prefix;
        
            if (prefix === 'tfv') {
              temp_view_folder_ids = temp_view_folder_ids ? `${temp_view_folder_ids},${folderId}` : folderId;
            } else if (prefix === 'tfe') {
              temp_editing_folder_ids = temp_editing_folder_ids ? `${temp_editing_folder_ids},${folderId}` : folderId;
            } else if (prefix === 'dfv') {
              doc_view_folder_ids = doc_view_folder_ids ? `${doc_view_folder_ids},${folderId}` : folderId;
            } else if (prefix === 'dfe') {
              doc_editing_folder_ids = doc_editing_folder_ids ? `${doc_editing_folder_ids},${folderId}` : folderId;
            } else {
              console.log('Unknown prefix: ' + prefix); 
            }
          }
        });
      
        if (temp_view_folder_ids !== '') {
          folder_ids_string = folder_ids_string.concat('&tfv=', temp_view_folder_ids);
        }
        if (temp_editing_folder_ids !== '') {
          folder_ids_string = folder_ids_string.concat('&tfe=', temp_editing_folder_ids);
        }
        if (doc_view_folder_ids !== '') {
          folder_ids_string = folder_ids_string.concat('&dfv=', doc_view_folder_ids);
        }
        if (doc_editing_folder_ids !== '') {
          folder_ids_string = folder_ids_string.concat('&dfe=', doc_editing_folder_ids);
        }
        console.log('folder_ids_string : ',folder_ids_string)
        setSrcIframe(`https://script.google.com/a/yourdomain.com/macros/s/AKfycbz5xEAR79-imLhqhuPqvGltAs00NcUenIvwwEGi/exec?user_id=${user._id}&email=${user.email}${folder_ids_string}&active_page=launch`)
      }
    }
  }, [user, navigate, dispatch, folderIds])

  useEffect(() => {
    if(folderIds.length === 0){
      dispatch(getFolderIds())
    }
  },[isLoadingFId])



	return (
    user !== null ? 
      <div >
        {user.role === 'user' ? 
        srcIframe === '' || isLoadingFId ? 
        <Spinner/>
        :
        <div className='iframe-container'>
          {isIframeLoaded && 
          <>
            <IframeUserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle} 
            setIsSettingsOpen={setIsSettingsOpen} setIsLibraryOpen={setIsLibraryOpen}/> 
          </>}
          <iframe allow="clipboard-read; clipboard-write" allowpaymentrequest width="100%" height="100%" frameborder = "0" 
          className="iframe" onLoad={ () => setTimeout(() => {setIsIframeLoaded(true)}, 1500)}
          src={srcIframe}/>
          {isSettingsOpen && <SettingsPage setIsSettingsOpen={setIsSettingsOpen} isFromWave1={true}/>}
          {isLibraryOpen && <LibraryPage setIsLibraryOpen={setIsLibraryOpen} isFromWave1={true}/>}
        </div>
        :
        user.role !== 'user' ? 
        <div className='content-board'>
          <ShortMenu chosenPage={'launchpad'}/>
          <div className='board-body'>
            <LaunchpadHeader stage={'no'}/>
            <div className="home"> 
              <div className="home-page-description">
                Welcome to the Majoto. This is your home page, please go to there "My templates" to start work with your templates.
              </div>
              <div>
                <div id="info" >Account info: </div> 
                <div id="name" >Name: {user.name}</div> 
                <div> Email: {user.email}</div>
                <div> Role: {user.role}</div>
              </div> 
            </div>
          </div>
        </div>
        :
        <div>Login or register please</div>}
      </div>
      : navigate('/login')
		);
}
 
export default HomePage;
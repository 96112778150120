import arrow from "../../icons/arrow-icon.svg"
import whip_in from "../../icons/zoom-in-icon.svg"
import whip_out from "../../icons/zoom-out-icon.svg"
import list_icon from "../../icons/list-header-icon.svg"
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside"
import { useSelector } from "react-redux";
import UserSideMenu from "../UserSideMenu"

const AdminPageHeader = ({stage, setStage, onLogout}) => {

  const {user} = useSelector( (state) => state.auth)

  const [isDown, setIsDown] = useState(false)
	const [iframeButtonTitle, setIframeButtonTitle] = useState('U')
  const ref = useOnclickOutside(() => {
    isDown && setIsDown(prev => false);
  })

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])

	return (   
		<div className="admin-page-header">
      <div className="admin-page-header-stages">
        <div className={stage === "registration" ? "admin-page-state-s" : "admin-page-state"}
        onClick={() => setStage("registration")}>
          Registration
        </div>
        <div className={stage === "dashboard" ? "admin-page-state-s" : "admin-page-state"}
         onClick={() => setStage("dashboard")}> 
          Dashboard
        </div>
        <div className={stage === "archive" ? "admin-page-state-s" : "admin-page-state"}
        onClick={() => setStage("archive")}>
          Archive
        </div>
      </div>

			<div className="admin-page-header-options">
				<div className="editor-options-column">
					<div ref={ref} className="actions-droplist">
						<div className="actions-droplist-header" onClick={ () => setIsDown(prev => !isDown)}>
							Actions
							<img style={{marginLeft:'8px', transform:( isDown && 'rotate(-180deg)'), 
							transition:'260ms linear all'}} src={arrow} alt="arrow"/>
						</div>
						{ isDown && 
						<div className="actions-droplist-body">
							<div style={{position:'relative'}}>
								<div className="actions-droplist-option" onClick={onLogout}>
									Logout
								</div>
							</div>
						</div> }
					</div>
				</div>   
				<hr className="header-vertical-hr"/>
				<div className="editor-options-column">
					<div style={{display:'inline-flex', marginRight: '12px', borderRadius: '8px',boxShadow: '0px 1px 8px 1px rgba(0, 0, 0, 0.15)'}}>
						<div className="small-button-in">
							<img src={whip_in} alt="in"/>
						</div>
						<div className="small-button-out">
							<img src={whip_out} alt="out"/>
						</div>
					</div>
					<div className="small-button-list">
						<img src={list_icon} alt="list"/>
					</div>
					<UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/> 
				</div>
			</div>
		</div>  
	)
}
 
export default AdminPageHeader;
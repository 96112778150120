import axios from 'axios'

const API_URL = '/api/folderRegisters/'

// Create new folderRegister
const createFolderRegister = async (folderRegisterData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, folderRegisterData, config)

  return response.data
}

// Get user folderRegisters
const getFolderRegisters = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// change user document
const changeFolderRegister = async (folderRegisterData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let folderRegisterBody = folderRegisterData
  let folderRegisterId = folderRegisterData._id;
  const response = await axios.post(API_URL + folderRegisterId, folderRegisterBody, config)

  return response.data
}
// update user document
const updateFolderRegister = async (folderRegisterData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let folderRegisterBody = folderRegisterData
  let folderRegisterId = folderRegisterData._id;
  const response = await axios.put(API_URL + folderRegisterId, folderRegisterBody, config)

  return response.data
}

// Delete user folderRegister
const deleteFolderRegister = async (folderRegisterId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + folderRegisterId, config)

  return response.data
}

const folderRegisterService = {
  createFolderRegister,
  getFolderRegisters,
  changeFolderRegister,
  updateFolderRegister,
  deleteFolderRegister,
}

export default folderRegisterService
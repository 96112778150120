import { BrowserRouter as Router, Route, Routes  } from 'react-router-dom';
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Agreements from './pages/Agreements';
import Templates from './pages/Templates';
import Analytics from './pages/Analytics';
import HomePage from './pages/HomePage';
import LaunchPage from './pages/LaunchPage';
import Register from './pages/Register';
import Login from './pages/Login'
import DocumentEditorPage from './pages/DocumentEditorPage';
import Portfolio from './pages/Portfolio';
import Styles from './pages/Styles';
import StyleEditorPage from './pages/StyleEditorPage';
import SelectPage from './pages/SelectPage';
import Welcome from './pages/Welcome';
import AccountPage from './pages/AccountPage';
import TestPage from './pages/TestPage';
import NotFoundPage from './pages/NotFoundPage';
import AdminPage from './pages/AdminPage';
import LinkupPage from './pages/LinkupPage';
import { useEffect } from "react";
import { useDispatch } from 'react-redux';
import { logout } from './features/auth/authSlice';
import GoogleAuthPage from './pages/GoogleAuthPage';
import AuthErrorPage from './pages/AuthErrorPage';
import SettingsPage from './pages/SettingsPage';
import LibraryPage from './pages/LibraryPage';
import IntegrationPage from './pages/IntegrationPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import ResetPasswordPage from './pages/ResetPasswordPage';


function App() {

  const dispatch = useDispatch()

  useEffect(() => {
    const handleUnload = (event) => {
      // Handle browser closing or tab navigation
     //dispatch(logout())
    };

    window.addEventListener('beforeunload', handleUnload);

    return () => {
      window.removeEventListener('beforeunload', handleUnload);
    };
  }, []);

  return (
    <>
      <Router>
        <div className="App">
          <div className='content'>
            <Routes >
              <Route path='/' element={<HomePage />}/>
              <Route path='/linkup/:token' element={<LinkupPage />}/>
              <Route path='/register' element={<Register />}/>
              <Route path='/register/:token' element={<Register />}/>
              <Route path='/login' element={<Login />}/>
              <Route path='/forgot-password' element={<ForgotPasswordPage />}/>
              <Route path='/reset-password' element={<ResetPasswordPage />}/>
              <Route path='/googleLoginSuccess' element={<GoogleAuthPage />}/>
              <Route path='/googleLoginFail' element={<AuthErrorPage />}/>
              <Route path='/googleDriveIntegration' element={<IntegrationPage />}/>
              <Route path='/welcome' element={<Welcome/>}/>
              <Route path='/account' element={<AccountPage/>}/>
              <Route path='/administration' element={<AdminPage/>}/>
              <Route path='/analytics' element={<Analytics />}/>
              <Route path='/analytics/portfolio' element={<Portfolio />}/>
              <Route path='/settings' element={<SettingsPage isFromWave1={false}/>}/>
              <Route path='/library' element={<LibraryPage isFromWave1={false}/>}/>
              <Route path='/launch' element={<LaunchPage />}/>
              <Route path='/launch/agreements' element={<Agreements />}/>
              <Route path='/launch/templates' element={<Templates/>}/>
              <Route path='/launch/styles' element={<Styles/>}/>
              <Route path='/s/:id/:stage' element={<StyleEditorPage/>}/>
              <Route path='/d' element={<DocumentEditorPage/>}/>
              <Route path='/d/:id/:stage' element={<DocumentEditorPage/>}/>
              <Route path='/select' element={<SelectPage />}/>
              <Route path='/select/:id' element={<SelectPage />}/>
              <Route path='/test' element={<TestPage />}/>
              <Route path='*' element={<NotFoundPage/>}/>
            </Routes >
          </div>
        </div>
      </Router>
      <ToastContainer/>
    </>
  );
}

export default App;

import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDocument } from "../features/documents/documentSlice";
import { createStyle } from "../features/styles/styleSlice";

const style_level1_holder = {
  display:'flex', padding: '20px 16px',
  gap:'8px',
  width: '300px', height:'120px',
  outlineWidth:'1px',
  outlineOffset:'-1px',
  outlineStyle: 'solid',
  outlineColor: 'rgba(229,225,225,255)',
  borderRadius: '8px', alignItems:'center',
  position: 'relative',
  overflow: 'hidden',
  flexDirection:'column', isUnicStyle:false,
}
const style_module = {
  display:'flex', flexDirection:'column', padding: '20px 16px', 
  gap:'8px', fontFamily: 'Montserrat',
  textAlign: 'left', alignItems:'center',
  color:'#000', border: '1px solid #ECF1E6', borderRadius: '10px',
  fontSize:'14px', fontWeight: 'normal', fontStyle: 'normal',
  height: 'fit-content'
}
const newDocument = {
  name: "New document",
  documentBody:[
    {id: 1, index: 1, name: "Key Terms", 
    typeOfContent:'module',
    style:  style_module, 
    content:[{
      parent:1, typeOfContent:'level1', 
      style:  style_level1_holder, 
      origin:'root', 
      position: Math.round(Math.random()*10000)-Math.round(Math.random()*1000),
      content:[], name:'Document data'
    }]},
    {id: 2, index: 2, name: "Governance", 
    typeOfContent:'module',
    style:  style_module, 
    content:[{
      parent:2, typeOfContent:'level1',
      style:  style_level1_holder, 
      origin:'root', 
      position: Math.round(Math.random()*10000)-Math.round(Math.random()*1000),
      content:[], name:'Parties',
    }]},
  ],
  documentClasses: {
    module : {
      display:'flex', flexDirection:'column',
      background: "#FFFFFF",
      color: "#000",
      fontSize: "14px",
      fontStyle: "normal",
      fontFamily: 'Montserrat',
      fontWeight: "normal",
      alignItems: "center",
      padding: '20px 10px',
      outline:'1px solid rgb(229, 225, 225)',
      borderRadius:'8px',
      isUnicStyle: false,
    },
  },
}
const newStyle = {
  name: "New style",
  styleBody:[],
  classes: {
    based:'module'
  },
}

export const useCreateNewDocument = (stage) => {
  const dispatch = useDispatch()

  return useCallback(() =>  { 
    if(stage === 'templates'){dispatch(createDocument(newDocument))}
    if(stage === 'styles'){dispatch(createStyle(newStyle))}
  }, [dispatch])
};
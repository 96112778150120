import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import InvitationForm from "../components/InvitationForm";
import ShortMenu from "../components/ShortMenu";
import {getAll, logout, reset} from '../features/auth/authSlice'
import RegisterControl from "../components/admin_page_components/RegisterControl";
import AdminPageHeader from "../components/admin_page_components/AdminPageHeader";
import DashboardControl from "../components/admin_page_components/DashboardControl";
import ArchiveControl from "../components/admin_page_components/ArchiveControl";
import AdminPageScrollbar from "../components/admin_page_components/AdminPageScrollbar";
import { getCustomerSpaces } from "../features/customerSpaces/customerSpaceSlice";
import { getUsers } from "../features/users/userSlice";
import { getFolderIds } from "../features/folderIds/folderIdSlice";
import { getFolderRegisters } from "../features/folderRegister/folderRegisterSlice";

const AdminPage = () => {
  const navigate = useNavigate()
	const dispatch = useDispatch()


  const {user} = useSelector( state => state.auth)
  const [pageState, setPageState] = useState("registration")

	const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }
 
  useEffect(() => {
    if (!user) {
      navigate('/login')
    }

    dispatch(getCustomerSpaces())
    dispatch(getFolderIds())
    dispatch(getUsers())
    dispatch(getFolderRegisters())

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, dispatch])


	return (
		<div className='content-board'>
			<ShortMenu chosenPage={'account'}/>
			<div className='board-body'>
				<AdminPageHeader stage={pageState} setStage={setPageState} onLogout={onLogout}/>
        {user ? 
          <div className='admin-control-body'>
            <AdminPageScrollbar>
              { pageState === "registration" ?
              <RegisterControl/>  
              : pageState === "dashboard" ?
              <DashboardControl/>
              :
              <ArchiveControl/>
              }
            </AdminPageScrollbar>
          </div> 
        : <div>Login or register please</div>
        } 
      </div>
		</div>
  );
}
 
export default AdminPage;


// <div style={{display:'flex', flexDirection:'column',width: '400px', gap:'10px'} }>
// <div id="info" >Invitation form </div>
// <InvitationForm/>
// </div>
import { useDispatch } from 'react-redux'
import { deleteAgreement } from '../../features/agreements/agreementSlice'
import closeIcon from "../../icons/right-panel-close.svg"

const details = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0',
  pointerEvents: 'none',
  transition: '0.2s',
  zIndex:'10',
}

const DeleteAgreementForm = ({active, setActive, agreements, setUpdate}) => {

  const dispatch = useDispatch()

  const onSubmit = (e) => {
    e.preventDefault()
    //dispatch(removeActiveDocument(agreement))
    if(agreements.lenght !== 0){
      agreements.forEach(agreement => {
        dispatch(deleteAgreement(agreement._id))
      })
      setUpdate(prev => true)
    }
    setActive(false)
  }
  return ( 
    <div style={active ? {...details, opacity: '1', pointerEvents: 'all'} : details}>
      <div className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
        <div className="form-header">
          <div >Do you want to delete this agreement ?</div>
          <div className="close_button" onClick={() => setActive(false)}><img src={closeIcon} alt="close"/></div> 
        </div>
        <form className="style-form" onSubmit={onSubmit}>
          <button className="form-bottom-button" type='submit'>
            Delete 
          </button>
        </form>
      </div>
    </div>
  );
}
 
export default DeleteAgreementForm;
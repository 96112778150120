import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel1, setChosenComponent, updateComponentsOrderInLevel1, updatingComponentsOrderInLevel1, updateSizeForLevel1, addDesignItemInLevel1 } from "../../../features/localDocument/editingDocumentSlice"
import EmptyComponent from "./EmptyComponent"
import Heading from "./Heading"
import Level2 from "./Level2"
import SubHeading from "./SubHeading"
import TextArea from "./TextArea"
import ColumnsHolder from "./ColumnsHolder"
import Line from "./Line"
import Image from "./Image"
import ImageHolder from "./ImageHolder"
import { useParentHight } from "../../../hooks/useParentHight"
import { useDeleteComponent } from "./deleteComponentFromTemplate"

const Level1 = ({part, index, moduleId, 
  columnData,  
  changeClickability,
  setStylingPanel, setStylingComponent, 
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveChildFromParent}) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()
  const [isDeleting, setIsDeleting] = useState(false)
  const deleteComponent = useDeleteComponent({
    component: part,
    positionOfParent: moduleId,
    typeOfParent: "module",
    columnData: columnData,
    isDeleting: isDeleting,
    setIsDeleting: setIsDeleting,
    setSidePanel: setStylingPanel
  });
  const [style, setStyle] = useState({})
  const [isDragAllow, setIsDragAllow] = useState(true)
  const [levelSize, setLevelSize] = useState({})

  const [isResizingOver, setIsResizingOver] = useState(true)
  const {parentHeight} = useParentHight(part, 'module', columnData)

  const changeLevelClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }

  const keyPressHandler = (event) => {
    if(chosenComponent.position=== part.position){
      if(event.key === 'Delete'){
        setIsDeleting(prev => true)
      }
    }
  }

  const refBox = useRef(null);
  const refLeft = useRef(null);
  const refTopLeft = useRef(null);
  const refTop = useRef(null);
  const refTopRight = useRef(null);
  const refRight = useRef(null);
  const refBottomRight = useRef(null);
  const refBottom = useRef(null);
  const refBottomLeft = useRef(null);
  const minWidth = 140
  const maxWidth = 840
  const minHeight = 60
  const maxHeight = 840

  useEffect(()=> {
    setStyle(prev => ({...part.style, width: '400px', height:'60px'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style, maxHeight: parentHeight}))
    console.log('parent height: ', parentHeight)
  }, [part.style, dispatch, parentHeight])

  const updateLevel1Style = (newStyle) => {
    dispatch(updateSizeForLevel1([moduleId, newStyle, part.position]))
  }

  useEffect(() => {
    const resizableComponent = refBox.current;
    setLevelSize(prev => ({
      width: resizableComponent.offsetWidth - 2*parseInt(part.style.padding),
      height: resizableComponent.offsetHeight - 2*parseInt(part.style.padding)
    }))
    //console.log('parent size changes ', levelSize)
  },[dispatch, isResizingOver, style])

  useEffect(() => {
      const resizableComponent = refBox.current;
      const size = {width: style.width ? parseInt(style.width) : 200, height: style.height ? parseInt(style.height) : 60};
      let width = parseInt( size.width, 10);
      let height = parseInt( size.height, 10);
      let x = 0;
      let y = 0;
  
      // Top Left resize
      const onMouseMoveTopLeftResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpTopLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch top left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopLeftResize);
        document.removeEventListener("mouseup", onMouseUpTopLeftResize);
      };
      const onMouseDownTopLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = size.right;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.addEventListener("mouseup", onMouseUpBottomLeftResize);
      };
      // Top resize
      const onMouseMoveTopResize = (event) => {
        const dy = event.clientY - y;
        y = event.clientY;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
      const onMouseUpTopResize = (event) => {
        const newStyleProperties = {height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height){
          console.log('will be dispatch top ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopResize);
        document.removeEventListener("mouseup", onMouseUpTopResize);
      };
      const onMouseDownTopResize = (event) => {
        changeClickability()

        y = event.clientY;
        const  size = window.getComputedStyle(resizableComponent);
        resizableComponent.style.bottom =  size.bottom;
        resizableComponent.style.top = null;
        document.addEventListener("mousemove", onMouseMoveTopResize);
        document.addEventListener("mouseup", onMouseUpTopResize);
      };
  
      // Top Right resize
      const onMouseMoveTopRightResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpTopRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch top right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopRightResize);
        document.removeEventListener("mouseup", onMouseUpTopRightResize);
      };
      const onMouseDownTopRightResize = (event) => {
        changeClickability()
        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  size.left;
        resizableComponent.style.right = null;
        document.addEventListener("mousemove", onMouseMoveTopRightResize);
        document.addEventListener("mouseup", onMouseUpTopRightResize);
      };
  
      // Right resize
      const onMouseMoveRightResize = (event) => {
        console.log('trigger ')
        const dx = event.clientX - x;
        x = event.clientX;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
      };
  
      const onMouseUpRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width}
        if(resizableComponent.style.width !== style.width){
          console.log('will be dispatch right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveRightResize);
        document.removeEventListener("mouseup", onMouseUpRightResize);
      };
      const onMouseDownRightResize = (event) => {
        changeClickability()
          
        x = event.clientX;
        resizableComponent.style.left =  size.left;
        resizableComponent.style.right = null;
        document.addEventListener("mousemove", onMouseMoveRightResize);
        document.addEventListener("mouseup", onMouseUpRightResize);
      };
      // Bottom Right resize
      const onMouseMoveBottomRightResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpBottomRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch bottom right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomRightResize);
        document.removeEventListener("mouseup", onMouseUpBottomRightResize);
      };
      const onMouseDownBottomRightResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = null;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomRightResize);
        document.addEventListener("mouseup", onMouseUpBottomRightResize);
      };
  
      // Bottom resize
      const onMouseMoveBottomResize = (event) => {
        const dy = event.clientY - y;
        y = event.clientY;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
      const onMouseUpBottomResize = (event) => {
        const newStyleProperties = {height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height){
          console.log('will be dispatch bottom ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomResize);
        document.removeEventListener("mouseup", onMouseUpBottomResize);
      };
      const onMouseDownBottomResize = (event) => {
        changeClickability()
         
        y = event.clientY;
        const  size = window.getComputedStyle(resizableComponent);
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomResize);
        document.addEventListener("mouseup", onMouseUpBottomResize);
      };
      // Bottom Left resize
      const onMouseMoveBottomLeftResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpBottomLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch bottom left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.removeEventListener("mouseup", onMouseUpBottomLeftResize);
      };
      const onMouseDownBottomLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = size.right;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.addEventListener("mouseup", onMouseUpBottomLeftResize);
      };
  
      // Left resize
      const onMouseMoveLeftResize = (event) => {
        const dx = event.clientX - x;
        x = event.clientX;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
      };
      const onMouseUpLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width}
        if(resizableComponent.style.width !== style.width){
          console.log('will be dispatch left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveLeftResize);
        document.removeEventListener("mouseup", onMouseUpLeftResize);
         
      };
      const onMouseDownLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        resizableComponent.style.right =  size.right;
        resizableComponent.style.left = null;
        document.addEventListener("mousemove", onMouseMoveLeftResize);
        document.addEventListener("mouseup", onMouseUpLeftResize);
      };
  
      // Add mouse down event listener
      const resizerTopLeft = refTopLeft.current;
      resizerTopLeft.addEventListener("mousedown", onMouseDownTopLeftResize);
      const resizerTop = refTop.current;
      resizerTop.addEventListener("mousedown", onMouseDownTopResize);
      const resizerTopRight = refTopRight.current;
      resizerTopRight.addEventListener("mousedown", onMouseDownTopRightResize);

      const resizerRight = refRight.current;
      resizerRight.addEventListener("mousedown", onMouseDownRightResize);

      const resizerBottomRight = refBottomRight.current;
      resizerBottomRight.addEventListener("mousedown", onMouseDownBottomRightResize);
      const resizerBottom = refBottom.current;
      resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);
      const resizerBottomLeft = refBottomLeft.current;
      resizerBottomLeft.addEventListener("mousedown", onMouseDownBottomLeftResize);

      const resizerLeft = refLeft.current;
      resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);
  
      return () => {
        resizerTopLeft.removeEventListener("mousedown", onMouseDownTopLeftResize)
        resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
        resizerTopRight.removeEventListener("mousedown", onMouseDownTopRightResize)

        resizerRight.removeEventListener("mousedown", onMouseDownRightResize);

        resizerBottomRight.removeEventListener("mousedown", onMouseDownBottomRightResize)
        resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
        resizerBottomLeft.removeEventListener("mousedown", onMouseDownBottomLeftResize)

        resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
      };
  }, [style]);

  const ref = useRef(null)
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'level2','columns_holder'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent && item.columnData === undefined){
        if(item.type !== undefined && item.parentType !== 'module' && item.columnData === undefined){ 
          if(item.type === 'new_image'){
            setUploadImageData('new_image');
            setUploadImageParent({position: part.position, type: 'level1'}); 
            setImageForm(prev => true)
          }else{
            if(item.type === 'design_item'){
              dispatch(addDesignItemInLevel1([item, part.position]))
            }else{
              dispatch(addComponentInLevel1([ item, part.position]))
            }
          }
        }
        if(item.parentType === 'level1' && item.part.parent !== part.position && part.content.length === 0){
          dispatch(updateComponentsOrderInLevel1(part.position, item.part, moduleId))
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined && item.parentType === 'module' && item.columnData === undefined){
        moveChildFromParent(dragIndex, hoverIndex, item.part)
        item.index = hoverIndex
      } 
      // if(item.type !== 'level2'){
      //   moveChildFromParent(dragIndex, hoverIndex, item)
      //   item.index = hoverIndex
      // } 
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType: 'module' }
    },
    canDrag: () => {
      return isDragAllow && chosenComponent.position=== part.position
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    })
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  useEffect(() => {
    const onLevelClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, moduleId, 'module', columnData]);
        setStylingPanel(prev=> true)
        console.log('it was a level click')
      }
    }

    const levelRef = refBox.current;
    levelRef.addEventListener("click", onLevelClick);

    return () => {
      levelRef.removeEventListener("click", onLevelClick);
    };
  }, [isResizingOver])

  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
      (child.part === undefined && child.parent === part.position) &&
    dispatch(updatingComponentsOrderInLevel1(
      [dragIndex, hoverIndex, moduleId, part.position, child ]))
  }, []) 

  const changeChild = useCallback(
    (child) => {
    dispatch(updateComponentsOrderInLevel1(
      [part.position, child, moduleId]))
  }, []) 

  const hoverRes = isOverCurrent 
  const renderLevel2 = useCallback((child, index) => {
    return (
      <Level2 
        key={child.position} part = {child} index={index}
        changeClickability={changeLevelClickability}
        moduleId={moduleId}
        parentData = {part.position}
        parentType = {"level1"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
        changeChildFromParent = {changeChild}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback((child, index) => {
    return (
      <ColumnsHolder 
        key={child.position} part = {child} index={index}
        moduleId = {moduleId} 
        parentType = {'level1'}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <TextArea 
        key={child.position} index={index} part={child} 
        parentData = {part.position} 
        parentType = {"level1"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
        changeChildFromParent = {changeChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <Heading 
        key={child.position} part = {child}  index={index}
        parentData = {part.position} 
        parentType = {"level1"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
        changeChildFromParent = {changeChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <SubHeading 
        key={child.position} part = {child} index={index}
        parentData = {part.position}  
        parentType = {"level1"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
        changeChildFromParent = {changeChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <ImageHolder 
        key={child.position}  part={child} index={index}
        changeClickability={changeLevelClickability}
        parentSize={levelSize}
        parentData = {part.position} 
        parentType = {"level1"}
        setImageForm={setImageForm}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent}  
        moveChildFromParent = {moveChild}
      />
    )
  }, [levelSize])
  const renderImage = useCallback((child, index) => {
    return (
      <Image 
        key={child.position} part={child} index={index}
        changeClickability={changeLevelClickability}
        parentSize={levelSize}
        parentData = {part.position} 
        parentType = {"level1"}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [levelSize])
  const renderLine = useCallback((child, index) => {
    return (
      <Line 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        parentType = {'level1'}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderEmptyComponent = useCallback((child, index, visibility) => {
    return (
      <EmptyComponent 
        key={index} part = {child} index={index}
        parentData = {part.position} 
        parentType = {"level1"}
        changeChildFromParent = {changeChild}
        visibility={visibility}
      />
    )
  }, [])

  return (
    <div  className="resizable-container">

    <div ref={refBox} className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{height: style.height, width: style.width,}}
    onKeyDown={keyPressHandler}
    tabIndex="0"
    >
      <div ref={ref} data-handler-id={handlerId} style={{...style, cursor: 'grab', opacity, height: '100%', width:'100%'}}
        >
        {part.content.length !== 0 ? 
          part.content.map( (child, index) => 
            child.typeOfContent === 'text_field' ?
            renderTextArea(child, index)
            : 
            child.typeOfContent === "level2" ? 
            renderLevel2(child, index)
            : 
            child.typeOfContent === "columns_holder" ? 
            renderColumnsHolder(child, index)
            : 
            child.typeOfContent === "heading" ? 
            renderHeading(child, index)
            : 
            child.typeOfContent === "subheading" ? 
            renderSubHeading(child, index)
            : 
            child.typeOfContent === "image_holder" ?
            renderImageHolder(child, index)
            : 
            child.typeOfContent === "image" ?
            renderImage(child, index)
            :
            child.typeOfContent === "line" ? 
            renderLine(child, index)
            : 
            child.position === 0 ? 
            renderEmptyComponent(child, index, true)
            : console.log('ceivo', child)
          )
        :  <></>
        }
      </div>
      <div ref={refLeft} className="resizer resizer-l"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refTopLeft} className="resizer resizer-t-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTop} className="resizer resizer-t" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTopRight} className="resizer resizer-t-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refRight} className="resizer resizer-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refBottomRight} className="resizer resizer-b-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottom} className="resizer resizer-b" draggable="false"  
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottomLeft} className="resizer resizer-b-l" draggable="false" 
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
    </div>
    </div>
  )
}

export default Level1
import { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import emailjs from '@emailjs/browser';
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomerSpaces } from "../../features/customerSpaces/customerSpaceSlice";
import { changeUser, getUsers, updateUser } from "../../features/users/userSlice";
import { getFolderIds } from "../../features/folderIds/folderIdSlice";
import { changeFolderRegister, deleteFolderRegister, getFolderRegisters } from "../../features/folderRegister/folderRegisterSlice";
import ArchiveRegisterRowsTableRow from "./ArchiveRegisterRowsTableRow";


const ArchiveRegisterRowsTable = () => {

  
  const template_id = 'template_3w75the'
  const service_id = 'service_cdtgthm'
  const public_key = '3WYeIk6ploNZtWWLi'

  const dispatch = useDispatch()
  const  { folderIds, isLoadingFId } = useSelector( 
    state => state.folderIds)
  const  { users, isLoadingU } = useSelector( 
    state => state.users)
  const  { folderRegisters, isLoadingFR } = useSelector( 
    state => state.folderRegisters)

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [userUpdateTrigger, setUserUpdateTrigger] = useState(false)
  const [registerUpdateTrigger, setRegisterUpdateTrigger] = useState(false)

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getFolderIds())
        dispatch(getUsers())  
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  useEffect(() => {
    if(registerUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderRegisters())
        setRegisterUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [registerUpdateTrigger])

  const [openRows, setOpenRows] = useState([]);

  const generateFolderId = (folderType, type) => {
    let prefix = folderType === 'template' ? 'tf' : 'df';
    if (type === 'View') {
      prefix += 'v';
    } else if (type === 'Edit') {
      prefix += 'e';
    }
    return prefix;
  };

  const sendInvitationUserHandler = () => {
    if(openRows.length > 1){
      toast.error('Chose one user please', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{ 
      const userId = folderRegisters.find(row => row._id === openRows[0]).user
      const userToUpdate = users.find((user) => user._id === userId);
      if (userToUpdate.userStatus !== 'Active' && userToUpdate.userStatus !== 'Invite sent') {
        
        const emailData = {
          user_name: userToUpdate.name,
          user_email: userToUpdate.email,
          user_link: userToUpdate.invitationLink,
        }
        toast.promise( () => 
          emailjs.send(service_id, template_id, emailData, public_key),
          {
            pending: 'Sending invitation..',
            success: 'Invitation sent',
            error: 'Promise rejected 🤯'
          }
        )
        const updatedUser = { ...userToUpdate, userStatus: 'Invite sent' };
        dispatch(changeUser(updatedUser))
        setUserUpdateTrigger(prev => true)
        setRegisterUpdateTrigger(prev => true)
        setOpenRows(prev => [])
      } else {
        toast.error('This user is already invited', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        })
      }
    }
  }
    
  const activateRowsHandler = () => {
    openRows.forEach(rowId => { 
      const rowToUpdate = folderRegisters.find(reg => reg._id === rowId);
      let userFoldersData = []
      userFoldersData.push({
        folderId: rowToUpdate.templateFolder,
        prefix: generateFolderId('template', rowToUpdate.type),
      })
      userFoldersData.push({
        folderId: rowToUpdate.docFolder,
        prefix: generateFolderId('document', rowToUpdate.type),
      })
      const userUpdateData = {
        _id: rowToUpdate.user,
        folderIds: userFoldersData,
        type: 'folders',
      }
      console.log('userUpdateData  ',userUpdateData)
      dispatch(updateUser(userUpdateData))
      setUserUpdateTrigger(prev => true)
      setRegisterUpdateTrigger(prev => true)
      setOpenRows(prev => [])
    })
  }

  const registerToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows.includes(index);
      if (isOpen) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };
  const [isRActionsMenu, setIsRActionsMenu] = useState(false)

  useEffect(() => {
    openRows.length !== 0 ? setIsRActionsMenu(prev => true) : setIsRActionsMenu(prev => false) 
  }, [openRows])

  const rActionsHandler = (option) => {
    openRows.forEach((rowId) => {
      const rowToUpdate = folderRegisters.find((row) => row._id === rowId);
  
      if (rowToUpdate) {
        if(option === 'activate with invite' ){
          sendInvitationUserHandler()
          activateRowsHandler()
          const updatedRow = { ...rowToUpdate, registerStatus: 'Active with invite' };
          dispatch(changeFolderRegister(updatedRow))
        }else{ 
          if(option === 'activate without invite'){
            activateRowsHandler()
            const updatedRow = { ...rowToUpdate, registerStatus: 'Active' };
            dispatch(changeFolderRegister(updatedRow))
          }else{ 
            if(option === 'delete'){
              toast.promise(
                dispatch(deleteFolderRegister(rowToUpdate._id)),
                {
                  pending: 'Deleting..',
                  success: `Register row deleted`,
                  error: 'Promise rejected 🤯'
                }
              )
            }else{
              const updatedRow = { ...rowToUpdate, registerStatus: option };
              toast.promise(
                dispatch(changeFolderRegister(updatedRow)),
                {
                  pending: 'Applying changes',
                  success: `Changed register state to ${option}`,
                  error: 'Promise rejected 🤯'
                }
              )
            }
          }
        }
      }
    })
    if (openRows.length !== 0){
      setRegisterUpdateTrigger(prev => true)
      setOpenRows(prev => [])
    }
  }

  const renderRow = useCallback((row, index) => {
    return(
      <ArchiveRegisterRowsTableRow row={row} key={index} index={index} 
      registerToggle={registerToggle} 
      setUpdateTrigger={setRegisterUpdateTrigger}/>
    )
  }, [folderRegisters, isLoadingFR])


  return ( 
    <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Folder`s Register table
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <th>Customer space</th>
              <th>User</th>
              <th>Status</th>
              <th>Template folder</th>
              <th>Doc folder</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoadingFR || updateTrigger ? <div className="admin-control-loading"><Spinner/></div>
               : folderRegisters.filter(row => row.registerStatus === 'archive').length !== 0 ? 
              <>
                {folderRegisters.filter(row => row.registerStatus === 'archive').map((row, index) => renderRow(row, index))} 
              </>
              : <tr></tr>
            }
          </tbody>
        </table>
        {folderRegisters.filter(row => row.registerStatus === 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no register`s row in archive</div>}
      </div>
      <>
        { isRActionsMenu && 
          <div className="admin-register-actions">
            <div className="admin-register-actions-header">
              Actions
            </div>
            <div className="admin-register-actions-option"
              onClick={() => rActionsHandler('activate with invite')}>
              Activate with invite
            </div>
            <div className="admin-register-actions-option"
              onClick={() => rActionsHandler('activate without invite')}>
              Activate without invite
            </div>
            <div className="admin-register-actions-option-delete"
              onClick={() => rActionsHandler('delete')}>
              Delete
            </div>
          </div>
        }
      </>
    </div>
   );
}
 
export default ArchiveRegisterRowsTable;
import { useEffect, useState } from "react";
import closeIcon from "../../icons/right-panel-close.svg"
import ActionsPanel from "./ActionsPanel";
import StylingPanel from "./StylingPanel";
import { useDispatch } from "react-redux";
import { setChosenComponent } from "../../features/localDocument/editingDocumentSlice";
import ClassesPanel from "./ClassesPanel";
import PDFSettingsPanel from "./PDFSettingsPanel";

const headings = {
  'module': 'Module',
  'level1': 'Level 1',
  'level2': 'Level 2',
  'level3': 'Level 3',
  'heading': 'Heading',
  'subheading': 'Subheading',
  'text_field': 'Textarea',
  'columns_holder': 'Columns holder',
  'column': 'Column',
  'dynamic_block': 'Dynamic block',
  'dynamic_part': 'Dynamic part',
  'line': 'Line',
  'image': 'Image',
  'image_holder': 'Image holder',
}
const SidePanel = ({active, setSidePanel, stylingComponent, classes}) => {

  const dispatch = useDispatch()
  const [sticky, setSticky] = useState("");

  const [mode, setMode] = useState('styling')

  useEffect(() => {
    const scrollTop = window.scrollY;
    const heightOfHeader = 74;
    const stickyClass = scrollTop >= heightOfHeader ? "-is-sticky" : '';
    setSticky(prev => stickyClass);
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    }
  }, [active, mode])
  
  const isSticky = () => {
    const scrollTop = window.scrollY;
    const heightOfHeader = 74;
    const stickyClass = scrollTop >= heightOfHeader ? "-is-sticky" : '';
    setSticky(prev => stickyClass);
  }
  const panelClass = `styling-panel${sticky}`;

  const closeHandler = () => {
    setSidePanel(prev=> false)
    dispatch(setChosenComponent([{id:'', position: ''}]))
  }

  return ( 
  <div className="styling-panel-container">
    <div className={panelClass}>
      <div className="stilyng-panel-header">
        <div className="stilyng-panel-header-name">
          {headings[stylingComponent[0].typeOfContent]}
        </div>
        <div className="styling-panel-close-button" onClick={closeHandler}>
          <img src={closeIcon} alt="close"/>
        </div> 
        
      </div>
      <div className="side-panel-mode-choser">
        <div className={mode === 'styling' ? "side-panel-mode-chosen" :"side-panel-mode"} 
        onClick={() => setMode('styling')}>
          Styling
        </div>
        <div className={mode === 'actions' ? "side-panel-mode-chosen" :"side-panel-mode"} 
        onClick={() => setMode('actions')}>
          Actions
        </div>
        <div className={mode === 'classes' ? "side-panel-mode-chosen" :"side-panel-mode"} 
        onClick={() => setMode('classes')}>
          Classes
        </div>
        {stylingComponent[0].typeOfContent === 'module' &&
          <div className={mode === 'PDFformat' ? "side-panel-mode-chosen" :"side-panel-mode"} 
        onClick={() => setMode('PDFformat')}>
          PDF settings
        </div>}
      </div>
      <div className="styling-panel-body">
        {
          mode === 'styling' ?
          <StylingPanel active={mode==='styling'} stylingComponent={stylingComponent}/>
          :
          mode === 'actions' ?
          <ActionsPanel setPanel={setSidePanel} chosenComponent={stylingComponent}/>
          :
          mode === 'classes' ?
          <ClassesPanel chosenComponent={stylingComponent} classes={classes}/>
          :
          <PDFSettingsPanel />
        }
      </div>
    </div>
  </div>
  );
}
 
export default SidePanel;
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import emailjs from '@emailjs/browser';
import 'react-datepicker/dist/react-datepicker.css';
import { getCustomerSpaces } from "../../features/customerSpaces/customerSpaceSlice";
import { changeUser, getUsers, updateUser } from "../../features/users/userSlice";
import { getFolderIds } from "../../features/folderIds/folderIdSlice";
import { changeFolderRegister,  getFolderRegisters } from "../../features/folderRegister/folderRegisterSlice";
import RegisterRowsTableBody from "./RegisterRowsTableBody";
import SortableTh from "./SortableTh";


const RegisterRowsTable = () => {
  
  const template_id = 'template_3w75the'
  const service_id = 'service_cdtgthm'
  const public_key = '3WYeIk6ploNZtWWLi'

  const dispatch = useDispatch()
  const  { customerSpaces, isLoadingCS } = useSelector( 
    state => state.customerSpaces)
  const  { folderIds, isLoadingFId } = useSelector( 
    state => state.folderIds)
  const  { users, isLoadingU } = useSelector( 
    state => state.users)
  const  { folderRegisters, isLoadingFR } = useSelector( 
    state => state.folderRegisters)

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const [userUpdateTrigger, setUserUpdateTrigger] = useState(false)
  const [registerUpdateTrigger, setRegisterUpdateTrigger] = useState(false)
  const [registerData, setRegisterData] = useState([])
  
  const [sortedColumn, setSortedColumn] = useState('name');
  const [sortDirection, setSortDirection] = useState('asc');
  
  useEffect(() =>{
    setRegisterData(folderRegisters.filter(row => row.registerStatus !== 'archive'))
    //console.log('folderRegistersData: ',registerData)
  },[folderRegisters])

  const handleSort = (columnName) => {
    setSortedColumn(columnName);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    setRegisterData(prev => prev.sort((a, b) => {

        let valueA = a[columnName];
        let valueB = b[columnName];
        
        if(columnName ==='customerSpace' && customerSpaces && customerSpaces.length!== 0){
          valueA = customerSpaces.find(group => group._id === a.customerSpace).name;
          valueB = customerSpaces.find(group => group._id === b.customerSpace).name;
        }
        if(columnName ==='user' && users && users.length!== 0){
          valueA = users.find(user => user._id === a.user).email
          valueB = users.find(user => user._id === b.user).email
        }
        if(columnName ==='templateFolder' && folderIds && folderIds.length!== 0){
          valueA = folderIds.find(folder => folder._id === a.templateFolder).name
          valueB = folderIds.find(folder => folder._id === b.templateFolder).name
        }
        if(columnName ==='docFolder' && folderIds && folderIds.length!== 0){
          valueA = folderIds.find(folder => folder._id === a.docFolder).name
          valueB = folderIds.find(folder => folder._id === b.docFolder).name
        }

        if (sortDirection === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    )
    //console.log('customerSpacesData: ',customerSpacesData)
  }

  const generateFolderId = (folderType, type) => {
    let prefix = folderType === 'template' ? 'tf' : 'df';
    if (type === 'View') {
      prefix += 'v';
    } else if (type === 'Edit') {
      prefix += 'e';
    }
    return prefix;
  };

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        dispatch(getFolderIds())
        dispatch(getUsers())  
        setUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [updateTrigger])

  useEffect(() => {
    if(registerUpdateTrigger){
      setTimeout(() => {
        dispatch(getFolderRegisters())
        dispatch(getUsers())  
        setRegisterUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [registerUpdateTrigger])

  const [openRows, setOpenRows] = useState([]);

  const sendInvitationUserHandler = () => {
    if(openRows.length > 1){
      toast.error('Chose one user please', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{ 
      const userId = folderRegisters.find(row => row._id === openRows[0]).user
      const userToUpdate = users.find((user) => user._id === userId);
      if (userToUpdate.userStatus !== 'Active' && userToUpdate.userStatus !== 'Invite sent') {
      
        const emailData = {
          user_name: userToUpdate.name,
          user_email: userToUpdate.email,
          user_link: userToUpdate.invitationLink,
        }
        toast.promise( () => 
          emailjs.send(service_id, template_id, emailData, public_key),
          {
            pending: 'Sending invitation..',
            success: 'Invitation sent',
            error: 'Promise rejected 🤯'
          }
        )
        const updatedUser = { ...userToUpdate, userStatus: 'Invite sent' };
        dispatch(changeUser(updatedUser))
        setUserUpdateTrigger(true)
        setRegisterUpdateTrigger(true)
        setOpenRows([])
      } else {
        toast.error('This user is already invited', {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        })
      }
    }
  }
  const deleteRowsHandler = () => {
    openRows.forEach(row => { 
      const rowToUpdate = folderRegisters.find(reg => reg._id === row);
      const userToUpdate = users.find(user => user._id === rowToUpdate.user);
      if(userToUpdate){
        let userFolders = userToUpdate.folderIds
        const prefixTemplateFolderToDelete = generateFolderId('template', rowToUpdate.type)
        const prefixDocFolderToDelete = generateFolderId('document', rowToUpdate.type)
        
        //console.log('userFolders before delete: ', userFolders)
        userFolders = userFolders.filter(folder => {
          var isEqual = (folder.folderId == rowToUpdate.templateFolder) && (folder.prefix == prefixTemplateFolderToDelete)
          return !isEqual
        }).filter(folder => {
          var isEqual = (folder.folderId == rowToUpdate.docFolder) && (folder.prefix == prefixDocFolderToDelete)
          return !isEqual
        })
        //console.log('userFolders after : ', userFolders)
        const updatedUser = { ...userToUpdate, folderIds: userFolders };
        console.log('user data after delete from reg table: ', updatedUser)
        dispatch(changeUser(updatedUser))
        setUserUpdateTrigger(true)

      }
      setRegisterUpdateTrigger(true)
      setOpenRows([])
    })
  }

  const registerToggle = (index) => {
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows.includes(index);
      if (isOpen) {
        return prevOpenRows.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevOpenRows, index];
      }
    });
  };
  const [isRActionsMenu, setIsRActionsMenu] = useState(false)

  useEffect(() => {
    //console.log('openRows : ',openRows)
    openRows.length !== 0 ? setIsRActionsMenu(true) : setIsRActionsMenu(false) 
  }, [openRows])

  const addFoldersToUser = (rowId) => {
    const rowToUpdate = folderRegisters.find(reg => reg._id === rowId);
    let userFoldersData = []
    userFoldersData.push({
      folderId: rowToUpdate.templateFolder,
      prefix: generateFolderId('template', rowToUpdate.type),
    })
    userFoldersData.push({
      folderId: rowToUpdate.docFolder,
      prefix: generateFolderId('document', rowToUpdate.type),
    })
    const userUpdateData = {
      _id: rowToUpdate.user,
      folderIds: userFoldersData,
      type: 'folders',
    }
    dispatch(updateUser(userUpdateData))
  }

  const rActionsHandler = (option) => {
    openRows.forEach((rowId) => {
      const rowToUpdate = folderRegisters.find((row) => row._id === rowId);
      let formatedOption = option
      if(rowToUpdate.registerStatus == 'disactivated'){
        if(option === 'activate without invite' ||formatedOption === 'activate with invite'){
          addFoldersToUser(rowId);
        }
      }
      if(option === 'activate without invite'){
        formatedOption = 'Active';
      }
      if (rowToUpdate) {
        if(formatedOption === 'activate with invite'){
          
          const userId = folderRegisters.find(row => row._id === openRows[0]).user
          const userToUpdate = users.find((user) => user._id === userId);
          if(userToUpdate){
              sendInvitationUserHandler()
              let option = 'Activated with invited'
              const updatedRow = { ...rowToUpdate, registerStatus: option };
              dispatch(changeFolderRegister(updatedRow))
          }else{
            deleteRowsHandler()
          }
        }else{ 
          if(formatedOption === 'disactivated' || formatedOption === 'archive'){
            deleteRowsHandler()
            const updatedRow = { ...rowToUpdate, registerStatus: formatedOption };
            dispatch(changeFolderRegister(updatedRow))
          }else{
            const updatedRow = { ...rowToUpdate, registerStatus: formatedOption };
            toast.promise(
              dispatch(changeFolderRegister(updatedRow)),
              {
                pending: 'Applying changes',
                success: `Changed register state to ${formatedOption}`,
                error: 'Promise rejected 🤯'
              }
            )
          }
        }
      }
    })
    if (openRows.length !== 0){
      setUserUpdateTrigger(prev => true)
      setRegisterUpdateTrigger(true)
      setOpenRows([])
    }
  }

  return ( 
    <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Folder`s Register table
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th className="admin-control-table-empty"></th>
              <SortableTh columnName="customerSpace" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Customer space
              </SortableTh>
              <SortableTh columnName="user" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                User
              </SortableTh>
              <SortableTh columnName="registerStatus" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Status
              </SortableTh>
              <SortableTh columnName="templateFolder" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Template folder
              </SortableTh>
              <SortableTh columnName="docFolder" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Doc folder
              </SortableTh>
              <SortableTh columnName="type" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
                Type
              </SortableTh>
            </tr>
          </thead>
          {isLoadingFR || updateTrigger ? 
            <div className="admin-control-loading">
              <Spinner/>
            </div>
            : 
            <RegisterRowsTableBody tableData={registerData}
            customerSpaceList={customerSpaces} isLoadingCS={isLoadingCS}
            usersList={users} isLoadingU={isLoadingU}
            folderIdsList={folderIds} isLoadingFId={isLoadingFId}
            updateTrigger={updateTrigger} setRegisterUpdateTrigger={setRegisterUpdateTrigger}
            registerToggle={registerToggle} />
          }
        </table>
      </div>
      <>
        { isRActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => rActionsHandler('activate with invite')}>
                Activate with invite
              </div>
              <div className="admin-register-actions-option"
                onClick={() => rActionsHandler('activate without invite')}>
                Activate without invite
              </div>
              <div className="admin-register-actions-option"
                onClick={() => rActionsHandler('disactivated')}>
                Disactivate
              </div>
              <div className="admin-register-actions-option"
                onClick={() => rActionsHandler('archive')}>
                Archive
              </div>
          </div>
        }
      </>
    </div>
   );
}
 
export default RegisterRowsTable;
import { useDispatch } from "react-redux";
import { deleteComponentInLevel1, deleteComponentInLevel1Column, deleteComponentInLevel2, deleteComponentInLevel2Column, deleteComponentInModule, deleteComponentInModuleColumn, deleteModule } from "../../features/localDocument/editingDocumentSlice";
import DesignItemPreview from "./DesignItemPreview";
import { useCallback, useEffect, useState } from "react";
import { useDisplayChosenComponent } from "../../hooks/useDisplayChosenComponent";
import { createDesignItem } from "../../features/designItems/designItemSlice";

const ActionsPanel = ({setPanel, chosenComponent}) => {

  //chosenComponent[0] = part
  //chosenComponent[1] = position or id of parent
  //chosenComponent[2] = type of parent
  //chosenComponent[3] = position of columns holder

  const component = chosenComponent[0]
  const positionOfParent = chosenComponent[1]
  const typeOfParent = chosenComponent[2]
  const columnData = chosenComponent[3]
  
  useEffect(() => {
    console.log('this is chosen component in actions panel: ', chosenComponent)
  }, [chosenComponent])
  const [displayingComponent] = useDisplayChosenComponent(chosenComponent[0], typeOfParent, columnData)
  const [designItemName, setDesignItemName] = useState('')

  const dispatch = useDispatch()

  const deleteComponentHandler = () => {
    component.typeOfContent === 'module' ?  dispatch(deleteModule([component.id]))
    :
    (typeOfParent === 'module' && columnData === undefined) || component.typeOfContent === 'level1' ? 
    dispatch(deleteComponentInModule([component.position, positionOfParent]))
    :
    typeOfParent === 'module' && columnData !== undefined && component.typeOfContent !== 'column' ? 
    dispatch(deleteComponentInModuleColumn([component.position, columnData]))
    :
    typeOfParent === 'level1' && columnData === undefined ?
    dispatch(deleteComponentInLevel1([component.position, positionOfParent]))
    :
    typeOfParent === 'level1' && columnData !== undefined && component.typeOfContent !== 'column' ? 
    dispatch(deleteComponentInLevel1Column([component.position, columnData]))
    :
    typeOfParent === 'level2' && columnData === undefined ?
    dispatch(deleteComponentInLevel2([component.position, positionOfParent]))
    :
    typeOfParent === 'level2' && columnData !== undefined && component.typeOfContent !== 'column' ? 
    dispatch(deleteComponentInLevel2Column([component.position, columnData]))
    :
    console.log('')
    setPanel(prev => false)
  }
  const saveDesignItemHandler = () => {
    console.log('this is to save: ',displayingComponent)
    dispatch(createDesignItem({designItemBody: displayingComponent, name: designItemName}))
  }

  const renderDesignItem = useCallback(part => {
    return (
    <DesignItemPreview designItem={displayingComponent}/>
    )
  }, [displayingComponent])

  return ( 
  <>
    <div className="actions-panel-preview">
      {/* In development */}
      {renderDesignItem(component)}
    </div>
    <div className="actions-panel-row">
      <div className="actions-panel-design-item-input-container">
        <div style={{color:'grey'}}>Name:</div>
        <input type="text" className="actions-panel-design-item-input"
        value={designItemName}
        onChange={(e) => setDesignItemName(e.target.value)}
        />
      </div>
      <div className="actions-panel-save-button"
      onClick={saveDesignItemHandler}>
        Save a design item
      </div>
    </div>
    <div className="actions-panel-row">
      <div className="actions-panel-row-description" style={{color:'grey'}}>
        Delete this component from the document
        {/* <div style={{color:'grey'}}></div>
        <div style={{color:'grey'}}>Delete this component from the document</div> */}
      </div>
      <div className="actions-panel-delete-button"
      onClick={deleteComponentHandler}>
        Delete
      </div>
    </div>
  </> 
  );
}
 
export default ActionsPanel;
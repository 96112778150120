import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import { reset } from '../features/agreements/agreementSlice';
import SettingsHeader from '../components/SettingsHeader';
import google_drive_icon from '../icons/google-drive-icon.svg'
import IntegrationWindow from '../components/IntegrationWindow';
import { getUpToDateUser } from '../features/auth/authSlice';

const SettingsPage = ({setIsSettingsOpen, isFromWave1}) => {

	const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)
  const [isUserUpToDate, setIsUserUpToDate] = useState(false)

	const integrations = [
		{name: 'Google Drive', status:'Not connected', description:'Import your documents from Google Drive to the Majoto.'},
		{name: 'Future Integrations', status:'Not connected', description:'Custom description for future integrations to add.'},
	]
	const [integrationWindow, setIntegrationWindow] = useState(false)
	const [chosenIntegration, setChoseIntegration] = useState('')
	const [userIntegrations, setUserIntegrations] = useState([])
	
	const closeIntegrationWindow = () => {
		setIntegrationWindow(false);
		setChoseIntegration('');
	}

  useEffect(() => {

    if (!user) {
      navigate('/login')
    }
		if(user){
      if(!isUserUpToDate){
        dispatch(getUpToDateUser({_id: user._id}));
        setIsUserUpToDate(true)
      }
			console.log('user:  ',user)
			if(user && user.integrations){
				setUserIntegrations(user.integrations)
			}
		}

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, dispatch])

	return ( 
		<div className='wave1-content-board'>
			<SettingsHeader user={user} setIsSettingsOpen={setIsSettingsOpen} isFromWave1={isFromWave1}/>
				{user ? ( 
				<div className="settings-page-content">
					<div className="settings-page-heading" >Available integrations </div> 
					<div className="settings-page-body">
						{ userIntegrations.length !== 0 ?
							userIntegrations.map((integration, index) => {
								return(
									<div key={index} className={"settings-page-body-tile" + (chosenIntegration.name === integration.name ? ' chosen-settings-tile' : '')} 
									onClick={() => {setChoseIntegration(integration); setIntegrationWindow(true)}}>
										<div className="settings-page-body-tile-icon">
											<img src={google_drive_icon} alt="drive"/>
										</div>
										<div className="settings-page-body-tile-text">
											<div className="settings-page-body-tile-title">
												<div className="settings-page-body-tile-name">
													{integration.name}
												</div>
												<div className="settings-page-body-tile-status-connected">
													Connected
												</div>
											</div>
											<div className="settings-page-body-tile-description">
												Import your documents from Google Drive to the Majoto.
											</div>
										</div>
									</div>
								)
							})
							: 
							integrations.map((integration, index) => {
								return(
									<div key={index} className={"settings-page-body-tile" + (chosenIntegration.name === integration.name ? ' chosen-settings-tile' : '')} 
									onClick={() => {setChoseIntegration(integration); setIntegrationWindow(true)}}>
										<div className="settings-page-body-tile-icon">
											<img src={google_drive_icon} alt="drive"/>
										</div>
										<div className="settings-page-body-tile-text">
											<div className="settings-page-body-tile-title">
												<div className="settings-page-body-tile-name">
													{integration.name}
												</div>
												<div className="settings-page-body-tile-status">
													{integration.status}
												</div>
											</div>
											<div className="settings-page-body-tile-description">
													{integration.description}
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					<IntegrationWindow active={integrationWindow} closeWindow={closeIntegrationWindow} integration={chosenIntegration}/>
				</div>
				) : <div className="message">Log in to get access to page</div>}
		</div>
		);
}
 
export default SettingsPage;
import { useEffect, useState } from "react";
import checker from '../../icons/selected-checker-icon.svg'

const AgreementsListTableHead = ({ columns, handleSorting, isSelectedAll, setIsSelectedAll, 
  setIsSelectedAllTrigger, tableData, selectedNum }) => {
  const [sortField, setSortField] = useState("");
  const [order, setOrder] = useState("asc");

  const handleSortingChange = (accessor) => {
    const sortOrder =
      accessor === sortField && order === "asc" ? "desc" : "asc";
    setSortField(accessor);
    setOrder(sortOrder);
    handleSorting(accessor, sortOrder);
  };

  const onClickHandler = () => {
    setIsSelectedAllTrigger(prev => !prev)
    setIsSelectedAll(prev => !prev)
  }

  useEffect(() => {
    if(tableData.length !== selectedNum) setIsSelectedAll(false)
  }, [selectedNum])

  return (
    <thead>
      <tr>
        {columns.map(({ label, accessor, sortable }) => {
          const cl = sortable
            ? sortField === accessor && order === "asc"
              ? "up"
              : sortField === accessor && order === "desc"
              ? "down"
              : "default"
            : "";
          return (
            <th
              key={accessor}
              onClick={sortable ? () => handleSortingChange(accessor) : null}
              className={accessor === 'selector' ? 'agreements-list-selector' : accessor === 'three_dots' ? 'agreements-list-three-dots' : cl}
            >
              {accessor === 'selector' ? 
              <div className={isSelectedAll ? "agreements-list-selected" : "agreements-list-unselected"} onClick={() => onClickHandler()}>
                {isSelectedAll && <img src={checker}/>}
              </div> 
              : label}
            </th>
          );
        })}
      </tr>
    </thead>
  )
}
 
 export default AgreementsListTableHead;
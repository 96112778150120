import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { redirect } from 'react-router-dom'
import documentService from './documentService'

const initialState = {
  documents: [],
  newDocument: '',
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new document
export const createDocument = createAsyncThunk(
  'documents/create',
  async (documentData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await documentService.createDocument(documentData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user documents
export const getDocuments = createAsyncThunk(
  'documents/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await documentService.getDocuments(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update document
export const updateDocument = createAsyncThunk(
  'documents/update',
  async (document,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await documentService.updateDocument(document,  token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete user document
export const deleteDocument = createAsyncThunk(
  'documents/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await documentService.deleteDocument(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const documentSlice = createSlice({
  name: 'document',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createDocument.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.documents.push(action.payload)
        state.newDocument = action.payload
      })
      .addCase(createDocument.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getDocuments.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDocuments.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.documents = action.payload
      })
      .addCase(getDocuments.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateDocument.pending, (state) => {
        //state.isLoading = true
      })
      .addCase(updateDocument.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        // const indexDoc = state.documents.findIndex((element) => element._id === action.payload.documentId)
        // state.documents[indexDoc].body = action.payload.documentBody
      })
      .addCase(updateDocument.rejected, (state, action) => {
        //state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteDocument.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDocument.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.documents = state.documents.filter(
          (document) => document._id !== action.payload.id
        )
      })
      .addCase(deleteDocument.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = documentSlice.actions
export default documentSlice.reducer
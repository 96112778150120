import { useDispatch } from 'react-redux'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useLocation, useNavigate } from 'react-router-dom'
import google_drive_icon from '../icons/google-drive-icon.svg'

const IntegrationWindow = ({active, closeWindow, integration}) => {

  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
    
  const ref = useOnclickOutside(() => {
    active && closeWindow();
  })

  const onSubmit = (e) => {
    e.preventDefault()

    closeWindow()
  }
  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
      <div ref={ref} className="operation-form-content" style={{opacity: active && '1'}} onClick={event => event.stopPropagation}>
        <div className="integration-form-header">
          <div className="integration-form-header-title">
            <div className="settings-page-body-tile-icon">
              <img src={google_drive_icon} alt="drive"/>
            </div>
            {integration.name}
          </div>
          <div className="settings-page-body-tile-description">
              {integration.description}
          </div>
        </div>
        <form className="integration-form" onSubmit={onSubmit}>
          {
            integration.name === 'Google Drive' ?
            <form action="http://majoto.app/googleDrive/" method="get">
              <button className="integration-form-button" type='submit'>
                Connect 
              </button>
            </form>
            :
            <form action="http://majoto.app/googleDrive/" method="get">
              <button className="integration-form-button" type='submit'>
                Connect 
              </button>
            </form>   
          }
        </form>
      </div>
    </div>
  );
}
 
export default IntegrationWindow;
import React, { useCallback, useEffect, useState } from "react";
import PreviewColumn from "./PreviewColumn";

const holder_style = {
  display: 'grid',
  width:'90%',
  height: 'fit-content',
  overflow: 'hidden',
}

const PreviewColumnsHolder = ({ part}) => {

  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...holder_style, ...part.style, }))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...holder_style, ...part.style,}))
  }, [ part.style])

  const renderColumn = useCallback(content => {
    return (
      <PreviewColumn 
        key={content.position}
        part = {content}
      />
    )
  }, [])

  return (
    <div style={style}>
      {part.content.map((column, index) => 
      renderColumn(column, index))}
    </div>
  )
}
export default PreviewColumnsHolder
import { useCallback, useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { changeComponentInLevel2, changeComponentInModule, changeComponentInLevel1, changeComponentInModuleColumn, changeComponentInLevel1Column, changeComponentInLevel2Column, setChosenComponent, addComponentInModuleColumn, addComponentInLevel1Column, addComponentInLevel2Column } from "../../../features/localDocument/editingDocumentSlice";
import Heading from "./Heading"
import Level2 from "./Level2"
import SubHeading from "./SubHeading"
import TextArea from "./TextArea"
import ColumnsHolder from "./ColumnsHolder"
import Line from "./Line"
import Image from "./Image"
import ImageHolder from "./ImageHolder"
import Level3 from "./Level3";
import Level1 from "./Level1";

const DynamicPart = ({part, index, moduleId,
  changeClickability,
  parentData, holderData, parentType, 
  setStylingPanel, setStylingComponent,
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveChildFromParent }) => {

  const { chosenComponent } = useSelector(state => state.editingDocument)
  const dispatch = useDispatch()


  const [style, setStyle] = useState({})
  const [partSize, setPartSize] = useState({})

  const [isResizingOver, setIsResizingOver] = useState(true)
  const [levelNum, setLevelNum] = useState('')

  const changePartClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }
  
  useEffect(()=> {
    parentType === 'module' ?
    setLevelNum(prev => 'level1')
    :
    parentType === 'level1' ?
    setLevelNum(prev => 'level2')
    :
    parentType === 'level2' ?
    setLevelNum(prev => 'level3')
    :
    console.log('is not architecture`s component')
  }, [])

  useEffect(()=> {
    setStyle(prev => ({ ...part.style, width: '100%', height: '100%'}))
  }, [])
  useEffect(() => {
    setStyle(prev => ({ ...part.style, width: '100%', height: '100%'}))
  }, [ part.style])

  useEffect(() => {
    const resizableComponent = refBox.current;
    setPartSize(prev => ({
      width: resizableComponent.offsetWidth - 2*parseInt(part.style.padding),
      height: resizableComponent.offsetHeight - 2*parseInt(part.style.padding)
    }))
  },[dispatch, style])

  const ref = useRef(null)
  const refBox = useRef(null);
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'columns_holder', levelNum],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        if(item.type !== undefined && item.type !== 'columns_holder' 
        && (item.parentType === undefined || item.parentType === 'column')){
          if(item.type === 'new_image'){
            setUploadImageData('new_image');
            setUploadImageParent({
              position: part.id, 
              type: parentType,
              columnData:{holder: holderData, column: part.position, parent:parentData, moduleId: moduleId}
            }); 
            setImageForm(prev => true)
          }else{
            if(parentType === 'module'){
              dispatch(addComponentInModuleColumn([ item, moduleId, holderData, part.position]))
            }
            if(parentType === 'level1'){
              dispatch(addComponentInLevel1Column([ item, moduleId, holderData, part.position]))
            }
            if(parentType === 'level2'){
              dispatch(addComponentInLevel2Column([ item, moduleId, parentData, holderData, part.position]))
            }
          }
        }
      }
    },
  })
  drop(ref)
  const hoverRes = isOverCurrent

  const moveChild = useCallback(
    (dragIndex, hoverIndex, child) => {
    //   (child.part === undefined && child.parent === part.position) &&
    // dispatch(updatingComponentsOrderInLevel1(
    //   [dragIndex, hoverIndex, moduleId, part.position, child ]))
  }, []) 

  const renderLevel1 = useCallback((child, index) => {
    return (
      <Level1 
        key={child.position} part = {child} index={index}
        moduleId = {module.id} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel2 = useCallback((child, index) => {
    return (
      <Level2 
        key={child.position} part = {child} index={index}
        moduleId={moduleId}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {"level1"} 
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel3 = useCallback((child, index) => {
    return (
      <Level3 
        key={child.position} part = {child} index={index}
        moduleId={moduleId}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType} 
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderColumnsHolder = useCallback((child, index) => {
    return (
      <ColumnsHolder 
        key={child.position} part = {child} index={index}
        moduleId = {moduleId} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <TextArea 
        key={child.position} index={index} part={child} 
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <Heading 
        key={child.position} part = {child}  index={index}
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <SubHeading 
        key={child.position} part = {child} index={index}
        parentData = {part.position}  
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <ImageHolder 
        key={child.position}  part={child} index={index}
        changeClickability={changePartClickability}
        parentSize={partSize}
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setImageForm={setImageForm}
        setUploadImageData={setUploadImageData} 
        setUploadImageParent={setUploadImageParent}  
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImage = useCallback((child, index) => {
    return (
      <Image 
        key={child.position} part={child} index={index}
        changeClickability={changePartClickability}
        parentSize={partSize}
        parentData = {part.position} 
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <Line 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])

  useEffect(() => {
    const onDynamicPartClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, parentData, parentType, {holder: holderData, column: part.position, parent:parentData}]);
        setStylingPanel(prev=> true)
      }
    }

    const dynamicPartRef = ref.current;
    dynamicPartRef.addEventListener("dblclick", onDynamicPartClick);

    return () => {
      dynamicPartRef.removeEventListener("dblclick", onDynamicPartClick);
    };
  }, [isResizingOver])

  return (
    <div ref={refBox} className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{height: style.height, width: style.width,}}  
    >
    <div ref={ref} data-handler-id={handlerId} draggable="false" style={{...style, height: '100%', width:'100%'}}>
      {part.content.length !== 0 ? 
        part.content.map( (child, index) => 
          child.typeOfContent === 'text_field' ?
          renderTextArea(child, index)
          : 
          child.typeOfContent === "level1" ? 
          renderLevel1(child, index)
          : 
          child.typeOfContent === "level2" ? 
          renderLevel2(child, index)
          : 
          child.typeOfContent === "level3" ? 
          renderLevel3(child, index)
          : 
          child.typeOfContent === "columns_holder" ? 
          renderColumnsHolder(child, index)
          : 
          child.typeOfContent === "heading" ? 
          renderHeading(child, index)
          : 
          child.typeOfContent === "subheading" ? 
          renderSubHeading(child, index)
          : 
          child.typeOfContent === "image_holder" ?
          renderImageHolder(child, index)
          : 
          child.typeOfContent === "image" ?
          renderImage(child, index)
          :
          child.typeOfContent === "line" ? 
          renderLine(child, index)
          : console.log('ceivo', child)
        )
      :  <></>
      }
    </div>
    </div>
  )
}

export default DynamicPart
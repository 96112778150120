import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import editorPanelService from './editorPanelService'

//Get user from sessionStorage 
const user = JSON.parse(sessionStorage.getItem('user'))

const initialState = {
  editorPanel: {},
//   { 
//     applying: 'Default',
//     body:
//     [
//     {
//       header: "Structure",
//       isChosen: true,
//       components: [{
//         header: "Architecture",
//         isOpen: false,
//         components: [ 
//           {name:'Module', type:'module'},
//           {name:'Level1', type:'level1'},
//           {name:'Level2', type:'level2'},
//           {name:'Level3', type:'level3'}
//         ]
//       }]
//     },
//     {
//       header: "Components",
//       isChosen: false,
//       components: [{
//         header: "Text components",
//         isOpen: false,
//         components: [ 
//           {name:'Heading', type:'heading'},
//           {name:'Text field', type:'text_field'}
//         ]
//       }]
//     }
//   ]
// },
  isErrorEditorPanel: false,
  isSuccessEditorPanel: false,
  isLoadingEditorPanel: false,
  messageEditorPanel: '',
}

// Create new editorPanel
export const createEditorPanel = createAsyncThunk(
  'editorPanels/create',
  async (editorPanelData, thunkAPI) => {   
    try {
      const token = thunkAPI.getState().auth.user.token
      return await editorPanelService.createEditorPanel(editorPanelData, token)
    } catch (error) {
      const messageEditorPanel =
        (error.response &&
          error.response.data &&
          error.response.data.messageEditorPanel) ||
        error.messageEditorPanel ||
        error.toString()
      return thunkAPI.rejectWithValue(messageEditorPanel)
    }
  }
)

// Get user editorPanels
export const getEditorPanels = createAsyncThunk(
  'editorPanels/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await editorPanelService.getEditorPanels(token)
    } catch (error) {
      const messageEditorPanel =
        (error.response &&
          error.response.data &&
          error.response.data.messageEditorPanel) ||
        error.messageEditorPanel ||
        error.toString()
      return thunkAPI.rejectWithValue(messageEditorPanel)
    }
  }
)
// Get default user`s editorPanel
export const getDefaultEditorPanel = createAsyncThunk(
  'editorPanels/default',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await editorPanelService.getDefaultEditorPanel(token)
    } catch (error) {
      const messageEditorPanel =
        (error.response &&
          error.response.data &&
          error.response.data.messageEditorPanel) ||
        error.messageEditorPanel ||
        error.toString()
      return thunkAPI.rejectWithValue(messageEditorPanel)
    }
  }
)
// Update editorPanel
export const updateEditorPanel = createAsyncThunk(
  'editorPanels/update',
  async (editorPanel,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await editorPanelService.updateEditorPanel(editorPanel,  token)
    } catch (error) {
      const messageEditorPanel =
        (error.response &&
          error.response.data &&
          error.response.data.messageEditorPanel) ||
        error.messageEditorPanel ||
        error.toString()
      return thunkAPI.rejectWithValue(messageEditorPanel)
    }
  }
)

// Delete user editorPanel
export const deleteEditorPanel = createAsyncThunk(
  'editorPanels/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await editorPanelService.deleteEditorPanel(id, token)
    } catch (error) {
      const messageEditorPanel =
        (error.response &&
          error.response.data &&
          error.response.data.messageEditorPanel) ||
        error.messageEditorPanel ||
        error.toString()
      return thunkAPI.rejectWithValue(messageEditorPanel)
    }
  }
)

 
export const editorPanelSlice = createSlice({
  name: 'editorPanelSlice',
  initialState,
  reducers: {
    setTemplateEditorStage: (state, action) => {
      const stage = action.payload
      state.templateEditorStage = stage
      sessionStorage.setItem('editorPanel', JSON.stringify(state))
    },
    setChosenHeader: (state, action) => {
      const chosenHeader = action.payload

      state.editorPanel.body.map(header => {
        header.header !== chosenHeader 
        ? header.isChosen = false 
        : header.isChosen = true
      })
    },
    addNewHeader: (state, action) => {
      const newHeaderName = action.payload

      const newHeader = {
        header: newHeaderName,
        isChosen: false,
        components: []
      }
      state.editorPanel.body.push(newHeader)
    },
    changeColumnHeader: (state, action) => {
      const newHeaderName = action.payload[0]
      const columnIndex = action.payload[1]

      state.editorPanel.body[columnIndex].header = newHeaderName
    },
    addNewComponentGroup: (state, action) => {
      const headerName = action.payload[0]
      let newComponentGroupName = action.payload[1]

      const headerIndex = state.editorPanel.body.findIndex(header => header.header === headerName)

      const checkSameNames = state.editorPanel.body[headerIndex].components.findIndex(componentGroup => 
        componentGroup.header === newComponentGroupName)
      console.log('checkSameNames  ',checkSameNames)
      const newComponentGroup = {
        header: checkSameNames === -1 ? newComponentGroupName : newComponentGroupName+'(1)',
        isChosen: false,
        components: []
      }
      state.editorPanel.body[headerIndex].components.push(newComponentGroup)
    },
    deleteComponentGroup: (state, action) => {
      const columnHeader = action.payload[0]
      const groupIndex = action.payload[1]

      const columnIndex = state.editorPanel.body.findIndex(header => header.header === columnHeader)
      state.editorPanel.body[columnIndex].components.splice(groupIndex, 1)
    },
    changeComponentGroupHeader: (state, action) => {
      const newHeaderName = action.payload[0]
      const columnHeader = action.payload[1]
      const groupIndex = action.payload[2]

      const columnIndex = state.editorPanel.body.findIndex(header => header.header === columnHeader)
      state.editorPanel.body[columnIndex].components[groupIndex].header = newHeaderName
    },
    addNewComponentIntoGroup: (state, action) => {
      const headerName = action.payload[0]
      const componentGroupName = action.payload[1]
      const addingComponent = action.payload[2]

      const headerIndex = state.editorPanel.body.findIndex(header => header.header === headerName)
      console.log(headerIndex, headerName)
      const componentGroupIndex = state.editorPanel.body[headerIndex].components.findIndex(componentGroup => componentGroup.header === componentGroupName)
      const newComponent = {
        name: addingComponent.name,
        body: addingComponent.body !== undefined ? addingComponent.body : '',
        type: addingComponent.body !== undefined ? 'design_item' : addingComponent.type,
        style: addingComponent.style !== undefined ? addingComponent.style : {},
        Snum: addingComponent.Snum !== undefined ? addingComponent.Snum : '1'
      }

      const isInsertYet = state.editorPanel.body[headerIndex].components[componentGroupIndex].components.find(component => 
        (component.name === newComponent.name && component.type === newComponent.type))
      //console.log('adding newComponent and its insertion : ',newComponent, isInsertYet)

      if(isInsertYet === undefined){
        state.editorPanel.body[headerIndex].components[componentGroupIndex].components.push(newComponent)
      } else{
        //make a label, that this component is already inserted
      }
    },
    deleteComponentFromGroup: (state, action) => {
      const columnHeader = action.payload[0]
      const groupHeader = action.payload[1]
      const componentIndex = action.payload[2]

      const columnIndex = state.editorPanel.body.findIndex(header => header.header === columnHeader)
      const groupIndex = state.editorPanel.body[columnIndex].components.findIndex(group => group.header === groupHeader)
      state.editorPanel.body[columnIndex].components[groupIndex].components.splice(componentIndex, 1)
    },
    setComponentGroupOpen: (state, action) => {
      const chosenHeader = action.payload[0]
      const chosenComponentGroup = action.payload[1]
      state.editorPanel.body.map(header => {
        if(header.header === chosenHeader ){
          header.components.map(componentGroup => {
          if(componentGroup.header === chosenComponentGroup) componentGroup.isOpen = !componentGroup.isOpen
          }) 
        }
      })
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createEditorPanel.pending, (state) => {
        state.isLoadingEditorPanel = true
      })
      .addCase(createEditorPanel.fulfilled, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isSuccessEditorPanel = true
        state.editorPanel = action.payload
      })
      .addCase(createEditorPanel.rejected, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isErrorEditorPanel = true
        state.messageEditorPanel = action.payload
      })
      .addCase(getEditorPanels.pending, (state) => {
        state.isLoadingEditorPanel = true
      })
      .addCase(getEditorPanels.fulfilled, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isSuccessEditorPanel = true
        state.editorPanel = action.payload[0]
      })
      .addCase(getEditorPanels.rejected, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isErrorEditorPanel = true
        state.messageEditorPanel = action.payload
      })
      .addCase(getDefaultEditorPanel.pending, (state) => {
        state.isLoadingEditorPanel = true
      })
      .addCase(getDefaultEditorPanel.fulfilled, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isSuccessEditorPanel = true
        console.log(action) 
        state.editorPanel = action.payload[0]
      })
      .addCase(getDefaultEditorPanel.rejected, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isErrorEditorPanel = true
        console.log(action)
        state.messageEditorPanel = action.payload
      })
      .addCase(updateEditorPanel.pending, (state) => {
        //state.isLoadingEditorPanel = true
      })
      .addCase(updateEditorPanel.fulfilled, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isSuccessEditorPanel = true
        //state.editorPanel = action.payload
      })
      .addCase(updateEditorPanel.rejected, (state, action) => {
        //state.isLoadingEditorPanel = false
        state.isErrorEditorPanel = true
        state.messageEditorPanel = action.payload
      })
      .addCase(deleteEditorPanel.pending, (state) => {
        state.isLoadingEditorPanel = true
      })
      .addCase(deleteEditorPanel.fulfilled, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isSuccessEditorPanel = true
        state.editorPanel = {}
      })
      .addCase(deleteEditorPanel.rejected, (state, action) => {
        state.isLoadingEditorPanel = false
        state.isErrorEditorPanel = true
        state.messageEditorPanel = action.payload
      })
  },
})

export const {
  setTemplateEditorStage,
  setChosenHeader,
  addNewHeader,
  changeColumnHeader,
  addNewComponentGroup,
  deleteComponentGroup,
  changeComponentGroupHeader,
  addNewComponentIntoGroup,
  deleteComponentFromGroup,
  setComponentGroupOpen,
} = editorPanelSlice.actions

export default editorPanelSlice.reducer 
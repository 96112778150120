import { createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import authService from './authService'

//Get user from sessionStorage 
//const user = JSON.parse(sessionStorage.getItem('user'))
const user = JSON.parse(localStorage.getItem('user'))
const invite_user = JSON.parse(sessionStorage.getItem('invite_user'))

const initialState = {
  user: user ? user: null,
  isError: false,
  isSuccess: false,
  isLoading: false, 
  message: '',
  invite_user: invite_user ? invite_user : null
}

// Register user
export const register = createAsyncThunk(
  'auth/register',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Add user
export const add = createAsyncThunk(
  'auth/add',
  async (user, thunkAPI) => {
    try {
      return await authService.add(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// get user
export const get = createAsyncThunk(
  'auth/get',
  async (user, thunkAPI) => {
    try {
      return await authService.get(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// get all user
export const getAll = createAsyncThunk(
  'auth/getAll',
  async (user, thunkAPI) => {
    try {
      return await authService.getAll(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update user
export const update = createAsyncThunk(
  'auth/update',
  async (user, thunkAPI) => {
    try {
      return await authService.register(user)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Login user
export const login = createAsyncThunk(
  'auth/login',
  async (user, thunkAPI) => {
  try {
    return await authService.login(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})
// Login google user
export const loginGoogle = createAsyncThunk(
  'auth/loginGoogle',
  async (user, thunkAPI) => {
  try {
    return await authService.loginGoogle(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})
// get up to date user
export const getUpToDateUser = createAsyncThunk(
  'auth/getUpToDate',
  async (user, thunkAPI) => {
  try {
    return await authService.getUpToDateUser(user)
  } catch (error) {
    const message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()
    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

export const authSlice = createSlice({
  name:'auth',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false
      state.isSuccess = false
      state.isError = false
      state.message = ''
    }
  },
  extraReducers: (builder) => {
    builder.addCase(register.pending, (state) => {
      state.isLoading = true
    })
    .addCase(register.fulfilled, (state, action ) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    .addCase(register.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.user = null
    })
    .addCase(getUpToDateUser.pending, (state) => {
      state.isLoading = true
    })
    .addCase(getUpToDateUser.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    .addCase(getUpToDateUser.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.user = null
    })
    .addCase(login.pending, (state) => {
      state.isLoading = true
    })
    .addCase(login.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    .addCase(login.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.user = null
    })
    .addCase(loginGoogle.pending, (state) => {
      state.isLoading = true
    })
    .addCase(loginGoogle.fulfilled, (state, action) => {
      state.isLoading = false
      state.isSuccess = true
      state.user = action.payload
    })
    .addCase(loginGoogle.rejected, (state, action) => {
      state.isLoading = false
      state.isError = true
      state.message = action.payload
      state.user = null
    })
    .addCase(logout.fulfilled, (state) => {
      state.user = null
    })
  }
})

export const {  reset } = authSlice.actions
export default authSlice.reducer 
import axios from 'axios'

const API_URL = '/api/usersAdmin/'

// Create new user
const createUser = async (userData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, userData, config)

  return response.data
}

// Get user users
const getUsers = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}
// change user document
const changeUser = async (userData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let userBody = userData
  let userId = userData._id;
  const response = await axios.post(API_URL + userId, userBody, config)

  return response.data
}
// update user document
const updateUser = async (userData,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let userBody = userData  
  let userId = userData._id;
  const response = await axios.put(API_URL + userId, userBody, config)

  return response.data
}

// Delete user user
const deleteUser = async (userId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + userId, config)

  return response.data
}

const userService = {
  createUser,
  getUsers,
  changeUser,
  updateUser,
  deleteUser,
}

export default userService
import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel1ColumnStyleDoc, addComponentInLevel2ColumnStyleDoc, addComponentInModuleColumnStyleDoc, 
  setChosenComponent} from "../../../features/localDocument/editingStyleDocumentSlice"
import StyleHeading from "./StyleHeading";
import StyleSubHeading from "./StyleSubHeading";
import StyleTextArea from "./StyleTextArea";
import StyleLevel1 from "./StyleLevel1";
import StyleLevel2 from "./StyleLevel2";
import StyleLevel3 from "./StyleLevel3";
import StyleLine from "./StyleLine";
import StyleImageHolder from "./StyleImageHolder";

const dropColumnStyle = {
  minHeight:'40px',
  height:'fit-content',
  width:'60%',
  maxWidth: '90%',
  display:'flex',
  flexDirection:'column',
  borderRadius: '8px',
  position: 'relative',
  background:'#FFFFFF',
  gap: '8px',
}

const StyleDropColumn = ({part, index, moduleId, 
  parentData, holderData, parentType, 
  setParentShow,
  setStylingPanel, setStylingComponent,
  moveColumns}) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()
  const [isShown, setIsShown] = useState(false)
  const [isClickable, setIsClickable] = useState(true)
  const [style, setStyle] = useState({})
  useEffect(()=> {
    setStyle(prev => ({...dropColumnStyle, ...part.style, width:'90%', height:'fit-content'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...dropColumnStyle, ...part.style, width:'90%', height:'fit-content'}))
  }, [part.style])

  const ref = useRef(null)  
  useEffect(() => {
    const onColumnClick = (event) => {
      console.log('clicked on column')
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([part, parentData, parentType, {holder: holderData, column: part.position, parent:parentData}]);
      setStylingPanel(prev=> true)
    }
  
    const columnRef = ref.current;
    columnRef.addEventListener("click", onColumnClick);
  
    return () => {
      columnRef.removeEventListener("click", onColumnClick);
    };
  }, [])
  const [{ isOverCurrent, handlerId }, drop] = useDrop({
    accept: ['component', 'part'],
    collect(monitor) {
      return {
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( item ){
      if(isOverCurrent){
        if(item.type !== undefined && item.type !== 'columns_holder' 
        && (item.parentType === undefined || item.parentType === 'column')){
          if(parentType === 'module'){
            dispatch(addComponentInModuleColumnStyleDoc([ item, moduleId, holderData, part.position]))
          }
          if(parentType === 'level1'){
            dispatch(addComponentInLevel1ColumnStyleDoc([ item, moduleId, {holder: holderData, parent:parentData}, part.position]))
          }
          if(parentType === 'level2'){
            dispatch(addComponentInLevel2ColumnStyleDoc([ item, moduleId, parentData, holderData, part.position]))
          }
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientX = clientOffset.x - hoverBoundingRect.left
      if (dragIndex < hoverIndex && hoverClientX < hoverMiddleX) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientX > hoverMiddleX) {
        return
      }
      if(item.part !== undefined && item.parentData === holderData){
        moveColumns(dragIndex, hoverIndex)
        item.index = hoverIndex
      }
    },
  })

  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentData: holderData}
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return isShown
    }
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))

  const moveChild = useCallback(() => {}, []) 

  const changeChild = useCallback(
    (child) => {}, []) 

  const hoverRes = isOverCurrent 
  const renderLevel1 = useCallback((child, index) => {
    return (
      <StyleLevel1 
        key={child.position}
        part = {child} 
        index={index}
        moduleId = {module.id} 
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        setParentShow = {setIsShown}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel2 = useCallback((child, index) => {
    return (
      <StyleLevel2 
        key={child.position}
        part = {child} 
        index={index}
        moduleId={moduleId}
        parentData = {part.position}
        parentType = {"level1"} 
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLevel3 = useCallback((child, index) => {
    return (
      <StyleLevel3 
        key={child.position}
        part = {child} 
        index={index}
        moduleId={moduleId}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        parentData = {part.position}
        parentType = {parentType} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <StyleTextArea 
        key={child.position}
        part={child}
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <StyleHeading 
        key={child.position}
        part = {child} 
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}}
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <StyleSubHeading 
        key={child.position}
        part = {child} 
        index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent} 
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderImageHolder = useCallback((child, index) => {
    return (
      <StyleImageHolder 
        key={child.position} part={child} index={index}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown}
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <StyleLine 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        columnData = {{holder: holderData, column: part.position, parent:parentData}} 
        parentType = {parentType}
        setParentShow = {setIsShown} 
        setStylingPanel={setStylingPanel}
        setStylingComponent={setStylingComponent}
        moveChildFromParent = {moveChild}
      />
    )
  }, [])

  return (

    <div className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{width: style.width, height: style.height}}>
    <div ref={ref} data-handler-id={handlerId} 
    style={{...style, height: '100%', width:'100%',
      opacity}} 
      >
      {part.content.length !== 0 ? 
        part.content.map( (child, index) => 
          child.type === 'text_field' ?
          renderTextArea(child, index)
          : 
          child.type === "heading" ? 
          renderHeading(child, index)
          : 
          child.type === "subheading" ? 
          renderSubHeading(child, index)
          : 
          child.type === "image_holder" ?
          renderImageHolder(child, index)
          : 
          child.type === "level1" ? 
          renderLevel1(child, index)
          : 
          child.type === "level2" ? 
          renderLevel2(child, index)
          : 
          child.type === "level3" ? 
          renderLevel3(child, index)
          : 
          child.type === "line" ? 
          renderLine(child, index)
          : console.log('ceivo', child)
        )
      : <></>}
      </div>
    </div>
  )
}

export default StyleDropColumn
import { TextPart, Structure, ModuleComponent, 
  HeadingPart, Level, SubHeadingPart, 
  LinePart, TablePart, DesignItem, ColumnsHolder, ImagePart, ImageHolderPart, DynamicBlock } from "./DocumentEditorComponents";
import StylesList from "./StylesList";
import DesignItemsList from "./DesignItemsList";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from 'react-textarea-autosize';
import { setChosenHeader, setComponentGroupOpen, addNewHeader, addNewComponentGroup, createEditorPanel, getEditorPanels, getDefaultEditorPanel, updateEditorPanel, changeComponentGroupHeader, changeColumnHeader, deleteComponentGroup, deleteComponentFromGroup } from "../../features/editorPanel/editorPanelSlice";
import plus from '../../icons/component-list-plus-icon.svg'
import checker from '../../icons/selected-checker-icon.svg'
import trashBasket from "../../icons/trashcan-white-icon.svg"
import Spinner from "../Spinner";
import DocumentEditorComponentsPanelForm from "./DocumentEditorComponentsPanelForm";
import ComponentsPanelScrollbar from "./ComponentsPanelScrollbar";


const ComponentsPanel = ({chosenStyle, setChosenStyle, viewEditorMode, setChangeClassTrigger}) => {

  const {editorPanel, isLoadingEditorPanel } =  useSelector(
    state => state.editorPanel)

  const dispatch = useDispatch()

  const [panelForm, setPanelForm] = useState(false)
  const [currentHeader, setCurrentHeader] = useState('')
  const [currentComponentGroup, setCurrentComponentGroup] = useState('')
  
  const [newHeaderName, setNewHeaderName] = useState('Write a name')
  const [newComponentGroupName, setNewComponentGroupName] = useState('New name')
  const [isAdding, setIsAdding] = useState(false)

  const [hoveringGroupHeader, setHoveringGroupHeader] = useState('')
  const [hoveringComponent, setHoveringComponent] = useState('')

  const [updateTrigger, setUpdateTrigger] = useState(false) 

  useEffect( () => {
    if (!isLoadingEditorPanel && editorPanel === undefined){
      dispatch(getDefaultEditorPanel())
    }
  },[isLoadingEditorPanel, editorPanel])


  //opening group header handler
  const changeChosenHeaderHandler = (header) => {
    dispatch(setChosenHeader(header))
  }
  //opening group header handler
  const openGroupHandler = (header, groupName) => {
    dispatch(setComponentGroupOpen([header, groupName]))
  }

  //updating state of editor panel when openGroupHandler goes on
  useEffect(() => {
    dispatch(updateEditorPanel(editorPanel))
  }, [openGroupHandler])

  //updating state of editor panel only when view-editor mode is On
  useEffect(() => {
    if(viewEditorMode){
      dispatch(updateEditorPanel(editorPanel))
    }
  }, [editorPanel])

  useEffect(() => {
    dispatch(getEditorPanels())
  }, [])

  const addHandler = () => {
    dispatch(addNewHeader(newHeaderName))
    setIsAdding(prev => false)
    setNewHeaderName(prev => 'Write a name')
  }

  const addComponentGroupHandler = (header) => {
    dispatch(addNewComponentGroup([header, newComponentGroupName]))
    setNewComponentGroupName(prev => 'Component group')
  }

  const addComponentHandler = (header, componentGroup) => {
    setCurrentHeader(prev => header)
    setCurrentComponentGroup(prev => componentGroup)
    setPanelForm(prev => true)
  }

  const [sticky, setSticky] = useState("");

  useEffect(() => {
    const scrollTop = window.scrollY;
    const heightOfHeader = 74;
    const stickyClass = scrollTop >= heightOfHeader ? "-is-sticky" : '';
    setSticky(prev => stickyClass);
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    }
  }, [])

  const isSticky = () => {
    const scrollTop = window.scrollY;
    const heightOfHeader = 74;
    const stickyClass = scrollTop >= heightOfHeader ? "-is-sticky" : '';
    setSticky(prev => stickyClass);
  }
  const panelClass = `components-panel${sticky}`;

  return ( 
    <>
    <DocumentEditorComponentsPanelForm active={panelForm} setActive={setPanelForm}
    currentHeader={currentHeader} currentComponentGroup={currentComponentGroup}/>
    { 
    isLoadingEditorPanel || editorPanel === undefined || Object.keys(editorPanel).length === 0 ? 
    <div className="components-panel">
      <Spinner/>
    </div>
    :
   <div className="components-panel-container">
    <div className={panelClass}>
      { viewEditorMode && (!isAdding && editorPanel.body.length < 4) && 
      <div className="components-panel-headers-plus" onClick={() => setIsAdding(prev => true)}>
        <img src={plus} alt='plus'/>
      </div>
      }
      <div className="components-panel-headers">
        {
          editorPanel.body.map((header, index) => (
            <>
              {viewEditorMode ?
                <div className={header.isChosen ? "components-panel-header-s" 
                : "components-panel-header"}
                onClick={ () => changeChosenHeaderHandler(header.header)}>
                  <TextareaAutosize className="components-panel-header-input"
                  value={header.header} 
                  onChange={(e) => {dispatch(changeColumnHeader([e.target.value, index]))}}
                  />
                </div>
                :
                <div key={index}
                  className={header.isChosen ? "components-panel-header-s" 
                  : "components-panel-header"} 
                  onClick={ () => changeChosenHeaderHandler(header.header)}>
                  {header.header}
                </div>
                }
            </>
          ))
        }
        {  isAdding && 
          <div className= "components-panel-header"
            onClick={ () => addNewHeader()}>
            <TextareaAutosize className="components-panel-header-input" 
              value={newHeaderName}
              onChange={(e) => setNewHeaderName(prev => e.target.value)}/>
              <div className="dropdown-components-adding-checker" onClick={() => addHandler()}>
                <img src={checker}/>
              </div>
          </div>
        }
      </div>
      <div className="components-panel-body">
        { 
          editorPanel.body.map(header => (
            header.isChosen && 
            <ComponentsPanelScrollbar>
             {
              header.components.map((componentGroup, index) => (
              <div key={index} className="dropdown-body-components-group">
                {viewEditorMode ?
                <div className="dropdown-compontent-group-header-input-container"
                onMouseEnter={() => setHoveringGroupHeader(componentGroup.header)}
                onMouseLeave={() => setHoveringGroupHeader(prev => '')}
                >
                  <TextareaAutosize className="components-panel-group-header-input"
                  value={componentGroup.header} 
                  onClick={() => openGroupHandler(header.header, componentGroup.header)}
                  onChange={(e) => {dispatch(changeComponentGroupHeader([e.target.value, header.header, index]))}}
                  />
                  <div className="dropdown-components-deleting-button" 
                  style={{display: hoveringGroupHeader === componentGroup.header ? 'flex' : 'none'}}
                  onClick={() => dispatch(deleteComponentGroup([header.header, index]))}>
                    <img style={{width:'14px', height:'14px'}} src={trashBasket}/>
                  </div>
                </div>
                :
                <div className="dropdown-architecture-header"
                onClick={() => openGroupHandler(header.header, componentGroup.header)}>
                  {componentGroup.header}
                </div>
                }
                {componentGroup.isOpen && componentGroup.header !== 'Styles' ? 
                <div className={componentGroup.header ==='Architecture' ? "dropdown-architecture-content" : "dropdown-elements-container"}>
                  {componentGroup.components.map((component, index) => (
                    <div style={{position: 'relative'}}
                    onMouseEnter={() => setHoveringComponent(index)}
                    onMouseLeave={() => setHoveringComponent(prev => '')}>
                    {component.type==='module' ?
                    <ModuleComponent key={index}/>
                    : component.type==='level1' ?
                    <Level key={index} num={1}/>
                    : component.type==='level2' ?
                    <Level key={index} num={2}/>
                    : component.type==='level3' ?
                    <Level key={index} num={3}/>
                    : component.type==='heading' ?
                    <HeadingPart key={index} />
                    : component.type==='subheading' ?
                    <SubHeadingPart key={index} />
                    : component.type==='text_field' ?
                    <TextPart key={index} />
                    : component.type==='line' ?
                    <LinePart key={index} />
                    : component.type==='image_holder' ?
                    <ImageHolderPart key={index} />
                    : component.type==='image' ?
                    <ImagePart key={index} />
                    : component.type==='design_item' ?
                    <DesignItem key={index} item={component}/>
                    : component.type==='dynamic_block' ?
                    <DynamicBlock key={index} item={component}/>
                    : component.type==='columns_holder' ?
                    <ColumnsHolder key={index} Snum={component.Snum} 
                    gridTemplate={component.style.gridTemplateColumns}/>
                    : component.type==='design_items' ?
                    <DesignItemsList key={index}/>
                    : <></>}
                    <div className="dropdown-components-deleting-button" 
                    style={{display: viewEditorMode && hoveringComponent === index ? 'flex' : 'none'}}
                    onClick={() => dispatch(deleteComponentFromGroup([header.header, componentGroup.header, index]))}>
                      <img style={{width:'14px', height:'14px'}} src={trashBasket}/>
                    </div>
                    </div>
                  ))}
                  { viewEditorMode && 
                  <div onClick={() => addComponentHandler(header.header, componentGroup.header)} className="dropdown-component-plus">
                    <img src={plus} alt='plus'/>
                  </div>
                  }
                </div>
                : componentGroup.isOpen && componentGroup.header === 'Styles' ? 
                  <StylesList chosenStyle={chosenStyle} setChosenStyle={setChosenStyle} setChangeClassTrigger={setChangeClassTrigger}/>
                : <></>}
              </div>
              ))
            }
            { viewEditorMode && 
              <div className="dropdown-architecture-container">
                <div className="dropdown-architecture-header">
                  <TextareaAutosize className="components-panel-group-header-input"
                    value={newComponentGroupName}
                    onChange={(e) => setNewComponentGroupName(prev => e.target.value)}/>
                  <div className="dropdown-components-adding-checker" onClick={() => addComponentGroupHandler(header.header)}>
                    <img src={checker}/>
                  </div> 
                </div>
              </div>
            }
          </ComponentsPanelScrollbar>
          ))
        }
      </div>
    </div> 
  </div>
    }
  </>
  );
}
 
export default ComponentsPanel;

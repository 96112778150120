import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import arrow from "../../icons/arrow-icon.svg"
import plus from '../../icons/plus-grey-icon.svg'
import save from '../../icons/save-admin-row-icon.svg'
import close from '../../icons/right-panel-close.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import moment from "moment";
import 'react-datepicker/dist/react-datepicker.css';
import {createFolderId} from "../../features/folderIds/folderIdSlice";
import FolderIdsTableRow from "./FolderIdsTableRow";


const FolderIdsTableBody = ({tableData, customerSpaceList, isLoadingCS, updateTrigger, 
  setFolderUpdateTrigger, folderIdToggle, openCreatingWindow}) => {

  const dispatch = useDispatch()
 
  const [folderIdsData, setFolderIdsData] = useState([])
  const [customerSpacesData, setCustomerSpacesData] = useState([])
  
  useEffect(() => {
    setFolderIdsData(tableData)
  }, [tableData]);

  useEffect(() => {
    setCustomerSpacesData(customerSpaceList)
  }, [customerSpaceList]);

  const [folderIdCustomerSpace, setFolderIdCustomerSpace] = useState('not selected')
  const [folderIdName, setFolderIdName] = useState('');
  const [folderIdNum, setFolderIdNum] = useState('');
  const [folderIdType, setFolderIdType] = useState('Docs');
  const [folderIdStatus, setFolderIdStatus] = useState('')
  const [folderIdComments, setFolderIdComments] = useState('');
  
  const [isFolderIdTypeDown, setIsFolderIdTypeDown] = useState(false)
  const refFolderIdType = useOnclickOutside(() => {
    isFolderIdTypeDown && setIsFolderIdTypeDown(false);
  })
  const [isFolderIdCustomerSpaceDown, setIsFolderIdCustomerSpaceDown] = useState(false)
  const refFolderIdCustomerSpace = useOnclickOutside(() => {
    isFolderIdCustomerSpaceDown && setIsFolderIdCustomerSpaceDown(false);
  })

  const [isFolderIdAdding, setIsFolderIdAdding] = useState(false)
  const [folderIdSavingStatus, setFolderIdSavingStatus] = useState(false)

  const folderIdHandleSave = () => {
    if(folderIdName === '' || folderIdNum === ''
    || folderIdType === ''){
      toast.error('Fill all inputs !', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
      setFolderIdSavingStatus('Please, fill all fields')
    }else{
      setFolderIdSavingStatus('Saving a new folder id ')
      const folderIdData = {
        customerSpace: folderIdCustomerSpace,
        name: folderIdName,
        id: folderIdNum,
        folderType: folderIdType,
        comments: folderIdComments,
      };
      console.log('saved new folder ids ')
      toast.promise(
        dispatch(createFolderId(folderIdData)),
        {
          pending: 'Saving..',
          success: 'Saved new folder id',
          error: 'Promise rejected 🤯'
        }
      )
      setFolderUpdateTrigger(true)
      setIsFolderIdAdding(false)
      setFolderIdCustomerSpace('not selected')
      setFolderIdName('')
      setFolderIdNum('')
      setFolderIdType('')
      setFolderIdStatus('')
      setFolderIdComments('')
    }
  };

  return ( 
    <tbody>
      { folderIdsData.length !== 0 ? 
        <>
        {folderIdsData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)).map((folder, index) => 
          (<FolderIdsTableRow key={index} folder={folder} index={index} 
            updateTrigger={updateTrigger} folderIdToggle={folderIdToggle} 
            setUpdateTrigger={setFolderUpdateTrigger} setFolderUpdateTrigger={setFolderUpdateTrigger}/>)
        )}
        {
          !isFolderIdAdding ?
          <tr className="admin-control-usual-row"> 
            <td className="admin-control-space-selector" 
            onClick={() => {
              openCreatingWindow(true); 
              //setIsFolderIdAdding(true)
              }}>
              <div className="admin-control-adding-row">
                <img src={plus} alt='yes'/>
              </div>
            </td>
          </tr>
          :
          <tr className="admin-control-usual-row">
            <td className="admin-control-form-save-td">
              <img className="admin-control-form-save" 
              onClick={folderIdHandleSave}
              src={save} alt="save"/>
            </td>
            <td>
              <div ref={refFolderIdCustomerSpace} className="admin-selector-container">
                <div className="admin-selector-header" onClick={ () => setIsFolderIdCustomerSpaceDown(prev => !prev)}>
                  {folderIdCustomerSpace}
                  <img style={{marginLeft:'8px', transform:( isFolderIdCustomerSpaceDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isFolderIdCustomerSpaceDown && 
                  <div className="admin-selector-body">
                    {
                      isLoadingCS || updateTrigger ? <Spinner/> : customerSpacesData.length !== 0 ?
                      <>
                        {customerSpacesData.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                        <div className="admin-selector-body-option" onClick={() =>  setFolderIdCustomerSpace(space.name)}>
                          {space.name}
                        </div>
                        ))}
                      </>
                      : <div>There are no customer spaces yet </div>
                    }
                  </div>
                }
              </div>
            </td>
            <td>
              <input
                type="text"
                value={folderIdNum}
                onChange={(e) => setFolderIdNum(e.target.value)}
                required
              />
            </td>
            <td>
              <input
                type="text"
                value={folderIdName}
                onChange={(e) => setFolderIdName(e.target.value)}
                required
              />
            </td>
            <td> 
              {moment.utc(new Date()).format('D MMMM yyyy')}
            </td>
            <td>
              <div ref={refFolderIdType} className="admin-selector-container">
                <div className="admin-selector-header" onClick={ () => setIsFolderIdTypeDown(prev => !prev)}>
                  {folderIdType}
                  <img style={{marginLeft:'8px', transform:( isFolderIdTypeDown && 'rotate(-180deg)'), 
                  transition:'260ms linear all'}} src={arrow} alt="arrow"/>
                </div>
                { isFolderIdTypeDown && 
                <div className="admin-selector-body">
                  <div className="admin-selector-body-option" onClick={() => setFolderIdType('Docs')}>
                    Docs
                  </div>
                  <div className="admin-selector-body-option" onClick={() => setFolderIdType('Templates')}>
                    Templates
                  </div>
                </div> }
              </div>
            </td>
            <td>
              New
            </td>
            <td>
              <input
                type="text"
                value={folderIdComments}
                onChange={(e) => setFolderIdComments(e.target.value)}
              />
            </td>
            <td>
              <img className="admin-control-form-close" 
              onClick={() => setIsFolderIdAdding(false)}
              src={close} alt="close"/>
            </td>
          </tr>
        }
      </>
      : 
      !isFolderIdAdding ?
      <tr className="admin-control-usual-row"> 
        <td className="admin-control-space-selector" 
        onClick={() => {
          openCreatingWindow(true); 
          //setIsFolderIdAdding(true)
          }}>
          <div className="admin-control-adding-row">
            <img src={plus} alt='yes'/>
          </div>
        </td>
      </tr>
      :
      <tr className="admin-control-usual-row">
        <td className="admin-control-form-save-td">
          <img className="admin-control-form-save" 
          onClick={folderIdHandleSave}
          src={save} alt="save"/>
        </td>
        <td>
          <div ref={refFolderIdCustomerSpace} className="admin-selector-container">
            <div className="admin-selector-header" onClick={ () => setIsFolderIdCustomerSpaceDown(prev => !prev)}>
              {folderIdCustomerSpace}
              <img style={{marginLeft:'8px', transform:( isFolderIdCustomerSpaceDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isFolderIdCustomerSpaceDown && 
              <div className="admin-selector-body">
                {
                  isLoadingCS || updateTrigger ? <Spinner/> : customerSpacesData.length !== 0 ?
                  <>
                    {customerSpacesData.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                    <div className="admin-selector-body-option" onClick={() =>  setFolderIdCustomerSpace(space.name)}>
                      {space.name}
                    </div>
                    ))}
                  </>
                  : <div>There are no customer spaces yet </div>
                }
              </div>
            }
          </div>
        </td>
        <td>
          <input
            type="text"
            value={folderIdNum}
            onChange={(e) => setFolderIdNum(e.target.value)}
            required
          />
        </td>
        <td>
          <input
            type="text"
            value={folderIdName}
            onChange={(e) => setFolderIdName(e.target.value)}
            required
          />
        </td>
        <td> 
          {moment.utc(new Date()).format('D MMMM yyyy')}
        </td>
        <td>
          <div ref={refFolderIdType} className="admin-selector-container">
            <div className="admin-selector-header" onClick={ () => setIsFolderIdTypeDown(prev => !prev)}>
              {folderIdType}
              <img style={{marginLeft:'8px', transform:( isFolderIdTypeDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isFolderIdTypeDown && 
            <div className="admin-selector-body">
              <div className="admin-selector-body-option" onClick={() => setFolderIdType('Docs')}>
                Docs
              </div>
              <div className="admin-selector-body-option" onClick={() => setFolderIdType('Templates')}>
                Templates
              </div>
            </div> }
          </div>
        </td>
        <td>
          New
        </td>
        <td>
          <input
            type="text"
            value={folderIdComments}
            onChange={(e) => setFolderIdComments(e.target.value)}
          />
        </td>
        <td>
          <img className="admin-control-form-close" 
          onClick={() => setIsFolderIdAdding(false)}
          src={close} alt="close"/>
        </td>
      </tr>
    }
    </tbody>
   );
}
 
export default FolderIdsTableBody;
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { register, reset } from '../features/auth/authSlice'
import Spinner from '../components/Spinner'
import logo_icon from '../icons/majoto-logo-icon.svg'
import Select from 'react-select';
import { getInvitedUser } from '../features/invitations/invitationSlice'

const  Register = () =>  {

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    role:'user',
    password: '',
    password2: '',
    isRegistered: true,
  })

  const { name, email, role, password, password2, isRegistered } = formData

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const {token} = useParams()
  const { invitedUser, isLoadingIU } = useSelector(state => state.invitedUser)
  const [isSearchingForInvite, setIsSearchingForInvite] = useState(false)

  useEffect(() => {
    if(token){
      dispatch(getInvitedUser(token)) 
    }
  }, [])

  useEffect(() => {
    if(invitedUser){
      setFormData(prevData => ({ ...prevData,
        name: invitedUser.name,
        email: invitedUser.email,
      }))
    }
  }, [invitedUser])

  const roles = [
    { value: 'user', label: 'User' },
    { value: 'super_admin', label: 'Super admin*' },
  ]

  const { user, isLoading, isError, isSuccess, message } = useSelector(
    (state) => state.auth
  )

  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    if (isSuccess || user) {
      navigate('/welcome')
    }
    
    if(token){
      dispatch(getInvitedUser(token)) 
    }

    dispatch(reset())
  }, [user, isError, isSuccess, token, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
  }

  const onSubmit = (e) => {
    e.preventDefault()

    if (password !== password2) {
      toast.error('Passwords do not match')
    } else {
      const userData = {
        name,
        role,
        email,
        password,
        isRegistered,
      }

      dispatch(register(userData))
    }
  }

  if (isLoading) {
    return <Spinner />
  }

  return (
    <div className='auth-background'>
      <div className='auth-body'>
        <div><img src={logo_icon} alt="logo"/></div>
        <div className='auth-content'>
          <div className='auth-form-name'>
            Sign up
          </div>
        <form onSubmit={onSubmit}>
          <div className='auth-form-group'>
            <label htmlFor="name">Your name</label>
            <input
              type='text'
              className="input-field col s6"
              id='name' name='name'
              value={formData.name}
              placeholder='Enter your name'
              onChange={onChange}
            />
          </div>
          {/* <div className='auth-form-group'>
            <label htmlFor="role">Role</label>
            <Select 
            options={roles}
            onChange={(e) => {
              setFormData(prevState => ({
              ...prevState,
              ["role"]: e.value,})
              )}}
            />
          </div> */}
          <div className='auth-form-group'>
            <label htmlFor="email">Email address</label>
            <input
              type='email'
              className="input-field col s6"
              id='email' name='email'
              value={formData.email}
              placeholder='Email address'
              onChange={onChange}
            />
          </div>
          <div className='auth-form-group'>
            <label htmlFor="password">Password</label>
            <input
              type='password'
              className="validate"
              id='password' name='password'
              value={password}
              placeholder='Minimum 8 characters'
              onChange={onChange}
            />
          </div>
          <div className='auth-form-group'>
          <label htmlFor="password2">Confirm password</label>
            <input
              type='password'
              className="validate"
              id='password2' name='password2'
              value={password2}
              placeholder='Confirm password'
              onChange={onChange}
            />
          </div>
          <div className='auth-form-bottom'>
            <button type='submit' className='auth-btn'>
              Sign up
            </button>
            <div className='auth-form-variation'>
              <div>Already got an account ?</div>
              <a href='/login'>Log in</a>
            </div>
          </div>
        </form>
        
        </div>
      </div>
    </div>
  )
}

export default Register
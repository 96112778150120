import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import DocumentEditorHeader from "../components/document_editor_components/DocumentEditorHeader";
import SelectBody from "../components/SelectBody";
import ShortMenu from "../components/ShortMenu";
import { reset} from "../features/documents/documentSlice";

const SelectPage = () => {

	const navigate = useNavigate()
	const dispatch = useDispatch()
	const {id} = useParams()

	const {user} = useSelector((state) => state.auth)
	
	useEffect(() => {
    if (!user) {
      navigate('/login')
    }

    return () => {
      dispatch(reset())
    }
  }, [user, navigate])


	return ( 
		<div className='content-board'>
			<ShortMenu chosenPage={''}/>
			<div className='board-body'>
				{ user ? 
				<>
					<DocumentEditorHeader stage={'select'} id={id}/>
					<SelectBody/>
				</>
				:
				<div className="message">Log in to get access to data</div>}
			</div>
		</div>
		);
}
 
export default SelectPage;
import AgreementsListTableRow from './AgreementsListTableRow';

const AgreementsListTableBody = ({ tableData, columns, setSelectedAgreements, setSelectedNum, 
  isSelectedAll, isSelectedAllTrigger, chosenAgreement, setChosenAgreement }) => {
  return (
    <tbody>
      {tableData.map(data => {
        return <AgreementsListTableRow key={data._id} rowData={data} columns={columns} setSelectedAgreements={setSelectedAgreements}
        setSelectedNum={setSelectedNum} isSelectedAll={isSelectedAll} isSelectedAllTrigger={isSelectedAllTrigger}
        chosenAgreement={chosenAgreement} setChosenAgreement={setChosenAgreement}/>
      })}
    </tbody>
  )
 }
 
 export default AgreementsListTableBody;
import { useSelector } from "react-redux";
import Spinner from "../Spinner";
import moment from "moment";

const DashboardControl = () => {

  const  { customerSpaces, isLoadingCS} = useSelector( 
    state => state.customerSpaces)
  const  { folderIds, isLoadingFId} = useSelector( 
    state => state.folderIds)
  const  { users, isLoadingU } = useSelector( 
    state => state.users)
  const  { folderRegisters, isLoadingFR} = useSelector( 
    state => state.folderRegisters)
  

  return ( 
    <div className="admin-register-body">
      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Customer spaces
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th>Customer space</th>
              <th>Start date</th>
              <th>Duration</th>
              <th>Payment status</th>
              <th>Type</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoadingCS ? <div className="admin-control-loading"><Spinner/></div>
              : customerSpaces.length !== 0 ? 
              <>
              {customerSpaces.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
              <tr className="admin-control-usual-row"> 
                <td>{space.name}</td>
                <td>{moment.utc(space.start_date).format('D MMMM yyyy')}</td>
                <td> 
                  {!space.duration.type ?
                    ' '
                    :
                    space.duration.type !== 'months' ?
                    space.duration.duration
                    :
                    space.duration.duration+' months'
                  }
                </td>
                <td>{space.payment_status}</td>
                <td>{space.type}</td>
                <td>{space.comments}</td>
              </tr>
            ))}
            </>
            : <div></div>
            }
            
          </tbody>
        </table>
        {customerSpaces.filter(space => space.customerSpaceStatus !== 'archive').length === 0 && 
        <div className="admin-controll-archive-message">There are no customer spaces </div>}
      </div>
      <>
      </>
      </div>

      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Spaces and folders
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th>Customer space</th>
              <th>Folder ID</th>
              <th>Name</th>
              <th>Creation date</th>
              <th>Folder type</th>
              <th>Status</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
          {
              isLoadingFId ? <div className="admin-control-loading"><Spinner/></div>
              : folderIds.length !== 0 ? 
              <>
              {folderIds.filter(folder => folder.folderStatus !== 'archive').map((folder, index) => (
                <tr className="admin-control-usual-row"> 
                  <td>{folder.customerSpace}</td>
                  <td>{folder.id}</td>
                  <td>{folder.name}</td>
                  <td>{moment.utc(folder.createdAt).format('D MMMM yyyy')}</td>
                  <td>{folder.folderType}</td>
                  <td>{folder.folderStatus}</td>
                  <td>{folder.comments}</td>
                </tr>
              ))}
            </>
            : <div></div>
          }
          </tbody>
        </table>
        {folderIds.filter(folder => folder.folderStatus !== 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no folder ids</div>}
      </div>
      <>
      </>
      </div>

      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Users
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th>User name</th>
              <th>Email</th>
              <th>Added on</th>
              <th>Last access</th>
              <th>Status</th>
              <th>Comments</th>
            </tr>
          </thead>
          <tbody>
          {
              isLoadingU ? 
              <div className="admin-control-loading"><Spinner/></div>
              : users.length !== 0 ? 
              <>
            {users.filter(user => user.userStatus !== 'archive').map((user, index) => (
              <tr className="admin-control-usual-row"> 
                <td>{user.name}</td>
                <td>{user.email}</td>
                <td>{moment.utc(user.createdAt).format('D MMMM yyyy')}</td>
                <td>{user.userStatus !== 'Inactive' &&  moment.utc(user.lastAccess).format('D MMMM yyyy')}</td>
                <td>{user.userStatus}</td>
                <td>{user.comments}</td>
              </tr>
            ))}
            </>
            : <div></div>
            }
          </tbody>
        </table>
        {users.filter(user => user.userStatus !== 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no users </div>}
      </div>
      <>
      </>
      </div>
     
      <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Folder`s Register table
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
            <tr>
              <th>Customer space</th>
              <th>User</th>
              <th>Status</th>
              <th>Template folder</th>
              <th>Doc folder</th>
              <th>Type</th>
            </tr>
          </thead>
          <tbody>
            {
              isLoadingCS || isLoadingFId || isLoadingU || isLoadingFR ? <div className="admin-control-loading"><Spinner/></div>
               : customerSpaces.length!== 0 && folderIds.length!== 0 && users.length!== 0 && folderRegisters.length !== 0  ? 
            <>
                {folderRegisters.filter(row => row.registerStatus !== 'archive').map((row, index) => (
                <tr className="admin-control-usual-row"> 
                  <td>{customerSpaces.find(group => group._id === row.customerSpace).name}</td>
                  <td>{users.find(user => user._id === row.user).email}</td>
                  <td>{row.registerStatus}</td>
                  <td>{folderIds.find(folder => folder._id === row.templateFolder).name}</td>
                  <td>{folderIds.find(folder => folder._id === row.docFolder).name}</td>
                  <td>{row.type}</td>
                </tr>
                ))}
            </>
            : <tr></tr>
            }
          </tbody>
        </table>
        {folderRegisters.filter(row => row.registerStatus !== 'archive').length === 0  && 
        <div className="admin-controll-archive-message">There are no register`s rows</div>}
      </div>
      <>
      </>
      </div>
    </div>
   );
}
 
export default DashboardControl;
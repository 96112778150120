import { Page, StyleSheet } from '@react-pdf/renderer';
import { useCallback } from 'react';
import TextPDF from './Text';
import LevelFrament from './LevelFragment';
import LineFragment from './LineFragment';

const PagePDF = ({part, index}) => {

  const styles = StyleSheet.create({
    module: part.style
  })


  const renderText = useCallback((child, index) => {
    return (
      <TextPDF 
        key={child.position}
        part = {child}
        index={index}/>
    )
  }, [])
  const renderLine = useCallback((child, index) => {
    return (
      <LineFragment 
        key={child.position}
        part = {child}
        index={index}/>
    )
  }, [])
  const renderLevel = useCallback((child, index) => {
    return (
      <LevelFrament 
        key={child.position}
        part = {child}
        index={index}/>
    )
  }, [])
  return (
    <Page style={styles.module}>
      { 
      part.content.length !== 0 ?
      part.content.map((child, index) => 
        child.typeOfContent === 'text_field' ?
        renderText(child, index)
        : 
        child.typeOfContent === 'heading' ? 
        renderText(child, index)
        : 
        child.typeOfContent === 'subheading' ? 
        renderText(child, index)
        :
        child.typeOfContent === 'line'?
        renderLine(child, index) 
        :
        child.typeOfContent === "level1" ? 
        renderLevel(child, index)
        : 
        // child.typeOfContent === "columns_holder" ? 
        // renderColumnsHolder(child, index)
        // : 
        ''
      ) : ''
      }   
    </Page>
  )
};

export default PagePDF

import { useEffect, useState } from 'react';
import moment from 'moment'
import checker from '../../icons/selected-checker-icon.svg'
import more from "../../icons/more-horizontal-icon.svg"
import pin from "../../icons/agreement-pin-icon.svg"
import AgreementDropdownOptions  from './AgreementDropdownOptions';


const AgreementsListTableRow = ({ rowData, columns, setSelectedAgreements, setSelectedNum, 
  isSelectedAll, isSelectedAllTrigger,
  chosenAgreement, setChosenAgreement }) => {

  const [isSelected, setIsSelected] = useState(false)
  const [ isDropList, setIsDropList] = useState(false)

  const onClickHandler = () => {
    isSelected && setSelectedNum(prev => --prev) 
    isSelected && setSelectedAgreements(prev => prev.filter(agreement => agreement._id !== rowData._id))
    !isSelected && setSelectedNum(prev => ++prev)
    !isSelected && setSelectedAgreements(prev => [...prev, rowData])
    setIsSelected(prev => !prev)
  }

  useEffect(() => {
    isSelectedAll && !isSelected && setSelectedNum(prev => ++prev) 
    isSelectedAll && !isSelected && setSelectedAgreements(prev => [...prev, rowData])
    isSelectedAll && !isSelected && setIsSelected(prev => !prev)
    !isSelectedAll && isSelected && setSelectedNum(prev => --prev) 
    !isSelectedAll && isSelected && setSelectedAgreements(prev => prev.filter(agreement => agreement._id !== rowData._id))
    !isSelectedAll && isSelected && setIsSelected(prev => !prev)
  }, [isSelectedAllTrigger])

  const handleDropList = (part_id) => {
    if(isDropList){
      if(part_id === chosenAgreement){
        setIsDropList(prev=> false)
      } else{
        setChosenAgreement(prev => part_id)
      }
    }else{
      setChosenAgreement(prev => part_id)
      setIsDropList(prev => true)  
    }
  }

  return (
    <tr className={isSelected ? 'agreements-list-row-selected' : ''}>
    {columns.map(({ accessor }) => {
     if(accessor === 'selector'){
       return (
       <td key={accessor} className="agreements-list-selector" onClick={() => onClickHandler()}>
         <div className={isSelected ? "agreements-list-selected" : "agreements-list-unselected"}>
           {isSelected && <img src={checker} alt='yes'/>}
         </div>
       </td>
       )
     }else{
      if(accessor === 'three_dots'){
        return (
        <td key={accessor} className="agreements-list-selector">
          <div className="agreement-list-three-dots"
          onClick={() => handleDropList(rowData._id)}>
            <img src={more} alt="more"/>
            {isDropList && chosenAgreement === rowData._id && 
              <AgreementDropdownOptions id={rowData._id}/>
              }
          </div>
        </td>
        )
      } else {
        const tData = rowData[accessor] ? rowData[accessor] : "——";
        return <td key={accessor}>
          <div className='agreement-list-row-div'>
            {accessor === 'name' && rowData.isPinned && <img src={pin} alt='pin'/>}
            {accessor === 'updatedAt' ? moment(tData).format('D MMMM YYYY') : tData}
          </div>
          </td>
      }
    }
    })}
   </tr>
  );
 };
 
 export default AgreementsListTableRow;
import select_file from '../icons/select_file.svg'
import select_bookopen from '../icons/select_book-open.svg'
import select_copy from '../icons/select_copy.svg'
import select_codesandbox from '../icons/select_codesandbox.svg'
import { useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'

const select_form_content = {
  backgroundColor: '#FFFFFF',
  border: '1px solid #DADFF2',
  boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  gap: '40px',
  minWidth: '420px',
  maxWidth: '68vw',
  opacity:'0',
  transition: '0.2s all',
}

const SelectForm = ({active, setActive, setSelectChoice}) => {

  const [chosenOption, setChosenOption] = useState('')
  const ref = useOnclickOutside(() => {
    active && setActive(prev => false);
  })

  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active &&'all'}}>
      <div ref={ref} style={active ? { ...select_form_content, opacity: '1'} : select_form_content} >
        <div className="select-tittle">
          What would you like here? 
        </div>
        <div className="select-options">
          <div className="select-option">
            <div className="select-image">
              <img src={select_file} alt="file"/>
            </div>
            <div className="select-option-tittle">
              An empty placeholder
            </div>
            <div className={chosenOption === "file" ? "select-option-describe-s" : "select-option-describe"}>
              Insert an empty clause, formatted in the templates’s style
            </div>
            <div className={chosenOption === "file" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "file")}}>
              {chosenOption === "file" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
          <div className="select-option">
            <div className="select-image">
              <img src={select_bookopen} alt="book-open"/>
            </div>
            <div className="select-option-tittle">
              A clause from the library
            </div>
            <div className={chosenOption === "book" ? "select-option-describe-s" : "select-option-describe"}>
              Insert a clause block from the clause library
            </div>
            <div className={chosenOption === "book" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "book")}}>
              {chosenOption === "book" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
          <div className="select-option">
            <div className="select-image">
              <img src={select_codesandbox} alt="codesandbox"/>
            </div>
            <div className="select-option-tittle">
              A design pattern
            </div>
            <div className={chosenOption === "design" ? "select-option-describe-s" : "select-option-describe"}>
              Insert a visual item from the design library
            </div>
            <div className={chosenOption === "design" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "design")}}>
              {chosenOption === "design" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
          <div className="select-option">
            <div className="select-image">
              <img src={select_copy} alt="copy"/>
            </div>
            <div className="select-option-tittle">
              Clone the previous 
            </div>
            <div className={chosenOption === "copy" ? "select-option-describe-s" : "select-option-describe"}>
              Create a copy of the last clause of the same level. 
            </div>
            <div className={chosenOption === "copy" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "copy")}}>
              {chosenOption === "copy" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
        </div>
        <button className='select-insert-btn' onClick={() => setActive(prev => false)}>
          Insert
        </button>
      </div>
    </div>
   );
}
 
export default SelectForm;
import axios from 'axios'

const API_URL = '/api/designItems/'

// Create new designItem
const createDesignItem = async (designItemData, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL, designItemData, config)

  return response.data
}

// Get user designItems
const getDesignItems = async (token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.get(API_URL, config)

  return response.data
}

// update user designItem
const updateDesignItem = async (designItem,  token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  let designItemBody = designItem.body
  let designItemId = designItem._id;
  const response = await axios.put(API_URL + designItemId, designItemBody, config)

  return response.data
}
// Delete user designItem
const deleteDesignItem = async (designItemId, token) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.delete(API_URL + designItemId, config)

  return response.data
}

const designItemService = {
  createDesignItem,
  getDesignItems,
  updateDesignItem,
  deleteDesignItem,
}

export default designItemService
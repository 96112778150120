import { configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import agreementReducer from '../features/agreements/agreementSlice'
import customerSpaceSlice from '../features/customerSpaces/customerSpaceSlice'
import documentReducer from '../features/documents/documentSlice'
import designItemReducer from '../features/designItems/designItemSlice'
import styleReducer from '../features/styles/styleSlice'
import editingDocumentSlice  from '../features/localDocument/editingDocumentSlice'
import editorPanelSlice from '../features/editorPanel/editorPanelSlice'
import editingStyleDocumentSlice from '../features/localDocument/editingStyleDocumentSlice'
import styleEditorPanelSlice  from '../features/styleEditorPanel/styleEditorPanelSlice'
import userSlice from '../features/users/userSlice'
import folderIdSlice from '../features/folderIds/folderIdSlice'
import folderRegisterSlice from '../features/folderRegister/folderRegisterSlice'
import invitationSlice from '../features/invitations/invitationSlice'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    agreements: agreementReducer,
    customerSpaces: customerSpaceSlice,
    documents: documentReducer,
    designItems: designItemReducer,
    folderIds: folderIdSlice,
    folderRegisters: folderRegisterSlice,
    styles: styleReducer,
    editingDocument: editingDocumentSlice,
    editingStyleDocument: editingStyleDocumentSlice,
    editorPanel: editorPanelSlice,
    styleEditorPanel: styleEditorPanelSlice,
    users: userSlice,
    invitedUser: invitationSlice,
  },
})
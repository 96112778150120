import React, { useCallback, useEffect, useState } from "react";
import PreviewLevel from "./PreviewLevel";
import PreviewLine from "./PreviewLine";
import PreviewText from "./PreviewText";

let level1_style = {
  minHeight:'60px',
  height: 'fit-content',
  width:'90%',
  display:'flex',
  flexDirection:'column',
}

const PreviewColumn = ({ part}) => {

  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...level1_style, ...part.style, width:'90%',  height: 'fit-content',}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...level1_style, ...part.style, width:'90%',  height: 'fit-content',}))
  }, [part.style])

  const renderLevel = useCallback((content) => {
    return (
      <PreviewLevel 
        key={content.position}
        part = {content} 
      />
    )
  }, [])
  const renderTextField = useCallback(content => {
    return (
      <PreviewText 
        key={content.position}
        part = {content}
      />
    )
  }, [])
  const renderLine = useCallback(content => {
    return (
      <PreviewLine 
        key={content.position}
        part = {content}
      />
    )
  }, [])

  return (
    <div 
    style={style}
      className='level-holder'>
        {part.length !== 0 ?
         part.content.map( content => 
         (content.typeOfContent === "level1" || content.typeOfContent === "level2" || content.typeOfContent === "level3") ? 
          renderLevel(content)
          : content.typeOfContent === "heading" || content.typeOfContent === "subheading" || content.typeOfContent === "text_field" ? 
          renderTextField(content)
          : content.typeOfContent === 'line' ?
          renderLine(content)
          : <></>
          )
        :
        <></>}
    </div> 
  )
}
export default PreviewColumn
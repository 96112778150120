import { useEffect,  useState } from "react"
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch } from "react-redux";
import { addNewComponentIntoGroup } from "../../features/styleEditorPanel/styleEditorPanelSlice";

const structure_frame_style = {
  border: '2px dashed darkslategray',
  borderRadius: '8px',
}
const group_category_list = [
  'All',
  'Recently used',
  'Recently built',
  'Favourites',
]
const categories_list = [
  'Architecture',
  'Text components',
  'Images',
  'Geometric figures',
  'Columns',
  'Dynamic components',
  // 'Agreement maps',
  // 'Swimlanes',
  // 'Timelines',
  // 'User journeys',
  // 'Clause blocks',
  // 'Tables and charts',
  // 'Majoto specials',
  // 'Your custom designs',
]

const architecture_list = [
  {name: 'Module', type: 'module'},
  {name: 'Level', type: 'level'}
]
const text_components_list = [
  {name: 'Heading', type: 'heading'},
  {name: 'Subheading', type: 'subheading'},
  {name: 'Text field', type: 'text_field'}
]

const image_components_list = [
  {name: 'Image', type: 'image'},
  {name: 'Image holder', type: 'image_holder'},
]
const geometric_components_list = [
  {name: 'Line', type: 'line'},
]
const columns_components_list = [
  {name: 'Columns holder 1 1', type: 'columns_holder', Snum:'2', style:{ gridTemplateColumns: '1fr 1fr'}},
  {name: 'Columns_holder 1 1 1', type: 'columns_holder',Snum:'3', style:{ gridTemplateColumns: '1fr 1fr 1fr'}},
  {name: 'Columns_holder 1 1 1 1', type: 'columns_holder',Snum:'4', style:{ gridTemplateColumns: '1fr 1fr 1fr 1fr'}},
  {name: 'Columns_holder 1 2', type: 'columns_holder', Snum:'2', style:{ gridTemplateColumns: '1fr 2fr'}},
]
const dynamic_components_list = [
  {name: 'Dynamic block', type: 'dynamic_block'},
]
const StyleEditorComponentsPanelForm = ({active, setActive, currentHeader, currentComponentGroup}) => {

  const dispatch = useDispatch()

  const [category, setCategory] = useState('All')
  const [chosenComponent, setChosenComponent] = useState('')
  
  const ref = useOnclickOutside(() => {
    setActive(prev => false);
    setChosenComponent(prev => '')
  })

  const handleComponentClick = (optionName) => {
    optionName === chosenComponent ? 
    setChosenComponent(prev => '')
    :
    setChosenComponent(prev=> optionName)
  }

  const handleInsertClick = (option) => {
    dispatch(addNewComponentIntoGroup([currentHeader, currentComponentGroup, option]))
    setActive(prev=> false)
    setChosenComponent(prev => '')
  }

  return ( 
  <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active && 'all'}}>
    <div ref={ref} className="panel-form-content" style={{opacity: active && '1'}} 
    onClick={event => event.stopPropagation}>
      
      <div className="panel-components-header">
        Select a component
      </div>

      <div className="panel-components-body">
        <div className="panel-components-list">
          <div className="panel-components-list-categories">
            {
              group_category_list.map( (option, index) => (
                <div key={index} 
                className={category === option ? "panel-components-list-category-selected" :"panel-components-list-category"}
                onClick={() => setCategory(prev=> option)}>
                  {option}
                </div>
              ))
            }
          </div>
          <div className="panel-components-list-categories">
            <div style={{color: '#111111'}} className="panel-components-list-category">
              Categories
            </div>
            {
              categories_list.map( (option, index) => (
                <div key={index} 
                className={category === option ? "panel-components-list-category-selected" :"panel-components-list-category"}
                onClick={() => setCategory(prev=> option)}>
                  {option}
                </div>
              ))
            }
          </div>
        </div>
        <div className="panel-components-body-content">
          {
            category === 'All' ?
            categories_list.map( (option, index) => (
              <div key={index} className='panel-components-body-content-component'
              onClick={() => setCategory(prev=> option)}>
                {option}
              </div>
            ))
            :
            category === 'Architecture' ?
            architecture_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Architecture component
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                   className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
                onClick={() => handleComponentClick(option.name)}>
                {option.name}
              </div>
            ))
            :
            category === 'Text components' ?
            text_components_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Textarea component
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                  className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
                onClick={() => handleComponentClick(option.name)}>
                {option.name}
              </div>
              
            ))
            :
            category === 'Geometric figures' ?
            geometric_components_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Geometric component
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                  className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
                onClick={() => handleComponentClick(option.name)}>
                {option.name}
              </div>
            ))
            :
            category === 'Images' ?
            image_components_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Image component
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                  className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
                onClick={() => handleComponentClick(option.name)}>
                {option.name}
              </div>
            ))
            :
            category === 'Columns' ?
            columns_components_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Columns holder component
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                  className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
              onClick={() => handleComponentClick(option.name)}>
                <div className='panel-components-body-content-component-structure'
                  style={{gridTemplateColumns: option.style.gridTemplateColumns}}>
                  {option.Snum === '1' ? 
                    <div style={structure_frame_style}/> 
                    : option.Snum === '2' ? 
                    <>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                    </>
                    : option.Snum === '3' ? 
                    <>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                    </> 
                    : option.Snum === '4' ? 
                    <>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                      <div style={structure_frame_style}/>
                    </> 
                  : <></>}
                </div>
              </div>
            ))
            :
            category === 'Dynamic components' ?
            dynamic_components_list.map( (option, index) => (
              chosenComponent === option.name ?
              <div key={index} onClick={() => handleComponentClick(option.name)}
              className="panel-components-body-content-component-chosen">
                Dynamic components
                <div className="panel-components-body-content-component-options">
                  <div onClick={() => handleInsertClick(option)}
                  className="panel-component-insert-button">
                    Insert
                  </div>
                  <div className="panel-component-customise-button">
                    Customise
                  </div>
                </div>
              </div>
              :
              <div key={index} className='panel-components-body-content-component'
                onClick={() => handleComponentClick(option.name)}>
                {option.name}
              </div>
            ))
            :
            <></>
          }
        </div>
      </div>

    </div>
  </div>
   );
}
 
export default StyleEditorComponentsPanelForm;
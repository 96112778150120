import { NavLink, useNavigate } from "react-router-dom";
import arrow from "../icons/arrow-icon.svg"
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch } from "react-redux";
import { logout, reset } from "../features/auth/authSlice";
import UserSideMenu from "./UserSideMenu";


const SettingsHeader = ({user, setIsSettingsOpen, isFromWave1}) => {

  const dispatch = useDispatch()
	const navigate = useNavigate()
  const [iframeButtonTitle, setIframeButtonTitle] = useState('U')

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])

  
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  const goBackFunction = () =>{
    if(isFromWave1){
      setIsSettingsOpen(prev => false)
    } else{
      navigate('/launch/templates')
    }
  }

  const [isDown, setIsDown] = useState(false)
  const refActions = useOnclickOutside(() => {
    setIsDown(prev => false);
  })

  return ( 
    <div className="settings-header">
        <div className="launch-div">
          <div className="launch-link" onClick={goBackFunction}>
            Go back
          </div>
        </div>
      <div className="launchpad-header-stages">
        <div className="launch-div">
          <div className="settings-header-stage">
            Integrations
          </div>
        </div>
      </div>
      <div className="editor-options-column">
        <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/> 
      </div>
    </div>
   )
}
 
export default SettingsHeader;
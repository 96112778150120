import { Canvas } from '@react-pdf/renderer';
import { useEffect, useState } from 'react';

const LineFragment = ({part, index, parentStyle}) => {

  const [width, setWidth] = useState(595)

  useEffect(() => {
    //console.log(parentStyle)
    if(parentStyle){
      parentStyle.width ? setWidth(parseInt(parentStyle.width)) : setWidth(595)
    }
    //console.log(width )
  },[part, parentStyle])

  return (
    <Canvas paint={(painter) => {
      painter.strokeStyle = part.style.color;
      painter.lineWidth = parseInt(part.style.height); // set the width of the line 
      painter.moveTo(0, 0);
      painter.lineTo(width, 0);
      painter.stroke();
    }}/>
  );
};

export default LineFragment
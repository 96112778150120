import { Link} from "react-router-dom";

const NotFoundPage = () => {

  return ( 
    <div className='auth-background'>
      <div className='auth-body'>
        <div className='auth-content'>
          <div className='auth-form-name'>
            Page not found.
          </div>
          <Link to="/launch/templates">
            Back to the lanchpage..
          </Link>
        </div> 
      </div> 
    </div> 
  );
}
 
export default NotFoundPage;
import { useState } from "react";


const select_form_content = {
  position:'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '24px',
  gap: '40px',
  margin: '25vh 12vw',

}

const united_line = {
  border: '1px dashed #DADFF2',
  width: '70%',
}

const SelectBody = () => {

  const [chosenOption, setChosenOption] = useState('')

  return ( 
    <div style={select_form_content} >
      <div className="select-tittle">
        What is the project file ? 
      </div>
      <div className="select-options">
        <div className="select-option">
          <div className="select-option-tittle">
            Development
          </div>
          <div className={chosenOption === "file" ? "select-option-describe-s" : "select-option-describe"}>
            Software development and similar technical services
          </div>
          <div className={chosenOption === "file" ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => {setChosenOption(prev => "file")}}>
            {chosenOption === "file" ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div>
        <div className="select-option">
          <div className="select-option-tittle">
            Creative
          </div>
          <div className={chosenOption === "book" ? "select-option-describe-s" : "select-option-describe"}>
            Creative technical work such as UX design
          </div>
          <div className={chosenOption === "book" ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => {setChosenOption(prev => "book")}}>
            {chosenOption === "book" ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div>
        <div className="select-option">
          <div className="select-option-tittle">
            Consulting
          </div>
          <div className={chosenOption === "design" ? "select-option-describe-s" : "select-option-describe"}>
            Consulting, advisory and other non-technical services
          </div>
          <div className={chosenOption === "design" ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => {setChosenOption(prev => "design")}}>
            {chosenOption === "design" ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div>
        <div className="select-option">
          <div className="select-option-tittle">
            Intern 
          </div>
          <div className={chosenOption === "copy" ? "select-option-describe-s" : "select-option-describe"}>
            Internships or other form of temporary placement
          </div>
          <div className={chosenOption === "copy" ? "select-option-ellipse s" :"select-option-ellipse"}
          onClick={() => {setChosenOption(prev => "copy")}}>
            {chosenOption === "copy" ? <div className="select-option-ellipse-inside"/>
            :<></>}
          </div>
        </div>
      </div>
      <div className="select-bottom" >
        <hr style={united_line}/>
        {/* <div style={{display:'grid', gridTemplateColumns:'1fr 1fr'}}>
          <div className="select-option-describe">
            Highly technical
          </div>
          <div className="select-option-describe">
            Less technical
          </div>
        </div> */}
      </div>
    </div>
   );
}
 
export default SelectBody;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import folderRegisterService from './folderRegisterService'

const initialState = {
  folderRegisters: [],
  isErrorFR: false,
  isSuccessFR: false,
  isLoadingFR: false,
  messageCS: '',
}

// Create new folderRegister
export const createFolderRegister = createAsyncThunk(
  'folderRegisters/create',
  async (folderRegisterData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderRegisterService.createFolderRegister(folderRegisterData, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Get user folderRegisters
export const getFolderRegisters = createAsyncThunk(
  'folderRegisters/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderRegisterService.getFolderRegisters(token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Change folderRegister
export const changeFolderRegister = createAsyncThunk(
  'folderRegisters/change',
  async (folderRegister,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderRegisterService.changeFolderRegister(folderRegister,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Update folderRegister
export const updateFolderRegister = createAsyncThunk(
  'folderRegisters/update',
  async (folderRegister,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderRegisterService.updateFolderRegister(folderRegister,  token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

// Delete user folderRegister
export const deleteFolderRegister = createAsyncThunk(
  'folderRegisters/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await folderRegisterService.deleteFolderRegister(id, token)
    } catch (error) {
      const messageCS =
        (error.response &&
          error.response.data &&
          error.response.data.messageCS) ||
        error.messageCS ||
        error.toString()
      return thunkAPI.rejectWithValue(messageCS)
    }
  }
)

export const folderRegisterSlice = createSlice({
  name: 'folderRegister',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFolderRegister.pending, (state) => {
        state.isLoadingFR = true
      })
      .addCase(createFolderRegister.fulfilled, (state, action) => {
        state.isLoadingFR = false
        state.isSuccessFR = true
        state.folderRegisters.push(action.payload)
      })
      .addCase(createFolderRegister.rejected, (state, action) => {
        state.isLoadingFR = false
        state.isErrorFR = true
        state.messageCS = action.payload
      })
      .addCase(getFolderRegisters.pending, (state) => {
        state.isLoadingFR = true
      })
      .addCase(getFolderRegisters.fulfilled, (state, action) => {
        state.isLoadingFR = false
        state.isSuccessFR = true
        state.folderRegisters = action.payload
      })
      .addCase(getFolderRegisters.rejected, (state, action) => {
        state.isLoadingFR = false
        state.isErrorFR = true
        state.messageCS = action.payload
      })
      .addCase(changeFolderRegister.pending, (state) => {
        state.isLoadingFR = true
      })
      .addCase(changeFolderRegister.fulfilled, (state, action) => {
        state.isLoadingFR = false
        state.isSuccessFR = true
        let indexOfFolderRegister = state.folderRegisters.findIndex((element) => element._id === action.payload.folderRegisterId)
        state.folderRegisters[indexOfFolderRegister] = action.payload.folderRegisterBody
      })
      .addCase(changeFolderRegister.rejected, (state, action) => {
        state.isLoadingFR = false
        state.isErrorFR = true
        state.messageCS = action.payload
      })
      .addCase(updateFolderRegister.pending, (state) => {
        state.isLoadingFR = true
      })
      .addCase(updateFolderRegister.fulfilled, (state, action) => {
        state.isLoadingFR = false
        state.isSuccessFR = true
        let indexOfFolderRegister = state.folderRegisters.findIndex((element) => element._id === action.payload.folderRegisterId)
        state.folderRegisters[indexOfFolderRegister] = action.payload.folderRegisterBody
      })
      .addCase(updateFolderRegister.rejected, (state, action) => {
        state.isLoadingFR = false
        state.isErrorFR = true
        state.messageCS = action.payload
      })
      .addCase(deleteFolderRegister.pending, (state) => {
        state.isLoadingFR = true
      })
      .addCase(deleteFolderRegister.fulfilled, (state, action) => {
        state.isLoadingFR = false
        state.isSuccessFR = true
        state.folderRegisters = state.folderRegisters.filter(
          (folderRegister) => folderRegister._id !== action.payload.id
        )
      })
      .addCase(deleteFolderRegister.rejected, (state, action) => {
        state.isLoadingFR = false
        state.isErrorFR = true
        state.messageCS = action.payload
      })
  },
})

export const { reset } = folderRegisterSlice.actions
export default folderRegisterSlice.reducer
import select_file from '../../icons/select_file.svg'
import { useState } from 'react'
import useOnclickOutside from 'react-cool-onclickoutside'
import { useDispatch } from 'react-redux'

const PageSizesForm = ({active, setActive}) => {

  const dispatch = useDispatch()

  const measurments = {
    letter:{
      height: 1056,
      width: 816,
    }
  }

  const [pageHeight, setPageHeight] = useState(900)
  const [pageWidth, setPageWidth] = useState(450)


  const setPageSizesHandler = () => {
    console.log(pageHeight, pageWidth)

    setActive(prev => false)
  }
  const ref = useOnclickOutside(() => {
    active && setActive(prev => false);
  })

  return ( 
    <div className='form-page-hover' style={{opacity: active && '1', pointerEvents: active &&'all'}}>
      <div ref={ref} className='page-size-form' style={{opacity: active && '1'}}>
        <div className="size-form-tittle">
          Set default page`s size
        </div>
        <div className="size-form-body">
          <div className="size-form-choser">
            Height: 
            <div className="size-form-choser-container">
              <input
                type='number'
                name='font_size'
                value={pageHeight}
                autoComplete="off"
                onChange={(e) => setPageHeight(prev => e.target.value)}
              />
              px
            </div>
          </div>
          <div className="size-form-choser">
            Width: 
            <div className="size-form-choser-container">
              <input
                type='number'
                name='font_size'
                value={pageWidth}
                autoComplete="off"
                onChange={(e) => setPageWidth(prev => e.target.value)}
              />
              px
            </div>
          </div>
        </div>
        <div className="size-form-preview">
          <div className="size-form-preview-tittle">Preview: </div>
          <div className="size-form-preview-page" style={{height: pageHeight*0.2, width: pageWidth*0.2}}/>
        </div>
        <button className='select-insert-btn' onClick={setPageSizesHandler}>
          Apply
        </button>
      </div>
    </div>
   );
}
 
export default PageSizesForm;
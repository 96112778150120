import { useEffect, useState } from 'react';
import save from '../../icons/save-admin-row-icon.svg'
import checker from '../../icons/selected-checker-icon.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import moment from "moment";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changeFolderId } from '../../features/folderIds/folderIdSlice';
import Spinner from '../Spinner';

const FolderIdsTableRow = ({folder, index, updateTrigger, folderIdToggle, setUpdateTrigger}) => {

  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [isChosen, setIsChosen] = useState(false)
  
  const  { customerSpaces, isLoadingCS, isErrorCS, messageCS} = useSelector( 
    state => state.customerSpaces)


  const [folderIdCustomerSpace, setFolderIdCustomerSpace] = useState(folder.customerSpace)
  const [folderIdName, setFolderIdName] = useState(folder.name);
  const [folderIdStatus, setFolderIdStatus] = useState(folder.folderStatus)
  const [folderIdComments, setFolderIdComments] = useState(folder.comments);
  
  useEffect(()=>{
    setFolderIdCustomerSpace(folder.customerSpace)
    setFolderIdName(folder.name)
    setFolderIdComments(folder.comments)
  },[folder])
  
  const [isFolderIdCustomerSpaceDown, setIsFolderIdCustomerSpaceDown] = useState(false)
  const refFolderIdCustomerSpace = useOnclickOutside(() => {
    isFolderIdCustomerSpaceDown && setIsFolderIdCustomerSpaceDown(prev => false);
  })

  const folderIdHandleUpdate = () => {
    if(folderIdName === '' ){
      toast.error('You have empty inputs !', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{
      const folderIdData = {...folder,
        customerSpace: folderIdCustomerSpace,
        name: folderIdName,
        comments: folderIdComments,
      };
      dispatch(changeFolderId(folderIdData))
      setUpdateTrigger(prev => true)
      setIsEditing(prev => false)
    }
  };

  const choseHandler = (id) => {
    folderIdToggle(id)
    setIsChosen(prev => !prev)
  }

  const editingHandler = (property, value) => {
    if(folder[property] !== value){
      setIsEditing(prev => true)
    } else{
      setIsEditing(prev => false)
    }
  }

  return ( 
    <tr className={isChosen ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
      {isEditing ?
        <td className="admin-control-form-update-td">
          <img className="admin-control-form-update" 
          onClick={folderIdHandleUpdate}
          src={save} alt="save"/>
        </td>
        :
        <td key={index} className="admin-control-space-selector" onClick={() => choseHandler(folder._id)}>
          <div className={isChosen ? "admin-control-space-selected" : "admin-control-space-unselected"}>
            {isChosen && <img src={checker} alt='yes'/>}
          </div>
        </td>
      }
      <td 
      //ref={refFolderIdCustomerSpace} className="admin-selector-container-editing" onClick={ () => setIsFolderIdCustomerSpaceDown(prev => !prev)}
      >
        {folder.customerSpace}
        {/* {folderIdCustomerSpace}
        { isFolderIdCustomerSpaceDown && 
          <div className="admin-selector-body">
            {
              isLoadingCS || updateTrigger ? <Spinner/> : customerSpaces.length !== 0 ?
              <>
                {customerSpaces.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
                <div className="admin-selector-body-option" 
                onClick={() => {
                  editingHandler('customerSpace', space.name);
                  setFolderIdCustomerSpace(space.name)}}>
                  {space.name}
                </div>
                ))}
              </>
              : <div>There are no customer spaces yet </div>
            }
          </div>
        } */}
      </td>
      <td>
        {folder.id}
      </td>
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={folderIdName}
          onChange={(e) => {
            editingHandler('name', e.target.value);
            setFolderIdName(prev => e.target.value);
          }}/>
      </td>
      <td> 
        {moment.utc(folder.createdAt).format('D MMMM yyyy')}
      </td>
      <td>
        {folder.folderType}
      </td>
      <td>
        {folder.folderStatus}
      </td>
      <td>
        <input 
          type="text"
          className="admin-control-usual-row-input"
          value={folderIdComments}
          onChange={(e) => {
            editingHandler('comments', e.target.value);
            setFolderIdComments(prev => e.target.value);
          }}/>
      </td>
    </tr>
   );
}
 
export default FolderIdsTableRow;
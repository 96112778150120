import React, { useState, useEffect, useRef } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./croppingImage";
import useOnclickOutside from "react-cool-onclickoutside";

const aspectRatios = [
  { value: 4 / 3, text: "4/3" },
  { value: 16 / 9, text: "16/9" },
  { value: 1 / 2, text: "1/2" },
];

const ImageCropDialog = ({
  id,
  imageUrl,
  cropInit,
  zoomInit,
  onCancel,
  croppingHandler,
  resetImage,
  changeClickability,
}) => {
  if (zoomInit == null) {
    zoomInit = 1;
  }
  if (cropInit == null) {
    cropInit = { x: 0, y: 0 };
  }
  const [zoom, setZoom] = useState(zoomInit);
  const [crop, setCrop] = useState(cropInit);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  
  const ref = useOnclickOutside(() => {
    onCancel()
    onCrop() 
  })

  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onZoomChange = (zoom) => {
    setZoom(zoom);
  };

  const onCropComplete = (croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const onCrop = async () => {
    const croppedImageUrl = await getCroppedImg(imageUrl, croppedAreaPixels);
    croppingHandler(croppedImageUrl);
  };

  const onResetImage = () => {
    resetImage(id);
  };
  
  const cropperRef = useRef(null)
  // useEffect(() => {

  //   const imageCropper = cropperRef.current;
  //   imageCropper.addEventListener("dblclick", onCancel);

  //   return () => {
  //     imageCropper.removeEventListener("dblclick", onCancel);
  //   };
  // }, [])

  return (
    // <div>
    <div className="image" ref={ref}>
      <div className="crop-container" ref={cropperRef}>
        <Cropper
          image={imageUrl}
          zoom={zoom}
          crop={crop}
          onCropChange={onCropChange}
          onZoomChange={onZoomChange}
          onCropComplete={onCropComplete}
        />
      </div>
    </div>
    //   <div className="controls">
    //     <div className="button-area">
    //       <button className="crop-button" onClick={onCancel}>Cancel</button>
    //       <button className="crop-button" onClick={onResetImage}>Reset</button>
    //       <button className="crop-button" onClick={onCrop}>Crop</button>
    //     </div>
    //   </div>
    // </div>
  );
};

export default ImageCropDialog;
import { useEffect } from "react"
import { useDrop } from "react-dnd"

const empty_space = {
  height:'30px',
  width:'90%',
  border: '1px dashed rgb(120, 120, 121)',
  borderRadius: '8px',
}
const invisible_empty_space = {
  height:'30px',
  width:'100%',
  borderRadius: '8px',
}
const EmptyComponent = ({part, index, 
  parentData, parentType, changeChildFromParent, visibility }) => {

  const [{ handlerId }, drop] = useDrop({
    accept: ['part'],
    collect(monitor) {
      return {  
        handlerId: monitor.getHandlerId()
      }
    },
    drop( item ){ 
      console.log('drop item in empty space ', item)
      console.log('empty space ', part, parentType)
      if(item.part !== undefined && item.parentType === parentType){
        const dragIndex = item.index
        const hoverIndex = part.index
        // для відображання додавання елементів на документ
        // if(item.part.origin === 'sidebar'){
        //   dropSection(0, hoverIndex, item.part )
        // }else{
        if(item.part.parent !== parentData){
          changeChildFromParent(item.part)
        }
      }
    },
  })
  return (
    <div className="textarea-component" ref={drop} 
    data-handler-id={handlerId} 
    style={visibility ? empty_space : invisible_empty_space}/> 
  )
}
export default EmptyComponent
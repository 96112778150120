import { Link, NavLink, useNavigate } from "react-router-dom";
import plus from '../icons/plus-icon.svg'
import arrow from "../icons/arrow-icon.svg"
import whip_in from "../icons/zoom-in-icon.svg"
import whip_out from "../icons/zoom-out-icon.svg"
import list_icon from "../icons/list-header-icon.svg"
import tiles_icon from "../icons/view-tiles-icon.svg"
import graph_icon from "../icons/view-graph-icon.svg"
import { useEffect, useState } from "react";
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch, useSelector } from "react-redux";
import { useCreateNewDocument } from "../hooks/useCreateNewDocument";
import { logout, reset } from "../features/auth/authSlice";
import UserSideMenu from "./UserSideMenu";


const LaunchpadHeader = ({stage, view, setView}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)

	const { newDocument } = useSelector(state => state.documents)
  const { newStyle } = useSelector(state => state.styles)
  const [isDown, setIsDown] = useState(false)
  const [isViewListDown, setIsViewListDown] = useState(false)
  const [iframeButtonTitle, setIframeButtonTitle] = useState('U')
  
  const refActions = useOnclickOutside(() => {
    setIsDown(false);
  })
  const refView = useOnclickOutside(() => {
    setIsViewListDown(false);
  })
    

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])
  
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  const createNewDocument = useCreateNewDocument(stage)

  useEffect(() => {
    if(newDocument !== '' ){
      navigate(`/d/${newDocument._id}/design`)
    } 
  }, [newDocument])

  useEffect(() => {
    if(newStyle !== '' ){
      navigate(`/s/${newStyle._id}/design`)
    } 
  }, [newStyle])

  return ( 
    <div className="launchpad-header">
      <div className="launchpad-header-stages">
        <div className="launch-div">
          <NavLink to="/launch/templates"
            className={stage === 'templates' ? "launch-link-s" : "launch-link"}>
            My templates
          </NavLink>
        </div>
        <div className="launch-div"> 
          <NavLink to="/launch/agreements"
            className={stage === 'agreements' ? "launch-link-s" : "launch-link"}>
            My agreements
          </NavLink>
        </div>
        <div className="launch-div"> 
          <NavLink to="/launch/styles"
            className={stage === 'styles' ? "launch-link-s" : "launch-link"}>
            My styles
          </NavLink>
        </div>
      </div>
      <div className="launchpad-header-options">
        <div className="editor-options-column">
          <div className="create-new" onClick={() => createNewDocument()}>
            <div style={{marginRight:'8px', display:'flex'}}>
              <img src={plus}/>
            </div>
            Create new
          </div>
          <div ref={refActions} className="actions-droplist">
            <div className="actions-droplist-header" onClick={ () => setIsDown(prev => !prev)}>
              Actions
              <img style={{marginLeft:'8px', transform:( isDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isDown && 
            <div className="actions-droplist-body">
              <div style={{position:'relative'}}>
                <div className="actions-droplist-option" >
                  Action 1
                </div>
                <div className="actions-droplist-option" >
                  Action 2
                </div>
                <div className="actions-droplist-option" >
                  Action 3
                </div>
              </div>
            </div> }
          </div>
        </div>   
        <hr className="header-vertical-hr"/>
        <div className="editor-options-column">
          <div style={{display:'inline-flex', marginRight: '12px', borderRadius: '8px',boxShadow: '0px 1px 8px 1px rgba(0, 0, 0, 0.15)'}}>
            <div className="small-button-in">
              <img src={whip_in} alt="in"/>
            </div>
            <div className="small-button-out">
              <img src={whip_out} alt="out"/>
            </div>
          </div>
          <div ref={refView} className="view-droplist">
            <div className="small-button-list" onClick={ () => setIsViewListDown(prev => !prev)}>
              <img src={list_icon} alt="list"/>
            </div>
            { isViewListDown && 
            <div className="view-droplist-body">
              <div>
                <div className={view === 'list' ? "view-droplist-option-selected" :"view-droplist-option"} 
                onClick={() => setView(prev=> 'list')}>
                  <img src={list_icon} alt="list"/>
                  <div>List view</div>
                </div>
                <div className={view === 'tiles' ? "view-droplist-option-selected" :"view-droplist-option"} 
                onClick={() => setView(prev=> 'tiles')}>
                  <img src={tiles_icon} alt="tiles"/>
                  <div>Tile view</div>
                </div>
                <div className={view === 'graph' ? "view-droplist-option-selected" :"view-droplist-option"} 
                onClick={() => setView(prev=> 'graph')}>
                  <img src={graph_icon} alt="graph"/>
                  <div>Graph view</div>
                </div>
              </div>
            </div> }
          </div>
          <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle} /> 
        </div>
      </div>
    </div>
   )
}
 
export default LaunchpadHeader;
//function to convert image into base64
export const convertImageToBase64viaFileReader = (url, callback) => {
  let xhr = new XMLHttpRequest();
  xhr.responseType = 'blob';
  xhr.onload = function() {
    let reader  = new FileReader();
    reader.onloadend = function () {
        callback(reader.result);
    }
    reader.readAsDataURL(xhr.response);
  };
  xhr.open('GET', url);
  xhr.send();
}

//function to send base64 image to wave 1
export const handlePostRequest = async (postData) => {
  try {
    const response = await fetch('https://majoto.app/proxy-endpoint', {
     // mode: 'no-cors',
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        // Add other headers as needed
      },
      body: JSON.stringify(postData),
    });

    if (!response.ok) {
      throw new Error('Failed to submit data');
    }

    const responseData = await response.json();
    console.log('Response from server:', responseData);
    return responseData
  } catch (error) {
    console.error('Error making POST request:', error);
    return error
  }
};
import { useDrag } from "react-dnd";

const structure_frame_style = {
  border: '2px dashed darkslategray',
  borderRadius: '6px',
  background:'#fcfafb',
  minHeight: '30px',
  width: '100%'
}
const hierarchy_cmpnt_style = {
  cursor:'grab',
  padding: '12px ',
  background: '#FEF7F8',
  textAlign: 'center',
  boxShadow: '0px 1px 6px 1px rgba(0, 0, 0, 0.15)',
  borderRadius: '12px',
}

export const ModuleComponent = ({setDragZoneVisible}) => {
  const data = {
    type:'module',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'module',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{...hierarchy_cmpnt_style, opacity }}>
      Add module
    </div>
  );
}
export const SectionComponent = ({}) => {
  const data = {
    type:'section',

  }
  const [{ opacity }, drag] = useDrag({
    type: 'section' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{...hierarchy_cmpnt_style, opacity, background:'rgb(252, 224, 228)'}}>
      Add section
    </div>
  );
}
export const MultiLevel = ({}) => {
  const data = {
    type:'level',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'level',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} style={{...hierarchy_cmpnt_style, opacity }}>
      Add level
    </div>
  );
}
export const Level = ({num}) => {
  const data = {
    type:'level'+num,
  }
  const [{ opacity }, drag] = useDrag({
    type: 'level'+num,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  let  backgroundColor = num ==='1' ? '#FCE0E4' :
  num ==='2' ? '#FAC9D0' : '#F8B2BC' ;
  return (
    <div ref={drag} style={{...hierarchy_cmpnt_style, opacity, 
    background: backgroundColor}}>
      Add level {num}
    </div>
  );
}
export const DynamicBlock = () => {
  const data = {
    type:'dynamic_block',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      <div className="dynamic-block-panel-component-style" >
        <div className="dynamic-block-separator-panel-component-style"/>
      </div>
    </div>
  );
}
export const HeadingPart = () => {
  const data = {
    type:'heading',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Heading
    </div>
  );
}
export const SubHeadingPart = () => {
  const data = {
    type:'subheading',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Subheading
    </div>
  );
}
export const LinePart = () => {
  const data = {
    type:'line',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component',
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Line
    </div>
  );
}
export const ColumnsHolder = ({Snum, gridTemplate}) => {
  
  const data = {
    type:'columns_holder',
    num: Snum,
    style: {display:'grid', gridTemplateColumns: gridTemplate}
  }
  const [{ opacity }, drag] = useDrag({
    type: 'columns_holder' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });
  return (
    <div ref={drag} className='columns-panel-component-style'
    style={{gridTemplateColumns: gridTemplate, opacity }}>
      {Snum === '1' ? 
      <div style={structure_frame_style}/> 
      : Snum === '2' ? 
      <>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
      </>
      : Snum === '3' ? 
      <>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
      </> 
      : Snum === '4' ? 
      <>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
        <div style={structure_frame_style}/>
      </> 
      : <></>}
    </div>
  );
}

export const TextPart = () => {
  const data = {
    type:'text_field',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.9 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Text field
    </div>
  );
}

export const TablePart = () => {
  const data = {
    type:'table',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'in_dev' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.9 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Table
    </div>
  );
}
export const ImagePart = () => {
  const data = {
    type:'new_image',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.9 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Image
    </div>
  );
}
export const ImageHolderPart = () => {
  const data = {
    type:'image_holder',
  }
  const [{ opacity }, drag] = useDrag({
    type: 'component' ,
    item: () => { return data },
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.9 : 1
    })
  });
  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      Image holder
    </div>
  );
}
export const DesignItem = ({item}) => {

  const typeToDrag = (item.body.typeOfContent === 'module' || item.body.typeOfContent.startsWith('level')) ?
  item.body.typeOfContent : 'component'

  const [{ opacity }, drag] = useDrag({
    type: typeToDrag,
    item: () => { return {
      name: item.name,
      body: item.body,
      type : 'design_item'
    }},
    collect: monitor => ({
      opacity: monitor.isDragging() ? 0.4 : 1
    })
  });

  return (
    <div className="dropdown-elements-container-component" ref={drag} style={{opacity}}>
      { item.name }
    </div> 
  )
}

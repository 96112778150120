import axios from 'axios'

const API_URL = '/api/invitedUser/'

// find user by invitation link
const getInvitedUser = async (inviteToken, token) => {

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }



  const response = await axios.put(API_URL + inviteToken, {inviteToken: inviteToken}, config)

  return response.data
}
const invitationService = {
  getInvitedUser,
}

export default invitationService
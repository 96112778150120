import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux'
import { Link, NavLink, useNavigate, useParams } from 'react-router-dom'
import useOnclickOutside from 'react-cool-onclickoutside'
import moment from 'moment'
import arrow from "../../icons/arrow-icon.svg"
import whip_in from "../../icons/zoom-in-icon.svg"
import whip_out from "../../icons/zoom-out-icon.svg"
import list_icon from "../../icons/list-header-icon.svg"
import save_icon from "../../icons/save-icon.svg"
import trash_icon from "../../icons/trash-icon.svg"
import checker from '../../icons/selected-checker-icon.svg'
import logout_icon from '../../icons/logout-icon.svg'
import settings_icon from '../../icons/settings-icon.svg'
import library_icon from '../../icons/user-library-icon.svg'
import { templateExportToPDF, useExportToPDF } from "../../helpers/templateExportToPDF";
import { PDFDownloadLink, pdf } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { saveAs } from 'file-saver';
import DocumentPDF from "../generetingPDFDocumentFromJson/DocumentPDF";
import { updateDocument } from "../../features/documents/documentSlice";
import { updateDocumentName } from "../../features/localDocument/editingDocumentSlice";
import { logout, reset } from "../../features/auth/authSlice";
import UserSideMenu from "../UserSideMenu";

const doc_time = {
  fontWeight: '400',
  fontSize: '10px',
  color: '#7A8199',
}

const EditorHeader = ({ editingDocument, documentData,  setSaveDocForm, setDeleteForm, 
  setSelectForm, setIsDesignItem,  viewEditorMode, setViewEditorMode,
  setCloseDoc}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user} = useSelector( (state) => state.auth)

  const [isDown, setIsDown] = useState(false)
  const [isViewListDown, setIsViewListDown] = useState(false)
  const [documentName, setDocumentName] = useState('Default name')
	const [iframeButtonTitle, setIframeButtonTitle] = useState('U')
	const {id, stage} = useParams()
  const ref = useOnclickOutside(() => {
    isDown && setIsDown(prev => false);
  })
  const refView = useOnclickOutside(() => {
    setIsViewListDown(prev => false);
  })

  useEffect(() => {
    if(user){
      setIframeButtonTitle(user.name.charAt(0))
    }
  },[user])
	
  const onLogout = () => {
    dispatch(logout())
    dispatch(reset())
    navigate('/login')
  }

  useEffect(() => {
    if(documentData) {
      setDocumentName(documentData.name)
    }
  }, [documentData])

  const refDocumentHeader = useOnclickOutside(() => {
    if(documentName !== editingDocument.name && documentName !== undefined){
      const newDoc = {...editingDocument, name: documentName}
      dispatch(updateDocument(newDoc))
      dispatch(updateDocumentName([documentName]))
    }
  })

  let continueStage = stage === 'select' ? 'design' :
    stage === 'design' ? 'edit' :
    stage === 'edit' ? 'sign' :
    stage === 'sign' ? 'data' : 'data'
  

  const generatePdfDocument = async (documentData, fileName) => {
    try {
      const blob = await pdf((
        <DocumentPDF
          document={documentData}
        />
      )).toBlob();
      saveAs(blob, fileName);
    } catch (error) {
      console.error('An error occurred while generating the PDF document:', error);
    }
  };

  return ( 
    <div className="new-editor-header">
    <div style={{display: 'none'}}>
      {
        editingDocument && editingDocument.body ?
          <DocumentPDF document={editingDocument}/>
          :
        ''
      }
    </div>

      <div className="editor-stages">
        <NavLink to={"/d/"+id+'/select'}  
          className={stage === 'select' ? "nav-div selectedNav" : "nav-div"}>
          <div>Select</div>
          {stage === 'select' ? <div className="editor-stage" /> : <></>}
        </NavLink>
        <NavLink to={"/d/"+id+'/design'}
          className={stage === 'design' ? "nav-div selectedNav" : "nav-div"}>
          <div>Design</div>
          {stage === 'design' ? <div className="editor-stage"/> : <></>}
        </NavLink>
        <NavLink to={"/d/"+id+'/edit'} 
          className={stage === 'edit' ? "nav-div selectedNav" : "nav-div"}>
          <div>Edit</div>
          {stage === 'edit' ? <div className="editor-stage"/> : <></>}
        </NavLink>
        <NavLink to={"/d/"+id+'/sign'}
          className={stage === 'sign' ? "nav-div selectedNav" : "nav-div"}>
          <div>Sign</div>
          {stage === 'sign' ? <div className="editor-stage"/> : <></>}
        </ NavLink>
        <NavLink to={"/d/"+id+'/data'} 
          className={stage === 'data' ? "nav-div selectedNav" : "nav-div"}>
          <div>Data</div>
          {stage === 'data' ? <div className="editor-stage"/> : <></>}
        </NavLink>

      </div>

      {documentData !== undefined ?
        <div style={{display: 'flex', flexFlow: 'column', 
        alignItems: 'center', justifyContent: 'center'}}>
          <input ref={refDocumentHeader} 
          className="header-document-name-input" type="text" 
          value={documentName}
          onChange={(e)=> setDocumentName(prev => e.target.value)}/>
          <div style={doc_time}>Last updated: {moment(documentData.date).format('MMM DD')}, {moment(documentData.date).format('h:mma')}</div>
        </div> 
        : 
        <div style={{display: 'flex', flexFlow: 'column', 
        alignItems: 'center', justifyContent: 'center'}}/>
      }
      
      <div className="editor-header-options">
        <div className="editor-options-column">
          <div className="continue">
            <NavLink to={"/d/"+id+'/'+continueStage}>
              Continue
            </NavLink>
          </div>
          <div ref={ref} className="actions-droplist">
            <div className="actions-droplist-header" onClick={ () => setIsDown(prev => !isDown)}>
              Actions
              <img style={{marginLeft:'8px', transform:( isDown && 'rotate(-180deg)'), 
              transition:'260ms linear all'}} src={arrow} alt="arrow"/>
            </div>
            { isDown && 
            <div className="actions-droplist-body">
              <div style={{position:'relative'}}>
                {/* <div className="actions-droplist-option" onClick={()=>setSaveTrigger(prev => true)}>
                  <img src={save_icon} alt='save'/>Save
                </div> */}
                <div className="actions-droplist-option" onClick={()=>setSaveDocForm(prev => true)}>
                  <img src={save_icon} alt='save'/>Save As a New Template
                </div>
                {/* <div className="actions-droplist-option" onClick={()=>{setIsDesignItem(prev=> true); setSaveDocForm(prev => true)}}>
                  <img src={save_icon} alt='save'/>Save As Design Item
                </div> */}
                <div className="actions-droplist-option" onClick={()=>setDeleteForm(prev=>true)}>
                  <img src={trash_icon} alt='save'/>Delete
                </div>
                <div className="actions-droplist-option" onClick={()=>setSelectForm(prev => true)}>
                  Select
                </div>
                <div className="actions-droplist-option" onClick={() => generatePdfDocument(editingDocument, editingDocument.name)}>
                  Export to PDF
                </div>
                <div className="actions-droplist-option" onClick={()=>setCloseDoc(prev => true)}>
                  Close template
                </div>
              </div>
            </div> }
          </div>
        </div>   
        <hr className="editor-header-vertical-hr"/>
        <div className="editor-options-column">
          <div style={{display:'inline-flex', marginRight: '12px', borderRadius: '8px',boxShadow: '0px 1px 8px 1px rgba(0, 0, 0, 0.15)'}}>
            <div className="small-button-in">
              <img src={whip_in} alt="in"/>
            </div>
            <div className="small-button-out">
              <img src={whip_out} alt="out"/>
            </div>
          </div>
          <div ref={refView} className="view-droplist">
            <div className="small-button-list" onClick={ () => setIsViewListDown(prev => !isViewListDown)}>
              <img src={list_icon} alt="list"/>
            </div>
            { isViewListDown && 
            <div className="view-droplist-body">
              <div>
                <div className={viewEditorMode ? "view-droplist-option-selected" :"view-droplist-option"} 
                  onClick={() => setViewEditorMode(prev=> !viewEditorMode)}>
                  <img style={{visibility: !viewEditorMode && 'hidden'}} src={checker}/>
                  <div>View-editor mode</div>
                </div>
              </div>
            </div> }
          </div>
          <UserSideMenu user={user} onLogout={onLogout} iframeButtonTitle={iframeButtonTitle}/>
        </div>

      </div>
      

    </div>
   );
}
 
export default EditorHeader;
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import designItemService from './designItemService'

const initialState = {
  designItems: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new designItem
export const createDesignItem = createAsyncThunk(
  'designItems/create',
  async (designItemData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await designItemService.createDesignItem(designItemData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user designItems
export const getDesignItems = createAsyncThunk(
  'designItems/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await designItemService.getDesignItems(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)
// Update designItem
export const updateDesignItem = createAsyncThunk(
  'designItems/update',
  async (designItem,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await designItemService.updateDesignItem(designItem,  token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete user designItem
export const deleteDesignItem = createAsyncThunk(
  'designItems/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await designItemService.deleteDesignItem(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const designItemSlice = createSlice({
  name: 'designItem',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createDesignItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createDesignItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.designItems.push(action.payload)
      })
      .addCase(createDesignItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getDesignItems.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getDesignItems.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.designItems = action.payload
      })
      .addCase(getDesignItems.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateDesignItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateDesignItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        let indexDoc = state.designItems.findIndex((element) => element._id === action.payload.designItemId)
        state.designItems[indexDoc].body = action.payload.designItemBody
      })
      .addCase(updateDesignItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteDesignItem.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteDesignItem.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.designItems = state.designItems.filter(
          (designItem) => designItem._id !== action.payload.id
        )
      })
      .addCase(deleteDesignItem.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = designItemSlice.actions
export default designItemSlice.reducer
import { useDispatch } from "react-redux"
import { deleteComponentInLevel1, 
  deleteComponentInLevel1Column, 
  deleteComponentInLevel2, 
  deleteComponentInLevel2Column, 
  deleteComponentInModule, 
  deleteComponentInModuleColumn, 
  deleteModule } from "../../../features/localDocument/editingDocumentSlice";
import { useEffect } from "react";

export const useDeleteComponent = ({component, positionOfParent, typeOfParent, columnData, 
  isDeleting, setIsDeleting, setSidePanel}) => {

  const dispatch = useDispatch()

  useEffect(() => {
    if(isDeleting){
      component.typeOfContent === 'module' ?  dispatch(deleteModule([component.id]))
      :
      (typeOfParent === 'module' && columnData === undefined) || component.typeOfContent === 'level1' ? 
      dispatch(deleteComponentInModule([component.position, positionOfParent]))
      :
      typeOfParent === 'module' && columnData !== undefined && component.typeOfContent !== 'column' ? 
      dispatch(deleteComponentInModuleColumn([component.position, columnData]))
      :
      typeOfParent === 'level1' && columnData === undefined ?
      dispatch(deleteComponentInLevel1([component.position, positionOfParent]))
      :
      typeOfParent === 'level1' && columnData !== undefined && component.typeOfContent !== 'column' ? 
      dispatch(deleteComponentInLevel1Column([component.position, columnData]))
      :
      typeOfParent === 'level2' && columnData === undefined ?
      dispatch(deleteComponentInLevel2([component.position, positionOfParent]))
      :
      typeOfParent === 'level2' && columnData !== undefined && component.typeOfContent !== 'column' ? 
      dispatch(deleteComponentInLevel2Column([component.position, columnData]))
      :
      console.log('')
      setIsDeleting(prev => false)
      setSidePanel(prev => false)
    }
  }, [isDeleting])
}

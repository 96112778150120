import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const Welcome = () => {

  const navigate = useNavigate()
  const { user} = useSelector(
    (state) => state.auth
  )

  useEffect(() => {

    if (!user) {
      navigate('/login')
    } 
  }, [user, navigate])

  const keyPressHandler = (e) => {
    if (e.key === 'Enter') {
      console.log('do validate');
    }
  }

  return ( 
    user !== null &&
    <div className='auth-background'>
      <div className='auth-body'>
        <div className='auth-content'>
          <div className='auth-form-name'>
            Welcome to Majoto
          </div>
          <div className="auth-form-group">
            <div className="auth-form-variation">
            We're excited to have you.  Majoto is a new way to design, agree and manage legal documents - 
            that helps you close deals faster and build relationships. 
            We can't wait to see what you do with it!
            </div>
          </div>
          <div className='auth-form-bottom'>
            {user.role !== "user" ?
            <button className='auth-btn' onClick={() => navigate('/launch/templates')}>
              Get started
            </button>  
            : 
            //user.role === 'super_admin' ? if(e.key  === 13){navigate('/')} onKeyPress={e => {console.log(e)}} 
            <button className='auth-btn' onKeyDown={keyPressHandler} onClick={() => navigate('/')}>
              Get started
            </button>  
            }
            <div className='auth-form-variation'>
              <div> </div>
            </div>
          </div>
        </div>
      </div>
    </div>
   );
}
 
export default Welcome;
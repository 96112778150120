import React, { useCallback, useRef, useState } from "react";
import { useDrag, useDrop } from 'react-dnd'
import { useDispatch } from "react-redux";
import { addingNewLevel2, addNewLevel2, changeLevel1Name, removeEmptyComponents, updateLevel2Order, updatingLevel2Order } from "../../features/localDocument/editingDocumentSlice";
import MapLevel2 from "./MapLevel2";
import TextareaAutosize from 'react-textarea-autosize';

const empty_container = {
  display: 'flex',
  cursor: 'grab',
  borderRadius: '8px',
  border: '1px dashed blue',
  background: '#FFFFFF',
  opacity:'0.5',
}
const empty_row ={
  padding: '8px',
  paddingRight: '12px',
  width: '11vw',
  height: '32px' ,
}
const drop_holder = {
  paddingTop:'30px',
  borderRadius: '8px',
  border: '1px dashed #7A7E7E',
  width: '11vw',
  zIndex: '0',
  opacity:'0.5',
}

const MapLevel1 = ({ part, level1Position, level1Index, moveLevel, dropLevel1}) => {
  
  const ref = useRef(null)
  const dispatch = useDispatch()
  const [isOpen, setIsOpen] = useState(false)
  const [name, setName] = useState(part.position === 0 ? '' : part.name)

  const [{ handlerId }, drop] = useDrop({
    accept: ['level1', 'level2', 'level3'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId()
      }
    },
    drop( item ){ 
      if(item.part.typeOfContent !=='level2' && item.part.typeOfContent !=='level3'){
        if(item.part.origin === 'sidebar' || item.part.parent !== part.parent){
          dropLevel1( item.part, level1Position)
        }
      }
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragPosition = item.level1Position
      const hoverPosition = level1Position
      if (dragPosition === hoverPosition && item.part.parent === part.parent) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2 
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragPosition < hoverPosition && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragPosition > hoverPosition && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined && part.value !== null){
        if(item.part.typeOfContent === 'level2' || item.part.typeOfContent === 'level3'){
          setIsOpen(prev => true)
        }
        else{
          moveLevel(dragPosition, hoverPosition, item.part)
        }
      }
      
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'level1',
    item: () => {
      return {  level1Position, part }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      dispatch(removeEmptyComponents())
    }
  })
  
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  const moveLevel2 = useCallback(
    (dragPosition, hoverPosition, newLevel) => {
      newLevel.origin === 'root' ?  
      dispatch(updatingLevel2Order([dragPosition, hoverPosition, level1Position , newLevel])) 
  
      : dispatch(addingNewLevel2([hoverPosition, level1Position]))  
    }, [])
  
  const changeOrder = useCallback(
    (newLevel) => {
      newLevel.origin !== 'root' ? 
      dispatch(addNewLevel2([newLevel, level1Position]))  
      :  dispatch(updateLevel2Order([newLevel, level1Position]))  
    }, [])

  const renderLevel2 = useCallback((part, index) => {
    return (
      <MapLevel2
        key={part.position}
        index={index}
        parentIndex={level1Index}
        level2Position={part.position}
        part = {part}
        moveLevel2={moveLevel2}
        dropLevel2={changeOrder}
      />
    )
  }, [])
  return (
    <div className="agreement-map-level-container">
      <div ref={ref} data-handler-id={handlerId}
      className='agreement-map-level-heading'
      style={{opacity}}
      onClick={() => setIsOpen(prev => !prev)}>      
        {part !== undefined ?
          (part.value === null ?
            <div style={drop_holder}/>
          : part.position === 0 ?
          <div style={empty_container}>
            <div style={empty_row}>     </div>
          </div>
          :
          <>
            <div className="agreement-map-level1-num">{level1Index+1}</div>
            <div className="agreement-map-level-name-container">
              <TextareaAutosize className="agreement-map-level1-name" value={name}
              onChange={(e) => {
                setName(e.target.value)
                dispatch(changeLevel1Name([level1Position, e.target.value]))}}
              /> 
            </div>
          </>
          ) 
        : <></>}
        </div>
        {isOpen && part !== undefined && part.content !== undefined ?
          part.content !== undefined ?
            <div className="agreement-map-level2-list">
              {part.content.length !== 0 ? 
                part.content.filter(part => part.typeOfContent === "level2").length !== 0 ?
                  part.content.filter(part => part.typeOfContent === "level2").map((part, i) => 
                    renderLevel2(part, i) 
                  )
                : renderLevel2({value: 'no'}, 0) 
              : renderLevel2({value:'no'}, 0)}
            </div>
          : <></>
        :<></> 
        }
      </div>
  )
}


export default MapLevel1;
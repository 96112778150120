import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import agreementService from './agreementService'

const initialState = {
  agreements: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

// Create new agreement
export const createAgreement = createAsyncThunk(
  'agreements/create',
  async (agreementData, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await agreementService.createAgreement(agreementData, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Get user agreements
export const getAgreements = createAsyncThunk(
  'agreements/getAll',
  async (_, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await agreementService.getAgreements(token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Change agreement
export const changeAgreement = createAsyncThunk(
  'agreements/change',
  async (agreement,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await agreementService.changeAgreement(agreement,  token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Update agreement
export const updateAgreement = createAsyncThunk(
  'agreements/update',
  async (agreement,  thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await agreementService.updateAgreement(agreement,  token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

// Delete user agreement
export const deleteAgreement = createAsyncThunk(
  'agreements/delete',
  async (id, thunkAPI) => {
    try {
      const token = thunkAPI.getState().auth.user.token
      return await agreementService.deleteAgreement(id, token)
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()
      return thunkAPI.rejectWithValue(message)
    }
  }
)

export const agreementSlice = createSlice({
  name: 'agreement',
  initialState,
  reducers: {
    reset: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createAgreement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(createAgreement.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.agreements.push(action.payload)
      })
      .addCase(createAgreement.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(getAgreements.pending, (state) => {
        state.isLoading = true
      })
      .addCase(getAgreements.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.agreements = action.payload
      })
      .addCase(getAgreements.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(changeAgreement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(changeAgreement.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        let indexOfAgreement = state.agreements.findIndex((element) => element._id === action.payload.agreementId)
        state.agreements[indexOfAgreement] = action.payload.agreementBody
      })
      .addCase(changeAgreement.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(updateAgreement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(updateAgreement.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        let indexOfAgreement = state.agreements.findIndex((element) => element._id === action.payload.agreementId)
        state.agreements[indexOfAgreement] = action.payload.agreementBody
      })
      .addCase(updateAgreement.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
      .addCase(deleteAgreement.pending, (state) => {
        state.isLoading = true
      })
      .addCase(deleteAgreement.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.agreements = state.agreements.filter(
          (agreement) => agreement._id !== action.payload.id
        )
      })
      .addCase(deleteAgreement.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
      })
  },
})

export const { reset } = agreementSlice.actions
export default agreementSlice.reducer
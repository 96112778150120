import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify'
import { useEffect } from "react";
import Spinner from "../Spinner";
import 'react-datepicker/dist/react-datepicker.css';
import { changeCustomerSpace, getCustomerSpaces } from "../../features/customerSpaces/customerSpaceSlice";
import CustomerSpacesTableBody from "./CustomerSpacesTableBody";
import SortableTh from "./SortableTh";

const CustomerSpacesTable = () => {

  const dispatch = useDispatch()
  const  { customerSpaces, isLoadingCS, isErrorCS, messageCS} = useSelector( 
    state => state.customerSpaces)

  const [customerSpaceUpdateTrigger, setCustomerSpaceUpdateTrigger] = useState(false)
  const [customerSpacesData, setCustomerSpacesData] = useState([])
  
  const [sortedColumn, setSortedColumn] = useState('');
  const [sortDirection, setSortDirection] = useState('');

  
  useEffect(() =>{
    setCustomerSpacesData(customerSpaces.filter(space => space.customerSpaceStatus !== 'archive'))
    //console.log('customerSpacesData: ',customerSpacesData)
  },[customerSpaces])

  const handleSort = (columnName) => {
    setSortedColumn(columnName);
    setSortDirection((prevDirection) => (prevDirection === 'asc' ? 'desc' : 'asc'));
    setCustomerSpacesData(prev => prev.sort((a, b) => {
        const valueA = a[columnName];
        const valueB = b[columnName];

        if (sortDirection === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      })
    )
    //console.log('customerSpacesData: ',customerSpacesData)
  }

  useEffect(() => {
    if(customerSpaceUpdateTrigger){
      setTimeout(() => {
        dispatch(getCustomerSpaces())
        setCustomerSpaceUpdateTrigger(prev=>false)
      }, 500)
    }
  }, [customerSpaceUpdateTrigger, isLoadingCS])

  const [chosenGroups, setChosenGroups] = useState([]);
  const [isCSActionsMenu, setIsCSActionsMenu] = useState(false)

  const customerGroupToggle = (index) => {
    setChosenGroups((prevChosenGroups) => {
      const isOpen = prevChosenGroups.includes(index);
      if (isOpen) {
        return prevChosenGroups.filter((rowIndex) => rowIndex !== index);
      } else {
        return [...prevChosenGroups, index];
      }
    });
  }

  useEffect(() => {
    chosenGroups.length !== 0 ? setIsCSActionsMenu(prev => true) : setIsCSActionsMenu(prev => false) 
  }, [chosenGroups])

  const csActionsHandler = (option) => {
    chosenGroups.forEach((groupId) => {
      const customerSpaceToUpdate = customerSpaces.find((customerSpace) => customerSpace._id === groupId);
  
      if (customerSpaceToUpdate) {
        const updatedCustomerSpace = { ...customerSpaceToUpdate, customerSpaceStatus: option };
        toast.promise(
          dispatch(changeCustomerSpace(updatedCustomerSpace)),
          {
            pending: 'Applying changes',
            success: `Changed customer space state to ${option}`,
            error: 'Promise rejected 🤯'
          }
        )
      }
    })
    if (chosenGroups.length !== 0){
      setCustomerSpaceUpdateTrigger(prev => true)
      setChosenGroups(prevChosenGroups => [])
    }
  }

  return ( 
    <div className="admin-register-part">
      <div className="admin-register-form">
        <div className="admin-register-form-name">
          Customer spaces
        </div>
        <table className="admin-control-table">
          <thead className="admin-control-table-header">
          <tr>
            <th className="admin-control-table-empty"></th>
            <SortableTh columnName="name" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
              Customer space
            </SortableTh>
            <SortableTh columnName="start_date" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
              Start date
            </SortableTh>
            <th>Duration</th>
            <SortableTh columnName="payment_status" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
              Payment status
            </SortableTh>
            <SortableTh columnName="type" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
              Type
            </SortableTh>
            <SortableTh columnName="comments" currentSort={sortedColumn} sortDirection={sortDirection} onSort={handleSort}>
              Comments
            </SortableTh>
            <th>Logo</th>
          </tr>
          </thead>
          {isLoadingCS || customerSpaceUpdateTrigger ?
            <div className="admin-control-loading">
              <Spinner/>
            </div>
            : 
            <CustomerSpacesTableBody tableData={customerSpacesData} setUpdateTrigger={setCustomerSpaceUpdateTrigger}
            customerGroupToggle={customerGroupToggle}/>
          }
        </table>
      </div>
      <>
        { isCSActionsMenu && 
          <div className="admin-register-actions">
              <div className="admin-register-actions-header">
                Actions
              </div>
              <div className="admin-register-actions-option"
                onClick={() => csActionsHandler('archive')}>
                Archive
              </div>
          </div>
        }
      </>
    </div>
   );
}
 
export default CustomerSpacesTable;

import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner";
import { getAgreements } from "../../features/agreements/agreementSlice";
import AgreementsTilesScrollbar from "./AgreementsTilesScrollbar";
import AgreementTile from "./AgreementTile";

const AgreementsTiles = ({ }) => {

  const [ isDropList, setIsDropList] = useState(false)
  const [ chosenAgreement, setChosenAgreement] = useState('null')

  const [alerts, setAlerts] = useState([])
  const [incDraft, setIncDraft] = useState([])
  const [inProgress, setInProgress] = useState([])
  const [recSigned, setRecSigned] = useState([])
  const [signed, setSigned] = useState([])
  const [renewed, setRenewed] = useState([])
  const [incomplete, setIncomplete] = useState([])
  const [shared, setShared] = useState([])
  const [archived, setArchived] = useState([])

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const dispatch = useDispatch()

  const { agreements, isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )

  const handleDropList = (part_id) => {
    if(isDropList){
      if(part_id === chosenAgreement){
        setIsDropList(prev=> false)
      } else{
        setChosenAgreement(prev => part_id)
      }
    }else{
      setChosenAgreement(prev => part_id)
      setIsDropList(prev => true)  
    }
  }

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }
  }, [isError, message, dispatch])

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getAgreements())
        setUpdateTrigger(prev=>false)
      },200)
    }
  }, [updateTrigger])

  useEffect( () => {
    if(agreements.length !== 0 ){
      agreements.forEach( agreement => {
        agreement.status === 'alerts' ?
        setAlerts(prev => ([...prev, agreement]))
        :
        agreement.status === 'incomplete_draft' ?
        setIncDraft(prev => ([...prev, agreement]))
        :
        agreement.status === 'in_progress' ?
        setInProgress(prev => ([...prev, agreement]))
        :
        agreement.status === 'recently_signed' ?
        setRecSigned(prev => ([...prev, agreement]))
        :
        agreement.status === 'signed' ?
        setSigned(prev => ([...prev, agreement]))
        :
        agreement.status === 'renewed' ?
        setRenewed(prev => ([...prev, agreement]))
        :
        agreement.status === 'incomplete' ?
        setIncomplete(prev => ([...prev, agreement]))
        :
        agreement.status === 'shared' ?
        setShared(prev => ([...prev, agreement]))
        :
        agreement.status === 'archived' ?
        setArchived(prev => ([...prev, agreement]))
        :
        console.log('broken agreement', agreement)
      })
    }
  }, [agreements])

  // || updateTrigger
	return (
    <>
    {isLoading ? <Spinner /> : agreements.length !==0 ? 
    <div style={{position: 'relative',}}>
    <div className="agreements-tiles-container">
    <AgreementsTilesScrollbar>
      <div className="agreements-tiles">
        <div className="list-column" id="alerts">
          <div className="list-header"> 
              <div className="list-header-pointer" id="alerts-sign"></div>
              <div className="list-name">Alerts</div>
          </div>
          { alerts.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : alerts.map(tile => 
          <AgreementTile tile={tile}/>
          )}
        </div>
        <hr className='agreement-list-vr'/> 
        <div className="list-column" id="incomplete">
          <div className="list-header"> 
              <div className="list-header-pointer" id="incomplete-sign"></div>
              <div className="list-name">Incomplete drafts</div>
          </div>
          {incDraft.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : incDraft.map(tile => 
          <AgreementTile tile={tile}/>
          )}
        </div>
        <hr className='agreement-list-vr'/>
        <div className="list-column" id="in_progress">
          <div className="list-header"> 
              <div className="list-header-pointer" id="in_progress-sign"></div>
              <div className="list-name">In progress</div>
          </div>
          {inProgress.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : inProgress.map(tile => (
          <AgreementTile tile={tile}/>
          ))}
        </div>
        <hr className='agreement-list-vr'/>
        <div className="list-column" id="signed">
          <div className="list-header"> 
              <div className="list-header-pointer" id="signed-sign"></div>
              <div className="list-name">Recently signed</div>
          </div>
          {recSigned.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : recSigned.map(tile => (
          <AgreementTile tile={tile}/>
          ))}
        </div>
        <hr className='agreement-list-vr'/>
        <div className="list-column" id="signed">
          <div className="list-header"> 
              <div className="list-header-pointer" id="signed-sign"></div>
              <div className="list-name">Signed</div>
          </div>
          {signed.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : signed.map(tile => (
          <AgreementTile tile={tile}/>
          ))}
        </div>  
        <hr className='agreement-list-vr'/>
        <div className="list-column" id="renewed">
          <div className="list-header" > 
              <div className="list-header-pointer" id="renewed-sign"></div>
              <div className="list-name">Renewed</div>
          </div>
          {renewed.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : renewed.map(tile => (
          <AgreementTile tile={tile}/>
          ))}
        </div>
        <hr className='agreement-list-vr'/>
        <div className="list-column" id="signed">
          <div className="list-header"> 
              <div className="list-header-pointer" id="archived-sign"></div>
              <div className="list-name">Archived</div>
          </div>
          {archived.length === 0 ? 
          <div className="agreement-plate">
            No agreements of this state
          </div>
          : archived.map(tile => (
          <AgreementTile tile={tile}/>
          ))}
        </div> 
      </div>
    </AgreementsTilesScrollbar>
    </div>
    </div>
    : <></> 
    }
    </>
		);
	}
 
export default AgreementsTiles;
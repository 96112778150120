import { useCallback, useEffect, useRef, useState } from "react"
import { useDrag, useDrop } from "react-dnd"
import { useDispatch, useSelector } from "react-redux"
import { addComponentInLevel1StyleDoc, setChosenComponent, updateComponentsOrderInLevel1, updatingComponentsOrderInLevel1, updateSizeForLevel1StyleDoc,} from "../../../features/localDocument/editingStyleDocumentSlice"
import StyleHeading from "./StyleHeading"
import StyleLevel2 from "./StyleLevel2"
import StyleSubHeading from "./StyleSubHeading"
import StyleTextArea from "./StyleTextArea"
import StyleLine from "./StyleLine"

const Level1 = ({part, index, moduleId,  
  columnData,
  changeClickability,
  setSidePanel, setStylingComponent, 
  moveChildFromParent}) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()
  const [style, setStyle] = useState({})
  const [isDragAllow, setIsDragAllow] = useState(true)
  const [levelSize, setLevelSize] = useState({})

  const [isResizingOver, setIsResizingOver] = useState(true)

  const changeLevelClickability = () => {
    changeClickability()
    setIsResizingOver(prev=> !prev)
    console.log('isResizingOver under level ',isResizingOver)
  }

  const refBox = useRef(null);
  const refLeft = useRef(null);
  const refTopLeft = useRef(null);
  const refTop = useRef(null);
  const refTopRight = useRef(null);
  const refRight = useRef(null);
  const refBottomRight = useRef(null);
  const refBottom = useRef(null);
  const refBottomLeft = useRef(null);
  const minWidth = 140
  const maxWidth = 840
  const minHeight = 60
  const maxHeight = 840

  useEffect(()=> {
    setStyle(prev => ({...part.style, width: '400px', height:'60px'}))
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style}))
  }, [part.style, dispatch])

  const updateLevel1Style = (newStyle) => {
    dispatch(updateSizeForLevel1StyleDoc([moduleId, newStyle, part.position]))
  }

  useEffect(() => {
    const resizableComponent = refBox.current;
    setLevelSize(prev => ({
      width: resizableComponent.offsetWidth - 2*parseInt(part.style.padding),
      height: resizableComponent.offsetHeight - 2*parseInt(part.style.padding)
    }))
    console.log('parent size changes ', levelSize)
  },[dispatch, isResizingOver, style])

  useEffect(() => {
      const resizableComponent = refBox.current;
      const size = {width: style.width ? parseInt(style.width) : 200, height: style.height ? parseInt(style.height) : 60};
      let width = parseInt( size.width, 10);
      let height = parseInt( size.height, 10);
      let x = 0;
      let y = 0;
  
      // Top Left resize
      const onMouseMoveTopLeftResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpTopLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch top left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopLeftResize);
        document.removeEventListener("mouseup", onMouseUpTopLeftResize);
      };
      const onMouseDownTopLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = size.right;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.addEventListener("mouseup", onMouseUpBottomLeftResize);
      };
      // Top resize
      const onMouseMoveTopResize = (event) => {
        const dy = event.clientY - y;
        y = event.clientY;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
      const onMouseUpTopResize = (event) => {
        const newStyleProperties = {height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height){
          console.log('will be dispatch top ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopResize);
        document.removeEventListener("mouseup", onMouseUpTopResize);
      };
      const onMouseDownTopResize = (event) => {
        changeClickability()

        y = event.clientY;
        const  size = window.getComputedStyle(resizableComponent);
        resizableComponent.style.bottom =  size.bottom;
        resizableComponent.style.top = null;
        document.addEventListener("mousemove", onMouseMoveTopResize);
        document.addEventListener("mouseup", onMouseUpTopResize);
      };
  
      // Top Right resize
      const onMouseMoveTopRightResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height - dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpTopRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch top right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveTopRightResize);
        document.removeEventListener("mouseup", onMouseUpTopRightResize);
      };
      const onMouseDownTopRightResize = (event) => {
        changeClickability()
        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  size.left;
        resizableComponent.style.right = null;
        document.addEventListener("mousemove", onMouseMoveTopRightResize);
        document.addEventListener("mouseup", onMouseUpTopRightResize);
      };
  
      // Right resize
      const onMouseMoveRightResize = (event) => {
        console.log('trigger ')
        const dx = event.clientX - x;
        x = event.clientX;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
      };
  
      const onMouseUpRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width}
        if(resizableComponent.style.width !== style.width){
          console.log('will be dispatch right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveRightResize);
        document.removeEventListener("mouseup", onMouseUpRightResize);
      };
      const onMouseDownRightResize = (event) => {
        changeClickability()
          
        x = event.clientX;
        resizableComponent.style.left =  size.left;
        resizableComponent.style.right = null;
        document.addEventListener("mousemove", onMouseMoveRightResize);
        document.addEventListener("mouseup", onMouseUpRightResize);
      };
      // Bottom Right resize
      const onMouseMoveBottomRightResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width + dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpBottomRightResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch bottom right ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomRightResize);
        document.removeEventListener("mouseup", onMouseUpBottomRightResize);
      };
      const onMouseDownBottomRightResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = null;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomRightResize);
        document.addEventListener("mouseup", onMouseUpBottomRightResize);
      };
  
      // Bottom resize
      const onMouseMoveBottomResize = (event) => {
        const dy = event.clientY - y;
        y = event.clientY;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
      const onMouseUpBottomResize = (event) => {
        const newStyleProperties = {height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height){
          console.log('will be dispatch bottom ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomResize);
        document.removeEventListener("mouseup", onMouseUpBottomResize);
      };
      const onMouseDownBottomResize = (event) => {
        changeClickability()
         
        y = event.clientY;
        const  size = window.getComputedStyle(resizableComponent);
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomResize);
        document.addEventListener("mouseup", onMouseUpBottomResize);
      };
      // Bottom Left resize
      const onMouseMoveBottomLeftResize = (event) => {
        const dx = event.clientX - x;
        const dy = event.clientY - y;
        x = event.clientX;
        y = event.clientY;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
        height = height + dy;
        height > maxHeight ? resizableComponent.style.height = maxHeight
        :
        height < minHeight ? resizableComponent.style.height = minHeight
        :
        resizableComponent.style.height = `${height}px`;
      };
  
      const onMouseUpBottomLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
        if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
          console.log('will be dispatch bottom left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.removeEventListener("mouseup", onMouseUpBottomLeftResize);
      };
      const onMouseDownBottomLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        y = event.clientY;
        resizableComponent.style.left =  null;
        resizableComponent.style.right = size.right;
        resizableComponent.style.top =  size.top;
        resizableComponent.style.bottom = null;
        document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
        document.addEventListener("mouseup", onMouseUpBottomLeftResize);
      };
  
      // Left resize
      const onMouseMoveLeftResize = (event) => {
        const dx = event.clientX - x;
        x = event.clientX;
        width = width - dx;
        width > maxWidth ? resizableComponent.style.width = maxWidth
        :
        width < minWidth ? resizableComponent.style.width = minWidth
        :
        resizableComponent.style.width = `${width}px`;
      };
      const onMouseUpLeftResize = (event) => {
        const newStyleProperties = {width: resizableComponent.style.width}
        if(resizableComponent.style.width !== style.width){
          console.log('will be dispatch left ')
          updateLevel1Style(newStyleProperties)
        }
        setTimeout(()=> {
          changeClickability()
        }, [400])
        document.removeEventListener("mousemove", onMouseMoveLeftResize);
        document.removeEventListener("mouseup", onMouseUpLeftResize);
         
      };
      const onMouseDownLeftResize = (event) => {
        changeClickability()

        x = event.clientX;
        resizableComponent.style.right =  size.right;
        resizableComponent.style.left = null;
        document.addEventListener("mousemove", onMouseMoveLeftResize);
        document.addEventListener("mouseup", onMouseUpLeftResize);
      };
  
      // Add mouse down event listener
      const resizerTopLeft = refTopLeft.current;
      resizerTopLeft.addEventListener("mousedown", onMouseDownTopLeftResize);
      const resizerTop = refTop.current;
      resizerTop.addEventListener("mousedown", onMouseDownTopResize);
      const resizerTopRight = refTopRight.current;
      resizerTopRight.addEventListener("mousedown", onMouseDownTopRightResize);

      const resizerRight = refRight.current;
      resizerRight.addEventListener("mousedown", onMouseDownRightResize);

      const resizerBottomRight = refBottomRight.current;
      resizerBottomRight.addEventListener("mousedown", onMouseDownBottomRightResize);
      const resizerBottom = refBottom.current;
      resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);
      const resizerBottomLeft = refBottomLeft.current;
      resizerBottomLeft.addEventListener("mousedown", onMouseDownBottomLeftResize);

      const resizerLeft = refLeft.current;
      resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);
  
      return () => {
        resizerTopLeft.removeEventListener("mousedown", onMouseDownTopLeftResize)
        resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
        resizerTopRight.removeEventListener("mousedown", onMouseDownTopRightResize)

        resizerRight.removeEventListener("mousedown", onMouseDownRightResize);

        resizerBottomRight.removeEventListener("mousedown", onMouseDownBottomRightResize)
        resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
        resizerBottomLeft.removeEventListener("mousedown", onMouseDownBottomLeftResize)

        resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
      };
  }, [style]);

  useEffect(() => {
    const onLevelClick = (event) => {
      if(isResizingOver){
        event.stopPropagation();
        dispatch(setChosenComponent([part]))
        setStylingComponent([part, moduleId, 'module', columnData]);
        setSidePanel(prev=> true)
      }
    }

    const levelRef = refBox.current;
    levelRef.addEventListener("click", onLevelClick);

    return () => {
      levelRef.removeEventListener("click", onLevelClick);
    };
  }, [isResizingOver])

  const ref = useRef(null)
  const [{isOverCurrent, handlerId }, drop] = useDrop({
    accept: [ 'component', 'level'],
    collect: (monitor) => ({
        isOverCurrent: monitor.isOver({ shallow: true }),
        handlerId: monitor.getHandlerId(),
      }),
    drop( item ){
      if(isOverCurrent){
        dispatch(addComponentInLevel1StyleDoc([ item, part.position]))
      }
    },
  })
  drop(ref)
  const hoverRes = isOverCurrent 
 
  const renderLevel2 = useCallback((child, index) => {
    return (
      <StyleLevel2 
        key={child.position} part = {child} index={index}
        changeClickability={changeLevelClickability}
        moduleId={moduleId}
        parentData = {part.position}
        parentType = {"level1"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent} 
      />
    )
  }, [])
  // const renderColumnsHolder = useCallback((child, index) => {
  //   return (
  //     <ColumnsHolder 
  //       key={child.position} part = {child} index={index}
  //       moduleId = {moduleId} 
  //       parentType = {'level1'}
  //       setStylingPanel={setSidePanel}
  //       setStylingComponent={setStylingComponent} 
  //     />
  //   )
  // }, [])
  const renderTextArea = useCallback((child, index) => {
    return (
      <StyleTextArea 
        key={child.position} index={index} part={child} 
        parentData = {part.position} 
        parentType = {"level1"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent} 
      />
    )
  }, [])
  const renderHeading = useCallback((child, index) => {
    return (
      <StyleHeading 
        key={child.position} part = {child}  index={index}
        parentData = {part.position} 
        parentType = {"level1"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent} 
      />
    )
  }, [])
  const renderSubHeading = useCallback((child, index) => {
    return (
      <StyleSubHeading 
        key={child.position} part = {child} index={index}
        parentData = {part.position}  
        parentType = {"level1"}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent} 
      />
    )
  }, [])
  // const renderImageHolder = useCallback((child, index) => {
  //   return (
  //     <ImageHolder 
  //       key={child.position}  part={child} index={index}
  //       changeClickability={changeLevelClickability}
  //       parentSize={levelSize}
  //       parentData = {part.position} 
  //       parentType = {"level1"}
  //       setStylingPanel={setSidePanel}
  //       setStylingComponent={setStylingComponent}
  //     />
  //   )
  // }, [levelSize])
  // const renderImage = useCallback((child, index) => {
  //   return (
  //     <Image 
  //       key={child.position} part={child} index={index}
  //       changeClickability={changeLevelClickability}
  //       parentSize={levelSize}
  //       parentData = {part.position} 
  //       parentType = {"level1"}
  //       setStylingPanel={setSidePanel}
  //       setStylingComponent={setStylingComponent} 
  //     />
  //   )
  // }, [levelSize]) 
  const renderLine = useCallback((child, index) => {
    return (
      <StyleLine 
        key={child.position} part = {child} index={index}
        parentData = {part.position}
        parentType = {'level1'}
        setStylingPanel={setSidePanel}
        setStylingComponent={setStylingComponent}
      />
    )
  }, [])

  return (
    <div  className="resizable-container">

    <div ref={refBox} className={chosenComponent.position=== part.position ? "resizeable-box-chosen" :
    hoverRes ? 'resizeable-box-drop-hover ': 'resizeable-box'}
    style={{height: style.height, width: style.width,}}
    >
    <div className="style-element-tittle-level1">Level {part.name.slice(-1)}</div>
      <div ref={ref} data-handler-id={handlerId} style={{...style, height: '100%', width:'100%'}}
        >
        {part.content.length !== 0 ? 
          part.content.map( (child, index) => 
            child.type === 'text_field' ?
            renderTextArea(child, index)
            : 
            child.type === "level2" ? 
            renderLevel2(child, index)
            : 
            // child.type === "columns_holder" ? 
            // renderColumnsHolder(child, index)
            // : 
            child.type === "heading" ? 
            renderHeading(child, index)
            : 
            child.type === "subheading" ? 
            renderSubHeading(child, index)
            : 
            // child.type === "image_holder" ?
            // renderImageHolder(child, index)
            // : 
            child.type === "line" ? 
            renderLine(child, index)
            : console.log('ceivo', child)
          )
        :  <></>
        }
      </div>
      <div ref={refLeft} className="resizer resizer-l"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refTopLeft} className="resizer resizer-t-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTop} className="resizer resizer-t" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTopRight} className="resizer resizer-t-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refRight} className="resizer resizer-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refBottomRight} className="resizer resizer-b-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottom} className="resizer resizer-b" draggable="false"  
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottomLeft} className="resizer resizer-b-l" draggable="false" 
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
    </div>
    </div>
  )
}

export default Level1
import { useEffect, useState } from 'react';
import save from '../../icons/save-admin-row-icon.svg'
import checker from '../../icons/selected-checker-icon.svg'
import useOnclickOutside from "react-cool-onclickoutside";
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { changeFolderRegister } from '../../features/folderRegister/folderRegisterSlice';
import Spinner from '../Spinner';
import { changeUser } from '../../features/users/userSlice';

const RegisterRowsTableRow = ({row, index, updateTrigger, registerToggle, setUpdateTrigger}) => {

  const dispatch = useDispatch()
  const  { customerSpaces, isLoadingCS } = useSelector( 
    state => state.customerSpaces)
  const  { folderIds, isLoadingFId } = useSelector( 
    state => state.folderIds)
  const  { users, isLoadingU } = useSelector( 
    state => state.users)

  const [isEditing, setIsEditing] = useState(false)
  const [isChosen, setIsChosen] = useState(false)
   
  const [registerCustomerSpace, setRegisterCustomerSpace] = useState(row.customerSpace ? row.customerSpace : 'was deleted')
  const [registerCustomerSpaceName, setRegisterCustomerSpaceName] = useState('')
  const [registerUser, setRegisterUser] = useState(row.user ? row.user : 'was deleted')
  const [registerUserEmail, setRegisterUserEmail] = useState('')
  const [registerTemplateFolder, setRegisterTemplateFolder] = useState(row.templateFolder ? row.templateFolder: 'was deleted')
  const [registerTemplateFolderName, setRegisterTemplateFolderName] = useState('')
  const [registerDocFolder, setRegisterDocFolder] = useState(row.docFolder ? row.docFolder : 'was deleted')
  const [registerDocFolderName, setRegisterDocFolderName] = useState('')
  const [registerFolderType, setRegisterFolderType] = useState(row.type)
  
  const [isRegisterCustomerSpaceDown, setIsRegisterCustomerSpaceDown] = useState(false)
  const refRegisterCustomerSpace = useOnclickOutside(() => {
    isRegisterCustomerSpaceDown && setIsRegisterCustomerSpaceDown(prev => false);
  })
  const [isRegisterUserEmailDown, setIsRegisterUserEmailDown] = useState(false)
  const refRegisterUserEmail = useOnclickOutside(() => {
    isRegisterUserEmailDown && setIsRegisterUserEmailDown(prev => false);
  })
  const [isRegisterTemplateFolderDown, setIsRegisterTemplateFolderDown] = useState(false)
  const refRegisterTemplateFolder = useOnclickOutside(() => {
    isRegisterTemplateFolderDown && setIsRegisterTemplateFolderDown(prev => false);
  })
  const [isRegisterDocFolderDown, setIsRegisterDocFolderDown] = useState(false)
  const refRegisterDocFolder = useOnclickOutside(() => {
    isRegisterDocFolderDown && setIsRegisterDocFolderDown(prev => false);
  })
  const [isRegisterFolderTypeDown, setIsRegisterFolderTypeDown] = useState(false)
  const refRegisterFolderType = useOnclickOutside(() => {
    isRegisterFolderTypeDown && setIsRegisterFolderTypeDown(prev => false);
  })
  
  const generateFolderId = (folderType, type) => {
    let prefix = folderType === 'template' ? 'tf' : 'df';
    if (type === 'View') {
      prefix += 'v';
    } else if (type === 'Edit') {
      prefix += 'e';
    }
    return prefix;
  };
  
  useEffect(()=>{
    setRegisterCustomerSpace(row.customerSpace)
    setRegisterUser(row.user)
    setRegisterTemplateFolder(row.templateFolder)
    setRegisterDocFolder(row.docFolder)
    setRegisterFolderType(row.type)
  },[row])

  useEffect(() => {
    if(customerSpaces && customerSpaces.length!== 0){
      const rowCustomerSpace = customerSpaces.find(group => group._id === row.customerSpace)
      //console.log(rowCustomerSpace)
      if(rowCustomerSpace){
        setRegisterCustomerSpaceName(rowCustomerSpace.name)

      }
    }
  }, [customerSpaces, row])

  useEffect(() => {
    if(users && users.length!== 0 && 'was deleted'){
      const rowUser = users.find(user => user._id === row.user)
      if(rowUser){
        setRegisterUserEmail(rowUser.email)
      }
    }
  }, [users, row])

  useEffect(() => {
    if(folderIds && folderIds.length!== 0){
      const rowTemplateFolder = folderIds.find(folder => folder._id === row.templateFolder)
      const rowDocFolder = folderIds.find(folder => folder._id === row.docFolder)
      if(rowTemplateFolder){
        setRegisterTemplateFolderName(rowTemplateFolder.name)
      }else{
        setRegisterTemplateFolderName('none')
      }
      if(rowDocFolder){
        setRegisterDocFolderName(rowDocFolder.name)
      }else{
        setRegisterDocFolderName('none')
      }
    }
  }, [folderIds, row])


  const registerRowHandleUpdate = () => {
    console.log('this is registerRowHandleUpdate things')
    if(registerCustomerSpace === '' || registerUser === ''
    || registerTemplateFolder === '' || registerDocFolder === ''
    || registerFolderType === ''){
      toast.error('You have empty inputs !', {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      })
    }else{
      if(row.registerFolderType !== registerFolderType || row.templateFolder !== registerTemplateFolder || row.docFolder !== registerDocFolder){
        const userToUpdate = users.find(user => user._id === row.user);
        let userFolders = userToUpdate.folderIds
        const prefixTemplateFolderToDelete = generateFolderId('template', row.type)
        const prefixDocFolderToDelete = generateFolderId('document', row.type)
        userFolders = userFolders.filter(folder => {
          var isEqual = (folder.folderId == row.templateFolder) && (folder.prefix == prefixTemplateFolderToDelete)
          return !isEqual
        }).filter(folder => {
          var isEqual = (folder.folderId == row.docFolder) && (folder.prefix == prefixDocFolderToDelete)
          return !isEqual
        })
        
        userFolders.push({
          folderId: registerTemplateFolder,
          prefix: generateFolderId('template', registerFolderType),
        })
        userFolders.push({
          folderId: registerDocFolder,
          prefix: generateFolderId('document', registerFolderType),
        })
        let userUpdateData = { ...userToUpdate, folderIds: userFolders };
        if(row.customerSpace !== registerCustomerSpace){
          userUpdateData = { ...userToUpdate, userCustomerSpace: registerCustomerSpace, folderIds: userFolders };
        }
        console.log('userUpdateData  after filter ', userUpdateData)
        dispatch(changeUser(userUpdateData))
      }
      const updateData =  {...row, 
        customerSpace: registerCustomerSpace,
        user: registerUser,
        templateFolder: registerTemplateFolder,
        docFolder: registerDocFolder,
        type: registerFolderType,
      }
      dispatch(changeFolderRegister(updateData))
      setUpdateTrigger(prev => true)
      setIsEditing(prev => false)
    }
  };

  const choseHandler = (id) => {
    registerToggle(id)
    setIsChosen(prev => !prev)
  }

  const editingHandler = (property, value) => {
    if(row[property] !== value){
      setIsEditing(prev => true)
    } else{
      setIsEditing(prev => false)
    }
  }

  return ( 
    <tr className={isChosen ? "admin-control-usual-row-selected" : "admin-control-usual-row"}> 
      {isEditing ?
        <td className="admin-control-form-update-td">
          <img className="admin-control-form-update" 
          onClick={registerRowHandleUpdate}
          src={save} alt="save"/>
        </td>
        :
        <td key={index} className="admin-control-space-selector" onClick={() => choseHandler(row._id)}>
          <div className={isChosen ? "admin-control-space-selected" : "admin-control-space-unselected"}>
            {isChosen && <img src={checker} alt='yes'/>}
          </div>
        </td>
      }
      <td ref={refRegisterCustomerSpace} className="admin-selector-container-editing"
      onClick={() => setIsRegisterCustomerSpaceDown(prev => !prev)}>
        {registerCustomerSpaceName}
        { isRegisterCustomerSpaceDown && 
        <div className="admin-selector-body-editing">
          {
            isLoadingCS || updateTrigger ? <Spinner/> : customerSpaces.length !== 0 ?
            <>
              {customerSpaces.filter(space => space.customerSpaceStatus !== 'archive').map((space, index) => (
              <div className="admin-selector-body-option" 
              onClick={() => {
                editingHandler('customerSpace', space._id);
                setRegisterCustomerSpace(space._id);
                setRegisterCustomerSpaceName(space.name)}}>
                {space.name}
              </div>
              ))}
            </>
            : <div>There are no customer spaces yet </div>
          }
        </div> }
      </td>
      <td ref={refRegisterUserEmail} className="admin-selector-container-editing"
      //onClick={() => setIsRegisterUserEmailDown(prev => !prev)}
      >
        {registerUserEmail}
        { isRegisterUserEmailDown && 
        <div className="admin-selector-body-editing">
          {
            isLoadingU || updateTrigger ? <Spinner/> : users.length !== 0 ?
            <>
              {users.filter(user => user.userStatus !== 'archive').map((user, index) => (
              <div className="admin-selector-body-option" 
              onClick={() => {
                editingHandler('user', user._id);
                setRegisterUser(user._id);
                setRegisterUserEmail(user.email)}}>
                {user.email}
              </div>
              ))}
            </>
            : <div>There are no users yet </div>
          }
        </div> }
      </td>
      <td className={`register-row-status-${row.registerStatus}`}>{row.registerStatus}</td>
      <td ref={refRegisterTemplateFolder} className="admin-selector-container-editing"
      onClick={() => setIsRegisterTemplateFolderDown(prev => !prev)}>
        {registerTemplateFolderName}
        { isRegisterTemplateFolderDown && 
        <div className="admin-selector-body-editing">
          {
            isLoadingFId || updateTrigger ? <Spinner/> : folderIds.length !== 0 ?
            <>
              {folderIds.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Templates').map((folder, index) => (
              <div className="admin-selector-body-option" 
              onClick={() => {
                editingHandler('templateFolder', folder._id);
                setRegisterTemplateFolder(folder._id);
                setRegisterTemplateFolderName(folder.name)}}>
                {folder.name}
              </div>
              ))}
            </>
            : <div>There are no templates folders yet </div>
          }
        </div> }
      </td>
      <td ref={refRegisterDocFolder} className="admin-selector-container-editing"
      onClick={() => setIsRegisterDocFolderDown(prev => !prev)}>
        {registerDocFolderName}
        { isRegisterDocFolderDown && 
        <div className="admin-selector-body-editing">
          {
            isLoadingFId || updateTrigger ? <Spinner/> : folderIds.length !== 0 ?
            <>
              {folderIds.filter(folder => folder.folderStatus !== 'archive').filter(folder => folder.folderType === 'Docs').map((folder, index) => (
              <div className="admin-selector-body-option" 
              onClick={() => {
                editingHandler('docFolder', folder._id);
                setRegisterDocFolder(folder._id);
                setRegisterDocFolderName(folder.name)}}>
                {folder.name}
              </div>
              ))}
            </>
            : <div>There are no doc folders yet </div>
          }
        </div> }
      </td>
      <td ref={refRegisterFolderType} className="admin-selector-container-editing"
      onClick={() => setIsRegisterFolderTypeDown(prev => !prev)}>
        {registerFolderType}
        { isRegisterFolderTypeDown && 
          <div className="admin-selector-body">
            <div className="admin-selector-body-option" 
            onClick={() => {editingHandler('type','View'); setRegisterFolderType('View')}}>
              View
            </div>
            <div className="admin-selector-body-option" 
            onClick={() => {editingHandler('type','Edit'); setRegisterFolderType('Edit')}}>
              Edit
            </div>
          </div>
        }
      </td>
    </tr>
   );
}
 
export default RegisterRowsTableRow;
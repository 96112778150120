import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "../Spinner";
import { getAgreements } from "../../features/agreements/agreementSlice";
import moment from 'moment'
import AgreementsGraphItem from './AgreementsGraphItem'

const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

const AgreementsGraph = ({ }) => {

  const [ isDropList, setIsDropList] = useState(false)
  const [ chosenAgreement, setChosenAgreement] = useState('null')

  const [updateTrigger, setUpdateTrigger] = useState(false)
  const dispatch = useDispatch()

  const { agreements, isLoading, isError, message } = useSelector(
    (state) => state.agreements
  )


  let dateRow = [0, 0, 0, 0, 0, 0, 0, 0]

  dateRow.forEach( (date, index) => {
    let today = new Date()
    today.setMonth(today.getMonth() - index)
    today.setDate(1)
    dateRow[index] = {
      date: today,
      string: months[today.getMonth()] +' '+ moment(today).format('yyyy')}
  })
  dateRow = dateRow.reverse()

  const handleDropList = (part_id) => {
    if(isDropList){
      if(part_id === chosenAgreement){
        setIsDropList(prev=> false)
      } else{
        setChosenAgreement(prev => part_id)
      }
    }else{
      setChosenAgreement(prev => part_id)
      setIsDropList(prev => true)  
    }
  }

  useEffect(() => {
    if (isError) {
      console.log("Error: "+message)
    }
  }, [isError, message, dispatch])

  useEffect(() => {
    if(updateTrigger){
      setTimeout(() => {
        dispatch(getAgreements())
        setUpdateTrigger(prev=>false)
      },200)
    }
  }, [updateTrigger])


  // || updateTrigger
	return (
    <>
    {isLoading ? <Spinner /> : agreements.length !==0 ? 
    <div className='agreements-graph-container'>
      <div className="agreements-graph">
        <div className="agreements-graph-statuses">
          <div className="agreements-graph-status">
            Renewed
          </div>
          <div className="agreements-graph-status">
            Signed
          </div>
          <div className="agreements-graph-status">
            Pending signature
          </div>
          <div className="agreements-graph-status">
            Shared
          </div>
          <div className="agreements-graph-status">
            In progress
          </div>
          <div className="agreements-graph-status">
            Alerts
          </div> 
          <div className="agreements-graph-status">
            Incomplete drafts
          </div>
          <div className="agreements-graph-status">
            
          </div>
        </div>
        <div className="agreements-graph-lines">
          <div className="agreements-graph-status-column">
            <div className="agreements-graph-status-label">
              Status
            </div>
            <div className="agreements-graph-status-line"/>
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'renewed' &&
              <AgreementsGraphItem agreement={agreement} status={'renewed'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'signed' &&
              <AgreementsGraphItem agreement={agreement} status={'signed'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'pending' &&
              <AgreementsGraphItem agreement={agreement} status={'pending'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'shared' &&
              <AgreementsGraphItem agreement={agreement} status={'shared'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'in_progress' &&
              <AgreementsGraphItem agreement={agreement} status={'inprogress'} monthes={dateRow}/>
            ))} 
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'alerts' &&
              <AgreementsGraphItem agreement={agreement} status={'alerts'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            {agreements.map(agreement => (
              agreement.status === 'incomplete' &&
              <AgreementsGraphItem agreement={agreement} status={'incomplete'} monthes={dateRow}/>
            ))}
          </div>
          <div className="agreements-graph-row-line">
            <div className="agreements-graph-dates">
              {dateRow.map(date => (
                <div className="agreements-graph-date">
                  {date.string}
                </div>
              ))}
            </div>
            <div className="agreements-graph-date-label">
              Date created
            </div>
          </div>
        </div>
      </div>
    </div>
      // <div className='agreements-list' style={{height:'70vh'}}>
      // In development...
      // </div>
      : <></> 
    }
    </>
		);
	}
 
export default AgreementsGraph;
import { useState } from "react";
import { useDispatch } from "react-redux";
import { addComponentStyleDoc } from "../../features/localDocument/editingStyleDocumentSlice";

const page_hover = {
  height: '100vh',
  width: '100vw',
  backgroundColor: 'rgba(0, 0, 0, 0.4)',
  position: 'fixed',
  top: '0',
  left: '0',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  opacity: '0',
  pointerEvents: 'none',
  transition: '0.2s',
  zIndex:'1',
}
const form_content = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent:'center',
  gap:'16px',
  boxSizing: 'border-box',
  padding: '20px',
  borderRadius: '20px',
  minWidth: '280px',
  backgroundColor: 'white',
  opacity: '0',
  transition: '0.2s all',
}

const LevelChoserForm = ({active, setActive }) => {

  const dispatch = useDispatch()
  const [chosenOption, setChosenOption] = useState('module')

  const addComponent = () => {
    dispatch(addComponentStyleDoc(chosenOption))
    setActive(prev => false)
  }
  return ( 
    <div style={active ? { ...page_hover, opacity: '1', pointerEvents: 'all'} : page_hover}>
      <div style={active ? { ...form_content, opacity: '1'} : form_content} >
        <div className="select-tittle">
            Chose type of adding component 
          </div>
        <div className="level-options">
          <div className="select-option">
            <div className="select-option-tittle">
              Module
            </div>
            <div className={chosenOption === "module" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "module")}}>
              {chosenOption === "module" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
          <div className="select-option">
            <div className="select-option-tittle">
              Level
            </div>
            <div className={chosenOption === "level" ? "select-option-ellipse s" :"select-option-ellipse"}
            onClick={() => {setChosenOption(prev => "level")}}>
              {chosenOption === "level" ? <div className="select-option-ellipse-inside"/>
              :<></>}
            </div>
          </div>
        </div>
        <button className='select-insert-btn' onClick={() => addComponent()}>
          Add component
        </button>
      </div>
    </div> 
  );
}
 
export default LevelChoserForm;
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"


export const useDisplayChosenStyleComponent = (displayComponent, typeOfParent, columnData) => {
  const {editingStyleDocument, chosenComponent} = useSelector(state => state.editingStyleDocument)

  const [component, setComponent] = useState()

  const findChosenComponent = (data, typeOfParent, columnData) => {
    if(data.type === 'module'){
      setComponent(editingStyleDocument.body.find(part => part.id === data.id))
    }
    if(typeOfParent === 'module' && columnData === undefined){
      const parentIndex = editingStyleDocument.body.findIndex(part => part.id === data.parent)
      console.log(parentIndex, data)
      setComponent(editingStyleDocument.body[parentIndex].content.find(part => part.position === data.position))
    }
    if(typeOfParent === 'module' && columnData !== undefined){
      const indexOfModule = editingStyleDocument.body.findIndex(part => part.id === columnData.parent)
      const indexOfColHolder = editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
      setComponent(editingStyleDocument.body[indexOfModule].content[indexOfColHolder].content[indexOfColumn].content.find(part => part.position === data.position))
    }
    if(typeOfParent === 'level1' && columnData === undefined){
      const indexOfModule = editingStyleDocument.body.findIndex( module => 
      -1 !== module.content.findIndex( part => part.position === data.parent))
      const indexOfLevel1 = editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === data.parent)
      setComponent(editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.find(part => part.position === data.position))
    }
    if(typeOfParent === 'level1' && columnData !== undefined){
      const indexOfModule = editingStyleDocument.body.findIndex( module => 
      -1 !== module.content.findIndex( part => part.position === columnData.parent))
      const indexOfLevel1 = editingStyleDocument.body[indexOfModule].content.findIndex(part => part.position === columnData.parent)
      const indexOfColHolder = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
      setComponent(editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfColHolder].content[indexOfColumn].content.find(part => part.position === data.position))
    }
    if(typeOfParent === 'level2' && columnData === undefined){
      const indexOfModule = editingStyleDocument.body.findIndex( module => 
        -1 !== module.content.findIndex( level => level.type === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === data.parent)))
      const indexOfLevel1 = editingStyleDocument.body[indexOfModule].content.findIndex( level => level.type === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === data.parent))
      const indexOfLevel2 = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === data.parent)
      setComponent(editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.find(part => part.position === data.position))
    }
    if(typeOfParent === 'level2' && columnData !== undefined){
      const indexOfModule = editingStyleDocument.body.findIndex( module => 
        -1 !== module.content.findIndex( level => level.type === 'level1' &&
          -1 !== level.content.findIndex( level2 =>
            level2.position === columnData.parent)))
      const indexOfLevel1 = editingStyleDocument.body[indexOfModule].content.findIndex( level => level.type === 'level1' &&
        -1 !== level.content.findIndex( level2 =>
          level2.position === columnData.parent))
      const indexOfLevel2 = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content.findIndex(
        level1 => level1.position === columnData.parent)
      const indexOfColHolder = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content.findIndex(part => part.position === columnData.holder)
      const indexOfColumn = editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content.findIndex(part => part.position === columnData.column)
      setComponent(editingStyleDocument.body[indexOfModule].content[indexOfLevel1].content[indexOfLevel2].content[indexOfColHolder].content[indexOfColumn].content.find(part => part.position === data.position))
    }
  }

  useEffect(() => { 
    findChosenComponent(displayComponent, typeOfParent, columnData)
  }, [])

  useEffect(() => { 
    findChosenComponent(displayComponent, typeOfParent, columnData)
    return () => {
      setComponent('');
    };
  }, [editingStyleDocument, chosenComponent])

  return [component];
}
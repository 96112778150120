import { useCallback, useEffect, useRef  } from "react";
import { useState } from "react";
import { useDrag, useDrop } from "react-dnd";
import { useDispatch, useSelector } from "react-redux";
import { 
 // changeComponentInLevel2, changeComponentInModule, changeComponentInLevel1, changeComponentInModuleColumn, changeComponentInLevel1Column, changeComponentInLevel2Column, 
setChosenComponent, 
//updateStyleForDynamicBlock, 
//updateStyleForPartInModule 
} from "../../../features/localDocument/editingStyleDocumentSlice";
import StyleDynamicPart from "./StyleDynamicPart";


const StyleDynamicBlock = ({part, index, moduleId,
  changeClickability,
  parentData, columnData, parentType, 
  setStylingPanel, setStylingComponent,
  setImageForm,
  setUploadImageData, setUploadImageParent,
  moveChildFromParent }) => {

  const { chosenComponent } = useSelector(state => state.editingStyleDocument)
  const dispatch = useDispatch()

  const [isDragAllow, setIsDragAllow] = useState(true)
  const [gridState, setGridState] = useState()
  const [style, setStyle] = useState({})

  useEffect(()=> {
    setStyle(prev => ({...part.style, position:'relative'}))
    setGridState(prev => part.style.gridTemplateColumns)
  }, [])

  useEffect(() => {
    setStyle(prev => ({...part.style, position:'relative'}))
  }, [ part.style, dispatch])

  const updateDynamicBlockStyle = (newStyle) => {
    // parentType === 'module' ? 
    // dispatch(updateStyleForPartInModule([parentData, part.position, newStyle]))
    // :
    // console.log('parent type is not support yet ', parentType)
  }

  const refBox = useRef(null);
  const refLeft = useRef(null);
  const refTopLeft = useRef(null);
  const refTop = useRef(null);
  const refTopRight = useRef(null);
  const refRight = useRef(null);
  const refBottomRight = useRef(null);
  const refBottom = useRef(null);
  const refBottomLeft = useRef(null);
  const minWidth = 140
  const maxWidth = 840
  const minHeight = 60
  const maxHeight = 840
  
  const refSeparator = useRef(null);


  const ref = useRef(null)
  const [{ handlerId }, drop] = useDrop({
    accept: ['component', 'part', 'level1','columns_holder'],
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    drop( ){
    },
    hover(item, monitor) {
      if (!ref.current ) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index
      if (dragIndex === hoverIndex) {
        return
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect()
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
      const clientOffset = monitor.getClientOffset()
      const hoverClientY = clientOffset.y - hoverBoundingRect.top
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }
      if(item.part !== undefined && item.parentType === 'module' && item.columnData === undefined){
        moveChildFromParent(dragIndex, hoverIndex, item.part)
        item.index = hoverIndex
      } 
    },
  })


  const [{isDragging}, drag] = useDrag({
    type: 'part',
    item: () => {
      return {  index, part, parentType, columnData }
    },
    canDrag: () => {
      return isDragAllow && chosenComponent.position=== part.position
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  })
  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  
  useEffect(() => {
    const resizableComponent = refBox.current;
    const size = {width: style.width ? parseInt(style.width) : 200, height: style.height ? parseInt(style.height) : 60};
    let proportions = {
      first: gridState ? parseFloat(gridState.split(' ')[0]) : 1,
      second: gridState ? parseFloat(gridState.split(' ')[2]) : 1
    };
    let newGridTemplate = ''
    let fr 
    const blockWidth = parseInt(resizableComponent.style.width) -3.2
    const frForHalf = blockWidth/ 2
    let width = parseInt( size.width, 10);
    let height = parseInt( size.height, 10);
    let x = 0;
    let y = 0;

    // Parts resize
    const onMouseMovePartsResize = (event) => {
      if(gridState && fr){
        const dx = event.clientX - x ;
        if(dx !== 0 ){
          const newFr = fr + dx
          proportions.first = newFr / frForHalf
          proportions.second = 2 - newFr / frForHalf
          newGridTemplate = `${proportions.first}fr 10px ${proportions.second}fr`
          setGridState(prev => newGridTemplate)
        }
      } 
    };
    const onMouseUpPartsResize = (event) => {
      if(newGridTemplate && newGridTemplate !== style.gridTemplateColumns){
        const newStyleProperties = {gridTemplateColumns: newGridTemplate}
        updateDynamicBlockStyle(newStyleProperties)
        setTimeout(()=> {
          changeClickability()
        }, [400])
        setIsDragAllow(prev => true)
      }
      document.removeEventListener("mousemove", onMouseMovePartsResize);
      document.removeEventListener("mouseup", onMouseUpPartsResize);
    };
    const onMouseDownPartsResize = (event) => {

      if(gridState){
        changeClickability()
        x = event.clientX;
        setIsDragAllow(prev => false)
        fr = ((blockWidth - 10) * proportions.first) / 2
        if(fr){
          document.addEventListener("mousemove", onMouseMovePartsResize);
          document.addEventListener("mouseup", onMouseUpPartsResize);
        }
      }
    };

    // Top Left resize
    const onMouseMoveTopLeftResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      width = width - dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height = height - dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };

    const onMouseUpTopLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopLeftResize);
      document.removeEventListener("mouseup", onMouseUpTopLeftResize);
    };
    const onMouseDownTopLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = size.right;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
      document.addEventListener("mouseup", onMouseUpBottomLeftResize);
    };
    // Top resize
    const onMouseMoveTopResize = (event) => {
      const dy = event.clientY - y;
      y = event.clientY;
      height = height - dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };
    const onMouseUpTopResize = (event) => {
      const newStyleProperties = {height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopResize);
      document.removeEventListener("mouseup", onMouseUpTopResize);
    };
    const onMouseDownTopResize = (event) => {
      changeClickability()

      y = event.clientY;
      const  size = window.getComputedStyle(resizableComponent);
      resizableComponent.style.bottom =  size.bottom;
      resizableComponent.style.top = null;
      document.addEventListener("mousemove", onMouseMoveTopResize);
      document.addEventListener("mouseup", onMouseUpTopResize);
    };

    // Top Right resize
    const onMouseMoveTopRightResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      width = width + dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height = height - dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };

    const onMouseUpTopRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveTopRightResize);
      document.removeEventListener("mouseup", onMouseUpTopRightResize);
    };
    const onMouseDownTopRightResize = (event) => {
      changeClickability()
      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  size.left;
      resizableComponent.style.right = null;
      document.addEventListener("mousemove", onMouseMoveTopRightResize);
      document.addEventListener("mouseup", onMouseUpTopRightResize);
    };

    // Right resize
    const onMouseMoveRightResize = (event) => {
      console.log('trigger ')
      const dx = event.clientX - x;
      x = event.clientX;
      width = width + dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
    };

    const onMouseUpRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width}
      if(resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveRightResize);
      document.removeEventListener("mouseup", onMouseUpRightResize);
    };
    const onMouseDownRightResize = (event) => {
      changeClickability()
        
      x = event.clientX;
      resizableComponent.style.left =  size.left;
      resizableComponent.style.right = null;
      document.addEventListener("mousemove", onMouseMoveRightResize);
      document.addEventListener("mouseup", onMouseUpRightResize);
    };
    // Bottom Right resize
    const onMouseMoveBottomRightResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      width = width + dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height = height + dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };

    const onMouseUpBottomRightResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomRightResize);
      document.removeEventListener("mouseup", onMouseUpBottomRightResize);
    };
    const onMouseDownBottomRightResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = null;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomRightResize);
      document.addEventListener("mouseup", onMouseUpBottomRightResize);
    };

    // Bottom resize
    const onMouseMoveBottomResize = (event) => {
      const dy = event.clientY - y;
      y = event.clientY;
      height = height + dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };
    const onMouseUpBottomResize = (event) => {
      const newStyleProperties = {height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomResize);
      document.removeEventListener("mouseup", onMouseUpBottomResize);
    };
    const onMouseDownBottomResize = (event) => {
      changeClickability()
      
      y = event.clientY;
      const  size = window.getComputedStyle(resizableComponent);
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomResize);
      document.addEventListener("mouseup", onMouseUpBottomResize);
    };
    // Bottom Left resize
    const onMouseMoveBottomLeftResize = (event) => {
      const dx = event.clientX - x;
      const dy = event.clientY - y;
      x = event.clientX;
      y = event.clientY;
      width = width - dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
      height = height + dy;
      height > maxHeight ? resizableComponent.style.height = maxHeight
      :
      height < minHeight ? resizableComponent.style.height = minHeight
      :
      resizableComponent.style.height = `${height}px`;
    };

    const onMouseUpBottomLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width, height: resizableComponent.style.height}
      if(resizableComponent.style.height !== style.height || resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveBottomLeftResize);
      document.removeEventListener("mouseup", onMouseUpBottomLeftResize);
    };
    const onMouseDownBottomLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      y = event.clientY;
      resizableComponent.style.left =  null;
      resizableComponent.style.right = size.right;
      resizableComponent.style.top =  size.top;
      resizableComponent.style.bottom = null;
      document.addEventListener("mousemove", onMouseMoveBottomLeftResize);
      document.addEventListener("mouseup", onMouseUpBottomLeftResize);
    };

    // Left resize
    const onMouseMoveLeftResize = (event) => {
      const dx = event.clientX - x;
      x = event.clientX;
      width = width - dx;
      width > maxWidth ? resizableComponent.style.width = maxWidth
      :
      width < minWidth ? resizableComponent.style.width = minWidth
      :
      resizableComponent.style.width = `${width}px`;
    };
    const onMouseUpLeftResize = (event) => {
      const newStyleProperties = {width: resizableComponent.style.width}
      if(resizableComponent.style.width !== style.width){
        updateDynamicBlockStyle(newStyleProperties)
      }
      setTimeout(()=> {
        changeClickability()
      }, [400])
      document.removeEventListener("mousemove", onMouseMoveLeftResize);
      document.removeEventListener("mouseup", onMouseUpLeftResize);
      
    };
    const onMouseDownLeftResize = (event) => {
      changeClickability()

      x = event.clientX;
      resizableComponent.style.right =  size.right;
      resizableComponent.style.left = null;
      document.addEventListener("mousemove", onMouseMoveLeftResize);
      document.addEventListener("mouseup", onMouseUpLeftResize);
    };
  
    // Add mouse down event listener
    const resizerParts = refSeparator.current;
    resizerParts.addEventListener("mousedown", onMouseDownPartsResize);

    const resizerTopLeft = refTopLeft.current;
    resizerTopLeft.addEventListener("mousedown", onMouseDownTopLeftResize);
    const resizerTop = refTop.current;
    resizerTop.addEventListener("mousedown", onMouseDownTopResize);
    const resizerTopRight = refTopRight.current;
    resizerTopRight.addEventListener("mousedown", onMouseDownTopRightResize);

    const resizerRight = refRight.current;
    resizerRight.addEventListener("mousedown", onMouseDownRightResize);

    const resizerBottomRight = refBottomRight.current;
    resizerBottomRight.addEventListener("mousedown", onMouseDownBottomRightResize);
    const resizerBottom = refBottom.current;
    resizerBottom.addEventListener("mousedown", onMouseDownBottomResize);
    const resizerBottomLeft = refBottomLeft.current;
    resizerBottomLeft.addEventListener("mousedown", onMouseDownBottomLeftResize);

    const resizerLeft = refLeft.current;
    resizerLeft.addEventListener("mousedown", onMouseDownLeftResize);
  
    return () => {
      resizerParts.addEventListener("mousedown", onMouseDownPartsResize);

      resizerTopLeft.removeEventListener("mousedown", onMouseDownTopLeftResize)
      resizerTop.removeEventListener("mousedown", onMouseDownTopResize);
      resizerTopRight.removeEventListener("mousedown", onMouseDownTopRightResize)

      resizerRight.removeEventListener("mousedown", onMouseDownRightResize);

      resizerBottomRight.removeEventListener("mousedown", onMouseDownBottomRightResize)
      resizerBottom.removeEventListener("mousedown", onMouseDownBottomResize);
      resizerBottomLeft.removeEventListener("mousedown", onMouseDownBottomLeftResize)

      resizerLeft.removeEventListener("mousedown", onMouseDownLeftResize);
    };
   }, [style]);

  useEffect(() => {
    const onDynamicBlockClick = (event) => {
      event.stopPropagation();
      dispatch(setChosenComponent([part]))
      setStylingComponent([part, parentData, parentType]);
      setStylingPanel(prev=> true)
    }

    const dynamicBlockRef = refBox.current;
    dynamicBlockRef.addEventListener("click", onDynamicBlockClick);

    return () => {
      dynamicBlockRef.removeEventListener("click", onDynamicBlockClick);
    };
  }, [])

  return (
    
  <div  className="resizable-container">
    <div ref={refBox} className={chosenComponent.position=== part.position ? "resizeable-box-chosen" : 'resizeable-box'}
      style={{height: style.height, width: style.width}}>
      <div ref={ref} style={{...style, cursor: 'grab', opacity, height: '100%', width:'100%', gridTemplateColumns: gridState}}>
        {
        part.content.length === 2 ? 
          (
            <>
              <StyleDynamicPart 
                key={part.content[0].position} part = {part.content[0]} index={index}
                moduleId={moduleId}
                parentData = {part.parent}
                holderData = {part.position}  
                parentType = {parentType}
                setStylingPanel={setStylingPanel}
                setStylingComponent={setStylingComponent}
                changeClickability={changeClickability}  
                setImageForm={setImageForm}
                setUploadImageData={setUploadImageData} 
                setUploadImageParent={setUploadImageParent} 
              />
              <div className="separator-block">
                <div className={chosenComponent.position === part.position ? "dynamic-block-separator-chosen": "dynamic-block-separator"}
                style={chosenComponent.position !== part.position ? {backgroundColor: style.outlineColor, width: style.outlineWidth}: {}}/>
                <div ref={refSeparator} className="dynamic-block-separator-toggle"
                 style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}
                 /> 
              </div>
              <StyleDynamicPart 
                key={part.content[1].position} part = {part.content[1]} index={index}
                moduleId={moduleId}
                parentData = {part.parent}
                holderData = {part.position} 
                parentType = {parentType}
                setStylingPanel={setStylingPanel}
                setStylingComponent={setStylingComponent} 
                changeClickability={changeClickability} 
                setImageForm={setImageForm}
                setUploadImageData={setUploadImageData} 
                setUploadImageParent={setUploadImageParent} 
              />
            </>
            )
        : ''
        } 
      </div>
      <div ref={refLeft} className="resizer resizer-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refTopLeft} className="resizer resizer-t-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTop} className="resizer resizer-t" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refTopRight} className="resizer resizer-t-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refRight} className="resizer resizer-r" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>

      <div ref={refBottomRight} className="resizer resizer-b-r" draggable="false" 
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottom} className="resizer resizer-b" draggable="false"  
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
      <div ref={refBottomLeft} className="resizer resizer-b-l" draggable="false"
      style={{visibility: chosenComponent.position === part.position ? 'visible' : 'hidden'}}/>
    </div>
    </div>
  )
}

export default StyleDynamicBlock
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux'
import {reset } from '../features/agreements/agreementSlice';
import file_add_icon from '../icons/file-add-drive-icon.svg'
import image_add_icon from '../icons/image-add-drive-icon.svg'
import LibraryHeader from '../components/LibraryHeader';
import { getUpToDateUser } from '../features/auth/authSlice';
import LibraryUploadForm from '../components/LibraryUploadForm';
import Spinner from '../components/Spinner';
import LibraryFileWindow from '../components/LibraryFileWindow';

const LibraryPage = ({setIsLibraryOpen, isFromWave1}) => {

	const navigate = useNavigate()
  const dispatch = useDispatch()

  const {user, isLoadingU} = useSelector(state => state.auth)
  const [isUserUpToDate, setIsUserUpToDate] = useState(false)
	const [userData, setUserData] = useState()
  const [userToken, setUserToken] = useState('')
  const [userRefreshToken, setUserRefreshToken] = useState('')
  const [uploadForm, setUploadForm] = useState(false)
	const [updateTrigger, setUpdateTrigger] = useState(false)
	
  const [fileWindow, setFileWindow] = useState(false)
	const [chosenFile, setChosenFile] = useState()

	const [chosenFilesType, setChosenFilesType] = useState({name: 'Documents', type: 'documents'})
	const [driveFiles, setDriveFiles] = useState([])

	const filesPerPage = 12;

	const [currentPage, setCurrentPage] = useState(1);

  const [indexOfLastFile, setIndexOfLastFile] = useState(filesPerPage);
  const [indexOfFirstFile, setIndexOfFirstFile] = useState(0);
  const [currentFiles, setCurrentFiles] = useState([])

  const [totalPages, setTotalPages] = useState(3);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
		setIndexOfLastFile(newPage * filesPerPage);
		setIndexOfFirstFile(indexOfLastFile - filesPerPage)
		setCurrentFiles(driveFiles.slice((newPage * filesPerPage) - filesPerPage, newPage * filesPerPage))
  };

	const openFileHandler = (file) => {
		setChosenFile(file);
		setFileWindow(true)
	}

	useEffect(()=> {
		if(!updateTrigger){
			dispatch(getUpToDateUser({_id: user._id}));
			// if(user.userLibrary){
			// 	setTimeout(()=>{
			// 		setDriveFiles(user.userLibrary)
			// 	}, [1000])
			// }
		}
	}, [updateTrigger])

  useEffect(() => {

    if (!user) {
      navigate('/login')
    }
		console.log(user)
		if(user){
			setUserData(user)
      if(!isUserUpToDate){
        dispatch(getUpToDateUser({_id: user._id}));
        setIsUserUpToDate(prev => true)
      }
			if(user.integrations){
				if(user.integrations[0] && user.integrations[0].name === 'Google Drive'){
					setUserToken(user.integrations[0].accessToken)
					setUserRefreshToken(user.integrations[0].refreshToken)
				}
			}
			console.log('user changed ', user)
			console.log('driveFiles changed ', driveFiles)
			if(user.userLibrary){
				setDriveFiles(user.userLibrary)
				setCurrentFiles(user.userLibrary.slice(indexOfFirstFile, indexOfLastFile))
				setTotalPages(Math.ceil(user.userLibrary.length / filesPerPage))
			}
		
			// setDriveFiles(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type))
			// setCurrentFiles(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type).slice(indexOfFirstFile, indexOfLastFile))
			// setTotalPages(Math.ceil(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type).length / filesPerPage))
		}

    return () => {
      dispatch(reset())
    }
  }, [user, navigate, dispatch, uploadForm])


	// useEffect(() => {
	// 	setDriveFiles(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type))
	// 	setCurrentFiles(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type).slice(indexOfFirstFile, indexOfLastFile))
	// 	setTotalPages(Math.ceil(user.userLibrary.filter(file => file.mimeType.split('/')[0] === chosenFilesType.type).length / filesPerPage))
	// }, [chosenFilesType])


	useEffect(() => {
		console.log('driveFiles : ', driveFiles)
	}, [driveFiles])

	// useEffect(() => {
	// 	dispatch(getUpToDateUser({_id: user._id}));
	// }, [uploadForm])

	useEffect(() => {
		if(user && user.userLibrary){
			setTimeout(()=>{
				setDriveFiles(user.userLibrary)
			}, [1000])
		}
	}, [user])

	return ( 
		<>
		<div className='wave1-content-board'>
			<LibraryHeader chosenFilesType={chosenFilesType} setChosenFilesType={setChosenFilesType} 
      setIsLibraryOpen={setIsLibraryOpen} isFromWave1={isFromWave1}/>
				{isLoadingU || updateTrigger ? <Spinner/> :
				user ? ( 
				<div className="library-page-content">
					<div className="settings-page-heading-part">
						<div className="settings-page-heading">{chosenFilesType.name}</div> 
						<div className="library-add-new-file" onClick={() => setUploadForm(true)}>
							<img src={file_add_icon} alt='add'/>
						</div>
					</div>	
						{
              user.userLibrary && user.userLibrary.length !== 0 ?
							<>
							<div className="library-page-body">
								{driveFiles.map((file, index) => (
									<div key={index} className="library-file-tile"  onClick={() => openFileHandler(file)}>
										{file.type.startsWith('image')?
										<>
											<div className='library-file-tile-image'><img style={{maxHeight: '48x'}} src={file.content} /></div>
											<div className='library-file-tile-text'>{file.name}</div>
										</>
										: <></>
										}
									</div>
								))}
							</div>
							
							{/* <div className="library-page-pagination">
								<button className='library-page-pagination-button' onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
									Previous
								</button>
								<span>{currentPage} of {totalPages}</span>
								<button className='library-page-pagination-button' onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
									Next
								</button>
							</div> */}
							</>
							: 
								<></>
						}
				</div>
				) : <div className="message">Log in to get access to page</div>}
		</div>
		<LibraryFileWindow active={fileWindow} setActive={setFileWindow} file={chosenFile}/>
		<LibraryUploadForm active={uploadForm} setActive={setUploadForm} setUpdateTrigger={setUpdateTrigger}
		userToken={userToken} userRefreshToken={userRefreshToken} user={userData}/>
		</>
		);
}
 
export default LibraryPage;